import { ELEMENT_TYPES, FlowCall, FlowElement, FlowElementTypes, FlowFormElement, OptionGroup } from 'prace-common-components'
import { Template } from 'store/api/templates/types'

export interface CallsState {
  call: FlowCall,
  editorSelector: {
    step: number,
    form: number,
    element: number,
    elements: number[],
    lastSelection: ELEMENT_TYPES.STEP | ELEMENT_TYPES.FORM | ELEMENT_TYPES.ELEMENT,
  },
  errorStatus: Nullable<number>,
  optionGroups: OptionGroup[],
  templates: Template[],
}

// ISOString in UTC e.g.: 2025-06-08T20:00:00
export type ISOString = string

export interface AddElement {
  text: string,
  id: string,
  elementType: FlowFormElement['elementType'],
}

export enum GROUP_TYPES {
  RESEARCH_FIELD = 'Research field',
  TEAM_MEMBERS = 'Team members',
  PARTITIONS = 'Partitions',
  PARTITIONS_ALLOCATION = 'Partitions Award',
  ADD_CODE = 'Add code',
  REVIEW_UPLOAD = 'Review Upload',
  SCIENTIFIC_REVIEWER = 'Scientific Reviewer',
}

//TODO: Take care of return typing, should depend on type
export type GetElementOptions = (type: FlowElementTypes | GROUP_TYPES | string, parentId: Nullable<FlowElement['id']>, groupOptions?: OptionGroup[]) => any
