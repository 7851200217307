import styled, { css } from 'styled-components'
import { NewButton } from 'prace-common-components'
import Grid from '@material-ui/core/Grid'

//TODO: Code repeated in other modals
export const $ModalContainer = styled.div`
  width: 800px;
`

export const $Button = styled(NewButton)`
  float: right;
`

export const $Grid = styled(Grid)`
  height: 320px;
  overflow-y: auto;
  &.MuiGrid-container {
    margin: ${({ theme }) => `${theme.spacing(4)} 0`};
    padding: 0 24px;
    .MuiGrid-item {
      max-width: 160px;
    }
  }
`

export const $CardContainer = styled(Grid)`
  position: relative;
`

export const $Counter = styled.div`
  position: absolute;
  display: flex;
  top: ${({ theme }) => theme.spacing(2)};
  right: ${({ theme }) => theme.spacing(1)};
  z-index: 1;
`

const buttonCounter = () => css`
  border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
`

const defaultCounter = () => css`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.8em;
`

export const $CounterIcon = styled.div<{button?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 0px 1px;
  ${({ button }) => button ? buttonCounter() : defaultCounter()};
`
