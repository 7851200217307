import React, { useImperativeHandle, useRef, useState, forwardRef } from 'react';
import { SvgIcon } from '../SvgIcon';
import { $Menu, $MenuItem } from './styles';
export const NestedMenuItem = forwardRef(function NestedMenuItem(props, ref) {
    const { parentMenuOpen, label, rightIcon = React.createElement(SvgIcon, { name: 'chevron-right', size: 20, color: '#757778' }), children, className, tabIndex: tabIndexProp, ContainerProps: ContainerPropsProp = {}, ...MenuItemProps } = props;
    const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;
    const menuItemRef = useRef(null);
    useImperativeHandle(ref, () => menuItemRef.current);
    const containerRef = useRef(null);
    useImperativeHandle(containerRefProp, () => containerRef.current);
    const menuContainerRef = useRef(null);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const handleMouseEnter = (event) => {
        setIsSubMenuOpen(true);
        if (ContainerProps?.onMouseEnter) {
            ContainerProps.onMouseEnter(event);
        }
    };
    const handleMouseLeave = (event) => {
        setIsSubMenuOpen(false);
        if (ContainerProps?.onMouseLeave) {
            ContainerProps.onMouseLeave(event);
        }
    };
    const isSubmenuFocused = () => {
        const active = containerRef.current?.ownerDocument?.activeElement;
        for (const child of menuContainerRef.current?.children ?? []) {
            if (child === active) {
                return true;
            }
        }
        return false;
    };
    const handleFocus = (event) => {
        if (event.target === containerRef.current) {
            setIsSubMenuOpen(true);
        }
        if (ContainerProps?.onFocus) {
            ContainerProps.onFocus(event);
        }
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Escape')
            return;
        if (isSubmenuFocused())
            e.stopPropagation();
        const active = containerRef.current?.ownerDocument?.activeElement;
        if (e.key === 'ArrowLeft' && isSubmenuFocused())
            containerRef.current?.focus();
        if (e.key === 'ArrowRight' &&
            e.target === containerRef.current &&
            e.target === active) {
            const firstChild = menuContainerRef.current?.children[0];
            firstChild?.focus();
        }
    };
    const open = isSubMenuOpen && parentMenuOpen;
    let tabIndex = undefined;
    if (!props.disabled) {
        tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
    }
    return (React.createElement("div", { ...ContainerProps, ref: containerRef, onFocus: handleFocus, tabIndex: tabIndex, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, onKeyDown: handleKeyDown },
        React.createElement($MenuItem, { ...MenuItemProps, open: open, ref: menuItemRef },
            label,
            rightIcon),
        React.createElement($Menu, { style: { pointerEvents: 'none' }, anchorEl: menuItemRef.current, anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            }, open: open, autoFocus: false, disableAutoFocus: true, disableEnforceFocus: true, onClose: () => {
                setIsSubMenuOpen(false);
            } },
            React.createElement("div", { ref: menuContainerRef, style: { pointerEvents: 'auto' } }, children))));
});
