import React, { useState } from 'react';
import { $KeyboardDateTimePicker, $KeyboardDatePicker, $FormControl } from './styles';
import { $Italic } from '../newTypography';
import { $InputLabel } from '../newInput';
export const NewDatePicker = ({ withTime, value = null, onChange, name, InputProps, title, description, error, errorMsg, min, max, disabled, hideItalic, type, ...rest }) => {
    const stringValue = isNaN(value) ? String(value) : value;
    const isValid = value != null && String(stringValue).length > 0;
    const Component = withTime ? $KeyboardDateTimePicker : $KeyboardDatePicker;
    const [open, setOpen] = useState(false);
    const handleDateChange = (date) => {
        if (onChange)
            onChange(name, date?.toISOString() || '');
    };
    const handleOpen = (disabled) => {
        if (!disabled)
            setOpen(true);
    };
    return (React.createElement($FormControl, null,
        title &&
            React.createElement($InputLabel, { disableAnimation: true, shrink: true },
                title + (rest.required ? '*' : ''),
                !hideItalic &&
                    React.createElement($Italic, null, type)),
        React.createElement(Component, { ...rest, minDate: min, maxDate: max, helperText: errorMsg ? errorMsg : description, format: withTime ? 'dd-MM-yyyy HH:mm' : 'dd-MM-yyyy', open: open, inputVariant: 'outlined', onOpen: () => handleOpen(disabled), onClose: () => setOpen(false), value: isValid ? new Date(stringValue) : null, onChange: handleDateChange, error: error, disabled: disabled, clearable: true, InputProps: {
                readOnly: true,
                onClick: () => handleOpen(disabled),
                name,
                disabled,
                ...InputProps,
            } })));
};
