import { Routes } from 'constants/global'
import { NotificationType } from 'prace-common-components'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useConfirmEmailMutation } from 'store/api/auth'
import { useAppDispatch } from 'store/hooks'

export const ValidateAdmin: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [confirmEmail] = useConfirmEmailMutation()
  const { token }: { token?: string } = useParams()

  useEffect(() => {
    const validateToken = async (email: string, token: string) => {
      try {
        await confirmEmail({ email, token }).unwrap()
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'You can now define a password and login!' } })
        navigate(Routes.FORGOT_PASSWORD)
      } catch (err) {
        console.log(err)
      }
    }
    if (token) {
      try {
        const {email, token: emailToken } = JSON.parse(atob(token))
        if (email && emailToken) {
          validateToken(email, emailToken).catch((err) => console.log(err))
        }
      } catch (err) {
        console.log(err)
      }
    }
  }, [confirmEmail, dispatch, navigate, token])

  return null
}
