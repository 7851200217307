export const getFlowElement = (call, flowElementid) => {
    let foundPath = {
        step: null,
        form: null,
        element: null,
        groupElement: null,
    };
    if (flowElementid !== null) {
        call.flow.every((flowStep) => {
            if (flowStep.id == flowElementid) {
                foundPath = { step: flowStep, form: null, element: null, groupElement: null };
                return false;
            }
            return flowStep.flow?.every((flowForm) => {
                if (flowForm.id == flowElementid) {
                    foundPath = { step: flowStep, form: flowForm, element: null, groupElement: null };
                    return false;
                }
                return flowForm.flow?.every((flowElement) => {
                    const found = flowElement.id == flowElementid;
                    if (found) {
                        foundPath = {
                            step: flowStep,
                            form: flowForm,
                            element: flowElement,
                            groupElement: flowElement.flow?.length ? flowElement : null,
                        };
                        return false;
                    }
                    else {
                        if (flowElement.flow?.length) {
                            const childElement = flowElement.flow?.find((groupEl) => groupEl.id == flowElementid);
                            if (childElement) {
                                foundPath = {
                                    step: flowStep,
                                    form: flowForm,
                                    element: childElement,
                                    groupElement: flowElement,
                                };
                                return false;
                            }
                        }
                    }
                    return true;
                });
            });
        });
    }
    return foundPath;
};
