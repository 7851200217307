import styled from 'styled-components'
import { $Badge, SvgIcon } from 'prace-common-components'

type OptionProps = {
  direction?: 'column' | 'row',
}

export const $Calls = styled.div`
  flex: 1;
  max-width: 1400px;
  padding: 50px 25px;
  font-family: roboto-light, sans-serif;
  width: 100%;
  margin: 0 auto;
`

export const $SvgIcon = styled(SvgIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`

export const $Option = styled.div<OptionProps>`
  display: flex;
  flex-direction: ${({direction}) => direction || 'column'};
  width: 400px;
  margin: 10px 5px 10px 5px;
`

export const $Label = styled.p`
  color: ${({theme}) => theme.colors.greyScales[500]};
  font-size: 12px;
  margin-bottom: 10px;
`

export const $Chip = styled($Badge)`
  margin: 0;
  max-height: 25px;
  border-radius: 15px;
  padding: ${({ theme }) => `0 ${theme.spacing(1)}`};
`

export const $ChipContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  .simplebar-content-wrapper {
    display: flex;
    align-items: center;
  }
`

export const $RowContainer = styled.div`
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
  >* {
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
`
