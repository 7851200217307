import { ROLES, User, UserStatus } from 'prace-common-components'
import { UpdateUserDetailsPayload } from 'store/api/users/types'

export interface UserDetailsProps {
  open?: boolean,
  user: Nullable<User>,
  handleClose: () => void,
  onChangeUserDetails: (evt: UpdateUserDetailsPayload) => void,
}

export interface DetailsProps {
  user: User,
  onChangeRole: (value: ROLES) => void,
}

export interface NotesProps {
  user: User,
  onChangeUserRemarks: OnChangeUserDetails<string>,
  onChangeUserTags: OnChangeUserDetails<string>,
  onChangeStatus: (name: string, value: Extract<UserStatus, 'blocked' | 'active'>) => void,
}

export interface BankProps {
  user: User,
}

export const targetFields = {
  TAGS: 'tags',
  USER_REMARKS: 'userRemarks',
  STATUS: 'status',
  ROLE: 'role',
} as const

export const Tabs = {
  USER_DETAILS: 0,
  TAGS_AND_NOTES: 1,
  BANK_DETAILS: 2,
} as const

export type TabId = typeof Tabs[keyof typeof Tabs]

export type Tab = {
  id: TabId,
  label: string,
}

export const tabs: Tab[] = [
  {
    id: Tabs.USER_DETAILS,
    label: 'User Details',
  },
  {
    id: Tabs.TAGS_AND_NOTES,
    label: 'Tags & Notes',
  },
  {
    id: Tabs.BANK_DETAILS,
    label: 'Bank Details',
  },
]

export type TargetField = typeof targetFields[keyof typeof targetFields]

export type OnChangeUserDetails<T extends TargetField | string = TargetField> =
  T extends 'role' ? (value: ROLES) => void :
    T extends 'userRemarks' ? (value: string) => void :
      T extends 'tags' ? (value: string[]) => void :
        T extends 'status' ? (value: UserStatus) => void :
          (name: string, value: unknown) => void

export type MutableUser = UserDetailsProps['user'] & { touched?: boolean }

