/* eslint-disable no-param-reassign */
import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { CallsState } from './types'
import { callsApi } from 'store/api/calls'
import { Automation, ELEMENT_TYPES, FlowCall, FlowForm, FlowFormElement, FlowStep, PREVIEW_TYPE, removeHiddenElements } from 'prace-common-components'
import { optionsApi } from 'store/api/options'
import { templateApi } from 'store/api/templates'
import { elementsApi } from 'store/api/calls/elements'

const emptyForm = {
  id: 2,
  order: 0,
  parentId: 1,
  title: '',
  description: '',
  type: ELEMENT_TYPES.FORM,
  permissions: {
    inherit: false,
    export: [],
    visibility: [],
    dataVisibility: [],
  },
  effects: [],
  automations: [],
  flow: [],
}

const emptyStep = {
  id: 1,
  callId: 1,
  order: 0,
  title: '',
  description: '',
  type: ELEMENT_TYPES.STEP,
  permissions: {
    inherit: false,
    export: [],
    visibility: [],
    assign: [],
    assignable: [],
  },
  effects: [],
  automations: [],
  timeframes: [],
  config: {
    hasAdminCheck: false,
    isUnlockable: false,
    coi: '',
    showCoi: false,
    submitConfirmation: false,
    hasLead: false,
    maxAssignments: null,
    minAssignments: null,
    automaticAcceptance: false,
  },
  flow: [emptyForm],
}

const initialState: CallsState = {
  call: {
    id: 1,
    uid: '',
    createdAt: '',
    updatedAt: '',
    allowedToDownload: [],
    unconditionalVisibility: [],
    title: '',
    description: '',
    published: false,
    cutoffs: [],
    logo: null,
    flow: [emptyStep],
  },
  editorSelector: {
    step: 0,
    form: 0,
    element: 0,
    elements: [],
    lastSelection: ELEMENT_TYPES.STEP as ELEMENT_TYPES,
  },
  optionGroups: [],
  templates: [],
  errorStatus: null,
}

const updateCall = createAction<{call?: Partial<FlowCall>; newStepSelect?: number}, 'updateCall'>('updateCall')
const updateStep = createAction<{newValues: Partial<FlowStep> & {id: FlowStep['id']}; newFormSelect?: number}, 'updateStep'>('updateStep')
const updateForm = createAction<{newValues: Partial<FlowForm> & {id: FlowForm['id']}; newElementSelect?: number}, 'updateForm'>('updateForm')
const updateElement = createAction<{newValues: Partial<FlowFormElement> & {id: FlowFormElement['id']}}, 'updateElement'>('updateElement')
const updateGroupElement = createAction<{groupId: FlowFormElement['id']; newValues: Partial<FlowFormElement> & {id: FlowFormElement['id']}}, 'updateGroupElement'>('updateGroupElement')
const selectElements = createAction<{order: number}, 'selectElements'>('selectElements')
const selectElement = createAction<{type: ELEMENT_TYPES; order: number}, 'selectElement'>('selectElement')
const addSteps = createAction<{newElements: FlowStep[]}, 'addSteps'>('addSteps')
const addForms = createAction<{stepId: FlowStep['id']; newElements: FlowForm[]}, 'addForms'>('addForms')
const addElements = createAction<{stepId: FlowStep['id']; formId: FlowForm['id']; newElements: FlowFormElement[]}, 'addElements'>('addElements')
const deleteStep = createAction<{stepId: FlowStep['id']}, 'deleteStep'>('deleteStep')
const deleteForm = createAction<{formId: FlowForm['id']}, 'deleteForm'>('deleteForm')
const deleteElements = createAction<{ids: FlowFormElement['id'][]}, 'deleteElements'>('deleteElements')
const updateAutomations = createAction<{stepId: FlowStep['id']; automations?: Automation[]}, 'updateAutomations'>('updateAutomations')

const slice = createSlice({
  name: 'calls',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateCall, (state, { payload }) => {
      state.call = removeHiddenElements(payload.call ? { ...state.call, ...payload.call } : { ...state.call })
      if(payload.newStepSelect !== undefined)
        state.editorSelector = {
          lastSelection: ELEMENT_TYPES.STEP,
          step: payload.newStepSelect,
          form: 0,
          element: 0,
          elements: [],
        }
    })
    builder.addCase(updateStep, (state, { payload }) => {
      const stepIdx = state.call.flow.findIndex((step: FlowStep) => step.id === payload.newValues.id)
      state.call.flow[stepIdx] = {...state.call.flow[stepIdx], ...payload.newValues}
      if(payload.newFormSelect !== undefined) 
        state.editorSelector = {
          lastSelection: ELEMENT_TYPES.FORM,
          step: state.editorSelector.step,
          form: payload.newFormSelect,
          element: 0,
          elements: [],
        }
    })
    builder.addCase(updateForm, (state, { payload }) => {
      //TODO: We should receive the step id from payload
      const stepIdx = state.editorSelector.step
      const formIdx = state.call.flow[stepIdx].flow.findIndex((form: FlowForm) => form.id === payload.newValues.id)
      state.call.flow[stepIdx].flow[formIdx] = {...state.call.flow[stepIdx].flow[formIdx], ...payload.newValues}
      if(payload.newElementSelect !== undefined)
        state.editorSelector = {
          ...state.editorSelector,
          lastSelection: ELEMENT_TYPES.ELEMENT,
          element: payload.newElementSelect,
          elements: [],
        }
    })
    builder.addCase(updateElement, (state, { payload }) => {
      //TODO: We should receive the step and form ids from payload
      const stepIdx = state.editorSelector.step
      const formIdx = state.editorSelector.form
      if(payload.newValues) {
        const elementIdx = state.call.flow[stepIdx].flow[formIdx].flow
          .findIndex((element: FlowFormElement) => element.id === payload.newValues.id)
        state.call.flow[stepIdx].flow[formIdx].flow[elementIdx] = {
          ...state.call.flow[stepIdx].flow[formIdx].flow[elementIdx],
          ...payload.newValues,
        }
      } else {
        const elementIdx = state.editorSelector.element
        state.call.flow[stepIdx].flow[formIdx].flow[elementIdx] = 
        { ...state.call.flow[stepIdx].flow[formIdx].flow[elementIdx] }
      }
    })
    builder.addCase(updateGroupElement, (state, { payload }) => {
      //TODO: We should receive the step, form ids from payload
      const stepIdx = state.editorSelector.step
      const formIdx = state.editorSelector.form
      const elementIdx = state.call.flow[stepIdx].flow[formIdx].flow.findIndex(
        (element: FlowFormElement) => element.id === payload.groupId)

      const groupElementIdx = state.call.flow[stepIdx].flow[formIdx].flow[elementIdx].flow
        .findIndex((groupEl: FlowFormElement) => groupEl.id === payload.newValues.id)
      state.call.flow[stepIdx].flow[formIdx].flow[elementIdx].flow[groupElementIdx] = 
          {...state.call.flow[stepIdx].flow[formIdx].flow[elementIdx].flow[groupElementIdx], ...payload.newValues }
    })
    builder.addCase(updateAutomations, (state, { payload }) => {
      const stepIdx = state.call.flow.findIndex((step: FlowStep) => step.id === payload.stepId)
      state.call.flow[stepIdx].automations = payload.automations ?? state.call.flow[stepIdx].automations
    })
    builder.addCase(selectElements, (state, { payload }) => {
      const { order } = payload
      const { elements = [], element, lastSelection } = state.editorSelector
      let newElements = elements
      if(lastSelection === ELEMENT_TYPES.ELEMENT && !elements.includes(element)) newElements.push(element)
      /* If element already selected, remove it */
      if(elements.includes(order)) newElements = newElements.filter((element) => element !== order)
      else newElements.push(order)
      state.editorSelector = {
        ...state.editorSelector,
        elements: newElements.length < 2 ? [] : newElements,
        lastSelection: ELEMENT_TYPES.ELEMENT,
      }
    })
    builder.addCase(selectElement, (state, { payload }) => {
      const { type, order } = payload
      if(type !== ELEMENT_TYPES.ELEMENT)
        document.body.dispatchEvent(new Event('scrollToTop', {
          bubbles: true,
          cancelable: false,
          composed: false,
        }))
      state.editorSelector = {
        ...state.editorSelector,
        form: type === ELEMENT_TYPES.STEP ? 0 : state.editorSelector.form,
        [type]: order,
        elements: [],
        lastSelection: [ELEMENT_TYPES.STEP, ELEMENT_TYPES.FORM].includes(type) ?
          type : ELEMENT_TYPES.ELEMENT,
      }
    })
    builder.addCase(addSteps, (state, { payload }) => {
      const { newElements = [] } = payload
      const { order } = newElements[newElements.length - 1]
      state.editorSelector = {
        ...state.editorSelector,
        step: order,
        form: 0,
        lastSelection: ELEMENT_TYPES.STEP,
      }
      state.call.flow.push(...newElements)
    })
    builder.addCase(addForms, (state, { payload }) => {
      const { stepId, newElements = [] } = payload
      const stepIdx = state.call.flow.findIndex((step: FlowStep) => step.id === stepId)
      const { order } = newElements[newElements.length - 1]
      state.editorSelector = {
        ...state.editorSelector,
        form: order,
        lastSelection: ELEMENT_TYPES.FORM,
      }
      state.call.flow[stepIdx].flow.push(...newElements)
    })
    builder.addCase(addElements, (state, { payload }) => {
      const { stepId, formId, newElements } = payload
      const stepIdx = state.call.flow.findIndex((step: FlowStep) => step.id === stepId)
      const formIdx = state.call.flow[stepIdx].flow.findIndex((form: FlowForm) => form.id === formId)
      const flow = state.call.flow[stepIdx].flow[formIdx].flow
      const { order } = newElements[newElements.length - 1]
      const insertElements: FlowFormElement[] =
        newElements.map((el) => ({ ...el, flow: el.flow || [] } as FlowFormElement))
      let newItems = flow.filter((item) => item.elementType !== PREVIEW_TYPE)
      newItems = [...newItems.slice(0, order), ...insertElements, ...newItems.slice(order)]
        .map((element, i) => ({ ...element, order: i }))
      state.editorSelector = {
        ...state.editorSelector,
        element: order,
        elements: [],
        lastSelection: ELEMENT_TYPES.ELEMENT,
      }
      state.call.flow[stepIdx].flow[formIdx].flow = newItems
    })
    builder.addCase(deleteStep, (state, { payload }) => {
      //Remove element and update the orders of every remaining element
      const newFlow = (state.call.flow || []).filter((step: FlowStep) => step.id !== payload.stepId)
        .map((step: FlowStep, i: number) => ({ ...step, order: i }))
      state.editorSelector = {
        ...state.editorSelector,
        step: 0,
        lastSelection: ELEMENT_TYPES.STEP,
      }
      state.call.flow = newFlow
    })
    builder.addCase(deleteForm, (state, { payload }) => {
      //Remove element and update the orders of every remaining element
      const stepIdx = state.editorSelector.step
      const newFlow = state.call.flow[stepIdx].flow
        .filter((form: FlowForm) => form.id !== payload.formId)
        .map((form: FlowForm, i: number) => ({ ...form, order: i }))
      state.editorSelector = {
        ...state.editorSelector,
        form: 0,
        lastSelection: ELEMENT_TYPES.FORM,
      }
      state.call.flow[stepIdx].flow = newFlow
    })
    builder.addCase(deleteElements, (state, { payload }) => {
      //Remove element and update the orders of every remaining element
      const stepIdx = state.editorSelector.step
      const formIdx = state.editorSelector.form
      const newFlow = state.call.flow[stepIdx].flow[formIdx].flow
        .filter((element: FlowFormElement) => !payload.ids.includes(element.id))
        .map((element: FlowFormElement, i: number) => ({ ...element, order: i }))
      state.editorSelector = {
        ...state.editorSelector,
        element: 0,
        elements: [],
        lastSelection: ELEMENT_TYPES.FORM,
      }
      state.call.flow[stepIdx].flow[formIdx].flow = newFlow
    })
    builder.addMatcher(callsApi.endpoints.getCall.matchFulfilled,(state, { payload }) => {
      state.editorSelector = initialState.editorSelector
      state.call = removeHiddenElements({ ...state.call, ...payload })
    })
    builder.addMatcher(optionsApi.endpoints.listOptions.matchFulfilled,(state, { payload }) => {
      state.optionGroups = payload.data
    })
    builder.addMatcher(templateApi.endpoints.getTemplates.matchFulfilled,(state, { payload }) => {
      state.templates = payload.data
    })
    builder.addMatcher(callsApi.endpoints.uploadCallLogo.matchFulfilled,(state, { payload }) => {
      state.call.logo = payload.filename
    })
    builder.addMatcher(callsApi.endpoints.deleteCallLogo.matchFulfilled,(state) => {
      state.call.logo = null
    })
    builder.addMatcher(elementsApi.endpoints.addAutomation.matchFulfilled,(state, { payload }) => {
      const stepIdx = state.call.flow.findIndex((step: FlowStep) => step.id === payload.id)
      const stepAutomations = state.call.flow[stepIdx].automations
      /* Remove deleted automation and update all orders */
      state.call.flow[stepIdx].automations = [...stepAutomations, payload.automation]
    })
    builder.addMatcher(elementsApi.endpoints.deleteAutomation.matchFulfilled,(state, { payload }) => {
      const stepIdx = state.call.flow.findIndex((step: FlowStep) => step.id === payload.stepId)
      const stepAutomations = state.call.flow[stepIdx].automations
      /* Remove deleted automation and update all orders */
      state.call.flow[stepIdx].automations = stepAutomations.filter(
        (auto: Automation) => auto.id !== payload.id).map(
        (automation: Automation, i: number) => ({...automation, order: i+1}) as Automation)
    })
    builder.addMatcher(optionsApi.endpoints.createOptionsGroup.matchFulfilled,(state, { payload }) => {
      state.optionGroups.push(payload)
    })
    builder.addMatcher(optionsApi.endpoints.deleteOptionsGroup.matchFulfilled,(state, { payload }) => {
      const { step, form, element } = state.editorSelector
      const currentElement = state.call.flow[step].flow[form].flow[element]
      state.call.flow[step].flow[form].flow[element] = { // Remove optionsGroup from current element if it is used
        ...currentElement,
        optionsGroup: currentElement.optionsGroup?.id === payload ? undefined : currentElement.optionsGroup,
        flow: currentElement.flow.map(
          (groupElement) => groupElement.optionsGroup?.id === payload ?
            { ...groupElement, optionsGroup: undefined } : groupElement),
      }
      state.optionGroups = state.optionGroups.filter((group) => group.id !== payload)
    })
    builder.addMatcher(
      isAnyOf(
        optionsApi.endpoints.getOptions.matchFulfilled,
        optionsApi.endpoints.updateOptionsGroup.matchFulfilled,
      ),(state, { payload }) => {
        const { step, form, element } = state.editorSelector
        const currentElement = state.call.flow[step].flow[form].flow[element]
        state.optionGroups = state.optionGroups.map((group) => group.id === payload.id ? payload : group)
        state.call.flow[step].flow[form].flow[element] = { // Update optionsGroup from current element if it is used
          ...currentElement,
          optionsGroup: currentElement.optionsGroup?.id === payload.id ? payload : currentElement.optionsGroup,
          flow: currentElement.flow.map(
            (groupElement) => groupElement.optionsGroup?.id === payload.id ?
              { ...groupElement, optionsGroup: payload } : groupElement),
        }
      })
  },
})

export const callsReducer = slice.reducer
