import { FlowFormElement, FlowForm, FlowStep, FlowCall } from 'prace-common-components'

export type ElementIds = {
  step: Nullable<FlowStep['id']>,
  form: Nullable<FlowForm['id']>,
  element: Nullable<FlowFormElement['id']>,
  groupElement: Nullable<FlowFormElement['id']>,
}

export function getElementDetails(
  call: FlowCall,
  flowElement: ElementIds,
) {
  const { step, form, element, groupElement } = flowElement
  const foundStep = call.flow.find((flowStep: FlowStep) => flowStep.id == step)
  let foundForm
  let foundElement
  let foundGroupElement
  if(foundStep) {
    foundForm = foundStep.flow.find((flowForm: FlowForm) => flowForm.id == form)
    if(foundForm) {
      foundElement = foundForm.flow.find((flowElement: FlowFormElement) => flowElement.id == element)
      if(foundElement) {
        foundGroupElement = foundElement.flow.find((flowElement: FlowFormElement) => flowElement.id == groupElement)
      }
    }
  }
  return {
    step: foundStep as FlowStep,
    form: foundForm as FlowForm,
    element: foundElement as FlowFormElement,
    groupElement: foundGroupElement as FlowFormElement,
  }
}
