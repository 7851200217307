import React from 'react';
import ReactDOM from 'react-dom';
import { $MobileModalContainer, $MenuRow, $Modal, $MenuContainer, $MenuContainerBottom, $BottomItem } from './styles';
import Grid from '@material-ui/core/Grid';
import { SvgIcon } from '../';
export const MobileMenu = ({ open, onClose, mobileMenu, userMenuItems }) => {
    const domElement = document.getElementById('root');
    if (!domElement)
        return null;
    return ReactDOM.createPortal(React.createElement($Modal, { open: open, onClose: onClose },
        React.createElement($MobileModalContainer, null,
            React.createElement(Grid, { container: true, alignItems: 'stretch' },
                React.createElement($MenuContainer, { item: true, container: true }, mobileMenu.map((item) => React.createElement($MenuRow, { item: true, xs: 12, key: item.label, onClick: item.onClick }, item.label))),
                React.createElement($MenuContainerBottom, { item: true, container: true }, userMenuItems.map((item) => React.createElement($MenuRow, { item: true, xs: 12, key: item.label, onClick: item.onClick },
                    React.createElement($BottomItem, null,
                        React.createElement(SvgIcon, { name: item.icon || 'renew', size: 26 }),
                        item.label))))))), domElement);
};
