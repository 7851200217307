import React from 'react';
import ReactDOM from 'react-dom';
import { $LoadingWrapper, $LoadingContainer } from './styles';
export const Loading = ({ loading }) => {
    const domElement = document.getElementById('root');
    if (!domElement || !loading)
        return null;
    return ReactDOM.createPortal(React.createElement($LoadingContainer, null,
        React.createElement($LoadingWrapper, null,
            React.createElement("span", { className: 'circle circle-1' }),
            React.createElement("span", { className: 'circle circle-2' }),
            React.createElement("span", { className: 'circle circle-3' }),
            React.createElement("span", { className: 'circle circle-4' }),
            React.createElement("span", { className: 'circle circle-5' }))), domElement);
};
