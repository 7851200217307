import React, { useState } from 'react';
import { $Collapse, $List, $ListItem, $ListItemText } from './styles';
import { ExpandIcon } from '../newIcons';
import { SvgIcon } from '../SvgIcon';
import { VerticalStepper } from '../newStepper';
export const CollapseFlow = ({ selectedCollapse, selectedElement, workingStep = 0, flow = [], handleSelect, handleAdd, stepper, }) => {
    const [open, setOpen] = useState(true);
    const handleSelectChange = (order, type) => {
        if (selectedCollapse.type === type && selectedCollapse.order === order)
            setOpen((open) => !open);
        else
            setOpen(true);
        handleSelect(order, type);
    };
    return React.createElement($List, { component: 'div', disablePadding: true, "$stepper": stepper },
        flow.map((item, i) => {
            const isItemSelected = i == selectedCollapse.order;
            const itemFlow = item.flow || [];
            const multipleForms = itemFlow.length > 1;
            return React.createElement("div", { key: item.id },
                React.createElement($ListItem, { button: true, onClick: () => handleSelectChange(i, selectedCollapse.type), "$stepper": stepper },
                    React.createElement(SvgIcon, { name: (!stepper || item.workingStep) ? 'stop' : 'stop-outlined', size: 16, color: stepper ? 'secondary' : 'primary' }),
                    React.createElement($ListItemText, { "$stepper": stepper, error: item.error, selected: isItemSelected, primary: item.title }),
                    multipleForms && !item.hideChilds && React.createElement(ExpandIcon, { expanded: isItemSelected && open })),
                !item.hideChilds && React.createElement($Collapse, { in: isItemSelected && open, timeout: 'auto', unmountOnExit: true }, stepper && itemFlow.length ?
                    React.createElement(VerticalStepper, { steps: itemFlow, multipleForms: multipleForms, activeStep: selectedElement.order, workingStep: i < workingStep ? itemFlow.length : 0, onChangeActiveStep: (order) => handleSelectChange(order, selectedElement.type) })
                    :
                        React.createElement($List, { component: 'div', disablePadding: true },
                            itemFlow.map((el, j) => {
                                const isElementSelected = j == selectedElement.order;
                                return React.createElement($ListItem, { key: el.id || j, button: true, nested: true, selected: isElementSelected, onClick: () => handleSelectChange(j, selectedElement.type) },
                                    React.createElement(SvgIcon, { name: 'circle', size: 10, color: stepper ? 'secondary' : 'primary' }),
                                    React.createElement($ListItemText, { inset: true, selected: isElementSelected, primary: el.title || '' }));
                            }),
                            handleAdd &&
                                React.createElement($ListItem, { button: true, nested: true, secondary: true, onClick: () => handleAdd(selectedElement.type, item.order), "$stepper": stepper },
                                    React.createElement(SvgIcon, { name: 'plus', size: 16, color: 'secondary' }),
                                    React.createElement($ListItemText, { inset: true, primary: `New ${selectedElement.type}` })))));
        }),
        handleAdd &&
            React.createElement($ListItem, { button: true, secondary: true, onClick: () => handleAdd(selectedCollapse.type, flow.length), "$stepper": stepper },
                React.createElement(SvgIcon, { name: 'plus', size: 16, color: 'secondary' }),
                React.createElement($ListItemText, { inset: true, primary: `New ${selectedCollapse.type}` })));
};
