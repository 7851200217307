import { FlowFormElement, ELEMENT_TYPES, FORM_ELEMENT_TYPES } from 'prace-common-components'
import { GROUP_TYPES, GetElementOptions } from 'store/calls/types'
import { getElement } from './getElement'

export const getElementOptions: GetElementOptions = (elementType, parentId, groupOptions) => {
  switch(elementType) {
    case ELEMENT_TYPES.STEP: {
      return {
        description: 'Step description',
        config: {
          required: true,
          hasAdminCheck: false,
          isUnlockable: false,
          showCoi: false,
          submitConfirmation: false,
          hasLead: false,
        },
      }
    }
    case ELEMENT_TYPES.FORM: {
      return {
        description: '',
      }
    }
    case FORM_ELEMENT_TYPES.NUMBER: {
      return {
        config: {
          max: null,
          min: null,
          required: true,
          decimal: false,
        },
        description: '',
      }
    }
    case FORM_ELEMENT_TYPES.EMAIL: {
      return {
        config: {
          required: true,
        },
        description: '',
      }
    }
    case FORM_ELEMENT_TYPES.TEXT_AREA: {
      return {
        description: '',
        config: {
          required: true,
          max: null,
          min: null,
        },
      }
    }
    case FORM_ELEMENT_TYPES.CHECKBOX: {
      return {
        config: {
          required: true,
        },
      }
    }
    case FORM_ELEMENT_TYPES.TITLE: {
      return {}
    }
    case FORM_ELEMENT_TYPES.INSTRUCTIONS: {
      return {
        description: 'Type your text here',
      }
    }
    case FORM_ELEMENT_TYPES.DATE: {
      return {
        config: {
          required: true,
          max: null,
          min: null,
        },
        description: '',
      }
    }
    case FORM_ELEMENT_TYPES.PHONE_NUMBER: {
      return {
        config: {
          required: true,
        },
      }
    }
    case FORM_ELEMENT_TYPES.UPLOAD: {
      return {
        config: {
          required: true,
        },
        description: '',
      }
    }
    case FORM_ELEMENT_TYPES.TOGGLE:
    case FORM_ELEMENT_TYPES.SELECT:
    case FORM_ELEMENT_TYPES.SELECT_PARTITION: //Partitions are retrieved from BE on use
    case FORM_ELEMENT_TYPES.PARTITION_RESOURCES:
    case FORM_ELEMENT_TYPES.MULTISELECT: {
      return {
        items: [],
        config: {
          required: true,
        },
      }
    }
    case GROUP_TYPES.RESEARCH_FIELD: {
      return {
        title: 'Research Field',
        description: '',
        config: {
          required: false,
        },
        flow: [
          getElement<FlowFormElement>(null, parentId, 0, FORM_ELEMENT_TYPES.TEXT, { title: 'Title' }),
          getElement<FlowFormElement>(null, parentId, 1, FORM_ELEMENT_TYPES.TEXT, { title: 'Sub-title' }),
          getElement<FlowFormElement>(null, parentId, 2, FORM_ELEMENT_TYPES.NUMBER, { title: 'Share (%)', config: {min: 0, max: 100} }),
        ],
      }
    }
    case GROUP_TYPES.TEAM_MEMBERS: {
      return {
        title: 'Team Members',
        description: '',
        config: {
          required: false,
        },
        flow: [
          /* Gender */
          getElement<FlowFormElement>(null, parentId, 0, FORM_ELEMENT_TYPES.SELECT, { title: 'Gender', optionsGroup: { tag: 'Gender'} }, groupOptions),
          getElement<FlowFormElement>(null, parentId, 1, FORM_ELEMENT_TYPES.TEXT, { title: 'Title' }),
          getElement<FlowFormElement>(null, parentId, 2, FORM_ELEMENT_TYPES.TEXT, { title: 'First name' }),
          getElement<FlowFormElement>(null, parentId, 3, FORM_ELEMENT_TYPES.TEXT, { title: 'Last name' }),
          getElement<FlowFormElement>(null, parentId, 4, FORM_ELEMENT_TYPES.TEXT, { title: 'Initials' }),
          getElement<FlowFormElement>(null, parentId, 5, FORM_ELEMENT_TYPES.DATE, { title: 'Date of birth' }), //TODO: Handle Date of Birth
          getElement<FlowFormElement>(null, parentId, 6, FORM_ELEMENT_TYPES.EMAIL, { title: 'Email' }),
          /* Nationality */
          getElement<FlowFormElement>(null, parentId, 7, FORM_ELEMENT_TYPES.SELECT, { title: 'Nationality', optionsGroup: { tag: 'Nationalities'} }, groupOptions),
          getElement<FlowFormElement>(null, parentId, 8, FORM_ELEMENT_TYPES.PHONE_NUMBER, { title: 'Phone number' }),
          getElement<FlowFormElement>(null, parentId, 9, FORM_ELEMENT_TYPES.TEXT, { title: 'Job title' }),
          getElement<FlowFormElement>(null, parentId, 10, FORM_ELEMENT_TYPES.TEXT, { title: 'Website' }),
          getElement<FlowFormElement>(null, parentId, 11, FORM_ELEMENT_TYPES.TEXT, { title: 'Organisation name' }),
          getElement<FlowFormElement>(null, parentId, 12, FORM_ELEMENT_TYPES.TEXT, { title: 'Organisation department' }),
          getElement<FlowFormElement>(null, parentId, 13, FORM_ELEMENT_TYPES.TEXT, { title: 'Organisation group' }),
          getElement<FlowFormElement>(null, parentId, 14, FORM_ELEMENT_TYPES.TEXT, { title: 'Organisation address' }),
          getElement<FlowFormElement>(null, parentId, 15, FORM_ELEMENT_TYPES.TEXT, { title: 'Organisation postal code' }),
          getElement<FlowFormElement>(null, parentId, 16, FORM_ELEMENT_TYPES.TEXT, { title: 'Organisation city' }),
          /* Organisation country */
          getElement<FlowFormElement>(null, parentId, 17, FORM_ELEMENT_TYPES.SELECT, { title: 'Organisation country', optionsGroup: { tag: 'Countries'} }, groupOptions),
        ],
      }
    }
    case GROUP_TYPES.PARTITIONS: {
      return {
        title: 'Partitions',
        description: '',
        config: {
          required: false,
        },
        flow: [
          getElement<FlowFormElement>(null, parentId, 0, FORM_ELEMENT_TYPES.SELECT_PARTITION, { title: 'Partition name', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 1, FORM_ELEMENT_TYPES.MULTITEXT, { title: 'Code(s) used', description: 'Add codes separated by \',\'', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 2, FORM_ELEMENT_TYPES.PARTITION_RESOURCES, { title: 'Requested amount of resources 1st year in core hours', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 3, FORM_ELEMENT_TYPES.NUMBER, { title: 'Number of jobs simultaneously', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 4, FORM_ELEMENT_TYPES.NUMBER, { title: 'Wall clock time of a typical job execution (h)', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 5, FORM_ELEMENT_TYPES.TOGGLE, { title: 'Are you able to write checkpoint?', config: {required: true}, optionsGroup: { tag: 'Yes/No'} }, groupOptions),
          getElement<FlowFormElement>(null, parentId, 6, FORM_ELEMENT_TYPES.NUMBER, { title: 'Maximum time between 2 checkpoints (h)', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 7, FORM_ELEMENT_TYPES.NUMBER, { title: 'Minimum # cores', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 8, FORM_ELEMENT_TYPES.NUMBER, { title: 'Average # cores', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 9, FORM_ELEMENT_TYPES.NUMBER, { title: 'Maximum # cores', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 10, FORM_ELEMENT_TYPES.NUMBER, { title: 'Minimum job memory (total usage over all cores GB)', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 11, FORM_ELEMENT_TYPES.NUMBER, { title: 'Average job memory (total usage over all cores GB)', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 12, FORM_ELEMENT_TYPES.NUMBER, { title: 'Maximum job memory (total usage over all cores GB)', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 13, FORM_ELEMENT_TYPES.NUMBER, { title: 'Maximum amount of SCRATCH needed at a time (GB)', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 14, FORM_ELEMENT_TYPES.NUMBER, { title: 'Maximum amount of WORK needed at a time (GB)', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 15, FORM_ELEMENT_TYPES.NUMBER, { title: 'Maximum amount of HOME needed at a time (GB)', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 16, FORM_ELEMENT_TYPES.NUMBER, { title: 'Maximum amount of ARCHIVE needed at a time (GB)', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 17, FORM_ELEMENT_TYPES.NUMBER, { title: 'Maximum # files to be stored on SCRATCH (thousands)', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 18, FORM_ELEMENT_TYPES.NUMBER, { title: 'Maximum # files to be stored on WORK (thousands)', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 19, FORM_ELEMENT_TYPES.NUMBER, { title: 'Maximum # files to be stored on HOME (thousands)', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 20, FORM_ELEMENT_TYPES.NUMBER, { title: 'Maximum # files to be stored on ARCHIVE (thousands)', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 21, FORM_ELEMENT_TYPES.NUMBER, { title: 'Total amount of data to transfer to/from (GB)', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 22, FORM_ELEMENT_TYPES.INSTRUCTIONS, { title: 'Notice', description: 'In case of applying to LUMI-C please note that it is mandatory to define hours needed for the project execution. If applying to other systems, this field may be filled in but it is not mandatory.' }),
          getElement<FlowFormElement>(null, parentId, 23, FORM_ELEMENT_TYPES.NUMBER, { title: 'Requested amount of storage hours' }),
          getElement<FlowFormElement>(null, parentId, 24, FORM_ELEMENT_TYPES.TEXT_AREA, { title: 'Justification of data transfer', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 25, FORM_ELEMENT_TYPES.TEXT_AREA, { title: 'I/O Strategy', config: {required: true} }),
          getElement<FlowFormElement>(null, parentId, 26, FORM_ELEMENT_TYPES.TEXT, { title: 'I/O data trafic R/W per hour' }),
          getElement<FlowFormElement>(null, parentId, 27, FORM_ELEMENT_TYPES.TEXT, { title: 'I/O files generated per hour' }),
        ],
      }
    }
    case GROUP_TYPES.PARTITIONS_ALLOCATION: {
      return {
        title: 'Partitions Award',
        description: '',
        config: {
          required: false,
        },
        flow: [
          /* Partition */
          getElement<FlowFormElement>(null, parentId, 0, FORM_ELEMENT_TYPES.SELECT_PARTITION, { title: 'Partition' }, groupOptions),
          getElement<FlowFormElement>(null, parentId, 1, FORM_ELEMENT_TYPES.NUMBER, { title: 'Number of core hours' }), // Was Partition Resources
          getElement<FlowFormElement>(null, parentId, 2, FORM_ELEMENT_TYPES.NUMBER, { title: 'Conversion rate node - core hours' }), // Was Partition Resources
        ],
      }
    }
    case GROUP_TYPES.ADD_CODE: {
      return {
        title: 'Add Code',
        description: '',
        config: {
          required: false,
        },
        flow: [
          getElement<FlowFormElement>(null, parentId, 0, FORM_ELEMENT_TYPES.TEXT, { title: 'Code' }),
        ],
      }
    }
    case GROUP_TYPES.REVIEW_UPLOAD: {
      return {
        title: 'Review Upload',
        description: '',
        config: {
          required: false,
        },
        flow: [
          getElement<FlowFormElement>(null, parentId, 0, FORM_ELEMENT_TYPES.UPLOAD, { title: 'Add .pdf file to review' }),
        ],
      }
    }
    case GROUP_TYPES.SCIENTIFIC_REVIEWER: {
      return {
        title: 'Scientific reviewer',
        description: '',
        config: {
          required: false,
        },
        flow: [
          getElement<FlowFormElement>(null, parentId, 1, FORM_ELEMENT_TYPES.TEXT, { title: 'First name' }),
          getElement<FlowFormElement>(null, parentId, 2, FORM_ELEMENT_TYPES.TEXT, { title: 'Last name' }),
          getElement<FlowFormElement>(null, parentId, 3, FORM_ELEMENT_TYPES.TEXT, { title: 'Affiliation' }),
          getElement<FlowFormElement>(null, parentId, 4, FORM_ELEMENT_TYPES.EMAIL, { title: 'Email' }),
          getElement<FlowFormElement>(null, parentId, 5, FORM_ELEMENT_TYPES.TEXT, { title: 'Webpage' }),
          getElement<FlowFormElement>(null, parentId, 6, FORM_ELEMENT_TYPES.TOGGLE, { title: 'I have personally contacted this person already and got a positive feedback', optionsGroup: { tag: 'Yes/No'} }, groupOptions),
          getElement<FlowFormElement>(null, parentId, 7, FORM_ELEMENT_TYPES.TOGGLE, { title: 'I made a preliminary Col assessment and found no issue', optionsGroup: { tag: 'Yes/No'} }, groupOptions),
        ],
      }
    }
    default: { /* text fields */
      return {
        config: {
          required: true,
        },
        description: '',
      }
    }
  }
}
