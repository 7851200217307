import React, { FC, PropsWithChildren, useCallback, useEffect, useRef, useState, KeyboardEvent } from 'react'
import { Range, Editor, Transforms, Descendant } from 'slate'
import { Editable, ReactEditor, Slate } from 'slate-react'
import { getFlowElement, FlowCall } from 'prace-common-components'
import { CustomElement } from './custom-types'
import { Variable } from './components/Variable'
import { SubjectSlateEditorProps, ValidatedVariable } from './types'
import ReactDOM from 'react-dom'
import { $VariableAutoComplete, $VariableOption, $SingleLine, $Label } from './styles'
import { insertVariable } from './EditorController'

const Element: FC<PropsWithChildren<{
  attributes: {
    'data-slate-node': 'element',
    'data-slate-inline'?: true,
    'data-slate-void'?: true,
    dir?: 'rtl',
    ref: React.RefObject<any>,
  },
  element: CustomElement,
  call: FlowCall,
}>> = ({ attributes, children, element, call }) => {
  switch (element.type) {
    case 'variable': {
      const variableEl = getFlowElement(call, element.element)
      let tooltipText = ''
      //TODO: If there is no element, have the tooltip warn the user about it
      if(variableEl.element) tooltipText = `${variableEl.step?.title} > ${variableEl.form?.title} > ${variableEl.element?.title}`
      return <Variable attributes={attributes} tooltipText={tooltipText} element={element}>{children}</Variable>
    }
    case 'paragraph':
      return <p {...attributes}>{children}</p>
    default:
      return <span {...attributes}>{children}</span>
  }
}

//TODO: Very similar to BodySlate, maybe just one component?
export const SubjectSlate: FC<SubjectSlateEditorProps> = (
  { editor, value, chars, onChangeState, onChangeSearch, focusEditor, call },
) => {
  //TODO: Proper typing
  const renderElement = useCallback((props: any) => <Element {...props} call={call} />, [call])
  const ref = useRef<HTMLDivElement>(null)
  const [target, setTarget] = useState<NullableOptional<Range>>()
  const [index, setIndex] = useState(0)

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (target) {
        switch (e.key) {
          case 'ArrowDown': {
            e.preventDefault()
            const prevIndex = index >= chars.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          }
          case 'ArrowUp': {
            e.preventDefault()
            const nextIndex = index <= 0 ? chars.length - 1 : index - 1
            setIndex(nextIndex)
            break
          }
          case 'Tab':
          case 'Enter':
            e.preventDefault()
            Transforms.select(editor, target)
            insertVariable(editor, chars[index].name, chars[index].id, chars[index])
            setTarget(null)
            break
          case 'Escape':
            e.preventDefault()
            setTarget(null)
            break
        }
      } else {
        // This lets the user step into and out of the inline without stepping over characters.
        switch (e.key) {
          case 'ArrowLeft': {
            e.preventDefault()
            Transforms.move(editor, { unit: 'offset', reverse: true })
            break
          }
          case 'ArrowRight': {
            e.preventDefault()
            Transforms.move(editor, { unit: 'offset' })
            break
          }
        }
      }
    },
    [chars, editor, index, target],
  )

  useEffect(() => {
    if (target && chars.length > 0) {
      const el = ref.current
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      if(el) {
        el.style.top = `${rect.top + window.pageYOffset + 24}px`
        el.style.left = `${rect.left + window.pageXOffset}px`
      }
    }
  }, [chars.length, editor, index, target])

  return (
    <>
      <$Label>
        Email Subject
      </$Label>
      <$SingleLine>
        <Slate editor={editor} value={value} onChange={(value: Descendant[]) => {
          onChangeState(value)
          const { selection } = editor
          if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection)
            const wordBefore = Editor.before(editor, start, { unit: 'word' })
            const before = wordBefore && Editor.before(editor, wordBefore)
            const beforeRange = before && Editor.range(editor, before, start)
            const beforeText = beforeRange && Editor.string(editor, beforeRange)
            const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
            const after = Editor.after(editor, start)
            const afterRange = Editor.range(editor, start, after)
            const afterText = Editor.string(editor, afterRange)
            const afterMatch = afterText.match(/^(\s|$)/)
            if (beforeMatch && afterMatch) {
              setTarget(beforeRange)
              onChangeSearch(beforeMatch[1])
              setIndex(0)
              return
            }
          }
          setTarget(null)
        }}>
          <Editable
            placeholder='Write the email subject here...'
            renderElement={renderElement}
            onClick={() => focusEditor(editor)}
            autoCapitalize='false'
            autoCorrect='false'
            spellCheck='true'
            onKeyDown={onKeyDown}
          />
          {target && chars.length > 0 && typeof document === 'object' && (
            ReactDOM.createPortal(
              <$VariableAutoComplete ref={ref}>
                {chars.map((char: ValidatedVariable, i: number) => (
                  <$VariableOption
                    key={char.id}
                    onMouseDown={() => {
                      Transforms.select(editor, target)
                      insertVariable(editor, char.name, char.id, char)
                      setTarget(null)
                    }}
                    selected={i === index}
                  >
                    {char.name}
                  </$VariableOption>
                ))}
              </$VariableAutoComplete>,
              document.body)
          )}
        </Slate>
      </$SingleLine>
    </>
  )
}
