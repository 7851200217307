import { getElementName, Automation, ACTION_EVENTS, ACTIONS, ActionContext, EVENT_NAMES, FIRST_STEP_NAMES, ACTION_TARGET_NAMES, FlowCall } from 'prace-common-components'
import { Template } from 'store/api/templates/types'

export const getActionSubTitle = (action: Automation, templates: Template[], call: FlowCall, firstStep?: boolean) => {
  const { event, type, targets, every, lead } = action

  let displayEvent = event
  if(lead != null) {
    if(event === ACTION_EVENTS.SUBMITTED) {
      displayEvent = lead === true ? ACTION_EVENTS.LEAD_ASSIGNMENT_SUBMITTED
        : lead === false ? ACTION_EVENTS.NON_LEAD_ASSIGNMENT_SUBMITTED
          : event
    }

    else if(event === ACTION_EVENTS.ASSIGNED) {
      displayEvent = lead === true ? ACTION_EVENTS.LEAD_ASSIGNED
        : lead === false ? ACTION_EVENTS.NON_LEAD_ASSIGNED
          : event
    }
    else displayEvent = event
  }
  else if(every) displayEvent = ACTION_EVENTS.ALL_SUBMITTED

  if(type === ACTIONS.NOTIFY) {
    const context = action.context as ActionContext[ACTIONS.NOTIFY]
    const template = templates.find((template) =>
      (template.id === context.notificationId || template.id === context.notification?.id))
    return `${!firstStep ? EVENT_NAMES[displayEvent] : FIRST_STEP_NAMES[displayEvent]} > ${targets.length > 1 ? `${targets.length} targets` : (targets.length ? ACTION_TARGET_NAMES[targets[0].targetType] : '')} > ${template?.title}`
  } else {
    const elementName = getElementName((action.context as ActionContext[ACTIONS.ASSIGN]).flowElement.id, call)
    return `${!firstStep ? EVENT_NAMES[displayEvent] : FIRST_STEP_NAMES[displayEvent]} > ${targets.length > 1 ? `${targets.length} targets` : (targets.length ? ACTION_TARGET_NAMES[targets[0].targetType] : '')} > ${elementName}`
  }
}
