import styled from 'styled-components';
import { Divider } from '@material-ui/core';
export const $Divider = styled(Divider) `
  &.MuiDivider-root {
    width: 100%;
    margin: ${({ theme }) => `${theme.spacing(2)} 0`};
    margin-bottom: ${({ noMarginBottom = true, theme }) => noMarginBottom ? 0 : theme.spacing(2)};
    height: 1.2px;
    opacity: 0.5;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;
