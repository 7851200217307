import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FilterRenderer } from './FilterRenderer';
import { SortRenderer } from './SortRenderer';
import { $HeadGrid } from './styles';
export const HeadFilter = ({ props, type, onChange, onClickMenu, onSort, noFilterType, initialSort, noSort, colKey, }) => {
    const [sortDirection, setSortDirection] = React.useState(initialSort);
    const handleSort = () => {
        if (onSort && !noSort) {
            const newDirection = (sortDirection === 'ASC') ? 'DESC' : (sortDirection === 'DESC') ? undefined : 'ASC';
            setSortDirection(newDirection);
            onSort(newDirection);
        }
    };
    const handleFilter = (value) => {
        if (onChange)
            onChange(value);
    };
    return (React.createElement($HeadGrid, { container: true, wrap: 'nowrap' },
        React.createElement(Grid, { container: true },
            React.createElement(SortRenderer, { handleSort: handleSort, columnName: props.column?.name, noSort: noSort, sortDirection: sortDirection }),
            React.createElement(FilterRenderer, { ...props, colKey: colKey, noSort: noSort, sortDirection: sortDirection, handleFilter: handleFilter, noFilterType: noFilterType, type: type, onClickMenu: onClickMenu }))));
};
