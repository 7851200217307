import React from 'react';
import { $FilterColumn, $SortRenderer, $SvgIcon } from './styles';
import Grid from '@material-ui/core/Grid';
import { theme } from '../../newTheme';
export const SortIcon = ({ sortDirection, noSort }) => {
    return noSort ? null : React.createElement($SvgIcon, { name: sortDirection === 'DESC' ? 'arrow-down' : 'arrow-up', size: '16', color: sortDirection ? theme.colors.black : theme.colors.text.disabledLight });
};
export const SortRenderer = ({ noSort, sortDirection, columnName, handleSort }) => (React.createElement($SortRenderer, { container: true, onClick: () => handleSort() },
    React.createElement(SortIcon, { noSort: noSort, sortDirection: sortDirection }),
    React.createElement(Grid, { item: true, xs: true },
        React.createElement($FilterColumn, null, columnName))));
