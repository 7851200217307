import { clientApi } from '..'
import { CreatePartitionPayload, Center, CreateCenterPayload, DisableCentersPayload, GetPartitions, Partition, SearchCenters, SearchCentersPayload, UpdateCenterPayload, GetCenters } from './types'
import { withLoading } from 'prace-common-components'

export const partitionsApi = clientApi.injectEndpoints({
  endpoints: (build) => ({
    getPartitions: build.query<GetPartitions, { page: number; centerId?: number; pageSize?: number}>({
      query: ({ page = 1, centerId, pageSize = 999 }) => ({
        url: `/partitions?page=${page-1}&pageSize=${pageSize}${centerId ? `&centerId=${centerId}` : ''}`,
      }),
      transformResponse: (res: GetPartitions, _meta, args) => ({ ...args, ...res }),
      keepUnusedDataFor: 60, /* 1 minute cache */
    }),
    searchPartitions: build.query<GetPartitions, { search?: string; page: number; pageSize?: number}>({
      ...withLoading,
      query: ({ search, page, pageSize = 999 }) => ({
        url: `/partitions/search?search=${search}&page=${page-1}&pageSize=${pageSize}`,
      }),
      transformResponse: (res: GetPartitions, _meta, args) => ({ ...args, ...res }),
      keepUnusedDataFor: 60, /* 1 minute cache */
    }),
    getCenters: build.query<GetCenters, void>({
      query: () => ({
        url: '/centers',
      }),
      keepUnusedDataFor: 60, /* 1 minute cache */
    }),
    searchCenters: build.mutation<SearchCenters, SearchCentersPayload>({
      ...withLoading,
      query: (body) => ({
        method: 'POST',
        url: '/centers/search',
        body,
      }),
      transformResponse: (res: SearchCenters, _meta, args) => ({ ...args, ...res }),
    }),
    createCenter: build.mutation<Center, CreateCenterPayload>({
      ...withLoading,
      query: (body) => ({
        url: '/centers',
        method: 'POST',
        body,
      }),
    }),
    updateCenter: build.mutation<Center, UpdateCenterPayload>({
      ...withLoading,
      query: ({ id, ...body}) => ({
        url: `/centers/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    disableCenters: build.mutation<void, DisableCentersPayload>({
      ...withLoading,
      query: (body) => ({
        url: '/centers/disable',
        method: 'PATCH',
        body,
      }),
    }),
    createPartition: build.mutation<Partition, CreatePartitionPayload>({
      ...withLoading,
      query: (body) => ({
        url: '/partitions',
        method: 'POST',
        body,
      }),
    }),
    updatePartition: build.mutation<Partition, Partition>({
      ...withLoading,
      query: ({ id, ...body }) => ({
        url: `/partitions/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    disablePartitions: build.mutation<Partition, { ids: Partition['id'][] }>({
      ...withLoading,
      query: (body) => ({
        url: '/partitions/disable',
        method: 'PATCH',
        body,
      }),
    }),
    deletePartition: build.mutation<Partition, Partition['id']>({
      ...withLoading,
      query: (id) => ({
        url: `/partitions/${id}`,
        method: 'DELETE',
        body: { id },
      }),
    }),
  }),
})

export const {
  useGetCentersQuery,
  useGetPartitionsQuery,
  useSearchPartitionsQuery,
  useSearchCentersMutation,
  useCreateCenterMutation,
  useUpdateCenterMutation,
  useDisableCentersMutation,
  useCreatePartitionMutation,
  useUpdatePartitionMutation,
  useDisablePartitionsMutation,
  useDeletePartitionMutation,
} = partitionsApi
