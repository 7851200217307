import styled from 'styled-components';
export const $Landing = styled.div `
  display: flex;
  height: 100%;
  background-color: white;
`;
export const $FormSide = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  padding: 45px 8% 45px 8%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 24px 8% 24px 8%;
  }
`;
export const $Form = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
`;
export const $ImageSide = styled.div `
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 51px 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ image }) => `url(${image})`};
  background-position: 35%;
`;
export const $LogoContainer = styled.div `
  display: flex;
  width: 100%;
  margin-bottom: 15%;
  @media (max-width: 768px) {
    max-width: 300px;
  }
`;
export const $Logo = styled.img `
  height: 50%;
`;
export const $Title = styled.h1 `
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 4px;
`;
export const $Description = styled.p `
  color: ${({ theme }) => theme.colors.text.label};
  margin-bottom: 24px;
  margin-top: 0;
`;
export const $Selector = styled.div `
  display: flex;
  margin-bottom: 20px;
`;
