import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
const NoWrapStyling = () => css `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const $Container = styled(Grid) `
  &.MuiGrid-container {
    width: inherit;
    flex-wrap: ${({ $noWrap }) => $noWrap && 'nowrap'};
    white-space: ${({ multiline }) => multiline ? 'pre-line' : 'initial'};
    align-items: ${({ multiline }) => multiline ? 'baseline' : 'center'};
  }
`;
export const $Title = styled.h3 `
  ${({ $noWrap }) => $noWrap && NoWrapStyling()};
  font-weight: ${({ bold }) => !bold && 'normal'};
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : undefined};
  cursor: ${({ clickable }) => clickable && 'pointer'};
  margin: ${({ theme, noMargin, hasBack }) => noMargin ? hasBack ? `0 ${theme.spacing(1)}` : 0 : 'revert'};
  color: ${({ theme, alternate, color }) => alternate ? theme.colors.primary : color};
  max-width: ${({ multiline }) => multiline && '200px'};
`;
export const $SubTitle = styled.h4 `
  ${({ $noWrap }) => $noWrap && NoWrapStyling()};
  font-weight: ${({ bold }) => !bold && 'normal'};
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : undefined};
  margin: ${({ theme }) => `0 ${theme.spacing(1)}`};
  cursor: ${({ clickable }) => clickable && 'pointer'};
  margin-left: 0;
  color: ${({ theme, color, alternate }) => alternate ? theme.colors.primary : color};
  max-width: ${({ multiline }) => multiline && '200px'};
`;
export const $BodyText = styled.p `
  font-weight: 500;
  font-size: 0.9em;
  max-width: 100%;
  color: ${({ theme }) => theme.colors.feedback.disable};
`;
export const $Text = styled.div `
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  font-weight: 500;
  font-size: 0.9em;
  max-width: 100%;
  color: ${({ theme }) => theme.colors.feedback.disable};
  white-space: pre-wrap; //Respect \n
`;
export const $Italic = styled.span `
  font-size: 0.8em;
  margin-left: ${({ theme, noMargin }) => !noMargin && theme.spacing(3)};
  font-style: italic;
  color: ${({ theme }) => theme.colors.feedback.disable};
  opacity: 0.6;
`;
export const $ActionIcon = styled(Grid) `
  cursor: pointer;
`;
