import styled from 'styled-components';
export const $EmptyNotice = styled.div `
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  & > .MuiGrid-root {
    width: fit-content;
    margin: 0 auto;
  }
`;
export const $EmptySearch = styled.div `
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  & > .MuiGrid-root {
    width: fit-content;
    margin: 0 auto;
  }
`;
export const $ExpireContainer = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-width: 400px;
  img {
    max-width: 200px;
  }
  h3 {
    margin: 16px auto;
  }
  button {
    margin: 0 auto;
  }
`;
export const $MaintenanceNotice = styled.div `
  display: flex;
  flex-direction: column;
  margin: 60px auto;
  & > .MuiGrid-root {
    width: fit-content;
    margin: 0 auto;
  }
`;
export const $SubTitle = styled.p `
  font-weight: 500;
  font-size: 0.85em;
  max-width: 100%;
  margin-top: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.grey};
`;
export const $ImgContainer = styled.div `
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  img {
    width: 100%;
  }
`;
