/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { Organizationtate } from './types'
import { authApi } from 'store/api/auth'

const initialState: Organizationtate = {
  theme: undefined,
}

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.getTheme.matchFulfilled,(state, { payload }) => {
      state.theme = payload
    })
  },
})

export const organizationReducer = slice.reducer
