import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { NewButton } from '../';
import { RadioOptions } from '../newRadioOptions';
import { Input } from '../newInput';
import { $ModalContainer, $Grid, $Container } from './styles';
export const COIModal = ({ coi = false, comment = '', handleCOI, handleCancel, loading }) => {
    const [data, setData] = React.useState({ coi, comment });
    const onChange = (name, value) => setData((data) => ({ ...data, [name]: value }));
    const submitCOI = () => {
        handleCOI(String(data.coi) == 'true', data.comment || '');
    };
    return (React.createElement($ModalContainer, null,
        React.createElement(React.Fragment, null,
            React.createElement($Grid, { container: true },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(RadioOptions, { items: [{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }], title: 'Is there a Conflict of interest?', name: 'coi', required: true, value: String(data.coi), onChange: onChange, hideItalic: true })),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Input, { onChange: onChange, value: String(data.comment), title: 'Add a Comment', name: 'comment', multiline: true, minRows: 5, type: 'text', hideItalic: true }))),
            React.createElement($Container, { container: true, justifyContent: 'space-between' },
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement(NewButton, { loading: loading, onClick: submitCOI }, "Save Conflict of interest")),
                React.createElement(Grid, { item: true, xs: true, container: true, justifyContent: 'flex-end' },
                    React.createElement(NewButton, { disabled: loading, variant: 'outlined', onClick: handleCancel }, "Cancel"))))));
};
