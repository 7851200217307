import React, { PropsWithChildren } from 'react'
import { useFocused, useSelected } from 'slate-react'
import { $GroupElement, $SvgIcon, $DeleteIcon } from './styles'
import { Tooltip } from 'prace-common-components'

export const GroupElement: React.FC<PropsWithChildren<{
  attributes: {
    'data-slate-node': 'element',
    'data-slate-inline'?: true,
    'data-slate-void'?: true,
    dir?: 'rtl',
    ref: React.RefObject<HTMLDivElement>,
  },
  tooltipText?: string,
  error?: boolean,
  onDeleteGroup: () => void,
}>> = ({ attributes, children, tooltipText, error, onDeleteGroup }) => {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <$GroupElement {...attributes} error={error} border={selected && focused}>
      {(selected && focused) && <div contentEditable={false}>
        <$DeleteIcon name='delete' variant='secondary' onClick={onDeleteGroup} size={24} error={error} />
      </div>}
      {children}
      <Tooltip
        text={tooltipText || ''}
        arrow
        show
        placement='right-end'
      >
        <div contentEditable={false}>
          <$SvgIcon name='replay' variant='secondary' size={24} error={error} />
        </div>
      </Tooltip>
    </$GroupElement>
  )
}
