import React, { PropsWithChildren } from 'react'
import { useFocused, useSelected } from 'slate-react'
import { $ConditionElement, $SvgIcon, $DeleteIcon, $ConditionLabel } from './styles'

export const ConditionElement: React.FC<PropsWithChildren<{
  attributes: {
    'data-slate-node': 'element',
    'data-slate-inline'?: true,
    'data-slate-void'?: true,
    dir?: 'rtl',
    ref: React.RefObject<HTMLDivElement>,
  },
  error?: boolean,
  onEditCondition: () => void,
  onDeleteCondition: () => void,
}>> = ({ attributes, children, error, onEditCondition, onDeleteCondition }) => {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <$ConditionElement {...attributes} error={error} border={selected && focused}>
      <$ConditionLabel contentEditable={false} error={error}>
        THEN
      </$ConditionLabel>
      {(selected && focused) && <div contentEditable={false}>
        <$DeleteIcon name='delete' variant='primary' onClick={onDeleteCondition} size={24} error={error} />
      </div>}
      {children}
      <div contentEditable={false}>
        <$SvgIcon btn name='altEdit' onClick={onEditCondition} variant='primary' size={24} error={error}/>
      </div>
    </$ConditionElement>
  )
}
