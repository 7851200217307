import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import { SvgIcon, NewButton } from 'prace-common-components'

export const $Container = styled.div`
  max-width: 1400px;
  margin: ${({ theme }) =>  `${theme.spacing(2)} auto`};
`

export const $Grid = styled(Grid)`
  margin: ${({ theme }) => `${theme.spacing(3)} 0px`};
`

export const $Variables = styled(Grid)`
  margin: ${({ theme }) => `${theme.spacing(2)} 0px`};
`

export const $Form = styled.form`
  min-width: 300px;
`

export const $SvgIcon = styled(SvgIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`

//TODO: use theme variables
export const $Badge = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  background-color: #fff;
  color: #03247a;
  border: 1px solid #5d71b2;
  border-radius: 20px;
  padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(1.5)}`};
  margin: 0px;
  span {
    white-space: nowrap;
    margin: ${({ theme }) => theme.spacing(0.5)};
  }
`

//TODO: use theme greys
export const $EditorContainer = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 0 0 4px 4px;
  border: 1px solid #8D9192;
  background-color: #fff;
`

export const $TemplateContainer = styled.div`
  max-width: 1400px;
  padding: 0 25px;
  margin: 0 auto;
  width: 100%;
`

//TODO: use theme greys
export const $Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px 4px 0 0;
  border: 1px solid #8D9192;
  border-style: solid solid none solid;
  background-color: #EFF1F1;
`

//TODO: use theme spacings
export const $VariableAutoComplete = styled.div`
  top: -100vh;
  left: -100vw;
  position: absolute;
  z-index: 1;
  padding: 3px;
  background: white;
  border-radius: 4px;
  ${({theme}) => theme.elevations[0]};
`

//TODO: use theme spacings
//TODO: also add onHover highlight
export const $VariableOption = styled.div<{selected: boolean}>`
  padding: 1px 3px;
  border-radius: 3px;
  background: ${({ selected }) => selected ? '#B4D5FF' : 'transparent'};
  cursor: pointer;
`

export const $InfoIcon = styled(SvgIcon)`
  margin-right: 4px;
`

export const $AddVariableButton = styled(NewButton)`
  &.MuiButtonBase-root {
    padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
    line-height: normal;
  }
`

export const $TemplateManager = styled.div`
  width: 100%;
`

export const $Templates = styled.div`
  flex: 1;
  max-width: 1400px;
  margin: auto;
  padding: 50px 25px;
  width: 100%;
`

export const $TemplatesTop = styled(Grid)`
  padding-bottom: 16px;
`

export const $Label = styled.label`
  color: ${({ theme }) => theme.colors.feedback.disable};
  font-size: 0.9em;
`

export const $SingleLine = styled.div`
  border: 1px solid #A8ACAC;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  background-color: #fff;
  padding: ${({ theme }) => `0px ${theme.spacing(1.5)}`};
  margin: ${({ theme }) =>  `${theme.spacing(1.5)} 0px`};
  margin-bottom: ${({ theme }) =>  theme.spacing(4)};
  min-width: 600px;
  width: fit-content;
  p {
    margin: ${({ theme }) => `${theme.spacing(1.5)} auto`};
  }
`

//TODO: Similiar to $CallTitle
export const $TemplateTitle = styled(Grid)`
  white-space: nowrap;
  & > div {
    max-width: fit-content;
  }
`

export const $TemplateTop = styled(Grid)`
   &.MuiGrid-container {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`

export const $WarningGrid = styled(Grid)`
  &.MuiGrid-container {
    width: auto;
  }
  height: 100%;
`

export const $Warning = styled.div`
  margin-right: ${({ theme }) => theme.spacing(1)};
  font-weight: bold;
`

export const $Title = styled.h1`
  flex: 1;
  margin: 36px 10px;
`

export const $GroupButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const $Tags = styled(Grid)`
  align-items: center;
  overflow: auto;
  height: 100%;
`

export const $Link = styled(Link)<{valid?: boolean}>`
  color: ${({ theme, valid }) => valid ? theme.colors.primary : theme.colors.feedback.error };
`
