import styled from 'styled-components';
import { lightenDarkenColor } from '../utils';
export const $LoadingContainer = styled.div `
  position: fixed;
  z-index: 1500;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(8px);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const $LoadingWrapper = styled.div `
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);

  .circle{
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0px 5px;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    animation: loading 1.2s cubic-bezier(.8, .5, .2, 1.4) infinite;
    transform-origin: bottom center;
    position: relative;
  }
  @keyframes loading{
    0%{
      transform: translateY(0px);
      background-color: ${({ theme }) => lightenDarkenColor(theme.colors.primary, -50)};
    }
    50%{
      transform: translateY(50px);
      background-color: ${({ theme }) => theme.colors.primary};
    }
    100%{
      transform: translateY(0px);
      background-color: ${({ theme }) => lightenDarkenColor(theme.colors.primary, 50)};
    }
  }
  .circle-1{
    animation-delay: 0.1s;
  }
  .circle-2{
    animation-delay: 0.2s;
  }
  .circle-3{
    animation-delay: 0.3s;
  }
  .circle-4{
    animation-delay: 0.4s;
  }
  .circle-5{
    animation-delay: 0.5s;
  }
`;
