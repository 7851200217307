import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
export const $ActivityDates = styled(Grid) `
  padding: ${({ theme }) => theme.spacing(2)};
  max-width: 200px;
  border-radius: ${({ theme }) => theme.spacing(2)};
  border: ${({ theme }) => `1px solid ${theme.colors.secondary}`};
`;
export const $Date = styled.div `
  color: ${({ theme }) => theme.colors.greyScales[700]};
  white-space: nowrap;
  font-size: 0.85em;
`;
export const $Deadline = styled.div `
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-size: 0.95em;
`;
