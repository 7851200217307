import React from 'react'
import { useAppSelector } from 'store/hooks'
import { NewLoading as Loading } from 'prace-common-components'
import { RootState } from 'store'

export const LoadingWrapper = () => {
  const loading = useAppSelector((state: RootState) => state.loading.value || false)

  return <Loading loading={loading} />
}
