import styled from 'styled-components'
import { SvgIcon } from 'prace-common-components'
import Grid from '@material-ui/core/Grid'

export const $Grid = styled(Grid)`
  gap: ${({ theme }) => theme.spacing(2)};
`

export const $SvgIcon = styled(SvgIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`

export const $SwitchContainer = styled(Grid)`
  height: 100%;
`
