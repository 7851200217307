export interface CINESState {
  data: any[], //TODO: Handle any
  total: number,
  page: number,
}

export enum EXPORT_TYPE {
  REGULAR = 'regular',
  PREPARATORY = 'preparatory',
}
