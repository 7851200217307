import React from 'react';
import { $FilterColumn, $GridColumnFilter, $FilterContainer } from './styles';
import { CONDITIONS, FILTER_TYPE } from './types';
import { useFocusRef } from '../util/useFocusRef';
import { FilterDropdown } from './FilterDropdown';
const menuItems = {
    [FILTER_TYPE.TEXT]: [
        { label: 'Contains', value: CONDITIONS.CONTAINS },
        { label: 'Does not contain', value: CONDITIONS.NOT_CONTAINS },
    ],
    [FILTER_TYPE.NUMBER]: [
        { label: 'Greater or equal than', value: CONDITIONS.GREATER },
        { label: 'Lower or equal than', value: CONDITIONS.LOWER },
    ],
    [FILTER_TYPE.SELECT]: [
        { label: 'Equal to', value: CONDITIONS.EQUAL },
        { label: 'Not equal to', value: CONDITIONS.NOT_EQUAL },
    ],
    [FILTER_TYPE.DATE]: [
        { label: 'Greater or equal than', value: CONDITIONS.GREATER },
        { label: 'Lower or equal than', value: CONDITIONS.LOWER },
    ],
};
export const FilterContext = React.createContext(undefined);
export function FilterRenderer({ isCellSelected, handleFilter, noFilterType, type, colKey, onClickMenu, }) {
    const { filters } = React.useContext(FilterContext);
    const { ref, tabIndex } = useFocusRef(isCellSelected);
    function inputStopPropagation(e) {
        e.stopPropagation();
    }
    return (React.createElement($GridColumnFilter, { item: true, xs: 12 },
        React.createElement($FilterColumn, null, type === FILTER_TYPE.TEXT && React.createElement($FilterContainer, { container: true, wrap: 'nowrap', alignItems: 'center', onKeyDown: (e) => e.stopPropagation(), onClick: inputStopPropagation },
            React.createElement("input", { tabIndex: tabIndex, ref: ref, className: 'filterInput', value: filters[colKey], onChange: (e) => handleFilter(e.target.value) }),
            !noFilterType && React.createElement(FilterDropdown, { menuItems: menuItems[type], onClickMenu: onClickMenu })))));
}
