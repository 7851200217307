import React, { useState } from 'react';
import DataGrid from 'react-data-grid';
import { EXPORT } from './types';
import { NestedMenu, NewButton } from '../';
import { exportToCsv, exportToXlsx } from './util/exportUtils';
import { DataTable } from './DataTable';
import { $ButtonContainer } from './styles';
const menuItems = [
    { id: 0, label: React.createElement(React.Fragment, null, "CSV (.csv)") },
    { id: 1, label: React.createElement(React.Fragment, null, "Excel (.xlsx)") },
];
export const ExportableTable = ({ rows, columns, exportAll, title, onSortColumn, ...props }) => {
    const [selectedRows, setSelectedRows] = useState(() => new Set());
    const [storedSelectedRows, setStoredSelectedRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const handleSelectRows = (rowsSelected) => {
        setSelectedRows((prevSelects) => {
            const difference = [...rowsSelected].length - [...prevSelects].length;
            if (difference > 1) {
                setSelectAll(true);
            }
            else {
                setSelectAll(false);
                setStoredSelectedRows((prevSelected) => {
                    const uniqueRows = [...new Set(prevSelected.map((row) => row.id))];
                    const newArray = [...rowsSelected];
                    const newSelection = difference === 1 ? newArray[newArray.length - 1] : undefined;
                    if (newSelection && !uniqueRows.includes(newSelection)) {
                        const newRow = rows.find((row) => row.id == newSelection);
                        if (newRow)
                            prevSelected.push(newRow);
                    }
                    else {
                        return prevSelected.filter((row) => newArray.includes(row.id));
                    }
                    return prevSelected;
                });
            }
            return rowsSelected;
        });
    };
    const handleCSV = async (type) => {
        const rowsSelected = [...selectedRows || []];
        if (!rowsSelected.length)
            return;
        let downloadRows = storedSelectedRows;
        if (selectAll)
            downloadRows = await exportAll();
        const originalColumns = columns.map((col) => ({ ...col, formatter: undefined, headerRenderer: undefined }))
            .filter((col) => col.key !== 'actions');
        const gridElement = React.createElement(DataGrid, { rowKeyGetter: (row) => row.id, className: "fill-grid", columns: originalColumns, rows: downloadRows });
        switch (type) {
            case EXPORT.CSV: {
                exportToCsv(gridElement, `${title}.csv`);
                break;
            }
            case EXPORT.XLSX: {
                exportToXlsx(gridElement, `${title}.xlsx`);
                break;
            }
        }
    };
    const handleExportClick = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const handleItemClick = (id) => {
        setAnchorEl(null);
        switch (id) {
            case 0:
                handleCSV(EXPORT.CSV);
                break;
            case 1:
                handleCSV(EXPORT.XLSX);
        }
    };
    const displaySelectedRows = selectAll ? new Set(rows.map((row) => row.id)) : selectedRows;
    const displayButton = !![...displaySelectedRows].length;
    return (React.createElement(React.Fragment, null,
        displayButton && React.createElement($ButtonContainer, null,
            React.createElement(NewButton, { variant: 'outlined', onClick: (e) => handleExportClick(e) }, "Export selected rows")),
        React.createElement(DataTable, { ...props, rows: rows, columns: columns, onSortColumn: onSortColumn, selectedRows: displaySelectedRows, setSelectedRows: handleSelectRows }),
        React.createElement(NestedMenu, { anchorEl: anchorEl, setAnchorEl: setAnchorEl, items: menuItems, handleItemClick: handleItemClick })));
};
