const colors = {
    primary: '#294190',
    secondary: '#DA771E',
    tertiary: '#84CEEF',
    background: '#fff',
    focusBackground: '#EAFAFA',
    black: '#000',
    white: '#fff',
    grey: '#EFF1F1',
    darkPrimary: '#001554',
    primaries: {
        medium: '#b4c1ec',
        heavy: '#0B2979',
    },
    secondaries: {
        light: '#FFEDE0',
        medium: '#FFB781',
    },
    errors: {
        variable: '#FFEFEF',
    },
    neutral: {
        light: '#DFE3E3',
    },
    success: {
        light: '#B5D4A7',
    },
    text: {
        primary: '#8B8B8B',
        headLine: '#646464',
        grey: '#757778',
        disabled: 'rgba(69, 71, 72, 0.38)',
        disabledLight: 'rgba(69, 71, 72, 0.2)',
        title: '#333333',
        label: '#ACACAC',
        link: '#3f51b5',
    },
    icons: {
        collapse: '#364D9C',
        visibility: 'rgba(0, 0, 0, 0.54)',
    },
    cards: {
        background: '#EFF0FF',
        backgroundDarker: '#D5DDFF',
        backgroundDark: '#c9d3fe',
        border: '#F0F0FB',
    },
    feedback: {
        error: '#DD3730',
        warning: '#E97D1C',
        success: '#62A048',
        disable: '#8D9192',
        info: '#5D73C4',
        focus: '#2DB7BA',
    },
    secondaryShades: {
        80: 'rgba(218, 119, 30, 0.8)',
    },
    errorShades: {
        90: 'rgba(255, 218, 212, 0.9)',
    },
    shades: {
        light: 'rgba(125,125,125,0.1)',
        medium: 'rgba(125,125,125,0.2)',
        heavy: 'rgba(125,125,125,0.3)',
    },
    greyScales: {
        20: '#F7F7F7',
        30: '#EBEBEB',
        50: '#EEEEEE',
        100: '#E3E3E3',
        300: '#D1D1D1',
        350: '#BCC1C7',
        400: '#ACACAC',
        500: '#8B8B8B',
        600: '#646464',
        700: '#666666',
        800: '#515151',
        900: '#131313',
    },
    button: {
        primary: '#DA771E',
        secondary: '#364D9C',
        terceary: '#FF1515',
        disabled: '#EFF1F1',
        hover: '#FA9945',
        hoverSecondary: '#EFF0FF',
        error: '#DD3730',
    },
};
const fontSizes = {
    btn: '14px',
    checkBox: '14px',
};
const fontWeights = {
    normal: 400,
    heavier: 600,
    bold: 700,
};
const paddings = {
    btn: '0px 24px',
    collapse: '40px',
};
const dimensions = {
    btnRadius: '100px',
    borderRadius: '4px',
    collapseIcon: '16px',
};
const spacing = (n) => (n * 8) + 'px';
export const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
};
export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
};
const elevations = {
    0: 'box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    1: 'box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    2: 'box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    3: 'box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    4: 'box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
    5: 'box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
};
export const theme = {
    colors,
    dimensions,
    fontSizes,
    fontWeights,
    paddings,
    spacing,
    elevations,
};
