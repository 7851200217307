import React, { useState, useCallback, useEffect, useRef, PropsWithChildren, KeyboardEvent } from 'react'
import ReactDOM from 'react-dom'
import { getFlowElement, FlowCall } from 'prace-common-components'
import { Range, Editor, Transforms } from 'slate'
import { Editable, ReactEditor, Slate } from 'slate-react'
import { ConditionElement } from './components/ConditionElement'
import { GroupElement } from './components/GroupElement'
import { Variable } from './components/Variable'
import { Link } from './components/Link'
import { CustomElement, CustomText, LinkElement, ConditionElement as ConditionElementType } from './custom-types'
import { Toolbar } from './Toolbar'
import { $EditorContainer, $VariableAutoComplete, $VariableOption } from './styles'
import { BodySlateEditorProps, ValidatedVariable } from './types'
import { defaultVariables, insertVariable } from './EditorController'
import { Descendant } from 'slate'

//TODO: Consider transfering some of the components of the template manager to the common-components
//TODO: Change the structure of the plugins to something used by the client, e.g: In a hoc folder on common-components?

const Leaf: React.FC<PropsWithChildren<{attributes: { 'data-slate-leaf': true }; leaf: CustomText}>> = ({ attributes, children, leaf }) => {
  if (leaf.bold) children = <strong>{children}</strong>
  if (leaf.italic) children = <em>{children}</em>
  if (leaf.underline) children = <u>{children}</u>

  return <span {...attributes}>{children}</span>
}

const Element: React.FC<PropsWithChildren<{
  attributes: {
    'data-slate-node': 'element',
    'data-slate-inline'?: true,
    'data-slate-void'?: true,
    dir?: 'rtl',
    ref: React.RefObject<any>,
  },
  element: CustomElement,
  call: FlowCall,
  onEditCondition: (element: ConditionElementType) => void,
  onEditLink: (element: LinkElement) => void,
  onDeleteCondition: () => void,
  onDeleteGroup: () => void,
}>> = ({ attributes, children, element, call, onEditCondition, onDeleteCondition, onDeleteGroup, onEditLink }) => {
  // TODO: Do the textAlign in a better way
  switch (element.type) {
    case 'variable': {
      const foundDefault = defaultVariables.find((defaultV: ValidatedVariable) => (defaultV.name == element.character))
      const variableEl = getFlowElement(call, element.element)
      let tooltipText = ''
      if(variableEl.element || !!foundDefault) tooltipText = foundDefault ? 'This is a default variable' : `${variableEl.step?.title} > ${variableEl.form?.title} > ${variableEl.element?.title}`
      if((element.error || !variableEl.element) && !foundDefault) tooltipText = 'This variable is incorrect, replace it by a valid one.'
      return <Variable
        defaultVar={!!foundDefault}
        attributes={attributes}
        tooltipText={tooltipText}
        element={element}>
        {children}
      </Variable>
    }
    case 'group': {
      const groupEl = getFlowElement(call, element.element.element)
      let tooltipText = ''
      if(groupEl.element) tooltipText = `${groupEl.step?.title} > ${groupEl.form?.title} > ${groupEl.element?.title}`
      if(element.error || !groupEl.element) tooltipText = 'This group element is incorrect.'
      return <GroupElement
        attributes={attributes}
        tooltipText={tooltipText}
        error={element.error}
        onDeleteGroup={onDeleteGroup}
      >
        {children}
      </GroupElement>
    }
    case 'condition':
      return <ConditionElement
        onEditCondition={() => onEditCondition(element)}
        onDeleteCondition={onDeleteCondition}
        attributes={attributes}
        error={element.error}
      >
        {children}
      </ConditionElement>
    case 'numbered-list':
      return <ol style={{ textAlign: element.align }} {...attributes}>{children}</ol>
    case 'bulleted-list':
      return <ul style={{ textAlign: element.align }} {...attributes}>{children}</ul>
    case 'list-item':
      return <li style={{ textAlign: element.align }} {...attributes}>{children}</li>
    case 'link':
      return <Link
        onEditLink={() => onEditLink(element)}
        attributes={attributes}
        element={element}>
        {children}
      </Link>
    default:
      return <p style={{ textAlign: element.align, margin: 0 }} {...attributes}>{children}</p>
  }
}

// TODO: Must get the variables from template
export const BodySlate: React.FC<BodySlateEditorProps> = (
  {
    call,
    editor,
    value,
    chars,
    onChangeState,
    onChangeSearch,
    focusEditor,
    handleGroupCreation,
    handleConditionButton,
    onEditCondition,
    onEditLink,
    openLink,
    onDeleteCondition,
    onDeleteGroup,
  },
) => {
  const renderElement = useCallback(
    (props: any) => //TODO: Proper typing
      <Element
        {...props}
        call={call}
        onEditCondition={onEditCondition}
        onEditLink={onEditLink}
        onDeleteCondition={onDeleteCondition}
        onDeleteGroup={onDeleteGroup}
      />, [call, onDeleteCondition, onDeleteGroup, onEditCondition, onEditLink],
  )
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []) //TODO: Proper typing
  const ref = useRef<HTMLDivElement>(null)
  const [target, setTarget] = useState<NullableOptional<Range>>()
  const [index, setIndex] = useState(0)

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (target) {
        switch (e.key) {
          case 'ArrowDown': {
            e.preventDefault()
            const prevIndex = index >= chars.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          }
          case 'ArrowUp': {
            e.preventDefault()
            const nextIndex = index <= 0 ? chars.length - 1 : index - 1
            setIndex(nextIndex)
            break
          }
          case 'Tab':
          case 'Enter':
            e.preventDefault()
            Transforms.select(editor, target)
            insertVariable(editor, chars[index].name, chars[index].id, chars[index])
            setTarget(null)
            break
          case 'Escape':
            e.preventDefault()
            setTarget(null)
            break
        }
      } else {
        // This lets the user step into and out of the inline without stepping over characters.
        switch (e.key) {
          case 'ArrowLeft': {
            e.preventDefault()
            Transforms.move(editor, { unit: 'offset', reverse: true })
            break
          }
          case 'ArrowRight': {
            e.preventDefault()
            Transforms.move(editor, { unit: 'offset' })
            break
          }
        }
      }
    },
    [chars, editor, index, target],
  )

  useEffect(() => {
    if (target && chars.length > 0) {
      const el = ref.current
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      if(el) {
        el.style.top = `${rect.top + window.pageYOffset + 24}px`
        el.style.left = `${rect.left + window.pageXOffset}px`
      }
    }
  }, [chars.length, editor, index, target])

  return <Slate editor={editor} onChange={(value: Descendant[]) => {
    onChangeState(value)
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection)
      const wordBefore = Editor.before(editor, start, { unit: 'word' })
      const before = wordBefore && Editor.before(editor, wordBefore)
      const beforeRange = before && Editor.range(editor, before, start)
      const beforeText = beforeRange && Editor.string(editor, beforeRange)
      const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
      const after = Editor.after(editor, start)
      const afterRange = Editor.range(editor, start, after)
      const afterText = Editor.string(editor, afterRange)
      const afterMatch = afterText.match(/^(\s|$)/)
      if (beforeMatch && afterMatch) {
        setTarget(beforeRange)
        onChangeSearch(beforeMatch[1])
        setIndex(0)
        return
      }
    }
    setTarget(null)
  }}
  value={value}
  >
    <Toolbar
      openLink={openLink}
      call={call}
      handleGroupCreation={handleGroupCreation}
      handleConditionButton={handleConditionButton}
    />
    <$EditorContainer>
      <Editable
        placeholder='Write something...'
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onClick={() => focusEditor(editor)}
        autoCapitalize='false'
        autoCorrect='false'
        spellCheck='true'
        autoFocus
        onKeyDown={onKeyDown}
      />
    </$EditorContainer>
    {/* //TODO: Put this in subject editor also */}
    {target && chars.length > 0 && typeof document === 'object' && (
      ReactDOM.createPortal(
        <$VariableAutoComplete ref={ref}>
          {chars.map((char: ValidatedVariable, i: number) => (
            <$VariableOption
              key={char.id}
              onMouseDown={() => {
                Transforms.select(editor, target)
                insertVariable(editor, char.name, char.id, char)
                setTarget(null)
              }}
              selected={i === index}
            >
              {char.name}
            </$VariableOption>
          ))}
        </$VariableAutoComplete>,
        document.body)
    )}
  </Slate>
}
