import { FormControl, Toolbar } from '@material-ui/core';
import styled from 'styled-components';
import { SvgIcon } from '../..';
export const $Toolbar = styled(Toolbar) `
  &.MuiToolbar-gutters{
    padding:0px;
  }
  margin-bottom: 10px;
  display: flex;
  justify-content:right;
`;
export const $SvgIcon = styled(SvgIcon) `
  cursor: pointer;
  transition: padding 0.1s ease-out;
  ${({ theme, active }) => active && `color: ${theme.colors.feedback.focus}`};
  :active{
    padding: 2px;
  }
`;
export const $Icons = styled.div `
  display:flex;
  flex-direction:column;
`;
export const $FormControlGroup = styled.div `
  .MuiFormControl-root{
    .MuiInputBase-root{
      min-width: 200px;
      border-radius: 0px;
      fieldset{
        border-right-width: 0px;
      }
    }
    &:first-of-type{
      .MuiInputBase-root{
        border-radius: 5px 0px 0px 5px;
      }
    }
    &:last-of-type{
      .MuiInputBase-root{
        fieldset{
          border-right-width: 1px;
        }
        border-radius: 0px 5px 5px 0px;
      }
    }
  }
`;
export const $FormControl = styled(FormControl) `
  &.MuiFormControl-root{
    flex-direction: row;
  }
`;
export const $OrderByFormControl = styled(FormControl) `
  &.MuiFormControl-root{
    margin-right:auto;
    min-width: 200px;
    flex-direction: row;
  }

  .MuiInputBase-root{
    min-width: 200px;
  }

  .MuiInputAdornment-root{
    margin:0px;
  }

  .MuiSelect-icon{
    width:0px;
  }
`;
