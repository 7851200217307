import { createAction, createSlice } from '@reduxjs/toolkit';
import { NOTIFICATION_TIME } from '../constants/global';
const notification = createAction('notification');
const closeNotification = createAction('closeNotification');
const initialState = {
    notifications: [],
};
const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(notification, (state, { payload }) => {
            const uniqueNotifications = [{
                    ...payload,
                    timer: payload.timer || NOTIFICATION_TIME,
                    notificationNumber: Number(new Date().getTime()),
                }, ...state.notifications].filter((notif, index, self) => index === self.findIndex((t) => (t.msg === notif.msg)));
            state.notifications = uniqueNotifications;
        });
        builder.addCase(closeNotification, (state, { payload }) => {
            state.notifications = state.notifications.filter((n) => n.notificationNumber != payload.notificationNumber);
        });
    },
});
export const notificationsReducer = slice.reducer;
