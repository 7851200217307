export const ADD_ELEMENT_TYPE = 'addElement';
export const PREVIEW_TYPE = 'preview';
export var FORM_ELEMENT_TYPES;
(function (FORM_ELEMENT_TYPES) {
    FORM_ELEMENT_TYPES["TEXT"] = "text";
    FORM_ELEMENT_TYPES["TEXT_AREA"] = "textarea";
    FORM_ELEMENT_TYPES["TITLE"] = "title";
    FORM_ELEMENT_TYPES["EMAIL"] = "email";
    FORM_ELEMENT_TYPES["DATE"] = "date";
    FORM_ELEMENT_TYPES["NUMBER"] = "number";
    FORM_ELEMENT_TYPES["CHECKBOX"] = "checkbox";
    FORM_ELEMENT_TYPES["TOGGLE"] = "radio";
    FORM_ELEMENT_TYPES["UPLOAD"] = "upload";
    FORM_ELEMENT_TYPES["INSTRUCTIONS"] = "instructions";
    FORM_ELEMENT_TYPES["PHONE_NUMBER"] = "phone";
    FORM_ELEMENT_TYPES["SELECT_PARTITION"] = "partition_select";
    FORM_ELEMENT_TYPES["PARTITION_RESOURCES"] = "partition_resources";
    FORM_ELEMENT_TYPES["SELECT"] = "select";
    FORM_ELEMENT_TYPES["MULTISELECT"] = "multiselect";
    FORM_ELEMENT_TYPES["MULTITEXT"] = "multi_text";
    FORM_ELEMENT_TYPES["SPACE"] = "space";
    FORM_ELEMENT_TYPES["DIVIDER"] = "divider";
    FORM_ELEMENT_TYPES["GROUP"] = "group";
})(FORM_ELEMENT_TYPES || (FORM_ELEMENT_TYPES = {}));
export var ELEMENT_TYPES;
(function (ELEMENT_TYPES) {
    ELEMENT_TYPES["STEP"] = "step";
    ELEMENT_TYPES["FORM"] = "form";
    ELEMENT_TYPES["ELEMENT"] = "element";
})(ELEMENT_TYPES || (ELEMENT_TYPES = {}));
