import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'

//TODO: Make a general modal container like this for all modals
export const $ModalContainer = styled.div<{ width?: number}>`
  width: ${({ width = 800 }) => `${width}px`};
`

export const $Grid = styled(Grid)`
  margin: ${({ theme }) => `${theme.spacing(3)} 0px`};
`

export const $ColumnContainer = styled(Grid)<{ divider?: boolean }>`
  position: relative;
  border-left: ${({ divider }) => divider && '1px solid #A8ACAC'};
  margin: ${({ theme }) => `${theme.spacing(3)} 0px`};
  &>div {
    margin: ${({ theme }) => `${theme.spacing(1)} 0px`};
  }
`

export const $Form = styled.form`
  min-width: 300px;
`

export const $ConditionLabel = styled.div`
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.spacing(0.25)} ${theme.spacing(1.5)}`};
  font-size: 1.1em;
`

export const $ButtonContainer = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`

export const $Paragraph = styled.p<{ error?: boolean }>`
  color: ${({ error, theme }) => error ? theme.colors.feedback.error : theme.colors.black};
`

