import { Routes as ConstantRoutes } from 'constants/global'
import React, { FC, PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'
import { RootState } from 'store'
import { useAppSelector } from 'store/hooks'

export const PrivateRoute: FC<PropsWithChildren<any>> = ({ children }) => {
  const isAuth = useAppSelector((state: RootState) => state.auth.isAuth)
  const user = useAppSelector((state: RootState) => state.auth.user)
  if (!isAuth || !user) return <Navigate to={ConstantRoutes.LOGIN} />
  if (user) return children
  return null
}
