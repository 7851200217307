import React, { useState }  from 'react'
import { ConfirmAction, Grid, Input, NewButton, NewModal, ROLES, Title, isEmail } from 'prace-common-components'
import { $Paper, $Admins, $ActionBox, $ModalContainer, $DeleteIcon } from './styles'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useGetAdminsQuery, useInviteAdminMutation, useRevokeAdminMutation } from 'store/api/auth'

export const Admins = () => {
  const dispatch = useAppDispatch()
  const role = useAppSelector((state) => state.auth.user?.role)
  const [open, setOpen] = useState<'invite' | 'revoke' | undefined>()
  const [stateEmail, setStateEmail] = useState<string>('')
  const { data: admins = [], refetch } = useGetAdminsQuery(undefined, { skip: role !== ROLES.SUPER_ADMIN })
  const [inviteAdmin] = useInviteAdminMutation()
  const [revokeAdmin] = useRevokeAdminMutation()

  const handleClose = () => {
    setOpen(undefined)
    setStateEmail('')
  }

  const handleInviteAdmin = async () => {
    if(!stateEmail || !isEmail(stateEmail)) {
      dispatch({ type: 'notification', payload: { type: 'error', msg: 'Please enter a valid email' } })
      return
    }
    try {
      await inviteAdmin({ email: stateEmail }).unwrap()
      dispatch({ type: 'notification', payload: { type: 'success', msg: 'Admin invite sent successfully' } })
      handleClose()
      await refetch()
    } catch (err) {
      console.log(err)
    }
  }

  const handleRevokeAdmin = async () => {
    if(!stateEmail || !isEmail(stateEmail)) {
      dispatch({ type: 'notification', payload: { type: 'error', msg: 'Please enter a valid email' } })
      return
    }
    try {
      await revokeAdmin({ email: stateEmail }).unwrap()
      dispatch({ type: 'notification', payload: { type: 'success', msg: 'Admin revoked successfully' } })
      handleClose()
      await refetch()
    } catch (err) {
      console.log(err)
    }
  }

  const isInvite = open == 'invite'

  return (
    <$Admins>
      <$Paper>
        <Title alternate fontSize={22}>Manage Admins</Title>
        <$ActionBox>
          <NewButton
            onClick={() => setOpen('invite')}
          >
            Invite a new Admin
          </NewButton>
          <Title subTitle>Existing Admins:</Title>
          {admins.map((admin) => (
            <Title
              noMargin
              subTitle
              key={admin.id}
              actionIcon={<$DeleteIcon name='close' color='#DD3730' clickable />}
              onAction={() => {
                setOpen('revoke')
                setStateEmail(admin.email)
              }}>
              {admin.email}
            </Title>
          ))}
        </$ActionBox>
      </$Paper>
      {open &&
      <NewModal open title={isInvite ? 'Invite a new Admin' : 'Remove existing Admin'} onClose={handleClose}>
        <$ModalContainer>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Input
                title='Email'
                name='email'
                required
                value={stateEmail}
                type='email'
                hideItalic
                onChange={(_n, value) => setStateEmail(String(value))}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='space-between'>
                <ConfirmAction deletion={!isInvite} 
                  title={isInvite ? 'Invite a new Admin' : 'Revoke existing Admin'}
                  description={isInvite ? 'An admin invite will be sent to the email. Once accepted, the user will gain access to the Admin Portal.' : 'The user associated with the email will lose access to the Admin Portal'}
                  onConfirm={isInvite ? handleInviteAdmin : handleRevokeAdmin}
                >
                  <NewButton>{isInvite ? 'Invite' : 'Revoke'}</NewButton>
                </ConfirmAction>
                <NewButton variant='outlined' onClick={handleClose}>Cancel</NewButton>
              </Grid>
            </Grid>
          </Grid>
        </$ModalContainer>
      </NewModal>
      }
    </$Admins>
  )
}
