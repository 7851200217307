import styled from 'styled-components'
import CallBg from 'assets/banner.png'
import { hex2rgba, lightenDarkenColor } from 'prace-common-components'

export const $Background = styled.div<{small?: boolean}>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: ${({ small }) => small ? '200px' : '370px'};
  background-image: ${({ theme }) => `url(${CallBg}), linear-gradient(90deg, ${lightenDarkenColor(theme.colors.primary, -20)} 0%, ${hex2rgba(theme.colors.primary, 1, true)} 60%, ${lightenDarkenColor(theme.colors.primary, 15)} 100%)`};
  background-repeat: no-repeat;
  background-position: right;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
`

export const $Container = styled.div<{small?: boolean}>`
  flex: 1;
  display: flex;
  height: max-content;
  //padding: ${({ small }) => small ? '50px 0 60px 0' : '100px 0 60px 0'}; //TODO: Is padding necessary for mobile?
  max-width: 1200px;
`

export const $Details = styled.div<{isMobile?: boolean}>`
  display: flex;
  flex-direction: column;
  height: max-content;
  max-width: ${({ isMobile }) => isMobile ? '100%' : '60%'};
  margin-left: ${({ theme }) => theme.spacing(5)};
`
