import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

export const $Users = styled.div`
  flex: 1;
  max-width: 1400px;
  margin: 0 auto;
  padding: 50px 25px;
  font-family: roboto-light, sans-serif;
  width: 100%;
`

export const $SubTitle = styled.p`
  flex: 0;
  margin: 10px;
  margin-bottom: 50px;
`

export const $Paper = styled(Paper)`
  width: 100%;
  margin: ${({ theme }) => `${theme.spacing(3)} auto`};
  &.MuiPaper-elevation1 {
    box-shadow:none;
    border: none;
  }
  .MuiInputBase-root {
    margin-top: ${({ theme }) => theme.spacing(1)};
    max-width: 400px;
  }
`

export const $UserClick = styled.div<{disabled?: boolean}>`
  text-decoration: underline;
  color: ${({theme, disabled}) => disabled ? theme.colors.text.disabled : theme.colors.primary};
  cursor: pointer;
`

export const $UserText = styled.div<{disabled?: boolean}>`
  color: ${({theme, disabled}) => disabled ? theme.colors.text.disabled : 'initial'};
`

export const $Tags = styled(Grid)`
  align-items: center;
  overflow: auto;
  height: 100%;
`

export const $ActionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:first-child {
    flex: 1;
  }
`
