import React, { FC } from 'react'
import { CenterDetailsProps as Props } from './types'
import { DrawerModal, NotificationType } from 'prace-common-components'
import { AddCenter } from './AddCenter'
import { useCreateCenterMutation, useUpdateCenterMutation } from 'store/api/partitions'
import { useAppDispatch } from 'store/hooks'

export const CenterDetails: FC<Props> = ({
  open,
  center,
  handleClose,
}) => {
  const dispatch = useAppDispatch()
  const [updateCenter] = useUpdateCenterMutation()
  const [createCenter] = useCreateCenterMutation()

  const handleUpdateCenter = async (id: number, name: string, userId: number) => {
    try {
      await updateCenter({ id, name, userId }).unwrap()
      handleClose(true)
      dispatch({
        type: 'notification',
        payload: { type: NotificationType.success, msg: 'Center updated' } })
    } catch (err) {
      console.log(err)
    }
  }

  const handleCreateCenter = async (name: string, userId: number) => {
    if (name && userId > 0) {
      try {
        await createCenter({ name, userId }).unwrap()
        handleClose(true)
        dispatch({
          type: 'notification',
          payload: { type: NotificationType.success, msg: 'Center created' } })
      } catch (err) {
        console.log(err)
      }
    }
  }

  return <DrawerModal open={open} title={center?.name || 'Add Center'} handleClose={() => handleClose(false)}>
    <AddCenter
      open={open}
      center={center}
      handleUpdateCenter={handleUpdateCenter}
      handleAddCenter={handleCreateCenter}
    />
  </DrawerModal>
}
