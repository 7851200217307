import React, { FC, useState, useMemo, useEffect, useCallback, FormEvent } from 'react'
import { getFlowElement, Input, ConfirmModal, EditableMultiSelect, Title, NewButton, NewModal, SvgIcon, Tooltip, newTheme, FlowCall, FlowFormElement, NotificationType, removeHiddenElements } from 'prace-common-components'
import { ReactEditor, withReact } from 'slate-react'
import withSingleLine from './plugins/withSingleLine'
import { withGroups, withLayout, withInlines } from './plugins'
import { VariableElement, CustomElement, GroupElement, ConditionElement, CustomText, LinkElement } from './custom-types'
import { Variables } from './components/Variables'
import { AddVariable } from './components/AddVariable'
import { AddCondition } from './components/AddCondition'
import { removeGroup, toggleBlock } from './components/BlockButton'
import { BaseEditor, createEditor, Descendant, Editor, Transforms, Element as SlateElement } from 'slate'
import { $Container, $Grid, $Form, $TemplateTitle, $Warning, $WarningGrid, $Label, $TemplateTop } from './styles'
import Grid from '@material-ui/core/Grid'
import { HistoryEditor, withHistory } from 'slate-history'
import { SubjectSlate } from './SubjectSlate'
import { BodySlate } from './BodySlate'
import { EditorControllerProps, ValidatedVariable } from './types'
import { AddConditionForm } from './components/types'
import { $ModalContainer } from 'components/FormModal/styles'
import { wrapLink } from './plugins/withInlines'
import { useAppDispatch } from 'store/hooks'
import { TemplateCondition, TemplateElement, Variable as VariableType } from 'store/api/templates/types'
import { useListOptionsQuery } from 'store/api/options'

export const defaultVariables: ValidatedVariable[] = [
  { id: -1, error: false, name: 'Call name', variableType: 'default', flowElementId: null },
  { id: -2, error: false, name: 'Application ID', variableType: 'default', flowElementId: null },
  { id: -3, error: false, name: 'Application owner name', variableType: 'default', flowElementId: null },
  { id: -4, error: false, name: 'Target name', variableType: 'default', flowElementId: null },
  { id: -5, error: false, name: 'Assignment partition name', variableType: 'default', flowElementId: null },
  { id: -6, error: false, name: 'Assignment owner name', variableType: 'default', flowElementId: null },
  { id: -7, error: false, name: 'Assignment assigner name', variableType: 'default', flowElementId: null },
  { id: -8, error: false, name: 'Assignment owner role', variableType: 'default', flowElementId: null },
  { id: -9, error: false, name: 'Assignment owner email', variableType: 'default', flowElementId: null },
  { id: -10, error: false, name: 'Assignment deadline', variableType: 'default', flowElementId: null },
  { id: -11, error: false, name: 'Approved proposals', variableType: 'default', flowElementId: null },
  { id: -12, error: false, name: 'Allocation start', variableType: 'default', flowElementId: null },
  { id: -13, error: false, name: 'Allocation end', variableType: 'default', flowElementId: null },
  //TODO: Deprecated default variable
  //{ id: -14, error: false, name: 'Created assignment deadline', variableType: 'default', flowElementId: null },
  { id: -15, error: false, name: 'Reasons', variableType: 'default', flowElementId: null },
]

const insertLink = (editor: Editor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

//TODO: Pass this to another file
export const insertVariable = (editor: Editor, character: string, id: number, variable: ValidatedVariable) => {
  const newVariable: VariableElement = {
    type: 'variable',
    character,
    id,
    error: variable.error,
    element: variable.flowElementId,
    groupElement: variable.groupElement,
    children: [{ text: '' }],
  }
  Transforms.insertNodes(editor, newVariable)
  Transforms.move(editor)
}

//TODO: To another file
export const getGroupActive = (editor: Editor) => {
  const { selection } = editor
  if (!selection) return null

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        (n as CustomElement).type === 'group',
    }),
  )
  if(!match) return null
  return match[0] as GroupElement
}

//TODO: The check for errors should occur also during editing somehow to
// tell the user if the errors are fixed, maaybe check from time to time if
// template errors is true
export const EditorController: FC<EditorControllerProps> = ({ call, template, onSave, onExample }) => {
  const dispatch = useAppDispatch()
  const [openExample, setOpenExample] = React.useState<boolean>(false)
  const [applicationId, setApplicationId] = React.useState<Nullable<StringNumber>>('')
  const [assignmentId, setAssignmentId] = React.useState<Nullable<StringNumber>>('')
  const [openDelete, setOpenDelete] = React.useState<'condition' | 'group'>()
  const [openCondition, setOpenCondition] = React.useState<AddConditionForm | undefined>(undefined)
  const [templateErrors, setTemplateErrors] = React.useState<boolean>(template.error || false)
  const [openLink, setOpenLink] = React.useState<LinkElement | undefined>(undefined)
  const [templateProps, setTemplateProps] = useState<{title: string; tags: string[]}>(
    {title: template.title, tags: template.tags},
  )
  const { data: optionGroups } = useListOptionsQuery({})
  const options_groups = optionGroups?.data || []

  const subjectEditor = useMemo(() => withSingleLine(withInlines(withReact(createEditor()))), [])
  const bodyEditor = useMemo(
    () => withLayout(withInlines(withGroups(withReact(withHistory(createEditor()))))),
    [])
  const [activeEditor, setActiveEditor] = useState<BaseEditor & ReactEditor & HistoryEditor>(bodyEditor)
  const [subjectState, setSubjectState] = useState<Descendant[]>(template.subject.data?.length ?
    template.subject.data : [{ type: 'paragraph', children: [{ text: '', type: 'text' }] }])
  const [bodyState, setBodyState] = useState<Descendant[]>(template.body.data?.length ?
    template.body.data : [{ type: 'paragraph', children: [{ text: '', type: 'text' }] }])
  const [open, setOpen] = useState<boolean>(false)
  const validatedVariables = useMemo(() => (template.variables || []).map((variable) => {
    return {
      ...variable,
      error: !(getFlowElement(call!, variable.flowElementId)?.element),
    }
  }),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [call])

  const [variables, setVariables] = useState<ValidatedVariable[]>(
    [...defaultVariables, ...validatedVariables])

  const handleCreateVariable = useCallback((newVariable: ValidatedVariable, create?: boolean) => {
    setVariables((variables) => {
      const variableNames = [...variables.map((v) => v.name), ...defaultVariables.map((v) => v.name), 'Created assignment deadline']
      const variableExists = variables.find((variable: ValidatedVariable) =>
        newVariable.flowElementId === variable.flowElementId &&
          newVariable.groupElement === variable.groupElement)
      const variableNameExists = variableNames.includes(newVariable.name)
      if(variableExists || (variableNameExists && create)) {
        /* Only display error for user variables */
        if(create && variableNameExists)
          dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'There is a variable with this name' } })
        if(create && variableExists && !newVariable.groupElement)
          dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'This element already has a variable' } })
        return variables
      } else {
        setOpen(false)
        if(variableNameExists) return [...variables, {...newVariable, name: newVariable.name + ' '}]
        return [...variables, newVariable]
      }
    })
  }, [dispatch])

  const onRemoveVariable = (variable: ValidatedVariable) => {
    setVariables((variables) => variables.filter((stateVariable) => stateVariable.id !== variable.id))
  }

  //TODO: DO the same but for deleting group blocks, in that case removing variables?
  const handleGroupCreation = useCallback((groupElement: TemplateElement) => {
    const groupFlowElement = getFlowElement(call!, groupElement.element).element
    const elements = groupFlowElement?.flow

    elements?.forEach((element: FlowFormElement) => {
      handleCreateVariable({
        name: element.title,
        id: element.id,
        error: false,
        variableType: 'element_value',
        groupElement: groupFlowElement?.id,
        flowElementId: element.id,
      })
    })
  }, [call, handleCreateVariable])

  const checkEveryNode = useCallback((call: FlowCall, state: Descendant[]) => {
    const newState: Descendant[] = state.map((node: Descendant) => {
      switch(node.type) {
        case 'variable': {
          const { element, groupElement, id } = node
          /* Negative id means that the node is a default variable */
          const newError = id > -1 ? !getFlowElement(call, groupElement ? groupElement : element).element : false
          if(newError !== !!node.error) Transforms.setNodes(bodyEditor,
            { error: !!newError },
            { voids: true,
              mode: 'all',
              at: [],
              match: (n: any) => { //TODO: Handle any
                return !Editor.isEditor(n) &&
                  SlateElement.isElement(n) &&
                  n.type == 'variable' &&
                  (n.element == element && n.groupElement == groupElement)
              },
            },
          )
          setTemplateErrors((templateErrors) => (newError && !templateErrors) ? true : templateErrors)
          return {
            ...node,
            error: newError,
          }
        }
        case 'condition': {
          const { element } = node.condition
          const newError = !getFlowElement(call, element).element
          if(newError !== !!node.error) Transforms.setNodes(bodyEditor,
            { error: !!newError },
            { voids: true,
              mode: 'all',
              at: [],
              match: (n: any) => { //TODO: Handle any
                return !Editor.isEditor(n) &&
                  SlateElement.isElement(n) &&
                  n.type == 'condition' &&
                  n.condition.element == element
              },
            },
          )
          setTemplateErrors((templateErrors) => (newError && !templateErrors) ? true : templateErrors)
          return {
            ...node,
            error: newError,
            children: checkEveryNode(call, node.children),
          }
        }
        case 'group': {
          //Add Group variables here (if not already available)
          handleGroupCreation(node.element)
          const { element } = node.element
          const newError = !getFlowElement(call, element).element
          if(newError !== !!node.error) Transforms.setNodes(bodyEditor,
            { error: !!newError },
            { voids: true,
              mode: 'all',
              at: [],
              match: (n: any) => { //TODO: Handle any
                return !Editor.isEditor(n) &&
                  SlateElement.isElement(n) &&
                  n.type == 'group' &&
                  node.element.element == element
              },
            },
          )
          setTemplateErrors((templateErrors) => (newError && !templateErrors) ? true : templateErrors)
          return {
            ...node,
            error: newError,
            children: checkEveryNode(call, node.children),
          }
        }
        default: {
          if(node.children && node.children.length > 0) {
            return {
              ...node,
              children: checkEveryNode(call, node.children),
            }
          } else {
            return node
          }
        }
      }
    })
    return newState
  }, [bodyEditor, handleGroupCreation])

  /* Validates states */
  useEffect(() => {
    //TODO: Are the sets really necessary? (Maybe the Transforms are enough)
    setBodyState((prevState) => checkEveryNode(call!, prevState))
    setSubjectState((prevState) => checkEveryNode(call!, prevState))
  }, [call, checkEveryNode])

  //TODO: Not proud of this
  const [filterVariables, setFilterVariables] = useState<boolean>(false)
  const [search, setSearch] = useState('')

  const activeGroup: Nullable<GroupElement> = getGroupActive(activeEditor)
  const filteredVariables = activeGroup ?
    variables.filter((variable) => //FIXME: Have to solve the Group element variables with BE
      variable.groupElement === undefined || (variable.groupElement === activeGroup.element.element),
    ) :
    variables.filter((variable) => variable.groupElement === undefined)

  const chars = filteredVariables.filter((variable) =>
    variable.name.toLowerCase().startsWith(search.toLowerCase()),
  ).slice(0, 10)

  const handleSubjectChange = (value: Descendant[]) => {
    setSubjectState(value)
    setActiveEditor(subjectEditor)
  }

  const handleBodyChange = (value: Descendant[]) => {
    setBodyState(value)
    setActiveEditor(bodyEditor)
  }

  const handleChange = (name: string, value: Nullable<StringNumber> | string[]) => {
    setTemplateProps((prevTemplate) => ({
      ...prevTemplate,
      [name]: value,
    }))
  }

  const focusEditor = (editor: Editor) => {
    setActiveEditor(editor)
    //TODO: Not proud of this:
    setFilterVariables((filterVariables) => !filterVariables)
  }

  const handleSave = () => {
    const userVariables = variables.filter((variable) =>
      (variable.groupElement === undefined && variable.variableType !== 'default'))
    const subjectLength = subjectState.length
    const firstChild = subjectLength ? subjectState[0] : undefined
    if(!subjectLength ||
      (firstChild?.children && firstChild?.children.length == 1 && !(firstChild?.children[0] as CustomText).text)
    ) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Please add a subject to the email' } })
      return
    }
    onSave({
      ...template,
      ...templateProps,
      error: templateErrors,
      subject: { data: subjectState },
      body: { data: bodyState },
      variables: userVariables as VariableType[],
    })
  }

  const handleConditionInput = (condition: TemplateCondition) => {
    /* Adding new Condition */
    if(openCondition?.element === null) {
      toggleBlock(bodyEditor, 'condition', false, condition)
    }else {
      /* Edditing existing Condition */
      Transforms.setNodes(bodyEditor,
        { condition, error: false },
        {
          voids: true,
          mode: 'all',
          at: [],
          match: (n: any) => { //TODO: Handle any
            return !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n.type == 'condition' &&
            n.id == openCondition?.id
          },
        },
      )
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Condition updated' } })
    }
    setOpenCondition(undefined)
  }

  const handleConditionButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setOpenCondition({
      step: null, form: null, element: null, groupElement: null, condition: null, conditionValue: null, id: 0,
    })
  }

  const onEditCondition = (element: ConditionElement) => {
    setOpenCondition({...element.condition, id: element.id} as AddConditionForm)
  }
  const onDeleteCondition = () => setOpenDelete('condition')
  const onDeleteGroup = () => setOpenDelete('group')

  const handleLinkInput = (e: FormEvent<HTMLFormElement>, id = 0) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      url: { value: string },
    }
    const url = target.url.value
    if(url) {
      if(id) {
        Transforms.setNodes(bodyEditor,
          { url },
          {
            voids: true,
            mode: 'all',
            at: [],
            match: (n: any) => { //TODO: Handle any
              return !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n.type == 'link' &&
              n.id == id
            },
          },
        )
      } else {
        insertLink(bodyEditor, url)
      }
      setOpenLink(undefined)
    }
  }

  const onEditLink = (element: LinkElement) => {
    setOpenLink(element)
  }

  const handleSendExampleEmail = (id: string, applicationId: string, assignmentId: string) => {
    if(id && applicationId && assignmentId) {
      onExample(id, applicationId, assignmentId)
      setOpenExample(false)
    }
  }

  return (
    <$Container>
      <$TemplateTop container spacing={2} alignItems='center'>
        <$TemplateTitle item xs={6}>
          <Title>Template manager - {call.title}</Title>
        </$TemplateTitle>
        <Grid item xs={6}>
          <Grid container wrap='nowrap' alignItems='center' justifyContent='flex-end'>
            {/* //TODO: Make this a common component */}
            {templateErrors &&
            <Tooltip
              text='The errors are highlighted in red color on the editors below'
              arrow
              show
              placement='top'
            >
              <$WarningGrid container>
                <SvgIcon name='warning-outlined' color={newTheme.colors.feedback.error} size={20} />
                <$Warning>This template contains errors</$Warning>
              </$WarningGrid>
            </Tooltip>
            }
            <NewButton variant='text' onClick={() => setOpenExample(true)}>Send Email Preview</NewButton>
            <NewButton onClick={handleSave}>Save Template</NewButton>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Input
            name='title'
            value={templateProps.title}
            title='Template name'
            placeholder='Insert template name...'
            hideItalic
            error={!templateProps.title}
            required
            debounce
            errorMsg={templateProps.title ? '' : 'This is required'}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <EditableMultiSelect
            name='tags'
            value={templateProps.tags ? templateProps.tags.filter((tag) => !!tag) : []}
            title='Tags'
            placeholder='Separate tags by a comma: tag1, tag2, tag3...'
            hideItalic
            onChange={handleChange}
          />
        </Grid>
      </$TemplateTop>
      <Title subTitle>Variables</Title>
      <$Label>Select or type in the variables starting with @</$Label>
      <Variables
        call={call}
        filterVariables={filterVariables}
        activeEditor={activeEditor}
        variables={filteredVariables}
        openAddVariable={() => setOpen(true)}
        onRemoveVariable={onRemoveVariable}
      />
      <SubjectSlate
        call={call}
        chars={chars}
        editor={subjectEditor}
        value={subjectState}
        onChangeState={handleSubjectChange}
        onChangeSearch={setSearch}
        focusEditor={focusEditor}
      />
      <BodySlate
        call={call}
        chars={chars}
        editor={bodyEditor}
        value={bodyState}
        onChangeState={handleBodyChange}
        onChangeSearch={setSearch}
        focusEditor={focusEditor}
        handleGroupCreation={handleGroupCreation}
        handleConditionButton={handleConditionButton}
        onEditCondition={onEditCondition}
        onEditLink={onEditLink}
        openLink={() => setOpenLink({ id: 0, url: '', type: 'link', children: [] })}
        onDeleteCondition={onDeleteCondition}
        onDeleteGroup={onDeleteGroup}
      />
      {open &&
        <NewModal title='Create Variable' alternateTitle open onClose={() => setOpen(false)}>
          <AddVariable call={removeHiddenElements(call)} onAddVariable={(value) => handleCreateVariable(value, true)} />
        </NewModal>
      }
      {openExample &&
        <NewModal
          title='Email Preview'
          alternateTitle
          open
          onClose={() => setOpenExample(false)}
          notice='Make sure you saved your template changes'
        >
          <>
            <Input
              name='applicationId'
              title='Application ID'
              placeholder='e.g: EHPC-DEV-2023D11-016'
              description='The Application must be from the same Call as the Email Template.'
              hideItalic
              onChange={(_name: string, value: Nullable<StringNumber>) => setApplicationId(value)}
            />
            <Input
              name='assignmentId'
              title='Assignment ID'
              placeholder='e.g: 12581'
              description='The Assignment must be from the above Application and will be used to fill the template with data.'
              hideItalic
              onChange={(_name: string, value: Nullable<StringNumber>) => setAssignmentId(value)}
            />
            <$Grid container justifyContent='flex-end'>
              <NewButton onClick={() =>
                handleSendExampleEmail(String(template.id), applicationId as string, assignmentId as string)}>
                Send Email Preview
              </NewButton>
            </$Grid>
          </>
        </NewModal>
      }
      {openCondition &&
        <NewModal open alternateTitle title='Add Condition' onClose={() => setOpenCondition(undefined)}>
          <AddCondition
            optionGroups={options_groups}
            prevCondition={openCondition}
            insideGroup={!!activeGroup}
            call={removeHiddenElements(call)}
            onAddCondition={handleConditionInput}
          />
        </NewModal>
      }
      {openDelete &&
        <ConfirmModal
          open
          title={`Delete ${openDelete}`}
          description={`Deleting a ${openDelete} block will erase everything inside it.`}
          onConfirm={() => {
            if(openDelete === 'group') toggleBlock(bodyEditor, 'group', true)
            else removeGroup(bodyEditor, 'condition')
            setOpenDelete(undefined)
          }}
          onClose={() => setOpenDelete(undefined)}
        />
      }
      {openLink && //TODO: This should go to a component or use form modal
        <NewModal alternateTitle title='Insert a link' open onClose={() => setOpenLink(undefined)}>
          <$ModalContainer>
            <$Form onSubmit={(e) => handleLinkInput(e, openLink.id)}>
              <Input title='Link url' hideLabel hideItalic defaultValue={openLink.url} name='url' placeholder='https://prace-ri.eu/' />
              <$Grid container justifyContent='flex-end'>
                <NewButton type='submit'>
                  Confirm
                </NewButton>
              </$Grid>
            </$Form>
          </$ModalContainer>
        </NewModal>
      }
      {/* For debugging */}
      {/* <pre style={{ border: '1px solid red' }}>
        {JSON.stringify(bodyState, null, '  ')}
      </pre> */}
    </$Container>
  )
}
