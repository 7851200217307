import React from 'react'
import { $Icon, $IconPath } from './styles'

interface Icon {
  (props: {
    width?: number,
    height?: number,
  }): JSX.Element,
}

export const $LockIcon: Icon = (props) => {
  return (
    <$Icon
      $width={props.width}
      $height={props.height}
    >
      <$IconPath
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
        d='M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z'
      />
    </$Icon>
  )
}

export const $UnlockIcon: Icon = (props) => {
  return (
    <$Icon
      $width={props.width}
      $height={props.height}
    >
      <$IconPath
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
        d='M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z'
      />
    </$Icon>
  )
}
