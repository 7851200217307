import { Checkbox, CircularProgress, TableCell, TableContainer, TableRow } from '@material-ui/core';
import styled from 'styled-components';
export const $TableContainer = styled(TableContainer) `
  &.MuiTableContainer-root {
    box-shadow:none;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    max-height: ${({ maxHeight }) => maxHeight > 0 ? `${maxHeight}px` : undefined};
  }
`;
export const $TableRow = styled(TableRow) `
  &:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.colors.greyScales[20]};
  }

  &.MuiTableRow-root.MuiTableRow-hover:hover{
    background-color: ${({ theme }) => theme.colors.greyScales[100]};
  }

  td:first-child {
    color:black;
  }

  &.MuiTableRow-root{
    background-color: ${({ theme, checked }) => checked && theme.colors.greyScales[100]};
    opacity: ${({ checked }) => checked && '40%'};
    pointer-events: ${({ checked }) => checked && 'none'};
    box-shadow: ${({ checked }) => checked && 'inset 0px 0px 3px 1px rgba(0,0,0,0.1)'};
  }

  :hover {
    cursor: ${({ onClick }) => onClick ? 'pointer' : 'inherit'};
  }
`;
export const $TableCell = styled(TableCell) `
  &.MuiTableCell-root{
    padding: 10px 12px;
    color: ${({ theme }) => theme.colors.greyScales[700]};
  }

  &.MuiTableCell-head{
    color:black;
    background-color: white;
  }

  :hover.MuiTableCell-root {
    cursor: ${({ onClick }) => onClick ? 'pointer' : 'inherit'};
    text-decoration: ${({ onClick }) => onClick ? 'underline' : 'none'};
    color: ${({ theme, onClick }) => onClick ? theme.colors.feedback.focus : 'initial'};
  }
`;
export const $Checkbox = styled(Checkbox) `
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${({ theme }) => theme.colors.feedback.focus};
    pointer-events: ${({ checked }) => checked && 'initial'};
  }
`;
export const $CircularProgress = styled(CircularProgress) `
  &.MuiCircularProgress-root{
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 65px;
    display: flex;
  }
`;
