import * as React from 'react'
import { FORM_ELEMENT_TYPES, EditableMultiSelect, Input, Title, Select, NewButton, NewDatePicker } from 'prace-common-components'
import { $ModalContainer, $Form, $Grid, $ConditionLabel, $ButtonContainer, $ColumnContainer, $Paragraph } from './styles'
import { ButtonElement, ColumnElements, FormElement, FormModalProps } from './types'
import { GridSize } from '@material-ui/core/Grid'

export const FormModal: React.FC<FormModalProps> = (
  { title, columns, onSubmit, onChange, submitText, modalWidth, disabled },
) => {
  const [displayErrors, setDisplayErrors] = React.useState<string[]>([])
  const [dataValues, setDataValues] = React.useState<any>({})

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = new FormData(e.target as HTMLFormElement)
    const values = Object.fromEntries(data.entries())
    const invalidNames: string[] = []
    Object.keys(values).forEach((key) => {
      columns.forEach((column) => {
        const found = column.elements.find((element) => element.name == key)
        /* Error for required elements that were not filled */
        if(found && found.options.required && !values[key]) invalidNames.push(key)
      })
    })
    setDisplayErrors(invalidNames)
    if(!invalidNames.length) onSubmit(e, dataValues)
  }

  const renderInput = (element: ColumnElements) => {
    const error = element.options.required && displayErrors.includes(element.name || '')
    const errorMsg = error ? 'This is required' : undefined
    switch(element.elementType) {
      case FORM_ELEMENT_TYPES.SELECT:
        return <Select
          key={element.id}
          name={element.name}
          value={element.value as string || dataValues[element.name || '']}
          items={(element as FormElement).items || []}
          title={(element as FormElement).title || ''}
          error={error}
          errorMsg={errorMsg}
          {...element.options}
          onChange={(name: string, value: Nullable<string[] | StringNumber>) => {
            if(onChange) onChange(name, value)
            else setDataValues((prevData: any) => ({...prevData, [name]: value}))
          }}
        />
      case FORM_ELEMENT_TYPES.TEXT:
      case FORM_ELEMENT_TYPES.TEXT_AREA:
      case FORM_ELEMENT_TYPES.NUMBER:
        return <Input
          key={element.id}
          name={element.name}
          value={element.value as StringNumber}
          title={(element as FormElement).title || ''}
          type={element.elementType}
          hideItalic
          error={error}
          errorMsg={errorMsg}
          {...element.options}
          onChange={onChange}
        />
      case FORM_ELEMENT_TYPES.DATE:
        return <NewDatePicker
          key={element.id}
          name={element.name}
          value={element.value as Date}
          title={(element as FormElement).title || ''}
          error={error}
          {...element.options}
          type={undefined}
          onChange={onChange}
        />
      case FORM_ELEMENT_TYPES.MULTITEXT:
        return <EditableMultiSelect
          key={element.id}
          name={element.name}
          value={element.value as string[] || dataValues[element.name || '']}
          title={(element as FormElement).title || ''}
          error={error}
          {...element.options}
          hideItalic
          onChange={(name: string, value: Nullable<string[] | StringNumber>) => {
            if(onChange) onChange(name, value)
            else setDataValues((prevData: any) => ({...prevData, [name]: value}))
          }}
        />
      case 'button':
        return <NewButton key={element.id} onClick={(element as ButtonElement).onClick} {...element.options}>
          {element.value}
        </NewButton>
      default :
        return <$Paragraph {...element.options} key={element.id}>{element.value as string}</$Paragraph>
    }
  }

  return <$ModalContainer width={modalWidth}>
    {title && <Title alternate>{title}</Title>}
    <$Form onSubmit={handleSubmit}>
      <$Grid container spacing={4}>
        {columns.map((col, i) => <$ColumnContainer
          key={col.title}
          divider={i>0}
          item
          xs={12/(columns.length) as GridSize}
        >
          {col.title && <$ConditionLabel>{col.title}</$ConditionLabel>}
          {col.elements.map((element: ColumnElements) => renderInput(element))}
        </$ColumnContainer>,
        )}
      </$Grid>
      <$ButtonContainer container justifyContent='flex-end'>
        <NewButton disabled={disabled} type='submit'>
          {submitText}
        </NewButton>
      </$ButtonContainer>
    </$Form>
  </$ModalContainer>
}
