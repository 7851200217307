import { Chip } from '@material-ui/core'
import styled from 'styled-components'

export const $MultiUserFeedback = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
`

export const $Cursor = styled.svg<{ x: number; y: number }>`
  position: absolute;
  pointer-events: none;
  z-index: 9999;
  left: 0;
  top: 0;
  transform: ${({ x, y }) => `translateX(${x}px) translateY(${y}px)`};
`

export const $Name = styled.div<{ color: string; x: number; y: number }>`
  color: ${({color}) =>  color};
  z-index: 9999;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
  margin-left: 15px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  transform: ${({ x, y }) => `translateX(${x}px) translateY(${y}px)`};
`

export const $OnlineUsers = styled.div`
  position: absolute;
  display: flex;
  pointer-events: none;
  z-index: 9999;
  bottom: 40px;
  left: 15px;
  overflow: hidden;
`

export const $Chip = styled(Chip)<{bgColor?: string}>`
  margin: 2px;
  font-weight: 600;
  pointer-events: none;
  &.MuiChip-root {
    height: 34px;
    color: #fff;
    background-color: ${({ bgColor }) => bgColor};
    border: ${({ bgColor }) => `1px solid ${bgColor}`};
  }
`

export const $RefreshNotice = styled(Chip)`
  position: absolute;
  pointer-events: none;
  z-index: 9999;
  bottom: 40px;
  right: 15px;
  &.MuiChip-root {
    height: 24px;
    color: #fff;
    background-color: #E57373;
    border: 1px solid #E57373;
  }
`
