import styled from 'styled-components';
export const $Table = styled.div `
  width: 100%;
  margin: auto;
  .rdg {
    --rdg-header-background-color: ${({ theme }) => theme.colors.white};
    --rdg-row-hover-background-color: ${({ theme }) => theme.colors.white};
    --rdg-background-color: ${({ theme }) => theme.colors.white};
    --rdg-color: #555e68;
    --rdg-color-scheme: ${({ theme }) => theme.colors.white};
    --rdg-summary-border-color: #555;
    --rdg-border-color: unset;
    --rdg-checkbox-color: #7986cb;
    --rdg-selection-color: unset;
    --rdg-row-selected-background-color: #dee1f2;
    --rdg-row-selected-hover-background-color: #1768ab;
    ${({ noFilters }) => noFilters ? '' : `
      --rdg-header-row-height: 70px !important;
      grid-template-rows: 70px repeat(4, 40px) !important;
    `}

    border-radius: 2px;
    /* //TODO: Height should take consideration of the number of rows per page also, handle this better in the future */
    height: ${({ rowNumber, noFilters }) => `${(rowNumber + (noFilters ? 1 : 2)) * 40}px`};
    .rdg-row {
      &:nth-of-type(odd) {
        background-color: rgba(239,241,241, 0.18);
      }
      &:hover {
        background-color: rgba(121,134,203, 0.1);
      }
      &[aria-selected=true] {
        &:nth-of-type(odd) {
          background-color: rgba(121,134,203, 0.2);
        }
        &:hover {
          background-color: rgba(121,134,203, 0.25);
        }
      }
    }
    .rdg-header-row {
      .rdg-cell {
        overflow: visible;
      }
      .filterInput {
        width: inherit;
        border: 1px solid #e4e3e2;
        background-color: ${({ theme }) => theme.colors.white};
        height: 20px;
        border-radius: 2px;
        margin: 0;
        /* &:focus-visible {
          border: 1px solid #7986cb;
        } */
      }
    }
  }

  .rdg-cell {
    box-shadow: unset;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }

  .rdg-group-cell-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .rdg-sort-arrow {
    display: none;
  }

  .rdg-checkbox {
    border: solid 2px #8d9192;
    border-radius: 2px;
  }
`;
export const $RowContainer = styled.div `
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
  >* {
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
`;
export const $TableButton = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const $ButtonContainer = styled.div `
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: ${({ theme }) => `${theme.spacing(2)} 0`};
`;
