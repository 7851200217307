import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
export const $ModalContainer = styled.div `
  min-width: 500px;
`;
export const $Grid = styled(Grid) `
  gap: ${({ theme }) => theme.spacing(2)};
`;
export const $Container = styled(Grid) `
  margin-top: ${({ theme }) => theme.spacing(3)};
`;
