/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { CINESState } from './types'
import { cinesApi } from 'store/api/cines'

const initialState: CINESState = {
  data: [],
  total: 0,
  page: 0,
}

const slice = createSlice({
  name: 'cines',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(cinesApi.endpoints.getCINESApps.matchFulfilled,(state, { payload }) => {
      state.data = payload.data
      state.total = payload.total
      state.page = payload.page
    })
    builder.addMatcher(cinesApi.endpoints.getCINESApp.matchFulfilled,(state, { payload }) => {
      const idx = state.data.findIndex((cinesApp) => cinesApp?.scientificReviewAll?.id == payload.id)
      if(idx === -1) {
        state.total = state.total + 1
        state.data.push(payload)
      } else {
        state.data = state.data.map((app) => app.id == payload.id ? payload : app)
      }
    })
  },
})

export const cinesReducer = slice.reducer
