import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { $Badge } from 'prace-common-components'

export const $Legacy = styled.div`
  flex: 1;
  max-width: 1400px;
  margin: auto;
  padding: 50px 25px;
  font-family: roboto-light, sans-serif;
  width: 100%;
`

export const $RowContainer = styled.div`
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
  >* {
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
`

export const $AppDetails = styled(Grid)`
  height: max-content;
  margin: 0 auto;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(5)}`};
  max-width: 1200px;
  min-height: max-content;

  .MuiGrid-item {
    padding: ${({ theme }) => theme.spacing(1)};
  }
`

export const $Paper = styled(Paper)`
  width: 100%;
  margin: ${({ theme }) => `${theme.spacing(3)} auto`};
  &.MuiPaper-elevation1 {
    box-shadow:none;
    border: none;
  }
  .MuiInputBase-root {
    margin-top: ${({ theme }) => theme.spacing(1)};
    max-width: 400px;
  }
`

export const $KeyParam = styled.span`
  font-weight: bold;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
`

export const $Chip = styled($Badge)`
  display: inline-flex;
  margin: 0;
  max-height: 25px;
  border-radius: 15px;
  padding: ${({ theme }) => `0 ${theme.spacing(1.5)}`};
`
