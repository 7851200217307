import FormControl from '@material-ui/core/FormControl';
import { $Divider } from '../newDivider/styles';
import styled from 'styled-components';
export const $FormElementControl = styled(FormControl) `
  width: 100%;
  &.MuiFormControl-root {
    padding: 0;
  }
`;
export const $Card = styled.div `
  border: 1px solid #A8ACAC;
  width: 100%;
  height: fit-content;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
`;
export const $Handler = styled.div `
  display: flex;
  align-items: center;
  cursor: move;
  margin-right: 8px;
  height: 100%;
  min-height: 64px;
  border-radius: 4px;
`;
export const $FormElementText = styled.h4 `
  color: #757778;
  margin-left: ${({ theme }) => theme.spacing(2)};
`;
export const $Space = styled.div `
  padding: ${({ theme }) => theme.spacing(1)};
`;
export const $DividerElement = styled($Divider) `
  &.MuiDivider-root {
    margin: ${({ theme }) => `${theme.spacing(1)} 0`};
  }
`;
