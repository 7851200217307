import { ACTIONS, Action, Automation, Condition, FlowCall } from 'prace-common-components'
import { Template } from 'store/api/templates/types'

/**
 * This function is used to get the usage of a specific flow element in automations, conditions, and templates.
 * 
 * @param {number} flowElementId - The ID of the flow element.
 * @param {FlowCall} call - The flow call object.
 * @param {Template[]} templates - The array of templates.
 * 
 * @returns {Object} - An object containing arrays of automations, conditions, and templates that use the flow element.
 */
export const getElementUsage = (flowElementId: number, call: FlowCall, templates: Template[]) => {
  // Initialize arrays to store automations and conditions that target the flow element
  let automationsTargetting: Automation[] = []
  let conditionsTargetting: Condition[] = []

  // Iterate over each step in the flow
  call?.flow?.forEach((step) => {
    // Check if there are automations using this element
    const automations = (step.automations || []).filter(
      (automation) => (automation?.condition?.conditionalElement as { id: number })?.id == flowElementId ||
        automation?.parentId == flowElementId || 
        automation?.targets?.find((target) => target.targetElement?.id == flowElementId) ||
        (automation?.context as Action<ACTIONS.VISIBILITY>['context'])?.flowElement?.id == flowElementId,
    ) || []
    // Add the found automations to the array
    automationsTargetting = automationsTargetting.concat(automations)

    // Iterate over each form in the step
    step.flow.forEach((form) => {
      form.flow.forEach((element) => {
        // Check if there are conditions using this element
        const conditions = element.effects?.filter((condition) => 
          condition.validation?.elementsIds?.includes(flowElementId) ||
            condition.validation?.elements.find((element) => element.id == flowElementId),
        ) || []
        // Add the found conditions to the array
        conditionsTargetting = conditionsTargetting.concat(conditions)

        // If the element has a flow, iterate over each group element in the flow
        if(element.flow?.length)
          element.flow.forEach((groupElement) => {
            // Check if there are conditions using this group element
            const groupConditions = groupElement.effects?.filter((condition) => 
              condition.validation?.elementsIds?.includes(flowElementId) ||
                condition.validation?.elements.find((element) => element.id == flowElementId),
            ) || []
            // Add the found conditions to the array
            conditionsTargetting = conditionsTargetting.concat(groupConditions)
          })
      })
    })
  })

  // Check if there are templates using this element
  const templatesTargetting = (templates || []).filter((template: Template) => 
    template.variables.find((variable) => variable.flowElementId == flowElementId),
  )

  // Return the arrays of automations, conditions, and templates that use the flow element
  return {
    automations: automationsTargetting,
    conditions: conditionsTargetting,
    templates: templatesTargetting,
  }
}
