import { createAction, createSlice } from '@reduxjs/toolkit';
const activateLoading = createAction('activateLoading');
const stopLoading = createAction('stopLoading');
const toggleLoading = createAction('toggleLoading');
const activateSaving = createAction('activateSaving');
const saveSuccess = createAction('saveSuccess');
const saveFail = createAction('saveFail');
const initialState = { value: false, saving: false, success: false, fail: false };
const slice = createSlice({
    name: 'loading',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(activateLoading, (state) => {
            state.value = true;
        });
        builder.addCase(stopLoading, (state) => {
            state.value = false;
        });
        builder.addCase(toggleLoading, (state) => {
            state.value = !state.value;
        });
        builder.addCase(activateSaving, (state) => {
            state.saving = true;
            state.fail = false;
            state.success = false;
        });
        builder.addCase(saveSuccess, (state) => {
            state.saving = false;
            state.success = true;
            state.fail = false;
        });
        builder.addCase(saveFail, (state) => {
            state.saving = false;
            state.success = false;
            state.fail = true;
        });
    },
});
export const loadingReducer = slice.reducer;
export const withLoading = {
    onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        dispatch({ type: 'activateLoading' });
        try {
            await queryFulfilled;
            dispatch({ type: 'stopLoading' });
        }
        catch {
            dispatch({ type: 'stopLoading' });
        }
    }
};
export const withAutoSave = {
    onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        dispatch({ type: 'activateSaving' });
        try {
            await queryFulfilled;
            dispatch({ type: 'saveSuccess' });
        }
        catch {
            dispatch({ type: 'saveFail' });
        }
    }
};
