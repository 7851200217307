import { FlowCall, getFlowElement } from 'prace-common-components'
import { Descendant } from 'slate'

const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/
const nonLocalhostDomainRE = /^[^\s.]+\.\S{2,}$/

// TODO: Only works if there is a protocol like https
export const isUrl = (string: string) => {
  if (typeof string !== 'string') {
    return false
  }

  const match = string.match(protocolAndDomainRE)
  if (!match) {
    return false
  }

  const everythingAfterProtocol = match[1]
  if (!everythingAfterProtocol) {
    return false
  }

  if (nonLocalhostDomainRE.test(everythingAfterProtocol)) {
    return true
  }

  return false
}

export const validateTemplate = (call?: FlowCall, templateState?: Descendant[], error = false): boolean => {
  let anyError = error
  if(!call || !templateState) return false
  templateState.forEach((node: Descendant) => {
    switch(node.type) {
      case 'variable': {
        const { element, groupElement, id } = node
        /* Negative id means that the node is a default variable */
        anyError = id > -1 ? !getFlowElement(call, groupElement ? groupElement : element).element : false
        break
      }
      case 'condition': {
        const { element } = node.condition
        anyError = !getFlowElement(call, element).element
        break
      }
      case 'group': {
        const { element } = node.element
        anyError = !getFlowElement(call, element).element
        break
      }
      default: {
        if(node.children && node.children.length > 0) {
          validateTemplate(call, node.children, anyError)
        }
      }
    }
  })
  return !anyError
}
