import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Routes } from 'constants/global'
import { EditorController } from './EditorController'
import { $TemplateContainer } from './styles'
import { useGetCallQuery } from 'store/api/calls'
import { useGetTemplateQuery, useUpdateTemplateMutation, useSendExampleEmailMutation } from 'store/api/templates'
import { Template } from 'store/api/templates/types'
import { NotificationType } from 'prace-common-components'
import { useAppDispatch } from 'store/hooks'

export const EmailTemplate = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { id = '' } = useParams<{ id?: string }>()
  const { data: template, isFetching, isLoading } = 
    useGetTemplateQuery(Number(id), { skip: !id, refetchOnMountOrArgChange: true })
  const { data: call, isLoading: callLoading } =
    useGetCallQuery(Number(template?.callId || ''), { skip: !template?.callId, refetchOnMountOrArgChange: true })
  const [editTemplate] = useUpdateTemplateMutation()
  const [sendExampleEmail] = useSendExampleEmailMutation()

  if(!template || !call || !call.flow || isFetching || isLoading || callLoading) return null

  const handleSave = async (template: Template) => {
    try {
      await editTemplate(template).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Template updated' } })
      navigate(Routes.TEMPLATE_MANAGER)
    } catch (err) {
      console.log(err)
    }
  }

  const handleSendExampleEmail = async (id: string, applicationId: string, assignmentId: string) => {
    try {
      await sendExampleEmail({ id: Number(id), applicationId, assignmentId }).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Email sent' } })
    } catch (err) {
      console.log(err)
    }
  }

  return  (
    <$TemplateContainer>
      <EditorController
        call={call}
        template={template}
        onSave={handleSave}
        onExample={handleSendExampleEmail}
      />
    </$TemplateContainer>
  )
}
