import React, { FormEvent, useMemo } from 'react'
import { CallPartitionResource, Item, FORM_ELEMENT_TYPES, OptionGroup, FlowCall, getFlowElement, NotificationType } from 'prace-common-components'
import { FormModal } from 'components/FormModal'
import useElementSelects from 'util/useElementSelects'
import parseLocaleNumber from 'util/parseLocaleNumber'
import { FormElement, ParagraphElement } from 'components/FormModal/types'
import { AddConditionForm } from './types'
import { CONDITION_EFFECT } from 'constants/global'
import { useAppDispatch } from 'store/hooks'
import { TemplateCondition } from 'store/api/templates/types'

enum NUMBER_EFFECT_NAME {
  LESSER = 'lesser',
  HIGHER = 'higher',
  EQUAL = 'equal',
  DIFFERENT = 'different',
  EXISTS = 'non_empty',
}

enum TEXT_EFFECT_NAME {
  EXISTS = 'non_empty',
  EQUAL = 'equal',
  DIFFERENT = 'different',
}

const TYPES_NOT_ALLOWED = [
  FORM_ELEMENT_TYPES.UPLOAD,
  FORM_ELEMENT_TYPES.INSTRUCTIONS,
  FORM_ELEMENT_TYPES.TITLE,
  FORM_ELEMENT_TYPES.SPACE,
  FORM_ELEMENT_TYPES.DIVIDER,
]

export const AddCondition: React.FC<{
  call?: FlowCall,
  insideGroup?: boolean,
  prevCondition: AddConditionForm,
  optionGroups: OptionGroup[],
  onAddCondition: (condition: TemplateCondition) => void,
}> = ({ call, insideGroup, optionGroups, onAddCondition, prevCondition }) => {
  const dispatch = useAppDispatch()
  const [formValues, setFormValues] = React.useState<AddConditionForm>(prevCondition)
  const flowElement = formValues?.element !== null && getFlowElement(call!, formValues?.element)?.element
  const selectedGroupElement = !!flowElement && flowElement.elementType === FORM_ELEMENT_TYPES.GROUP
  const disableGroupElement = !insideGroup && selectedGroupElement
  
  const [_, elementSelects] = useElementSelects(call?.flow, formValues, '', false, false, !disableGroupElement)

  const handleChange = (name: string, value: Nullable<StringNumber | string[]>) => {
    setFormValues((prevState: AddConditionForm) => ({...prevState, [name]: value as Nullable<string> }))
  }

  const { element } = getFlowElement(call!, formValues.groupElement ? formValues.groupElement : formValues.element)

  const handleConditionInput = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      step: { value: number },
      form: { value: number },
      element: { value: number },
      groupElement: { value: number },
      condition: { value: string },
      conditionValue: { value: string },
    }
    const elementId = target.element.value
    const groupElementId = target.groupElement?.value

    const conditionValue = element?.elementType === FORM_ELEMENT_TYPES.NUMBER ?
      parseLocaleNumber(target.conditionValue?.value || '') : target.conditionValue?.value

    if(target.condition.value !== CONDITION_EFFECT.non_empty && !conditionValue) return
    if(elementId && target.condition.value) {
      if(element && TYPES_NOT_ALLOWED.includes(element.elementType as FORM_ELEMENT_TYPES)) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: `Cannot choose an element of type ${element.elementType}` } })

        return
      }
      if(element && element.elementType === FORM_ELEMENT_TYPES.GROUP && groupElementId === null) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Must choose a group element' } })
        return
      }
      const updatedCondition = {
        step: Number(target.step.value),
        form:  Number(target.form.value),
        element: Number(elementId),
        groupElement: groupElementId ? Number(groupElementId) : null,
        condition: target.condition.value,
        conditionValue,
      }
      onAddCondition(updatedCondition)
    } else {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'All fields are required' } })
    }
  }

  const elementType = element?.elementType
  const selectElement = elementType == FORM_ELEMENT_TYPES.SELECT ||
  elementType == FORM_ELEMENT_TYPES.MULTISELECT ||
  elementType == FORM_ELEMENT_TYPES.TOGGLE ||
  elementType == FORM_ELEMENT_TYPES.SELECT_PARTITION
  const selectPartition = elementType == FORM_ELEMENT_TYPES.SELECT_PARTITION

  const dateElement = elementType === FORM_ELEMENT_TYPES.DATE
  const numberElement = elementType === FORM_ELEMENT_TYPES.NUMBER

  const ConditionItems = useMemo(
    () => Object.values(numberElement || dateElement ? NUMBER_EFFECT_NAME : TEXT_EFFECT_NAME)
      .map((cond: keyof typeof CONDITION_EFFECT) => ({ label: cond, value: CONDITION_EFFECT[cond] }),
        /* Only want to redo the conditions array if the element type changed */
        // eslint-disable-next-line react-hooks/exhaustive-deps
      ), [elementType])

  const { condition, conditionValue } = formValues

  const columns = useMemo(() => {
    let allResources: CallPartitionResource[] = []
    call?.cutoffs.forEach((cutOff) => {
      allResources = [...allResources, ...cutOff.resources]
    })
    /* If the cutoff is not active, it will not show to the users (applications) */
    const partitionIds = [...new Set(allResources.map((resource) => resource?.partition?.id))] || []
    const partitionOptions: Item[] = partitionIds.map((id) => {
      const partitionName = allResources.find((resource) => resource.partition?.id == id)?.partition?.name || ''
      return { value: id, label: partitionName }
    }) || []

    /* Sort partitions by name */
    const sortedPartitionOptions = partitionOptions?.sort((a, b) => {
      if (a.label < b.label) return -1
      if (a.label > b.label) return 1
      return 0
    }) || []

    const thenElements: FormElement[] = [
      { id: 3, name: 'condition',
        elementType: FORM_ELEMENT_TYPES.SELECT,
        title: 'What condition triggers the action?',
        items: ConditionItems,
        options: {
          value: condition,
          placeholder: 'Choose a condition',
          description: '',
          required: true,
        },
      }]

    if(condition !== CONDITION_EFFECT.non_empty)
      thenElements.push({ id: 4, name: 'conditionValue',
        elementType: selectElement ? FORM_ELEMENT_TYPES.SELECT :
          (dateElement || numberElement) ? elementType : FORM_ELEMENT_TYPES.TEXT,
        items: selectPartition ? sortedPartitionOptions :
          selectElement ? (element?.optionsGroup?.options ||
          optionGroups.find((optGroup) => optGroup.tag === element?.optionsGroup?.tag)?.options || []) : [],
        options: {
          value: conditionValue as Nullable<CONDITION_EFFECT>,
          placeholder: 'Conditional value',
          description: '',
          decimal: numberElement,
          required: true,
          hideLabel: true,
        },
      })

    return [
      { title: 'IF', elements: disableGroupElement ? [
        ...elementSelects as FormElement[], { id: 6, name: 'condition',
          elementType: 'paragraph',
          value: 'Can only use group elements in IF\'s from inside said groups.',
          options: { error: true },
        } as ParagraphElement,
      ] : elementSelects as FormElement[] },
      { elements: thenElements},
    ]
  }, [
    call?.cutoffs,
    disableGroupElement,
    elementSelects,
    element,
    optionGroups,
    ConditionItems,
    condition,
    dateElement,
    numberElement,
    elementType,
    conditionValue,
    selectElement,
    selectPartition,
  ])

  return (
    <FormModal
      columns={columns}
      disabled={disableGroupElement}
      submitText='Proceed to then'
      onSubmit={handleConditionInput}
      onChange={handleChange}
    />
  )
}
