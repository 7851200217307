import { ELEMENT_TYPES, FlowCutOff, FlowForm, FlowFormElement, FlowStep } from 'prace-common-components'

export type EditElementProps = {
  selectedType: ELEMENT_TYPES,
  selectedElement: Nullable<FlowFormElement>,
  selectedStep: FlowStep,
  selectedForm: FlowForm,
  cutoffs: FlowCutOff[],
  selectedElements: number[],
  tab: number,
  open?: boolean,
  onChangeDrawer: () => void,
  onChangeTab: (newTab: number) => void,
  onEndAddDrag: () => void,
  handleCrumbClick: (id?: StringNumber) => void,
}

export const ACTION = 'Action'

export type ActionsProps = {
  step: FlowStep,
}

export interface AssignabilityProps {
  onChange: (name: string, value: string[]) => void,
  type: ELEMENT_TYPES,
  values: string[][],
}

export interface MinMaxConfigProps {
  title?: string,
  minName: string,
  maxName: string,
  onChange: (name: string, value: number) => void,
  min: Nullable<number>,
  max: Nullable<number>,
}

export interface AddElementProps {
  onEndDrag?: () => void,
}
