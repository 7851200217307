import styled from 'styled-components';
export const $Logo = styled.div `
  border: dashed #A8ACAC 1px;
  cursor: pointer;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  font-size: 1.1em;
  font-weight: bold;
  color: #A8ACAC;
  vertical-align: middle;
  line-height: 56px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: ${({ src }) => src ? 'no-repeat center/100% url(' + src + ')' : 'unset'};
  background-size: cover;
  transition: opacity 0.2s;
  position: relative;
`;
export const $Content = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  text-align: center;
  line-height: normal;
  font-size: 0.7em;
  & > div {
    width: fit-content;
    margin: 0 auto;
  }
`;
