import React from 'react';
import { $Radio, $FormLabel, $FormHelperText } from './styles';
import { RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';
import { $Italic } from '../newTypography/styles';
export const RadioOptions = ({ title, error, description, name = 'toggle', required, disabled, hideItalic, items, value, onChange = () => null, errorMsg }) => {
    const handleChange = (e) => {
        onChange(name, e.target.value);
    };
    return React.createElement(FormControl, { component: 'fieldset' },
        React.createElement($FormLabel, null,
            title,
            required && '*',
            !hideItalic &&
                React.createElement($Italic, null, "toggle")),
        React.createElement(RadioGroup, { row: true, "aria-label": name, "aria-autocomplete": 'none', name: name, value: value, onChange: handleChange }, items.map((opt) => (React.createElement(FormControlLabel, { key: opt.value, value: opt.value, disabled: disabled, control: React.createElement($Radio, { error: error, disabled: disabled }), label: opt.label })))),
        (description || errorMsg) &&
            React.createElement($FormHelperText, { error: error }, errorMsg || description));
};
