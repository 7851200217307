import React from 'react';
import { Grid, SvgIcon } from '../';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { $FormHelperText, $Input, $InputLabel } from '../newInput';
import { $FormElementControl } from '../newFormElement/styles';
import { $Autocomplete } from './styles';
export const AutocompleteInput = ({ title = '', multiple, description, value, name, required, disabled, onChange, onInputChange = () => null, onCreateItem, onEditItem, onDuplicateItem, items = [], withNone, }) => React.createElement($FormElementControl, null,
    React.createElement($InputLabel, { shrink: true },
        title,
        required && '*'),
    React.createElement($Autocomplete, { multiple: multiple, disabled: disabled, filterOptions: createFilterOptions({ stringify: (item) => `${item.label} ${item.value}` }), options: onCreateItem ?
            [{ label: `Create new ${name}`, value: 'create-new' }, ...(withNone ? [{ label: 'None', value: '' }] : []), ...items]
            : [...(withNone ? [{ label: 'None', value: '' }] : []), ...items], getOptionLabel: (option) => option.label, renderInput: (params) => {
            const { InputLabelProps, InputProps, ...rest } = params;
            return React.createElement($Input, { ...params.InputProps, ...rest, autoComplete: 'new-password', name: name });
        }, renderOption: !!onEditItem || !!onDuplicateItem || !!onCreateItem ?
            (option) => (React.createElement(React.Fragment, null,
                option.value === 'create-new' ? null : option.label,
                onCreateItem && option.value === 'create-new' &&
                    React.createElement(Grid, { container: true, wrap: 'nowrap', alignItems: 'center', onClick: (e) => {
                            e.stopPropagation();
                            onCreateItem();
                        } },
                        option.label,
                        React.createElement(SvgIcon, { name: 'plus', clickable: true, size: 16, color: '#757778' })),
                onEditItem && !!option.value && option.value !== 'create-new' && React.createElement(SvgIcon, { name: 'altEdit', clickable: true, size: 16, color: '#757778', onClick: (e) => {
                        e.stopPropagation();
                        onEditItem(option.value);
                    } }),
                onDuplicateItem && !!option.value && option.value !== 'create-new' && React.createElement(SvgIcon, { name: 'content-copy', clickable: true, size: 16, color: '#757778', onClick: (e) => {
                        e.stopPropagation();
                        onDuplicateItem(option.value);
                    } })))
            : undefined, disableClearable: true, value: value, onChange: onChange, onInputChange: onInputChange }),
    (description) &&
        React.createElement($FormHelperText, null, description));
