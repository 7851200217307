import * as React from 'react'
import { Select, NewDatePicker, Input, NewButton, Item, ROLES, NewCheckbox, stepTimeFrame, NotificationType, Switch, ROLE_NAME, ADMIN_PORTAL_ROLES } from 'prace-common-components'
import { $Container, $ModalContainer, $Grid, $Text } from '../AssignModal/styles'
import { InviteModalProps } from './types'
import Grid from '@material-ui/core/Grid'
import { STEPS } from 'constants/global'
import { useAppDispatch } from 'store/hooks'

export const InviteModal: React.FC<InviteModalProps> = (
  { data, steps, handleInvite, handleCancel, initialDeadline, cutoffId, cutoffs, canHaveLead, assignable, loading },
) => {
  const dispatch = useAppDispatch()
  const [inviteData, setInviteData] = React.useState<{firstName: string; lastName: string; email: string}>({
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    email: data?.email || '',
  })

  const filteredAssignableRoles = assignable.filter(
    (role) => ![...ADMIN_PORTAL_ROLES, ROLES.USER, ROLES.COMPUTE_CENTRE_REPRESENTATIVE].includes(role))

  const firstStepId = steps.length === 1 ? String(steps[0].id) : STEPS.SCIENTIFIC_REVIEW
  const [deadline, setDeadline] = React.useState<string>(initialDeadline || '')
  const [makeLead, setMakeLead] = React.useState<boolean>(false)
  const [customDeadline, setCustomDeadline] = React.useState<boolean>(!initialDeadline)
  const [role, setRole] = React.useState<Nullable<ROLES>>(
    filteredAssignableRoles.length ? filteredAssignableRoles[0] : null)
  const [stepId, setStepId] = React.useState<Nullable<string>>(firstStepId)

  const onChangeDeadline = (_name: string, value: StringNumber) => setDeadline(value as string)

  const handleInviteUser = () => {
    if(!stepId || !inviteData.firstName || !inviteData.lastName || !inviteData.email || !role) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Fill all required data' } })
      return
    }
    if(customDeadline && !deadline) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Custom Deadline is required' } })
      return
    }
    if(!!deadline && (new Date(deadline).getTime() < new Date().getTime())) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Deadline cannot be in the past' } })
      return
    }
    if(!role) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Role is required' } })
      return
    }
    handleInvite({...data, ...inviteData}, stepId, role, deadline, makeLead)
  }

  const onChange = (name: string, value: Nullable<StringNumber>) => {
    setInviteData((data) => ({...data, [name]: value as string}))
  }

  const onChangeStep = (_name: string, value: Nullable<string>) => {
    setStepId(value)
    const newStep = steps.find((step) => step.id === Number(value))
    if(newStep) {
      const { timeframe } = stepTimeFrame(cutoffs, cutoffId, newStep)
      if(timeframe) setDeadline(timeframe.end)
    }
  }

  const options: Item[] = steps.map((step) => ({ label: step.title, value: step.id })) || []

  const roleNameItems: Item[] = (filteredAssignableRoles || []).map((role) => ({ label: ROLE_NAME[role], value: role }))

  const today = new Date()
  today.setDate(today.getDate() + 1)

  return (
    <$ModalContainer>
      <>
        <$Grid container>
          <Grid item xs={12}>
            <Input onChange={onChange} required value={inviteData.firstName} title='First Name' name='firstName' type='text' hideItalic/>
          </Grid>
          <Grid item xs={12}>
            <Input onChange={onChange} required value={inviteData.lastName} title='Last Name' name='lastName' type='text' hideItalic/>
          </Grid>
          <Grid item xs={12}>
            <Input onChange={onChange} required value={inviteData.email} title='E-mail' name='email' type='email' hideItalic/>
          </Grid>
          <Grid item xs={12}>
            <Select
              required
              disabled
              title='To which step will the user be invited?'
              name='stepId'
              value={stepId}
              items={options}
              onChange={onChangeStep}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              required
              title='Which role will the user have?'
              description='The user will be assigned this role for this cut-off'
              name='role'
              value={role}
              items={roleNameItems}
              onChange={(_name, value) => setRole(value as ROLES)}
            />
          </Grid>
          <Grid item xs={12}>
            <NewCheckbox disabled={!canHaveLead} name='makeLead' value={makeLead} hideItalic onChange={(_, value: boolean) => setMakeLead(value)} title='Make this assignment lead' />
          </Grid>
          <Grid item xs={12}>
            <Switch name='customDeadline' value={customDeadline} onChange={(_, value: boolean) => setCustomDeadline(value)} title='Custom Deadline' />
          </Grid>
          <Grid item xs={12}>
            {customDeadline ? <NewDatePicker required withTime min={today} title='Deadline' name='deadline' value={deadline} onChange={onChangeDeadline} />
              : <$Text>{initialDeadline ? `Deadline: ${new Date(initialDeadline).toLocaleString()}` : 'No deadline'}</$Text>}
          </Grid>
        </$Grid>
        <$Container container justifyContent='space-between'>
          <Grid item xs={3}>
            <NewButton loading={loading} onClick={handleInviteUser}>Invite</NewButton>
          </Grid>
          <Grid item xs container justifyContent='flex-end'>
            <NewButton disabled={loading} variant='outlined' onClick={handleCancel}>Cancel</NewButton>
          </Grid>
        </$Container>
      </>
    </$ModalContainer>
  )
}
