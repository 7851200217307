import styled from 'styled-components';
import { Table, TableContainer, TableHead } from '@material-ui/core';
export const $EditTable = styled.div `
  margin-top: ${({ theme }) => theme.spacing(3)};
`;
export const $TableHeadActions = styled.div `
  min-height: unset;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
export const $Table = styled(Table) `
  border: solid 1px #C3C7C7;
  th {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
export const $TableHead = styled(TableHead) `
  background-color: #EFF0FF;
  .MuiTableCell-head {
    font-weight: 600;
    color: #0B2979;
  }
`;
export const $TableContainer = styled(TableContainer) `
  max-height: 440px;
`;
