import styled from 'styled-components'
import { $InputLabel } from 'prace-common-components'
import Grid from '@material-ui/core/Grid'

export const $ModalContainer = styled.div`
  width: 600px;
`

/* TODO: Use theme colors */
export const $Grid = styled(Grid)<{ divider?: boolean }>`
  max-height: 500px;
  overflow-y: auto;
  border-left: ${({ divider }) => divider && '1px solid #A8ACAC'};
  margin: ${({ theme }) => `${theme.spacing(3)} 0px`};
`

export const $Form = styled.form`
  min-width: 300px;
`

export const $Notice = styled.p`
  margin: 0;
  border-radius: 4px;
  font-size: 0.8em;
  padding: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.secondary};
`

export const $ActionButtons = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1)};
`

export const $LabelContainer = styled(Grid)`
  padding-bottom: 0;
`

export const $Label = styled($InputLabel)`
  &.MuiFormLabel-root {
    font-size: 1.2em;
  }
`

export const $OptionContainer = styled(Grid)`
  padding: ${({ theme }) => `${theme.spacing(1)} 0`};
`

export const $OptionRow = styled.div`
  width: 100%;
  padding-right: ${({ theme }) => theme.spacing(2)};
`
