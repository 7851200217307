import React from 'react';
import { SvgIcon } from '../../';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
export const FilterDropdown = ({ onClickMenu, menuItems, }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedValue, setSelectedValue] = React.useState(menuItems[0].value);
    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const handleSelect = (_e, value) => {
        onClickMenu(value);
        setSelectedValue(value);
        setAnchorEl(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SvgIcon, { name: 'filter', color: '#99a1aa', clickable: true, size: 16, viewBox: '0 0 15 16', onClick: handleOpen }),
        React.createElement(Menu, { anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: () => setAnchorEl(null) }, menuItems.map((item) => React.createElement(MenuItem, { key: item.value, onClick: (e) => handleSelect(e, item.value) },
            item.label,
            React.createElement(Radio, { checked: selectedValue === item.value, value: item.value }))))));
};
