import styled, { css } from 'styled-components';
import { TableRow } from '@material-ui/core';
import { SvgIcon } from '../../SvgIcon';
const defaultBorder = () => css `
  border: ${({ theme }) => `1px dashed ${theme.colors.secondary}`};
`;
const alternateBorder = (onlyHover) => css `
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  ${({ theme }) => theme.elevations[1]};
  border-left: ${({ theme }) => !onlyHover && `4px solid ${theme.colors.primaries.heavy}`};
`;
export const $Handle = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: move;
  margin: -1px;
  margin-right: 8px;
  min-height: 40px;
  border-radius: 4px;
`;
export const $Sortable = styled.div `
  display: flex;
  width: inherit;
  cursor: ${({ drag }) => drag ? 'move' : 'inherit'};
  align-items: stretch;
  opacity: ${({ isDragging, alternate }) => isDragging && !alternate ? 0 : 1};
  border: ${({ theme, selected, alternate, onlyHover }) => selected && !alternate ? `1px dashed ${theme.colors.secondaries.light}` : onlyHover ? 'unset' : `1px solid ${theme.colors.white}`};
  background-color:  ${({ theme, selected, alternate, onlyHover }) => selected && !alternate ? theme.colors.secondaries.light : onlyHover ? 'unset' : theme.colors.white};
  border-radius: ${({ theme, alternate }) => !alternate && theme.spacing(0.5)};
  ${$Handle} {
    display: ${({ onlyHover }) => onlyHover && 'none'};
    background-color: ${({ theme, selected, alternate, onlyHover }) => selected && !alternate ? theme.colors.secondaries.medium : onlyHover ? 'unset' : theme.colors.white};
  }
  &:hover {
    ${({ alternate, onlyHover }) => alternate ? alternateBorder(onlyHover) : defaultBorder()};
    background-color: ${({ theme, alternate, onlyHover }) => alternate ? onlyHover ? 'unset' : theme.colors.white : theme.colors.secondaries.light};
    .MuiButtonBase-root {
      padding-left: ${({ onlyHover }) => onlyHover && 0};
    }
    ${$Handle} {
      display: ${({ onlyHover }) => onlyHover && 'flex'};
      background-color: ${({ theme, alternate }) => !alternate && theme.colors.secondary};
    }
  }
`;
export const $SorTableContent = styled.div `
  width: 100%;
  padding: ${({ theme, noPadding }) => !noPadding && `0 ${theme.spacing(2)}`};
  ${({ noPointerEvents }) => noPointerEvents && 'input, textarea { pointer-events: none; }'}
`;
export const $SvgIcon = styled(SvgIcon) ``;
export const $SortableRow = styled(TableRow) `
  display: flex;
  height: 60px;
  align-items: stretch;
  opacity: ${({ isDragging }) => isDragging ? 0 : 1};
  border: ${({ theme, selected }) => selected ? `1px dashed ${theme.colors.secondaries.light}` : `1px solid ${theme.colors.white}`};
  background-color:  ${({ theme, selected }) => selected ? theme.colors.secondaries.light : theme.colors.white};
  border-radius: 4px;
  ${$Handle} {
    background-color: ${({ theme, selected }) => selected ? theme.colors.secondaries.medium : theme.colors.white};
  }
  &:hover {
    border: ${({ theme }) => `1px dashed ${theme.colors.secondary}`};
    background-color: ${({ theme }) => theme.colors.secondaries.light};
    ${$Handle} {
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;
