import { theme } from '../newTheme';
import React from 'react';
import ReactDOM from 'react-dom';
import { Title } from '../newTypography';
import { $Modal, $ModalContent, $ModalTop, $CloseIcon, $TitleContainer, $SvgIcon, $Warning, $WarningContainer, $WarningGrid } from './styles';
import { SvgIcon } from '../SvgIcon';
import { newTheme } from '../';
export const Modal = ({ children, title = '', noClose, open, type, small, notice, noTopMargin, alternateTitle, onClose = () => null }) => {
    const domElement = document.getElementById('root');
    if (!domElement)
        return null;
    const modalType = type === 'info' ? type : 'warning';
    return ReactDOM.createPortal(React.createElement($Modal, { open: open, onClose: onClose },
        React.createElement($ModalContent, { small: small },
            React.createElement($ModalTop, { noTopMargin: small || noTopMargin },
                type && React.createElement($SvgIcon, { name: type === 'warning-fill' ? 'warning' : modalType === 'warning' ? 'warning-outlined' : type, color: theme.colors.feedback[modalType], size: 26 }),
                React.createElement($TitleContainer, { fullWidth: !notice },
                    React.createElement(Title, { noWrap: true, alternate: alternateTitle }, title)),
                notice ?
                    React.createElement($WarningContainer, { item: true },
                        React.createElement($WarningGrid, { container: true },
                            React.createElement(SvgIcon, { name: 'warning', color: newTheme.colors.white, size: 16 }),
                            React.createElement($Warning, null, notice)))
                    : null,
                !noClose && React.createElement($CloseIcon, null,
                    React.createElement(SvgIcon, { name: 'close', size: 20, clickable: true, onClick: onClose, color: newTheme.colors.darkPrimary }))),
            children)), domElement);
};
