import styled from 'styled-components';
export const $SvgWrapper = styled.div `
  height: fit-content;
  cursor: ${({ clickable }) => clickable && 'pointer'};
  //TODO: Bound to clickable for legacy reasons, but all the references should be updated instead
  padding: ${({ clickable, noPadding }) => clickable && !noPadding && '6px'};
  border-radius: ${({ clickable }) => clickable && '50%'};
  :hover{
    background-color: ${({ clickable, theme }) => clickable && theme.colors.shades.light};
  }
  :active{
    background-color: ${({ clickable, theme }) => clickable && theme.colors.shades.medium};
  }
`;
export const $Svg = styled.svg `
  margin: 0 auto;
  display: block;
`;
export const $Image = styled.img `
  height: 50px;
  cursor: pointer;
`;
export const $USCAFlag = styled.img `
  margin-right: 4px;
`;
