import React, { FC } from 'react'
import { CardElement, Title } from 'prace-common-components'
import { $FieldContainer } from './styles'
import { ElementConditionsProps } from './types'
import { getConditionCardProps } from 'util/conditionUtils'

export const ElementConditions: FC<ElementConditionsProps> = ({ call, conditions = [], onEdit, onDelete }) => {
  const handleEdit = (id: number) => {
    onEdit(id)
  }

  const handleDelete = (id: number) => {
    onDelete(id)
  }

  return (
    <$FieldContainer container alignItems='center'>
      <Title subTitle>
        Conditions
      </Title>
      {conditions.map((condition, i) => (
        <CardElement
          {...getConditionCardProps(call, condition)}
        
          key={i}
          onEdit={() => handleEdit(condition.id)}
          onDelete={() => handleDelete(condition.id)}
        />
      ))}
    </$FieldContainer>
  )
}
