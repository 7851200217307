import { configureStore } from '@reduxjs/toolkit'
import { clientApi } from './api'
import { authReducer } from './auth'
import { loadingReducer, notificationsReducer } from 'prace-common-components'
import { cinesReducer } from './cines'
import { callsReducer } from './calls'
import { createBrowserHistory } from 'history'
import { organizationReducer } from './organization'

export const history = createBrowserHistory()

export const store = configureStore({
  reducer: {
    auth: authReducer,
    calls: callsReducer,
    notifications: notificationsReducer,
    loading: loadingReducer,
    cines: cinesReducer,
    organization: organizationReducer,
    [clientApi.reducerPath]: clientApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    clientApi.middleware,
    //sentryReduxMiddleware,
  ),
  devTools: ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
