import React, { FC, useState } from 'react'
import { DetailsProps as Props } from './types'
import { Collapse, Select, Title, ROLES, ROLE_NAME, capitalizeFirstChar, SvgIcon, NewModal, NewButton } from 'prace-common-components'
import { $GridContainer, $Label, $Text, $Divider, $Link, $ModalContainer } from './styles'
import Grid from '@material-ui/core/Grid'
import { useAppSelector } from 'store/hooks'
import { RootState } from 'store'
import { useTheme } from 'styled-components'

export const GlobalRoleNames = Object.values([
  ROLES.USER,
  ROLES.PRO,
]).map((role) => { return { label: ROLE_NAME[role], value: role }})

export const Details: FC<Props> = ({ user, onChangeRole }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [stateRole, setStateRole] = useState<ROLES>(user.role)
  const [openRole, setOpenRole] = useState(false)
  const theme = useTheme() as { colors: { icons: { visibility: string } }}
  const organization = useAppSelector((state: RootState) => state.organization.theme?.organization)

  const parseValue = (val: string | boolean) => {
    if (Date.parse(String(val))) {
      return new Date(String(val)).toLocaleDateString()
    }
    if (val === true) return 'YES'
    if (val === false) return 'NO'
    if(!val) return '-'
    return String(val)
  }
  const organizationName = organization?.shortName || 'PRACE'

  const handleClose = () => {
    setStateRole(user.role)
    setOpenRole(false)
  }

  return <>
    <$GridContainer container>
      {/* <Grid item xs={4}>
        //TODO: Need PHOTO FROM BE
      </Grid> */}
      <Grid item xs={4}>
        <$Label shrink> ID </$Label>
        <$Text>{user.id}</$Text>
      </Grid>
      <Grid item xs={2}>
        <$Label shrink> Gender </$Label>
        <$Text>{user.gender}</$Text>
      </Grid>
      <Grid item xs>
        <$Label shrink> Nationality </$Label>
        <$Text>{user.nationality}</$Text> {/* //TODO: Convert this date */}
      </Grid>
      <Grid item xs={4}>
        <$Label shrink> Date of Birth </$Label>
        <$Text>{parseValue(user.dateOfBirth)}</$Text>
      </Grid>
      <Grid item xs={6}>
        <$Label shrink>Role</$Label>
        <$Text inline>
          {capitalizeFirstChar(user.role)}
          {![ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(user.role) && <SvgIcon
            clickable
            noPadding
            color={theme.colors.icons.visibility}
            name='altEdit'
            onClick={() => setOpenRole(true)}
            size={20}
          />}
        </$Text>
      </Grid>
    </$GridContainer>
    <Title subTitle>Contacts</Title>
    <$GridContainer container>
      <Grid item xs={6}>
        <$Label shrink> Email </$Label>
        <$Text>{user.email}</$Text>
      </Grid>
      <Grid item xs>
        <$Label shrink> Phone </$Label>
        <$Text>{user.phoneNumber}</$Text> {/* //TODO: Need Number code */}
      </Grid>
      <Grid item xs={12}>
        <$Label shrink> Address </$Label>
        <$Text>{user.homeAddress}</$Text>
      </Grid>
      <Grid item xs={12}>
        <$Label shrink> Website </$Label>
        <$Link target='_blank' rel='noopener noreferrer' href={user.website}>{user.website}</$Link>
      </Grid>
      <Grid item xs={6}>
        <$Label shrink> Linkedin </$Label>
        <$Link target='_blank' rel='noopener noreferrer' href={user.linkedin}>{user.linkedin}</$Link>
      </Grid>
      <Grid item xs>
        <$Label shrink> Researchgate </$Label>
        <$Link target='_blank' rel='noopener noreferrer' href={user.researchgate}>{user.researchgate}</$Link>
      </Grid>
      <Grid item xs={6}>
        <$Label shrink> Google Schoolar </$Label>
        <$Link target='_blank' rel='noopener noreferrer' href={user.scholar}>{user.scholar}</$Link>
      </Grid>
      <Grid item xs>
        <$Label shrink> Scopus </$Label>
        <$Link target='_blank' rel='noopener noreferrer' href={user.scopus}>{user.scopus}</$Link>
      </Grid>
      <Grid item xs={6}>
        <$Label shrink> Other Link #1 </$Label>
        <$Link target='_blank' rel='noopener noreferrer' href={user.link1}>{user.link1}</$Link>
      </Grid>
      <Grid item xs>
        <$Label shrink> Other Link #2 </$Label>
        <$Link target='_blank' rel='noopener noreferrer' href={user.link2}>{user.link2}</$Link>
      </Grid>
    </$GridContainer>
    <Title subTitle>Research Fields</Title>
    <$GridContainer container>
      {user.fields.map((field, i) => <Grid key={i} item xs={12}>
        <$Label shrink>Research Field #{i+1}</$Label>
        <$Text>{field}</$Text>
      </Grid>)}
      <Grid item xs={12}>
        <$Label shrink>Keywords</$Label> {/* //TODO: NEED KEYWORDS FROM BE */}
        <$Text>-</$Text>
      </Grid>
    </$GridContainer>
    <$Divider />
    <Collapse title={organizationName} open={open} handleOpen={setOpen}>
      <$GridContainer container>
        <Grid item xs={12}>
          <$Label shrink>{`Was a ${organizationName} Committee member?`}</$Label>
          <$Text>{parseValue(user.temporary)}</$Text> {/* //TODO: Is this correct? */}
        </Grid>
        <Grid item xs={12}>
          <$Label shrink>Last mandate</$Label>
          <$Text>{`${parseValue(user.mandateStartDate)} - ${parseValue(user.mandateEndDate)}`}</$Text> {/* //TODO: DUMMY */}
        </Grid>
      </$GridContainer>
    </Collapse>
    {openRole &&
      <NewModal open={openRole} title='Change User Role' onClose={handleClose}>
        <$ModalContainer>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Select
                required
                title='Role'
                name='role'
                items={GlobalRoleNames}
                value={stateRole}
                onChange={(_n, value) => setStateRole(value as ROLES)}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='space-between'>
                <NewButton onClick={() => {
                  onChangeRole(stateRole)
                  setOpenRole(false)
                }}>Save</NewButton>
                <NewButton variant='outlined' onClick={handleClose}>Cancel</NewButton>
              </Grid>
            </Grid>
          </Grid>
        </$ModalContainer>
      </NewModal>
    }
  </>
}
