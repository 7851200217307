import React, { useState } from 'react';
import { NewButton, SvgIcon } from '../..';
import { $Pagination, $Input, $PaginationContainer } from './styles';
export const Pagination = ({ page, total = 0, rowsPerPage = 10, onChangePage = () => null }) => {
    const count = Math.ceil(total / rowsPerPage);
    const [searchPage, setSearchPage] = useState();
    const handleChangePage = () => {
        if (searchPage && searchPage > 0 && searchPage !== page && searchPage <= count)
            onChangePage(searchPage);
    };
    return (count > 1 ? React.createElement($PaginationContainer, null,
        React.createElement($Pagination, { color: 'primary', showLastButton: true, count: count, page: page, onChange: (_, page) => onChangePage(page) }),
        React.createElement($Input, { value: searchPage, onChange: (e) => setSearchPage(Number(e.target.value) || undefined) }),
        React.createElement(NewButton, { icon: React.createElement(SvgIcon, { name: 'search' }), onClick: handleChangePage, variant: 'text' }, "Go to page")) : null);
};
