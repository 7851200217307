import React, { FC } from 'react'
import { BankProps as Props } from './types'
import { Title } from 'prace-common-components'
import { $GridContainer, $Label, $Text } from './styles'
import Grid from '@material-ui/core/Grid'

export const Bank: FC<Props> = ({ user }) => {

  return <>
    <Title subTitle>Bank Details</Title>
    <$GridContainer container>
      <Grid item xs={12}>
        <$Label shrink> Bank Name </$Label>
        <$Text>{user.bankName || '-'}</$Text>
      </Grid>
      <Grid item xs={12}>
        <$Label shrink> Bank Holder Name </$Label>
        <$Text>{user.bankHolderName || '-'}</$Text>
      </Grid>
      <Grid item xs={12}>
        <$Label shrink> IBAN </$Label>
        <$Text>{user.iban || '-'}</$Text>
      </Grid>
      <Grid item xs={12}>
        <$Label shrink> Bank Routing </$Label>
        <$Text>{user.bankRouting || '-'}</$Text>
      </Grid>
      <Grid item xs={12}>
        <$Label shrink> Bank Remarks </$Label>
        <$Text>{user.bankRemarks || '-'}</$Text>
      </Grid>
      <Grid item xs={12}>
        <$Label shrink> Bank Address </$Label>
        <$Text>{user.bankAddress || '-'}</$Text>
      </Grid>
    </$GridContainer>
  </>
}
