import React, { useCallback, useState } from 'react'
import { DataTable, NestedMenu, NewButton, Title, DataRow, Search, FILTER_TYPE, newTheme } from 'prace-common-components'
import { Link } from 'react-router-dom'
import { $Chip, $Legacy, $Paper } from './styles'
import { $RowContainer } from './styles'
import { Routes } from 'constants/global'
import Grid from '@material-ui/core/Grid'
import { useGetCINESAppsQuery, useLazyDownloadExportQuery } from 'store/api/cines'
import { EXPORT_TYPE } from 'store/cines/types'

const menuItems = [
  {id: 0, label: <>Regular</>},
  {id: 1, label: <>Preparatory</>},
]

const limit = 10

export const Legacy = () => {
  const [page, setPage] = useState(1)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const { data: cines } =  useGetCINESAppsQuery(
    { search: debouncedSearch || '', page, limit, offset: limit * (page - 1) },
    { refetchOnMountOrArgChange: true },
  )
  const [downloadCinesExport, { isFetching: downloading }] = useLazyDownloadExportQuery()

  const handleChangePage = (page: number) => setPage(page)

  const handleSearch = useCallback((val: string) => {
    setPage(1)
    setDebouncedSearch(val)
  }, [])

  const handleExportClick = (e: React.MouseEvent<any>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleItemClick = (id: StringNumber) => {
    setAnchorEl(null)
    switch(id) {
      case 0:
        downloadExport(EXPORT_TYPE.REGULAR)
        break
      case 1:
        downloadExport(EXPORT_TYPE.PREPARATORY)
    }
  }

  const downloadExport = async (type: EXPORT_TYPE) => {
    try {
      await downloadCinesExport(type).unwrap()
    } catch (err) {
      console.log(err)
    }
  }

  const columns = [
    {
      key: 'id',
      type: FILTER_TYPE.NONE,
      noSort: true,
      name: 'Proposal ID',
      formatter(props: { row: DataRow }) {
        const { number = 0 } = props.row
        return <$RowContainer data-simplebar><Link to={Routes.CINES(number)}>{number}</Link></$RowContainer>
      },
    },
    { key: 'submitted', type: FILTER_TYPE.NONE, name: 'Submitted', noSort: true,
      formatter(props: { row: DataRow }) {
        const { submitted } = props.row
        return <$RowContainer data-simplebar>{
          <$Chip color={Number(submitted) ? newTheme.colors.primaries.medium : newTheme.colors.secondaries.medium}>
            {Number(submitted) ? 'Yes' : 'No'}
          </$Chip>
        }</$RowContainer>
      },
    },
    { key: 'callName', type: FILTER_TYPE.NONE, name: 'Call Name', noSort: true,
      formatter(props: { row: DataRow }) {
        const { call } = props.row
        return <$RowContainer data-simplebar>{call?.name || ''}</$RowContainer>
      },
    },
    { key: 'user', type: FILTER_TYPE.NONE, name: 'User', noSort: true,
      formatter(props: { row: DataRow }) {
        const { contact_name } = props.row
        return <$RowContainer data-simplebar>{contact_name || ''}</$RowContainer>
      },
    },
    { key: 'userEmail', type: FILTER_TYPE.NONE, name: 'User Email', noSort: true,
      formatter(props: { row: DataRow }) {
        const { contact_email } = props.row
        return <$RowContainer data-simplebar>{contact_email || ''}</$RowContainer>
      },
    },
    { key: 'machine', type: FILTER_TYPE.NONE, name: 'Call Machines', noSort: true,
      formatter(props: { row: DataRow }) {
        const { call } = props.row
        const machines = call?.machine || call?.machinePreparatory || []
        return <$RowContainer data-simplebar>{
          machines?.map((m: any) => m.name).join(', ')
          || call?.machinePreparatory?.map((m: any) => m.name).join(', ')
          || ''
        }</$RowContainer>
      },
    },
  ]
  //TODO: Handle any
  const rows = (cines?.data || []).map((row: any) => ({
    ...row,
    contact_name: row.regular?.contact_name || row.preparatory?.contact_name,
    contact_email: row.regular?.contact_email || row.preparatory?.contact_email,
  }))

  return (
    <$Legacy>
      <$Paper>
        <Title alternate fontSize={22}>Legacy</Title>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item xs={4}>
            <Search
              open
              placeholder='Search user emails'
              onChange={handleSearch}
            />
          </Grid>
          <Grid item>
            <NewButton
              loading={downloading}
              variant='outlined'
              onClick={(e: React.MouseEvent<any>) => handleExportClick(e)}>
              Export xlsx
            </NewButton>
          </Grid>
        </Grid>
      </$Paper>
      <DataTable
        noFilters
        rows={rows}
        columns={columns}
        total={cines?.total || 0}
        page={page}
        localFilter={false}
        rowsPerPage={limit}
        onChangePage={handleChangePage}
        noFilterType
      />
      <NestedMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        items={menuItems}
        handleItemClick={handleItemClick}
      />
    </$Legacy>
  )
}
