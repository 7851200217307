import '../assets/scripts/RVerify.js';
import '../assets/scripts/RVerify.min.css';
import acropole from '../assets/images/captcha/acropole.jpg';
import dog from '../assets/images/captcha/dog.jpg';
import tree from '../assets/images/captcha/tree.jpg';
import polar from '../assets/images/captcha/polar-bear.jpg';
import tiger from '../assets/images/captcha/tiger.jpg';
RVerify.configure({
    tolerance: 10,
    duration: 500,
    mask: .5,
    album: [
        polar,
        dog,
        tree,
        tiger,
        acropole,
    ],
});
export const activateCaptcha = (callBack) => {
    RVerify.action(callBack);
};
