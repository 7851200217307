import React from 'react';
import { $ImgContainer, $ExpireContainer } from './styles';
import expired from '../assets/icons/tokenExpired.svg';
import { NewButton, NewModal, Title } from '../';
export const ExpiredToken = ({ open, onLogin }) => (React.createElement(NewModal, { open: open, noClose: true },
    React.createElement($ExpireContainer, null,
        React.createElement($ImgContainer, null,
            React.createElement("img", { src: expired, alt: 'Token expired' })),
        React.createElement(Title, { alternate: true }, "Your session has expired"),
        React.createElement(NewButton, { onClick: onLogin }, "Please Login Again"))));
