import { createGlobalStyle } from 'styled-components';
import fontRegular from '../assets/fonts/Roboto-Regular.woff2';
import fontBold from '../assets/fonts/Roboto-Bold.woff2';
import fontLight from '../assets/fonts/Roboto-Light.woff2';
import fontMedium from '../assets/fonts/Roboto-Medium.woff2';
export const GlobalStyle = createGlobalStyle `
  body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;

    @font-face {
      font-family: roboto-regular;
      src: url(${fontRegular});
      font-style: normal;
    }

    @font-face {
      font-family: roboto-medium;
      src: url(${fontMedium});
      font-style: normal;
    }

    @font-face {
      font-family: roboto-bold;
      src: url(${fontBold});
      font-style: normal;
    }

    @font-face {
      font-family: roboto-light;
      src: url(${fontLight});
      font-style: normal;
    }

    font-family: roboto-regular, sans-serif;
  }

  *, *::after, *::before {
    box-sizing: inherit;
  }

  #root {
    display: grid;
    grid-template-areas: "side nav" "side main" "side foot";
    grid-template-rows: auto 1fr auto;
    grid-template-columns: auto 1fr;
    width: 100%;
    height: max-content;
    min-height: 100%;

    > div {
      grid-area: main;
    }
  }
`;
