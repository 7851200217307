import React, { PropsWithChildren } from 'react'
import { Editor, Transforms, Element as SlateElement } from 'slate'
import { useSlate } from 'slate-react'
import { Format } from '../custom-types'
import { $ToggleButton } from './styles'

const isMarkActive = (editor: Editor, format: Format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const toggleMark = (editor: Editor, format: Format) => {
  const isActive = isMarkActive(editor, format)
  if (isActive) {
    Editor.removeMark(editor, format)
    Transforms.setNodes<SlateElement>(
      editor,
      { [format]: false },
      {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === 'variable',
      },
    )
  } else {
    Editor.addMark(editor, format, true)
    Transforms.setNodes<SlateElement>(
      editor,
      { [format]: true },
      {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === 'variable',
      },
    )
  }
}

export const MarkButton: React.FC<PropsWithChildren<{format: Format}>> = ({ format, children }) => {
  const editor = useSlate()
  return (
    <$ToggleButton
      selected={isMarkActive(editor, format)}
      onClick={() => {
        toggleMark(editor, format)
      }}
    >
      {children}
    </$ToggleButton>
  )
}
