import styled from 'styled-components'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { SvgIcon, NewButton } from 'prace-common-components'

export const $ConditionLabel = styled.div<{ error?: boolean }>`
  background-color: ${({ theme, error }) => error ? theme.colors.feedback.error : theme.colors.primary};
  color: #fff;
  padding: 0px 12px;
  font-size: 0.9em;
  position: absolute;
  left: 8px;
  top: -8px;
  user-select: none;
`

/* TODO: Use theme colors */
export const $ConditionElement = styled.div<{ error?: boolean; border: boolean }>`
  max-width: fit-content;
  min-width: 500px;
  position: relative;
  padding: 8px;
  margin: 16px 0px;
  border: ${({ theme, border }) => border && `2px solid ${theme.colors.primary}`};
  background-color: ${({ theme, error }) => error ? 'rgba(255,21,21,0.15)' : theme.colors.cards.border};
`

export const $DeleteIcon = styled(SvgIcon)<{ error?: boolean; variant: 'primary' | 'secondary' }>`
  background-color: ${({ theme, error, variant }) => error ? theme.colors.feedback.error : theme.colors[variant]};
  border-radius: 50%;
  color: white;
  padding: 4px;
  position: absolute;
  right: -8px;
  top: -8px;
  user-select: none;
  cursor: pointer;
`

export const $SvgIcon = styled(SvgIcon)<{ error?: boolean; variant: 'primary' | 'secondary'; btn?: boolean }>`
  background-color: ${({ theme, error, variant }) => error ? theme.colors.feedback.error : theme.colors[variant]};
  border-radius: 50%;
  color: white;
  padding: 4px;
  position: absolute;
  right: -8px;
  bottom: -8px;
  user-select: none;
  cursor: ${({ btn }) => btn && 'pointer'};
`

/* TODO: Use theme colors */
export const $GroupElement = styled.div<{ error?: boolean; border: boolean }>`
  max-width: fit-content;
  position: relative;
  min-width: 500px;
  padding: 8px;
  margin: 16px 0px;
  border: ${({ theme, border }) => border && `2px solid ${theme.colors.secondary}`};
  background-color: ${({ error }) => error ? 'rgba(255,21,21,0.15)' : '#FFF6EF'};
`

export const $ToggleButton = styled(ToggleButton)<{ withPadding?: boolean }>`
  &.MuiToggleButton-root {
    height: 100%;
    border: none;
    border-radius: 0;
    text-transform: none;
    padding: ${({ withPadding }) => withPadding && '4px'};
  }
`

export const $DeleteWrapper = styled.div`
  display: none;
  margin-left: ${({ theme }) => theme.spacing(0.5)};

  &>div, svg {
    padding: 0;
    margin: 0;
  }
`

export const $Variable = styled.span<{
  boxShadow: boolean,
  error?: boolean,
  defaultVar?: boolean,
  groupVariable?: boolean,
  italic?: boolean,
  bold?: boolean,
  underline?: boolean,
  inline?: boolean,
  onRemove?: boolean,
}>`
  padding: ${({ theme, inline }) => inline ? `${theme.spacing(0.5)} ${theme.spacing(1)}` : `${theme.spacing(1)} ${theme.spacing(2)}`};
  margin: ${({ theme, inline }) => inline ? '0 1px' : theme.spacing(0.5)};
  vertical-align: baseline;
  display: ${({ onRemove }) => onRemove ? 'flex' : 'inline-block'};
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing(2)};
  border: ${({ theme, error, groupVariable }) => `1px solid ${error ? theme.colors.button.error : groupVariable ? theme.colors.button.primary : theme.colors.button.secondary}`};
  background-color: ${({ theme, defaultVar, error, groupVariable }) =>
    error ? theme.colors.errors.variable :
      groupVariable ? theme.colors.secondaries.light :
        defaultVar ? theme.colors.cards.background : theme.colors.cards.backgroundDarker};
  font-size: 0.9em;
  box-shadow: ${({ boxShadow }) => boxShadow ? '0 0 0 2px #B4D5FF' : 'none'};
  font-style: ${({ italic }) => italic && 'italic'};
  font-weight: ${({ bold }) => bold && 'bold'};
  text-decoration: ${({ underline }) => underline && 'underline'};

  &:hover {
    ${$DeleteWrapper} {
      display: block;
    }
  }
`

export const $AddVariableButton = styled(NewButton)`
  &.MuiButtonBase-root {
    padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
    line-height: normal;
  }
`

export const $VariableButton = styled.div`
  cursor: pointer;
`

export const $Link = styled.a<{ selected: boolean }>`
  position: relative;
  box-shadow: ${({ selected }) => selected ? '0 0 0 2px #ddd' : 'none'};
`

export const $LinkIcon = styled.div`
  position: absolute;
  padding: 4px;
  position: absolute;
  right: -4px;
  bottom: -4px;
`
