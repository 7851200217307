import React from 'react';
import { $Card, $CardTexts, $CardElementTitle, $CardElementSubTitle, $CardElementSubText } from './styles';
import { Grid } from '@material-ui/core';
import { SvgIcon } from '../SvgIcon';
import { ConfirmAction } from '../newConfirmModal';
import { Tooltip, newTheme } from '../';
export const CardElement = ({ title, error, subTitle, subText, icon, noBorder, noPadding, onEdit, onDelete, onActions, tooltipText, }) => React.createElement($Card, { noBorder: noBorder, noPadding: noPadding },
    React.createElement(Grid, { container: true, alignItems: 'center' },
        icon &&
            React.createElement(Grid, { item: true },
                React.createElement(SvgIcon, { name: icon, size: '30', color: '#0B2979' })),
        React.createElement($CardTexts, { item: true },
            React.createElement($CardElementTitle, { error: error }, title),
            tooltipText ?
                React.createElement(Tooltip, { arrow: true, placement: 'top', show: true, text: tooltipText },
                    React.createElement($CardElementSubTitle, null, subTitle))
                :
                    React.createElement($CardElementSubTitle, null, subTitle),
            subText &&
                React.createElement($CardElementSubText, null, subText)),
        onActions && React.createElement(SvgIcon, { name: 'dots-vrtical', size: '20', color: newTheme.colors.feedback.disable, clickable: true, onClick: onActions }),
        onEdit && React.createElement(SvgIcon, { name: 'altEdit', size: '20', color: newTheme.colors.feedback.disable, clickable: true, onClick: onEdit }),
        onDelete &&
            React.createElement(ConfirmAction, { deletion: true, title: 'Delete', onConfirm: onDelete },
                React.createElement(SvgIcon, { name: 'delete', size: '20', color: newTheme.colors.feedback.disable, clickable: true, onClick: onDelete }))));
