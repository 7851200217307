import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import { $Badge } from 'prace-common-components'

export const $Centers = styled.div`
  flex: 1;
  max-width: 1400px;
  margin: 0 auto;
  padding: 50px 25px;
  font-family: roboto-light, sans-serif;
  width: 100%;
`

export const $CenterName = styled.div`
  cursor: pointer;
`

export const $SubTitle = styled.p`
  flex: 0;
  margin: 10px;
  margin-bottom: 50px;
`

export const $Paper = styled(Paper)`
  width: 100%;
  margin: ${({ theme }) => `${theme.spacing(3)} auto`};
  &.MuiPaper-elevation1 {
    box-shadow:none;
    border: none;
  }
  .MuiInputBase-root {
    margin-top: ${({ theme }) => theme.spacing(1)};
    max-width: 400px;
  }
`

export const $Chip = styled($Badge)`
  margin: 0;
  max-height: 25px;
  border-radius: 15px;
  padding: ${({ theme }) => `0 ${theme.spacing(1)}`};
`

export const $ChipContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  .simplebar-content-wrapper {
    display: flex;
    align-items: center;
  }
`
