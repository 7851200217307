import styled from 'styled-components'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import { $InputLabel, Divider } from 'prace-common-components'
import { Box, IconButton, Typography } from '@material-ui/core'

export const $Drawer = styled(Drawer)`
  .MuiPaper-root {
    width: 500px;
    padding: ${({ theme }) => `${theme.spacing(3)} 0`};
    border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  }

  .MuiTab-root {
    min-width: unset;
  }
`

export const $DrawerTop = styled.div<{ noTopMargin?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ theme }) => `0 ${theme.spacing(3)}`};
  margin-bottom: ${({ theme, noTopMargin }) => !noTopMargin && theme.spacing(3)};

  h3 {
    margin: 0;
  }
`

export const $Container = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};

  h4 {
    margin-top: ${({ theme }) => theme.spacing(1)};
    margin-left: 4px;
  }
`

export const $Grid = styled(Grid)`
  gap: ${({ theme }) => theme.spacing(2)};
`

export const $GridContainer = styled($Grid)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`

export const $Label = styled($InputLabel)`
  &.MuiFormLabel-root {
    font-size: 1.2em;
  }
`

export const $Text = styled.div<{inline?: boolean}>`
  margin-left: 4px;
  display: ${({ inline }) => inline ? 'inline-flex' : 'block'};
`

export const $Divider = styled(Divider)`
  &.MuiDivider-root {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`

export const $Link = styled.a`
  position: relative;
  margin-left: 4px;
  font-size: 0.9em;
`
export const $Box = styled(Box)`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  padding-top: ${({ theme }) => theme.spacing(2)};
`

export const $Message = styled(Typography)`
  padding-top: ${({ theme }) => theme.spacing(1)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`

export const $Icon = styled.svg<{
  $width?: number,
  $height?: number,
}>`
  width: ${({ $width }) => ($width ? `${$width}px` : '24px')};
  height: ${({ $height }) => ($height ? `${$height}px` : '24px')};
  viewBox: ${({ $width, $height }) => `0 0 ${$width || 24} ${$height || 24}`};
  fill: none;
  stroke: currentColor;
`

export const $IconPath = styled.path`
  d: ${({ d }) => d};
`

export const $IconButton = styled(IconButton)`
  &.MuiIconButton-root {
    padding: ${({ theme }) => theme.spacing(1)};
    color: ${({ theme }) => theme.colors.primary};
    transition: all 0.3s ease;
  }

  &.MuiIconButton-root:hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.secondary}
  }
`

export const $ModalContainer = styled.div`
  width: 500px;
`
