import styled, { css } from 'styled-components';
import { Button } from '@material-ui/core';
const backgroundColor = (btncolor, error, type = 'secondary') => css `
  ${({ theme }) => error ? theme.colors.button.error : btncolor || theme.colors.button[type]};
`;
export const $Button = styled(Button) `
  width: ${({ fullWidth }) => fullWidth ? '100%' : 'max-content'};
  min-height: 38px;
  cursor: pointer;

  &.MuiButton-root {
    padding: ${({ theme }) => theme.paddings.btn};
    font-size: ${({ theme }) => theme.fontSizes.btn};
    border-radius: ${({ theme }) => theme.dimensions.btnRadius};
    font-family: roboto-medium, sans-serif;
    font-weight: 500;
    text-transform: none;
    letter-spacing: normal;
  }

  &.MuiButton-text {
    box-shadow: none;
    color: ${({ btncolor, error }) => backgroundColor(btncolor, error)};
  }

  &.MuiButton-contained {
    background-color: ${({ btncolor, error }) => backgroundColor(btncolor, error, 'primary')};
    box-shadow: none;
    color: white;
    &:hover {
      background-color: ${({ btncolor, error }) => backgroundColor(btncolor, error, 'primary')};
      filter: brightness(110%);
    }
  }

  &.MuiButton-outlined {
    border: 1px solid ${({ btncolor, error }) => backgroundColor(btncolor, error)};
    background-color: ${({ noBackground }) => noBackground ? 'transparent' : '#fff'};
    box-shadow: none;
    color: ${({ btncolor, error }) => backgroundColor(btncolor, error)};
    &:hover {
      background-color: ${({ noBackground }) => noBackground ? 'transparent' : '#fff'};
      ${({ theme }) => theme.elevations[1]};
      filter: brightness(95%);
    }
  }

  :disabled {
    &.MuiButton-outlined.Mui-disabled,
    &.MuiButton-contained.Mui-disabled {
      box-shadow: none;
      background-color: ${({ theme }) => theme.colors.button.disabled};
      color: ${({ theme }) => theme.colors.text.disabled};
      cursor: default;
    }
  }

  :focus {
    &.MuiButton-outlined.Mui-focusVisible,
    &.MuiButton-contained.Mui-focusVisible {
      box-shadow: none;
      filter: brightness(112%);
      color: white;
    }

    &.MuiButton-contained.Mui-focusVisible {
      background-color: ${({ theme }) => theme.colors.button.primary};
    }

    &.MuiButton-outlined.Mui-focusVisible {
      background-color: ${({ theme }) => theme.colors.button.secondary};
    }
  }

`;
