import React, { useCallback } from 'react'
import { Collapse, Divider, ElementOptions, ElementValue, FORM_ELEMENT_TYPES, NewButton } from 'prace-common-components'
import { EditGroupFieldProps } from './types'
import { $FieldContainer } from './styles'
import { ElementConditions } from './ElementConditions'
import { $ButtonContainer } from '../styles'
import { useToggle } from 'util/useToggle'

export const EditGroupField: React.FC<EditGroupFieldProps> = ({
  groupElement,
  index,
  onChange,
  onEditOptionsGroup,
  onDuplicateOptionsGroup,
  onCreate,
  call,
  onEdit,
  onDelete,
}) => {
  const { open, toggle } = useToggle()
  
  const handleChange = useCallback((name: string, value: ElementValue) => {
    onChange(name, value, groupElement.elementType as FORM_ELEMENT_TYPES, index)
  }, [groupElement.elementType, index, onChange])
  
  const handleAddConditionClick = useCallback(() => {
    onEdit(undefined, groupElement)
  }, [groupElement, onEdit])

  return (
    <$FieldContainer container alignItems='center'>
      <Collapse
        bold={false}
        title={groupElement.title}
        type={groupElement.elementType}
        open={open}
        handleOpen={toggle}
      >
        <ElementOptions
          key={groupElement.id}
          element={groupElement}
          onChange={handleChange}
          onCreate={onCreate}
          onEditItem={onEditOptionsGroup}
          onDuplicateItem={onDuplicateOptionsGroup}
        />
        <Divider />
        <ElementConditions
          conditions={groupElement.effects}
          call={call}
          onEdit={onEdit}
          onDelete={onDelete}
        />
        <$ButtonContainer>
          <NewButton
            variant='outlined'
            onClick={handleAddConditionClick}
          >
            Add Condition
          </NewButton>
        </$ButtonContainer>
      </Collapse>
      <Divider />
    </$FieldContainer>
  )
}
