import styled from 'styled-components';
import { Drawer, IconButton } from '@material-ui/core';
import { device } from '../newTheme';
const drawerMaxWidth = 450;
const drawerWidth = '100%';
const openedMixin = (maxWidth) => ({
    width: drawerWidth,
    minWidth: maxWidth ?? drawerMaxWidth,
    transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    overflowX: 'unset',
    overflowY: 'unset',
});
const closedMixin = (theme) => ({
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    overflowX: 'unset',
    overflowY: 'unset',
    [`@media ${device.mobileS}`]: {
        width: `calc(${theme.spacing(3)} + 1px)`,
    },
});
export const $Drawer = styled(Drawer)(({ theme, open, maxWidth }) => ({
    maxWidth: maxWidth ?? drawerMaxWidth,
    width: drawerWidth,
    height: '100%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    float: 'right',
    ...(open && {
        ...openedMixin(maxWidth),
        '& .MuiDrawer-paper': openedMixin(maxWidth),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
    '& .MuiDrawer-paper': {
        position: 'relative',
        zIndex: 5,
        overflowY: 'unset',
    },
}));
export const $IconButton = styled(IconButton) `
  &.MuiIconButton-root{
    position: absolute;
    ${({ left }) => left ? 'right: -12px' : 'left: -12px'};
    top: 3px;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    padding: 3px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 2px 0px, rgb(0 0 0 / 10%) 0px 2px 4px 0px;
  }
`;
export const $DrawerContainer = styled.div `
  position: sticky;
  top: 150px;
`;
export const $DrawerContent = styled.div `
  width: 100%;
  height: 100%;
  display: ${({ open }) => open ? 'flex' : 'none'};
  flex-direction: column;
  gap: 24px;
  padding: ${({ theme, noHorizPadding }) => noHorizPadding ? `${theme.spacing(3.5)} 0` : theme.spacing(3.5)};
  padding-top: ${({ noTopPadding }) => noTopPadding ? 0 : 'inherit'};
`;
export const $DrawerModal = styled(Drawer) `
  .MuiPaper-root {
    max-width: 500px;
    width: 100%;
    padding: ${({ theme }) => `${theme.spacing(3)} 0`};
    border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  }
  .MuiTab-root {
    min-width: unset;
  }
`;
export const $DrawerModalTop = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ theme }) => `0 ${theme.spacing(3)}`};
  margin-bottom: ${({ theme, noTopMargin }) => !noTopMargin && theme.spacing(3)};
  h3 {
    margin: 0;
  }
`;
export const $DrawerModalContainer = styled.div `
  padding: ${({ theme }) => `0 ${theme.spacing(3)}`};
  h4 {
    margin-top: ${({ theme }) => theme.spacing(1)};
    margin-left: 4px;
  }
`;
