import { makeStyles } from '@material-ui/core';
export default makeStyles({
    stack: {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        top: 42,
        left: 0,
        right: 0,
        margin: '0 auto',
        width: 'fit-content',
        zIndex: 1600,
        '& .MuiSnackbar-root': {
            position: 'relative',
        },
    },
});
