import styled from 'styled-components';
import Pagination from '@material-ui/lab/Pagination';
export const $PaginationContainer = styled.div `
  float: right;
  margin-top: ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
export const $Pagination = styled(Pagination) `
  & .MuiPaginationItem-textPrimary.Mui-selected {
    background-color: ${({ theme }) => theme.colors.primary};
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
export const $Input = styled.input `
  width: 60px;
  border: ${({ theme }) => `1px solid ${theme.colors.text.grey}`};
  background-color: ${({ theme }) => theme.colors.white};
  height: 32px;
  border-radius: 4px;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;
