export const isEmail = (email) => {
    return RegExp(/^(([^\\<>()\[\]\.,;:\s@\"]+(\.[^\\<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^\\<>()[\]\.,;:\s@\"]+\.)+[^\\<>()[\]\.,;:\s@\"]{2,})$/i).test(email);
};
export const isRequired = (value) => {
    if (typeof value === 'number')
        return true;
    if (value.length > 0)
        return true;
    return false;
};
export const isValidURL = (url) => {
    const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
    return re.test(String(url).toLowerCase());
};
export const isValidPassword = (pass) => {
    const passMinLength = 12;
    const uppercase = /[A-Z]/;
    const lowercase = /[a-z]/;
    const symbol = /[^a-zA-Z0-9]/g;
    const passLength = (pass.length >= passMinLength);
    return (uppercase.test(pass) && lowercase.test(pass) && symbol.test(pass) && passLength);
};
