import React, { useState, useEffect, useCallback, FC, ChangeEvent, useRef, useMemo } from 'react'
import { Select, NewButton, Item, AutocompleteInput, NewDatePicker, NewCheckbox, NotificationType, Switch, ADMIN_ID, Input, ROLES, ROLE_NAME_INITIALS, ROLE_NAME, User, ADMIN_PORTAL_ROLES } from 'prace-common-components'
import { $Container, $ModalContainer, $Grid, $Text } from './styles'
import { AssignModalProps } from './types'
import Grid from '@material-ui/core/Grid'
import { useSearchUsersMutation } from 'store/api/users'
import { useAppDispatch } from 'store/hooks'

export const AssignModal: FC<AssignModalProps> = ({
  assignable = [],
  handleAssign,
  handleCancel,
  applicationId,
  activeCutOff,
  initialPartition,
  initialDeadline,
  canHaveLead,
  loading,
}) => {
  const dispatch = useAppDispatch()
  const resources = activeCutOff?.resources ? activeCutOff.resources : []
  /* Remove resources that are not from the active cutoff and selected partition */
  const allowedResourcePartitions = resources.filter((resource) => !resource.partition.disabled)
  const partitionOptions: Item[] =  [...new Set(allowedResourcePartitions.map((resource) => ({
    label: resource.partition.name, value: resource.partition.id,
  } as Item)))] || []

  /* Sort partitions by name */
  const sortedPartitionOptions = partitionOptions?.sort((a, b) => {
    if (a.label < b.label) return -1
    if (a.label > b.label) return 1
    return 0
  }) || []

  const filteredAssignableRoles = useMemo(() => assignable.filter(
    (role) => ![...ADMIN_PORTAL_ROLES, ROLES.USER].includes(role)), [assignable])

  const defaultPartition = initialPartition ? String(initialPartition.id) : null

  const [partitionId, setPartitionId] = useState<Nullable<string>>(defaultPartition)
  const [adminAssignment, setAdminAssignment] = useState<boolean>(false)
  const [role, setRole] = useState<Nullable<ROLES>>(
    filteredAssignableRoles.length ? filteredAssignableRoles[0] : null)
  const [customDeadline, setCustomDeadline] = useState<boolean>(!initialDeadline)
  const [userItem, setUserItem] = useState<Item>()
  const [makeLead, setMakeLead] = useState<boolean>(false)
  const [deadline, setDeadline] = useState<string>(initialDeadline || '')
  const searchTimer = useRef<number>()
  const [userSuggestions, setUserSugestions] = useState<User[]>([])
  const [searchUsers] = useSearchUsersMutation()

  const getUsers = useCallback(async (search = '') => {
    try {
      const data = await searchUsers({ 
        search,
        roles: filteredAssignableRoles,
        applicationId,
        page: 0,
        pageSize: 20,
      }).unwrap()
      setUserSugestions(data.users || [])
    } catch (err) {
      console.log(err)
    }
  }, [searchUsers, applicationId, filteredAssignableRoles])

  useEffect(() => {
    getUsers('')
  }, [getUsers])

  const onChangeDeadline = (_name: string, value: StringNumber) => setDeadline(value as string)

  const onInputChange = (_: any, value: string) => {
    clearTimeout(searchTimer.current)
    if(filteredAssignableRoles.length) {
      try {
        searchTimer.current = window.setTimeout(async () => {
          await getUsers(value)
        }, 500)
      } catch (err) {
        console.log(err)
      }
    }
  }

  const handleAddAssign = () => {
    if(!userItem && !adminAssignment) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Please choose an assignee' } })
      return
    }
    if(customDeadline && !deadline) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Custom Deadline is required' } })
      return
    }
    if(!!deadline && (new Date(deadline).getTime() < new Date().getTime())) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Deadline cannot be in the past' } })
      return
    }
    if(!role && !adminAssignment) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Please choose a role' } })
      return
    }

    handleAssign(
      deadline || undefined,
      adminAssignment ? ADMIN_ID : Number(userItem?.value || 0),
      partitionId,
      makeLead,
      role || ROLES.ADMIN,
    )
  }

  const options: Item[] = userSuggestions.map((suggestion) => ({
    label: `${suggestion.firstName || ''} ${suggestion.lastName || ''} - ${suggestion.email || ''} ${suggestion.role ? `(${ROLE_NAME_INITIALS[suggestion.role as ROLES || ROLES.USER]})` : ''}`,
    value: suggestion.id,
  })) || []

  const today = new Date()
  today.setDate(today.getDate() + 1)

  const roleNameItems: Item[] = (filteredAssignableRoles || []).map((role) => ({ label: ROLE_NAME[role], value: role }))

  return (
    <$ModalContainer>
      <>
        <$Grid container>
          <Grid item xs={12}>
            {adminAssignment ? <Input title='User search' hideItalic disabled /> :
              <AutocompleteInput
                title='User search'
                name='assign'
                required
                disabled={adminAssignment}
                items={options}
                value={userItem}
                onChange={(_: ChangeEvent<unknown>, user: unknown) => {
                  const userRole = userSuggestions.find((o) => o.id == (user as Item).value)?.role
                  if(userRole) setRole(userRole as ROLES)
                  setUserItem(user as Item)
                }}
                onInputChange={onInputChange}
              />
            }
          </Grid>
          <Grid item xs={12}>
            {adminAssignment ? <Input title='Which role will the user have?' hideItalic disabled description='The user will be assigned this role for this cut-off' /> : <Select
              required
              disabled={!!userSuggestions.find((o) => o.id == userItem?.value)?.role}
              title='Which role will the user have?'
              description='The user will be assigned this role for this cut-off'
              name='role'
              value={role}
              items={roleNameItems}
              onChange={(_name, value) => setRole(value as ROLES)}
            />}
          </Grid>
          <Grid item xs={12}>
            <NewCheckbox name='adminAssignment' value={adminAssignment} hideItalic onChange={(_, value: boolean) => setAdminAssignment(value)} title='Admin assignment' />
          </Grid>
          <Grid item xs={12}>
            <Select
              title='Select Partition'
              name='partitionId'
              value={partitionId}
              items={sortedPartitionOptions}
              onChange={(_name: string, value: Nullable<string>) => setPartitionId(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <NewCheckbox
              disabled={!canHaveLead}
              name='makeLead'
              value={makeLead}
              hideItalic
              onChange={(_, value: boolean) => setMakeLead(value)}
              title='Make this assignment lead'
              description={canHaveLead ? '' : 'This step cannot have lead assignments.'}
            />
          </Grid>
          <Grid item xs={12}>
            <Switch name='customDeadline' value={customDeadline} onChange={(_, value: boolean) => setCustomDeadline(value)} title='Custom Deadline' />
          </Grid>
          <Grid item xs={12}>
            {customDeadline ? <NewDatePicker required withTime min={today} title='Deadline' name='deadline' value={deadline} onChange={onChangeDeadline} />
              : <$Text>{initialDeadline ? `Deadline: ${new Date(initialDeadline).toLocaleString()}` : 'No deadline'}</$Text>}
          </Grid>
        </$Grid>
        <$Container container justifyContent='space-between'>
          <Grid item xs={3}>
            <NewButton loading={loading} onClick={handleAddAssign}>Assign</NewButton>
          </Grid>
          <Grid item xs container justifyContent='flex-end'>
            <NewButton disabled={loading} variant='outlined' onClick={handleCancel}>Cancel</NewButton>
          </Grid>
        </$Container>
      </>
    </$ModalContainer>
  )
}
