import styled from 'styled-components';
import { SvgIcon } from '../SvgIcon';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
export const $Modal = styled(Modal) `
  @keyframes modalFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  animation: modalFadeIn 0.150s ease-in-out;
`;
export const $ModalTop = styled.div `
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme, noTopMargin }) => !noTopMargin && theme.spacing(3)};
  h3 {
    margin: 0;
  }
`;
export const $SvgIcon = styled(SvgIcon) `
  margin-right: ${({ theme }) => theme.spacing(1)};
`;
export const $CloseIcon = styled.div `
  margin-right: 0;
  margin-left: auto;
`;
export const $ModalContent = styled.div `
  max-width: ${({ small }) => small && '550px'};
  ${({ theme }) => theme.elevations[4]};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(5)};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
`;
export const $TitleContainer = styled.div `
  width: ${({ fullWidth }) => fullWidth && '100%'};
  max-width: ${({ fullWidth }) => fullWidth && '350px'};
  min-width: ${({ fullWidth }) => !fullWidth && 'fit-content'};
`;
export const $WarningContainer = styled(Grid) `
  &.MuiGrid-item {
    margin-left: ${({ theme }) => theme.spacing(4)};
    width: 100%;
  }
`;
export const $WarningGrid = styled(Grid) `
  height: 100%;
  padding: ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.colors.secondaryShades[80]};
  &.MuiGrid-container {
    width: fit-content;
  }
`;
export const $Warning = styled.div `
  margin-right: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.9em;
`;
