import * as React from 'react'
import update from 'immutability-helper'
import { NewCheckbox, NewModal, Title, NewDatePicker, FlowTimeFrame } from 'prace-common-components'
import { $ButtonTimeframes, $TimeframeContainer, $Propagate } from './styles'
import Grid from '@material-ui/core/Grid'
import { TimeFramesProps, EditFlowTimeFrame } from './types'

export const TimeFrames: React.FC<TimeFramesProps> = ({
  timeframes,
  callCutoffs = [],
  onSaveTimeframes,
  btnText,
}) => {
  const [stateTimeframes, setStateTimeframes] = React.useState<EditFlowTimeFrame[]>(
    timeframes.length === 0 ? [{ start: null, end: null }] : timeframes)
  const [openPropagate, setOpenPropagate] = React.useState<boolean>(false)
  const [propagateDeadlines, setPropagateDeadlines] = React.useState<boolean>(false)
  const hasCallCutoffs = !!callCutoffs.length

  const onChangeCutoffs = (name: string, value: StringNumber, idx: number) => {
    setStateTimeframes((stateTimeframes) => update(stateTimeframes, {
      [idx]: { [name]: { $set: value || null }},
    }),
    )
  }

  const onSubmitCutoffs = () => onSaveTimeframes(stateTimeframes as FlowTimeFrame[], propagateDeadlines)

  return <>
    <$TimeframeContainer container>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Title subTitle alternate>Cut-off Name</Title>
        </Grid>
        <Grid item xs={4}>
          <Title subTitle alternate>Opening Date</Title>
        </Grid>
        <Grid item xs={4}>
          <Title subTitle alternate>Closing Date</Title>
        </Grid>
      </Grid>
      {stateTimeframes.map((timeframe, idx) => <Grid key={idx} container spacing={2} alignItems='center'>
        <Grid item xs={4}>
          {callCutoffs.find((cutOff) => cutOff?.id == timeframe.cutoffId)?.title || ''}
        </Grid>
        <Grid item xs={4}>
          <NewDatePicker
            withTime
            placeholder='Start Timeframe Date'
            min={hasCallCutoffs && callCutoffs[idx]?.start ? new Date(callCutoffs[idx].start) : undefined}
            max={hasCallCutoffs && callCutoffs[idx]?.end ? new Date(callCutoffs[idx].end!) : undefined}
            name='start'
            value={timeframe.start}
            onChange={(name: string, value: StringNumber) => onChangeCutoffs(name, value, idx)}
          />
        </Grid>
        <Grid item xs={4}>
          <NewDatePicker
            withTime
            min={timeframe.start ? new Date(timeframe.start) : undefined}
            max={hasCallCutoffs && callCutoffs[idx]?.end ? new Date(callCutoffs[idx].end!) : undefined}
            error={!!(timeframe.start && timeframe.end &&
              (new Date(timeframe.start).getTime() > new Date(timeframe.end).getTime()))}
            placeholder='End Timeframe Date'
            name='end'
            value={timeframe.end}
            onChange={(name: string, value: StringNumber) => onChangeCutoffs(name, value, idx)}
          />
        </Grid>
      </Grid>,
      )}
    </$TimeframeContainer>
    <Grid container>
      <$ButtonTimeframes onClick={() => setOpenPropagate(true)}>
        {btnText}
      </$ButtonTimeframes>
    </Grid>
    {openPropagate &&
      <NewModal open small noTopMargin alternateTitle title='Propagate deadlines' onClose={() => setOpenPropagate(false)}>
        <>
          <$Propagate>
            <NewCheckbox name='propagateDeadlines' value={propagateDeadlines} hideItalic onChange={(_, value: boolean) => setPropagateDeadlines(value)} title='Propagate step deadlines to assignments?' />
          </$Propagate>
          <Grid container>
            <$ButtonTimeframes onClick={onSubmitCutoffs}>
              {btnText}
            </$ButtonTimeframes>
          </Grid>
        </>
      </NewModal>
    }
  </>
}
