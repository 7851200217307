import * as React from 'react'
import { ELEMENT_BASE_PARAMS, FlowStep, Permissions, FlowTimeFrame, FlowCutOff, NewModal, NewButton, ElementOptions, Divider, ELEMENT_TYPES, ElementValue, Collapse, ConfirmAction, NotificationType } from 'prace-common-components'
import Grid from '@material-ui/core/Grid'
import { MinMaxConfig } from './MinMaxConfig'
import { ElementPermission } from 'components/ElementPermission'
import { $Timeframes, $DeleteButton, $ModalContainer } from './styles'
import { TimeFrames } from 'components/EditTimeFrames'
import { useAppDispatch } from 'store/hooks'
import { useDeleteFlowElementsMutation, useUpdateElementMutation } from 'store/api/calls/elements'

export const EditStep: React.FC<{step: FlowStep; callCutoffs: FlowCutOff[]}> = ({ step, callCutoffs }) => {
  const dispatch = useAppDispatch()
  const [updateElement] = useUpdateElementMutation()
  const [deleteElement] = useDeleteFlowElementsMutation()
  const [openCollapse, setOpenCollapse] = React.useState<boolean>(false)
  const [openTimeFrames, setOpenTimeFrames] = React.useState<boolean>(false)
  const { config, permissions = [], id, order, timeframes = [] } = step

  const onChangeOption = async (name: string, value: ElementValue) => {
    try {
      const newValues = ELEMENT_BASE_PARAMS.includes(name)
        ? { id: step.id, [name]: value }
        : { id: step.id, config: { ...step.config, [name]: value }}
      await updateElement(newValues).unwrap()
      dispatch({ type: 'updateStep', payload: { newValues } })
    } catch (err) {
      console.log(err)
    }
  }

  const onChangeElementPermission = async (name: string, value: ElementValue) => {
    try {
      const newValues = {id: step.id, permissions: {...permissions, [name]: value } as Permissions}
      await updateElement(newValues).unwrap()
      dispatch({ type: 'updateStep', payload: { newValues } })
    } catch (err) {
      console.log(err)
    }
  }

  const handleDelete = async () => {
    try {
      await deleteElement({ ids: [id] }).unwrap()
      dispatch({ type: 'deleteStep', payload: { stepId: id } })
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Step deleted' } })
    } catch (err) {
      console.log(err)
    }
  }

  const handleSaveTimeframes = async (value: FlowTimeFrame[], propagateDeadlines: boolean) => {
    try {
      const newValues = {id: step.id, timeframes: value }
      await updateElement({
        ...newValues,
        propagateDeadlines,
      }).unwrap()
      dispatch({ type: 'updateStep', payload: { newValues } })
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Timeframes updated' } })
      setOpenTimeFrames(false)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Grid container>
      <ElementOptions element={step} onChange={onChangeOption} />
      <Divider />
      <ElementPermission
        permissions={permissions as Permissions}
        type={ELEMENT_TYPES.STEP}
        step={order}
        id={id}
        onChange={onChangeElementPermission}
      />
      <Divider />
      <MinMaxConfig
        title='Accepted Assignments'
        minName='minAssignments'
        maxName='maxAssignments'
        min={config?.minAssignments || null}
        max={config?.maxAssignments || null}
        onChange={onChangeOption}
      />
      <Collapse title='Step Timeframes' open={openCollapse} handleOpen={setOpenCollapse}>
        {timeframes.map((timeframe, i) => <$Timeframes key={String(timeframe.start) + i} container spacing={2}>
          <Grid item xs={4} data-simplebar>
            <b>{callCutoffs.find((cutOff) => cutOff?.id == timeframe.cutoffId)?.title || ''}</b>
          </Grid>
          <Grid item xs={4} data-simplebar>
            <div>{timeframe.start && `${new Date(timeframe.start).toLocaleDateString()} ${new Date(timeframe.start).toLocaleTimeString(undefined,{timeZoneName:'short'})}`}</div>
          </Grid>
          <Grid item xs={4} data-simplebar>
            <div>{timeframe.end && `${new Date(timeframe.end).toLocaleDateString()} ${new Date(timeframe.end).toLocaleTimeString(undefined,{timeZoneName:'short'})}`}</div>
          </Grid>
        </$Timeframes>)}
        <NewButton onClick={() => setOpenTimeFrames(true)} variant='outlined'>Edit Timeframes</NewButton>
      </Collapse>
      <Divider />
      <ConfirmAction deletion title='Delete step' description='Deleting this step will also delete all forms and elements inside.' onConfirm={handleDelete}>
        <$DeleteButton error onClick={handleDelete}>Delete Step</$DeleteButton>
      </ConfirmAction>
      {openTimeFrames &&
        <NewModal open noTopMargin alternateTitle title='Step TimeFrames' onClose={() => setOpenTimeFrames(false)}>
          <$ModalContainer width={650}>
            <TimeFrames
              timeframes={timeframes}
              callCutoffs={callCutoffs}
              onSaveTimeframes={handleSaveTimeframes}
              btnText='Save Timeframes'
            />
          </$ModalContainer>
        </NewModal>
      }
    </Grid>
  )
}
