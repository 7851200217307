import * as React from 'react'
import update from 'immutability-helper'
import { Title, ConfirmAction, Input, NewDatePicker, NewButton, SvgIcon, FlowCutOff } from 'prace-common-components'
import { $Button, $CutOffContainer } from './styles'
import Grid from '@material-ui/core/Grid'
import { EditTimeFramesProps, EditFlowCutOff } from './types'

export const EditTimeFrames: React.FC<EditTimeFramesProps> = (
  { cutoffs, callCutoffs = [], onSaveCutoffs, onChange, onChangeCutOffValue, onDeleteCutOff, btnText, published },
) => {
  const [stateCutoffs, setStateCutoffs] = React.useState<EditFlowCutOff[]>(cutoffs.length === 0 ? [{ title: '', start: null }] : cutoffs)
  const hasCallCutoffs = !!callCutoffs.length

  const checkInvalidCutOffs = (cutoffs: EditFlowCutOff[]) => {
    let invalid = false
    cutoffs.forEach((cutOff) => {
      if(!cutOff.title || !cutOff.start)
        invalid = true
    })
    return invalid
  }

  const onChangeCutoffs = (name: string, value: StringNumber, idx: number) => {
    setStateCutoffs((stateCutoffs) => {
      const newCutoffs = update(stateCutoffs, {
        [idx]: { [name]: { $set: value }},
      })
      onChangeCutOffValue(name, value, idx)
      return newCutoffs
    })
  }

  const addCutoff = () => {
    setStateCutoffs((stateCutoffs) => {
      if(checkInvalidCutOffs(stateCutoffs)) return stateCutoffs
      const newCutoffs = [...stateCutoffs, { title: '', start: null }]
      onChange(newCutoffs as FlowCutOff[])
      return newCutoffs
    })
  }

  const deleteCutoff = (idx: number) => {
    setStateCutoffs((stateCutoffs) => {
      const newCutoffs = stateCutoffs.filter((_, index) => index !== idx)
      onDeleteCutOff(idx)
      return newCutoffs
    })
  }

  const onSubmitCutoffs = () => { onSaveCutoffs(stateCutoffs as FlowCutOff[]) }

  return <>
    <$CutOffContainer container>
      <Grid container spacing={2}>
        {!hasCallCutoffs && <Grid item xs={4}>
          <Title subTitle alternate>Cut-off Name</Title>
        </Grid>}
        <Grid item xs={hasCallCutoffs ? 6 : 4}>
          <Title subTitle alternate>Opening Date</Title>
        </Grid>
      </Grid>
      {stateCutoffs.map((cutoff, idx) => <Grid key={idx} container spacing={2} alignItems='center'>
        {!hasCallCutoffs && <Grid item xs={4}>
          <Input placeholder='Cut-off Name' title='' hideItalic name='title' value={cutoff.title} onChange={(name: string, value: Nullable<StringNumber>) => onChangeCutoffs(name, value as StringNumber, idx)} />
        </Grid>}
        <Grid item xs={hasCallCutoffs ? 6 : 4}>
          <NewDatePicker
            withTime
            placeholder='Start Cut-Off Date'
            min={hasCallCutoffs && callCutoffs[idx]?.start ? new Date(callCutoffs[idx].start) : undefined}
            name='start'
            value={cutoff.start}
            onChange={(name: string, value: StringNumber) => onChangeCutoffs(name, value, idx)}
          />
        </Grid>
        {!hasCallCutoffs && <Grid item xs={2}>
          <ConfirmAction deletion ignore={!published} title='Delete Cut-Off' description='Removing cutoffs from an ongoing call lead to unexpected behaviours.' onConfirm={() => deleteCutoff(idx)}>
            <SvgIcon name='delete' size={24} color='#757778' clickable onClick={() => deleteCutoff(idx)}/>
          </ConfirmAction>
        </Grid>}
      </Grid>,
      )}
      {!hasCallCutoffs && <Grid item xs={12}>
        <ConfirmAction ignore={!published} title='Add Cut-Off' description='Adding cutoffs from an ongoing call lead to unexpected behaviours.' onConfirm={addCutoff}>
          <NewButton creator variant='outlined' onClick={addCutoff}>
          Add Cut-Off
          </NewButton>
        </ConfirmAction>
      </Grid>}
    </$CutOffContainer>
    <Grid container>
      <$Button onClick={onSubmitCutoffs}>
        {btnText}
      </$Button>
    </Grid>
  </>
}
