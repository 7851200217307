import React from 'react';
import { Grid } from '../newGrid';
import { $Title, $SubTitle, $Italic, $ActionIcon, $Container } from './styles';
import { SvgIcon } from '../SvgIcon';
export const Title = ({ children, icon = 'chevron-left', alternate = false, subTitle = false, onBackClick = () => null, hasBack = false, noMargin = false, noWrap = false, type, actionIcon, bold = true, color = '#000', onAction = () => null, fontSize, iconProps, onClick, multiline, fullWidth = false, }) => {
    return (React.createElement(Grid, { container: true, wrap: fullWidth ? 'nowrap' : undefined },
        React.createElement($Container, { container: true, item: true, xs: actionIcon && !fullWidth ? 10 : 12, multiline: multiline, "$noWrap": noWrap },
            hasBack &&
                React.createElement(SvgIcon, { name: icon, size: 30, clickable: true, ...iconProps, onClick: onBackClick }),
            subTitle ?
                React.createElement($SubTitle, { "$noWrap": noWrap, color: color, alternate: alternate, fontSize: fontSize, bold: bold, clickable: !!onClick, onClick: onClick, multiline: multiline }, children)
                :
                    React.createElement($Title, { alternate: alternate, clickable: !!onClick, onClick: onClick, color: color, "$noWrap": noWrap, bold: bold, fontSize: fontSize, noMargin: hasBack || noMargin, hasBack: hasBack, multiline: multiline }, children),
            type &&
                React.createElement($Italic, null, type)),
        actionIcon &&
            React.createElement($ActionIcon, { item: true, container: true, xs: 2, justifyContent: 'flex-end', alignItems: 'center', onClick: onAction }, actionIcon)));
};
