import styled from 'styled-components';
import { Radio, FormLabel, FormHelperText } from '@material-ui/core';
export const $Radio = styled(Radio) `
  &.MuiRadio-root {
    color: ${({ error, theme }) => error ? theme.colors.feedback.error : theme.colors.feedback.disable};
    &.Mui-checked {
      color:  ${({ error, disabled, theme }) => error ? theme.colors.feedback.error : disabled ? theme.colors.feedback.disable : theme.colors.secondary};
    }
  }
`;
export const $FormLabel = styled(FormLabel) `
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  &.MuiFormLabel-root {
    &.Mui-focused {
      color:  ${({ theme }) => theme.colors.secondary};
    }
  }
`;
export const $FormHelperText = styled(FormHelperText) `
  &.MuiFormHelperText-root {
    color: ${({ error, theme }) => error && theme.colors.feedback.error};
    margin: 0;
  }
`;
