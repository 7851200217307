export const removeHiddenElements = (call) => {
    const callCopy = JSON.parse(JSON.stringify(call));
    callCopy.flow.forEach((step) => step.flow.forEach((form) => {
        form.flow = form.flow.filter((element) => !element.config?.hidden);
        form.flow.forEach((element) => {
            if (element.flow.length) {
                element.flow = element.flow.filter((element) => !element.config?.hidden);
            }
        });
    }));
    return callCopy;
};
