export function getGroupsToCreate(data, forceGroupId) {
    const createInstances = data.filter((d) => !!d.needCreate);
    const feGroups = createInstances.map((groupFeData) => ({
        ...groupFeData,
        children: data.filter((d) => d.feId && (d.dataGroupId == groupFeData.feId) && d.needSave),
    })) || [];
    const filteredGroups = feGroups.filter((group) => (group.input?.order ? true :
        createInstances.filter((f) => f.flowElementId === group.flowElementId).length > 1)
        || !!group.children.length || (forceGroupId && (group.id === forceGroupId)));
    const allSavedIds = [];
    const newGroups = filteredGroups.map((group) => {
        allSavedIds.push(group.id);
        allSavedIds.push(...(group.children.map((child) => child.id)));
        return {
            ...group, id: undefined, feId: undefined, needCreate: undefined,
            children: group.children.map((child) => ({ ...child, id: undefined, feId: undefined, dataGroupId: undefined, dataGroup: undefined })),
        };
    });
    return { newGroups, allSavedIds };
}
