import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import { $Select } from '../newSelect/styles';
import { SvgIcon } from '../';
export const $Label = styled.div `
  font-size: 14px;
  align-items: center;
  display: flex;
  svg {
    background-color: transparent;
    height: 15px;
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }
`;
export const $SvgIcon = styled(SvgIcon) `
  &.MuiSelect-icon {
    right: -10px;
  }
`;
export const $CountrySelect = styled($Select) `
  .MuiSelect-root.MuiSelect-select {
    padding: 0;
    padding-right: ${({ theme }) => theme.spacing(2.5)};
  }
`;
export const $MenuItem = styled(MenuItem) `
  &.MuiMenuItem-root {
    padding: ${({ noPadding }) => noPadding && 0};
  }
`;
