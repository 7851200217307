import React from 'react';
import { $FormControl } from './styles';
import { $Italic, $Text } from '../newTypography';
import { replaceURLs } from '../';
export const Instructions = ({ hideItalic = false, description, }) => {
    return (React.createElement($FormControl, null,
        React.createElement($Text, { dangerouslySetInnerHTML: { __html: replaceURLs(description) } }),
        !hideItalic &&
            React.createElement($Italic, { noMargin: true }, "Instructions")));
};
