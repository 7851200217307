import * as React from 'react'
import { ROLE_NAME, USER_PORTAL_ROLES, ROLES, Title, SvgIcon, NotificationType, NewButton, Select, Divider, DrawerModal } from 'prace-common-components'
import { $RoleContainer, $ListsContainer, $ModalContainer, $Container, $ArrowIcon, $CopyRolesContainer } from './styles'
import Grid from '@material-ui/core/Grid'
import { EditRolesProps } from './types'
import { useUpdateCutOffRoleMutation, useLazyGetCallCutoffRolesQuery, useGetCutoffRoleListsQuery, useLazyGetCutoffRoleListsQuery } from 'store/api/calls'
import { FlowCallCutoff } from 'store/api/calls/types'
import { useAppDispatch } from 'store/hooks'
import { getCurrentCutoff } from 'util/getCurrentCutoff'
import { EditCutoffRoleModal } from './EditCutoffRoleModal'

const roles = USER_PORTAL_ROLES.filter(
  (role) => ![ROLES.USER, ROLES.SUPER_ADMIN].includes(role))

export const EditRolesModal: React.FC<EditRolesProps> = ({ cutoffs, callId, onClose }) => {
  const dispatch = useAppDispatch()
  const [originCutoff, setOriginCutoff] = React.useState<Nullable<string>>(null)
  const [copyRolesOpen, setCopyRolesOpen] = React.useState<boolean>(false)
  const [cutoffRoleOpen, setCutoffRoleOpen] = React.useState<ROLES | undefined>()
  const [selectedCutoffId, setSelectedCutoffId] = React.useState<number>(getCurrentCutoff(cutoffs).id || 0)
  const [callRoles, setCallRoles] = React.useState<{ cutoffRoles?: FlowCallCutoff }>({
    cutoffRoles: undefined,
  })
  const { data, refetch } = useGetCutoffRoleListsQuery(
    { callId: callId || 0, cutOffId: selectedCutoffId }, { skip: !selectedCutoffId || !callId})
  const [getCutOffRoleList] = useLazyGetCutoffRoleListsQuery()
  const [getCallCutoffRoles] = useLazyGetCallCutoffRolesQuery()
  const [updateCutOffRole] = useUpdateCutOffRoleMutation()

  const roleLists = data?.cutoffLists || []
  const userGroups = data?.userGroups || []
  const cutOffRoles = callRoles?.cutoffRoles

  React.useEffect(() => {
    const getRoles = async () => {
      try {
        if(!selectedCutoffId || !callId) return
        const callRoles = await getCallCutoffRoles({ callId, cutOffId: selectedCutoffId }).unwrap()
        setCallRoles(callRoles)
      } catch (err) {
        console.log(err)
      }
    }
    getRoles().catch((err) => console.log(err))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCallCutoffRoles, callId, selectedCutoffId])

  const getCutOffRoles = async () => {
    return await getCallCutoffRoles({ callId, cutOffId: selectedCutoffId }).unwrap()
  }

  const handleClose = () => {
    setCopyRolesOpen(false)
    setCutoffRoleOpen(undefined)
    setOriginCutoff(null)
  }

  const handleCopyRoles = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      cutoff: { value: string },
    }

    if(!target.cutoff.value) return

    try {
      const otherRoleLists = await getCutOffRoleList({ callId, cutOffId: Number(target.cutoff.value) }).unwrap()
      const roles = (otherRoleLists?.cutoffLists || []).map((list) => ({
        role: list.role,
        userGroupId: list.usersGroupId ?? undefined,
      }))
      await updateCutOffRole({ id: selectedCutoffId, roles }).unwrap()
      await refetch()
      const callRoles = await getCallCutoffRoles({ callId, cutOffId: selectedCutoffId }).unwrap()
      setCallRoles(callRoles)
      handleClose()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Roles Copied' } })
    } catch (err) {
      console.log(err)
    }

  }

  const userGroupItems = userGroups?.map((group) => ({ label: group.name, value: group.id })) || []

  const cutoffOptions = cutoffs?.map((cutoff) => ({ value: cutoff.id || 0, label: cutoff.title || '' })) || []
  const cutoff = cutoffs.find((cutoff) => cutoff.id === selectedCutoffId) || cutoffs[0]
  
  if(!cutoffs.length || !cutoff) return null

  /* Sort roles by number of users and then by name */
  const sortedRoles = cutOffRoles ? [
    ROLES.APPLICANT,
    ...roles.filter((role) => (role !== ROLES.APPLICANT && cutOffRoles?.[role]?.length)
     || (role === ROLES.COMPUTE_CENTRE_REPRESENTATIVE)).sort(),
  ] : roles

  const unusedRoles = roles.filter((role) => 
    ![ROLES.APPLICANT, ROLES.COMPUTE_CENTRE_REPRESENTATIVE].includes(role) && !cutOffRoles?.[role]?.length).sort()

  return <>
    <DrawerModal
      title='Call Roles'
      open
      handleClose={onClose}
    >
      <$ModalContainer>
        <Select
          required
          title='Select Cut-off'
          name='cutoff'
          items={cutoffOptions}
          value={selectedCutoffId}
          onChange={(_n, value) => setSelectedCutoffId(Number(value))}
        />
        {cutoffRoleOpen ? 
          <EditCutoffRoleModal
            refetch={refetch}
            callId={callId}
            cutOffId={selectedCutoffId}
            roleLists={roleLists}
            setCallRoles={setCallRoles}
            getCutOffRoles={getCutOffRoles}
            roles={sortedRoles}
            role={cutoffRoleOpen}
            userGroups={userGroups}
            cutoffRoleList={roleLists.find((list) => list.role === cutoffRoleOpen)}
            allCutoffRoles={cutOffRoles}
            cutOffRoles={cutOffRoles && cutoffRoleOpen ? cutOffRoles[cutoffRoleOpen] : []}
            handleClose={handleClose}
            userGroupItems={userGroupItems}
          /> :
          copyRolesOpen ? 
            <$ModalContainer>
              <$Container container justifyContent='space-between' spacing={2}>
                <Grid item>
                  <Title hasBack
                    icon='arrow-left'
                    iconProps={{ size: '20px' }}
                    onBackClick={handleClose}
                  >
                  Import Roles
                  </Title>
                </Grid>
                <Grid item>
                  <NewButton
                    variant='text'
                    onClick={handleClose}
                  >
                  Cancel
                  </NewButton>
                </Grid>
              </$Container>
              <form onSubmit={handleCopyRoles}>
                <$CopyRolesContainer>
                  <Title subTitle alternate>
                    Import roles from another cut-off
                  </Title>
                  <Select
                    name='cutoff'
                    placeholder='Select cut-off'
                    value={originCutoff}
                    items={cutoffs.filter((c) => c.id !== selectedCutoffId).map((c) => ({ label: c.title || '', value: c.id || 0 }))}
                    onChange={(_, val) => setOriginCutoff(val)}
                  />
                  <$ListsContainer container>
                    <Grid item xs={12}>
                      <NewButton fullWidth type='submit'>
                        Import Roles
                      </NewButton>
                    </Grid>
                  </$ListsContainer>
                </$CopyRolesContainer>
              </form>
            </$ModalContainer>
            :
            <>
              <$Container container justifyContent='space-between'>
                <Grid item>
                  <Title noWrap noMargin>
                    Active Roles
                  </Title>
                </Grid>
                <Grid item>
                  <NewButton
                    icon={<SvgIcon name='listDownload' color='#19317B' />}
                    variant='text'
                    onClick={() => setCopyRolesOpen(true)}
                  >
                    Import Roles
                  </NewButton>
                </Grid>
              </$Container>
              <$RoleContainer container>
                <Grid item xs={12}>
                  {sortedRoles.map((role) => <div key={role}>
                    <Title subTitle noWrap noMargin
                      onClick={() => setCutoffRoleOpen(role)}
                      actionIcon={<$ArrowIcon name='chevron-right' color='#19317B' />}
                      onAction={() => setCutoffRoleOpen(role)}
                    >
                      {ROLE_NAME[role]}
                    </Title>
                    <Divider noMarginBottom={false} />
                  </div>)}
                </Grid>
                <Grid item xs={12}>
                  <Title noWrap noMargin>
                    Unused roles
                  </Title>
                </Grid>
                <Grid item xs={12}>
                  {unusedRoles.map((role) => <div key={role}>
                    <Title subTitle noWrap noMargin
                      onClick={() => setCutoffRoleOpen(role)}
                      actionIcon={<$ArrowIcon name='chevron-right' color='#19317B' />}
                      onAction={() => setCutoffRoleOpen(role)}
                    >
                      {ROLE_NAME[role]}
                    </Title>
                    <Divider noMarginBottom={false} />
                  </div>)}
                </Grid>
              </$RoleContainer>
            </>
        }
      </$ModalContainer>
    </DrawerModal>
  </>
}
