import React from 'react';
import { FORM_ELEMENT_TYPES } from '../newFormElement';
import { Input } from '../newInput';
import { Switch } from '../newSwitch';
import { Grid } from '../newGrid';
import { getElementOptions } from './helpers';
import { NewDatePicker } from '../newDatePicker';
import { Select } from '../newSelect';
import { AutocompleteInput, NewButton, NewMultiSelect } from '../';
import { Checkbox } from '../newCheckbox';
export const ElementOptions = ({ element, onChange, onCreate = () => null, onEditItem, onDuplicateItem, }) => {
    const renderSwitch = (option) => {
        const handleChange = (name, value) => {
            if (option.type === FORM_ELEMENT_TYPES.DATE && (name === 'max' || name === 'min')) {
                onChange(name, Date.parse(value), option.type);
            }
            else {
                onChange(name, value, option.type);
            }
        };
        const { required, value, name, error, errorMsg } = option;
        const optionWithHandler = {
            items: [],
            ...option,
            error: error || (required && !value),
            errorMsg,
            hideItalic: true,
            debounce: true,
            onChange: handleChange,
        };
        switch (option.type) {
            case FORM_ELEMENT_TYPES.TEXT:
            case FORM_ELEMENT_TYPES.TITLE:
                return React.createElement(Input, { ...optionWithHandler, type: 'text' });
            case FORM_ELEMENT_TYPES.TEXT_AREA:
                return React.createElement(Input, { ...optionWithHandler, type: 'text', multiline: true, minRows: 3 });
            case FORM_ELEMENT_TYPES.EMAIL:
                return React.createElement(Input, { ...optionWithHandler, type: 'email' });
            case FORM_ELEMENT_TYPES.NUMBER:
                return React.createElement(Input, { ...optionWithHandler, type: 'number' });
            case FORM_ELEMENT_TYPES.DATE:
                return React.createElement(NewDatePicker, { ...optionWithHandler, type: undefined, value: value });
            case FORM_ELEMENT_TYPES.CHECKBOX:
                return name === 'required' ? React.createElement(Switch, { ...optionWithHandler, value: value }) :
                    React.createElement(Checkbox, { ...optionWithHandler, value: value });
            case FORM_ELEMENT_TYPES.SELECT:
                return name === 'options' ? React.createElement(AutocompleteInput, { ...optionWithHandler, onChange: (_, item) => handleChange(name, item.value), value: { label: value, value }, onEditItem: onEditItem, onDuplicateItem: onDuplicateItem })
                    : React.createElement(Select, { ...optionWithHandler, value: value, onEditItem: onEditItem });
            case FORM_ELEMENT_TYPES.MULTISELECT:
                return React.createElement(NewMultiSelect, { ...optionWithHandler, value: value });
            default:
                return null;
        }
    };
    const renderItems = (element) => (getElementOptions(element).map((option, i) => (React.createElement(Grid, { container: true, spacing: 2, key: `${element.id}-${i}` },
        React.createElement(Grid, { item: true, xs: 12 }, renderSwitch(option)),
        option.type === FORM_ELEMENT_TYPES.SELECT &&
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(NewButton, { variant: 'outlined', onClick: () => onCreate(FORM_ELEMENT_TYPES.SELECT) }, "Create new group"))))));
    return React.createElement(React.Fragment, null, renderItems(element).map((renderItem) => renderItem));
};
