import React from 'react';
import { FORM_ELEMENT_TYPES } from './types';
import { Input } from '../newInput';
import { Checkbox } from '../newCheckbox';
import { NewDatePicker } from '../newDatePicker';
import { Instructions } from '../newInstructions';
import { PhoneInput } from '../newPhoneInput';
import { RadioOptions } from '../newRadioOptions';
import { TitleElement } from '../newTitle';
import { Upload } from '../newUpload';
import { Select } from '../newSelect';
import { EditableMultiSelect, NewMultiSelect } from '../newMultiSelect';
import { getFlowElementProps } from './helpers';
import { $Space, $DividerElement } from './styles';
export const FormElement = ({ element, visible = true, whiteBackground = false }) => {
    if (!visible)
        return null;
    const renderElement = (element) => {
        const { elementType, value, disabled, config, onChange } = element;
        const elementOptions = {
            ...element,
            ...getFlowElementProps(element),
            id: element.id || config?.id,
            disabled,
            onChange,
            value,
            whiteBackground,
        };
        switch (elementType) {
            case FORM_ELEMENT_TYPES.TEXT:
                return React.createElement(Input, { ...elementOptions, type: 'text' });
            case FORM_ELEMENT_TYPES.INSTRUCTIONS:
                return React.createElement(Instructions, { ...elementOptions });
            case FORM_ELEMENT_TYPES.TEXT_AREA:
                return React.createElement(Input, { multiline: true, minRows: 3, ...elementOptions, type: 'text' });
            case FORM_ELEMENT_TYPES.TITLE:
                return React.createElement(TitleElement, { ...elementOptions });
            case FORM_ELEMENT_TYPES.EMAIL:
                return React.createElement(Input, { ...elementOptions, type: 'email' });
            case FORM_ELEMENT_TYPES.NUMBER:
            case FORM_ELEMENT_TYPES.PARTITION_RESOURCES:
                return React.createElement(Input, { ...elementOptions, type: 'number' });
            case FORM_ELEMENT_TYPES.DATE:
                return React.createElement(NewDatePicker, { ...elementOptions, type: 'date' });
            case FORM_ELEMENT_TYPES.PHONE_NUMBER:
                return React.createElement(PhoneInput, { ...elementOptions });
            case FORM_ELEMENT_TYPES.CHECKBOX:
                return React.createElement(Checkbox, { ...elementOptions });
            case FORM_ELEMENT_TYPES.TOGGLE:
                return React.createElement(RadioOptions, { ...elementOptions });
            case FORM_ELEMENT_TYPES.UPLOAD:
                return React.createElement(Upload, { ...elementOptions });
            case FORM_ELEMENT_TYPES.SELECT_PARTITION:
            case FORM_ELEMENT_TYPES.SELECT:
                return React.createElement(Select, { ...elementOptions, type: elementType });
            case FORM_ELEMENT_TYPES.MULTISELECT:
                return React.createElement(NewMultiSelect, { ...elementOptions, debounce: false });
            case FORM_ELEMENT_TYPES.MULTITEXT:
                return React.createElement(EditableMultiSelect, { ...elementOptions });
            case FORM_ELEMENT_TYPES.SPACE:
                return React.createElement($Space, null);
            case FORM_ELEMENT_TYPES.DIVIDER:
                return React.createElement($DividerElement, null);
            default:
                return null;
        }
    };
    return renderElement(element);
};
