import { FORM_ELEMENT_TYPES, selectTypes } from '../';
const addItems = (element, items = []) => {
    if (selectTypes.includes(element.elementType)) {
        return {
            ...element,
            items,
        };
    }
    else
        return element;
};
export const insertSelectItems = (elements, optionGroups) => {
    return elements.map((element) => {
        if (element.elementType === FORM_ELEMENT_TYPES.GROUP) {
            const groupElements = element.flow.map((groupEl) => {
                if (selectTypes.includes(groupEl.elementType)) {
                    const optionsGroup = optionGroups.find((optGroup) => optGroup.tag === groupEl.optionsGroup?.tag);
                    return addItems(groupEl, optionsGroup?.options);
                }
                else
                    return groupEl;
            });
            return {
                ...element,
                flow: groupElements,
            };
        }
        else {
            const optionsGroup = optionGroups.find((optGroup) => optGroup.tag === element.optionsGroup?.tag);
            return addItems(element, optionsGroup?.options);
        }
    });
};
const addOptions = (element, optionGroups, edit = true) => {
    if (selectTypes.includes(element.elementType)) {
        const selectItems = optionGroups.map((optGroup) => {
            return {
                ...optGroup,
                value: optGroup.tag,
                label: optGroup.tag,
                edit,
            };
        });
        return {
            ...element,
            items: selectItems,
        };
    }
    return element;
};
export const insertSelectOptionGroups = (elements, optionGroups, edit = true) => {
    return elements.map((element) => {
        if (element.elementType === FORM_ELEMENT_TYPES.GROUP) {
            const groupElements = element.flow.map((groupEl) => addOptions(groupEl, optionGroups, edit));
            return {
                ...element,
                flow: groupElements,
            };
        }
        else {
            return addOptions(element, optionGroups, edit);
        }
    });
};
