import styled from 'styled-components';
import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
export const $Collapse = styled(Collapse) `
  .MuiStepper-root {
    padding: 0;
    padding-left: ${({ theme }) => theme.spacing(5)};
  }
`;
export const $List = styled(List) `
  max-width: 250px;
  background-color: ${({ theme, $stepper }) => $stepper ? 'unset' : theme.colors.grey};
`;
export const $ListItem = styled(ListItem) `
  &.MuiListItem-root {
    padding-left: ${({ nested, theme }) => nested && theme.paddings.collapse};
    color: ${({ secondary, theme }) => secondary && theme.colors.secondary};
    &.Mui-selected {
      background-color: ${({ theme, selected }) => selected && theme.colors.white};
    }
    svg {
      min-width: 28px;
    }
  }
`;
export const $ListItemText = styled(ListItemText) `
  color: ${({ theme, selected, $stepper, error }) => error ? theme.colors.feedback.error : (!$stepper && selected && theme.colors.icons.collapse)};
  span {
    font-weight: ${({ theme, selected }) => selected && theme.fontWeights.heavier};
  }
  &.MuiListItemText-inset {
    padding-left: 0;
  }
`;
