export const activeCutOff = (cutOffs) => {
    if (!cutOffs.length)
        return undefined;
    const today = new Date();
    let result;
    for (const cutoff of cutOffs) {
        const cutOffStart = new Date(cutoff.start);
        if (today.getTime() >= cutOffStart.getTime() &&
            (!result || cutOffStart.getTime() > (new Date(result.start)).getTime())) {
            result = cutoff;
        }
    }
    return result;
};
