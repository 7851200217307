import { Collapse, TableBody, Toolbar } from '@material-ui/core';
import styled from 'styled-components';
import { SvgIcon, newTheme as theme } from '../..';
export const $SelectTableBody = styled(TableBody) `
  .MuiTableRow-root .MuiTableCell-root {
    background-color: ${theme.colors.focusBackground};
  }
`;
export const $SelectTable = styled.div `
  margin-bottom: 8px;
`;
export const $SvgIcon = styled(SvgIcon) `
  cursor: pointer;
`;
export const $Toolbar = styled(Toolbar) `
  display: flex;
  gap: 15px;
  justify-content: end;
`;
export const $Label = styled.div `
  margin-right: auto;
`;
export const $Collapse = styled(Collapse) `
  &.MuiCollapse-container {
    overflow-y: scroll;
  }
`;
