import React, { FormEvent } from 'react'
import { FORM_ELEMENT_TYPES, FlowCall, getFlowElement, NotificationType } from 'prace-common-components'
import { FormModal } from 'components/FormModal'
import useElementSelects from 'util/useElementSelects'
import { ValidatedVariable } from '../types'
import { FlowElementIds, FormElement } from 'components/FormModal/types'
import { useAppDispatch } from 'store/hooks'

const firstColumn = [
  { id: 0, index: 0, name: 'name', flow: [],
    elementType: FORM_ELEMENT_TYPES.TEXT,
    title: 'Variable Name',
    options: {
      placeholder: 'Variable name',
      description: '',
      required: true,
    },
  },
]

const TYPES_NOT_ALLOWED = [
  FORM_ELEMENT_TYPES.GROUP,
  FORM_ELEMENT_TYPES.UPLOAD,
  FORM_ELEMENT_TYPES.INSTRUCTIONS,
  FORM_ELEMENT_TYPES.TITLE,
]

//TODO: This component is almost the same as AddCondition!!!!!!!!!!!!!! Maybe just one ?
export const AddVariable: React.FC<{call?: FlowCall; onAddVariable: (variable: ValidatedVariable) => void}> = (
  { call, onAddVariable },
) => {
  const dispatch = useAppDispatch()
  const [selectValues, setSelectValues] = React.useState<FlowElementIds>({
    step: null, form: null, element: null, groupElement: null,
  })
  const [_, elementSelects] = useElementSelects(call?.flow, selectValues, 'Which element is the variable associated to?')

  const handleChange = (name: string, value: Nullable<StringNumber | string[]>) => {
    setSelectValues((prevState) => ({...prevState, [name]: value as Nullable<string> }))
  }

  const handleCreateVariable = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      name: { value: string },
      step: { value: number },
      form: { value: number },
      element: { value: number },
    }
    const elementId = target.element.value
    const variableName = target.name.value
    if(elementId && variableName) {
      const { element } = getFlowElement(call!, elementId)
      if(element?.elementType && TYPES_NOT_ALLOWED.includes(element.elementType as FORM_ELEMENT_TYPES)) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: `Cannot choose an element of type ${element.elementType}` } })
        return
      }
      const variable = {
        name: variableName,
        id:  Number(elementId),
        error: false,
        variableType: 'element_value',
        flowElementId: Number(elementId),
      }
      onAddVariable(variable)
    }
  }

  return (
    <FormModal
      columns={[
        { elements: firstColumn },
        { elements: elementSelects as FormElement[] },
      ]}
      submitText='Save'
      onSubmit={handleCreateVariable}
      onChange={handleChange}
    />
  )
}
