import { clientApi } from '..'
import { CreateUsersGroupApiPayload, ChangeIsReviewerPayload, ChangeStatusUsersPayload, SearchUsers, SearchUsersPayload, UpdateUserPayload, UserGroup } from './types'
import { User, withLoading } from 'prace-common-components'

export const usersApi = clientApi.injectEndpoints({
  endpoints: (build) => ({
    getUsersByIds: build.mutation<User[], number[]>({
      query: (body) => ({
        url: '/users',
        method: 'POST',
        body,
      }),
    }),
    getUsers: build.query<User[], void>({
      query: () => ({
        url: '/users',
      }),
    }),
    /* TODO: Use this endpoint for loadUserSuggestions (usersuggestions api) */
    searchUsers: build.mutation<SearchUsers, SearchUsersPayload>({
      query: (body) => ({
        url: '/users/search',
        method: 'POST',
        body,
      }),
      transformResponse: (res: SearchUsers, _meta, args) => ({ ...args, ...res }),
    }),
    /* TODO: Use this endpoint to update Role */
    /* TODO: Use this endpoint to update User Details */
    updateUser: build.mutation<void, UpdateUserPayload>({
      ...withLoading,
      query: ({ id, ...body }) => ({
        url: `/users/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    /* Cannot receive empty list of users */
    changeStatusUsers: build.mutation<void, ChangeStatusUsersPayload>({
      ...withLoading,
      query: (users) => ({
        url: '/users/change-status',
        method: 'PATCH',
        body: { users: [users[0]] },
      }),
    }),
    /* Cannot receive empty list of users */
    changeIsReviewer: build.mutation<void, ChangeIsReviewerPayload>({
      ...withLoading,
      query: (users) => ({
        url: `/users/${users[0].id}`,
        method: 'PATCH',
        body: { isReviewer: users[0].isReviewer },
      }),
    }),
    getUserOptions: build.query<UserGroup[], void>({
      query: () => ({
        url: '/users/groups',
      }),
    }),
    createUsersGroup: build.mutation<UserGroup, CreateUsersGroupApiPayload>({
      ...withLoading,
      query: (body) => ({
        url: '/users/groups',
        method: 'POST',
        body,
      }),
    }),
    updateUsersGroup: build.mutation<UserGroup, CreateUsersGroupApiPayload & {id: UserGroup['id']}>({
      ...withLoading,
      query: ({ id, ...body }) => ({
        url: `/users/groups/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteUsersGroup: build.mutation<void, UserGroup['id']>({
      ...withLoading,
      query: (id) => ({
        url: `/users/groups/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetUsersByIdsMutation,
  useGetUsersQuery,
  useSearchUsersMutation,
  useUpdateUserMutation,
  useChangeStatusUsersMutation,
  useChangeIsReviewerMutation,
  useGetUserOptionsQuery,
  useCreateUsersGroupMutation,
  useUpdateUsersGroupMutation,
  useDeleteUsersGroupMutation,
} = usersApi
