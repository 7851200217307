import styled from 'styled-components';
import { Tabs } from '@material-ui/core';
export const $Tabs = styled(Tabs) `
  &.MuiTabs-root {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  .MuiTab-root {
    white-space: nowrap;
  }

  .MuiButtonBase-root {
    border-bottom: solid 1px rgba(0, 0, 0, 0.3);
  }

  .MuiTab-wrapper {
    text-transform: none;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
  }

  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;
export const $VerticalTabs = styled(Tabs) `
  &.MuiTabs-root {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  .MuiButtonBase-root {
    border-right: solid 1px rgba(0, 0, 0, 0.3);
  }

  .MuiTab-wrapper {
    text-transform: none;
  }

  .MuiTab-textColorPrimary {
    color: ${({ theme, value }) => value === false ? theme.colors.primary : theme.colors.primaries.heavy};
    opacity: ${({ value }) => value === false ? '1' : '0.4'};
  }

  .MuiTab-textColorPrimary.Mui-selected {
    font-weight: 700;
    opacity: 1;
    color: ${({ theme }) => theme.colors.primary};
  }

  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

`;
