import { FlowFormElement } from 'prace-common-components'

export const groupElementDifferences = (oldElements: FlowFormElement[], newElements: FlowFormElement[]) => {
  const addedElements: FlowFormElement[] = []
  const deletedIds: number[] = []
  oldElements.forEach((oldEl: FlowFormElement) => {
    const found = newElements.find((newEl: FlowFormElement) => newEl.id === oldEl.id)
    if(!found) deletedIds.push(oldEl.id)
  })
  newElements.forEach((newEl: FlowFormElement) => {
    const found = oldElements.find((oldEl: FlowFormElement) => oldEl.id === newEl.id)
    if(!found) addedElements.push(newEl)
  })
  return [addedElements, deletedIds as number[]]
}
