import React from 'react';
import { $Card, $CardTexts } from './styles';
import { SvgIcon } from '../SvgIcon';
import { ElementIcon } from '../newElementIcon';
import { theme } from '../newTheme';
export const CardButton = ({ title, selected, icon, type, alternative, stacked, onClick }) => {
    const iconColor = selected ? (alternative ?
        theme.colors.white : theme.colors.primary) : (alternative ? theme.colors.primary : theme.colors.text.grey);
    return React.createElement($Card, { selected: selected, alternative: alternative, stacked: stacked, onClick: onClick },
        icon ?
            React.createElement(SvgIcon, { name: icon, size: '42', color: iconColor })
            :
                type &&
                    React.createElement(ElementIcon, { type: type, size: 42, color: iconColor }),
        React.createElement($CardTexts, { selected: selected, alternative: alternative }, title));
};
