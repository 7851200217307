import { activeCutOff, FLOW_STATUS } from '../';
export const canEditAssignment = (isOwner, status, deadline, cutOffs) => {
    const rejectedAssignment = status == FLOW_STATUS.REFUSED;
    const revokedAssignment = status == FLOW_STATUS.REVOKED;
    const changesRequested = status == FLOW_STATUS.CHANGES_REQUESTED;
    const submittedAssignment = status == FLOW_STATUS.SUBMITTED || status == FLOW_STATUS.RESUBMITTED;
    const disableForms = (status == FLOW_STATUS.APPROVED)
        || rejectedAssignment || revokedAssignment || submittedAssignment;
    const cutoff = activeCutOff(cutOffs);
    const today = new Date();
    const deadlineDate = deadline ? new Date(deadline) : null;
    const validDeadline = (!deadline || !!(deadlineDate && deadlineDate.getTime() >= today.getTime()));
    return isOwner && ((changesRequested && validDeadline) || (!disableForms && !!cutoff && validDeadline));
};
