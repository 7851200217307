import React from 'react';
import { Modal } from '../newModal';
import Grid from '@material-ui/core/Grid';
import { Button } from '../NewButton';
import { $ActionQuestion, $Description, $ModalContainer } from './styles';
export const ConfirmModal = ({ title, deletion, description, question = 'Are you sure you want to proceed?', open, disabled, onConfirm, onClose, }) => React.createElement(Modal, { open: open, small: true, title: title, type: 'warning-fill', onClose: onClose },
    React.createElement($ModalContainer, { container: true },
        React.createElement(Grid, { item: true },
            React.createElement($ActionQuestion, null, question)),
        React.createElement(Grid, { item: true },
            React.createElement($Description, null, description)),
        React.createElement(Grid, { container: true, justifyContent: 'space-between' },
            React.createElement(Button, { disabled: disabled, error: deletion, onClick: onConfirm }, deletion ? 'Delete' : 'Confirm'),
            React.createElement(Button, { variant: 'outlined', onClick: onClose }, "Cancel"))));
