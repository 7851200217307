import React, { PropsWithChildren } from 'react'
import { useFocused, useSelected } from 'slate-react'
import { Tooltip, SvgIcon, ConfirmAction } from 'prace-common-components'
import { VariableElement } from '../custom-types'
import { $DeleteWrapper, $Variable } from './styles'

export const Variable: React.FC<PropsWithChildren<{
  attributes?: {
    'data-slate-node': 'element',
    'data-slate-inline'?: true,
    'data-slate-void'?: true,
    dir?: 'rtl',
    ref: React.RefObject<HTMLDivElement>,
  },
  defaultVar?: boolean,
  element: VariableElement,
  tooltipText?: string,
  onRemove?: () => void,
}>> = ({ attributes, children, defaultVar, element, tooltipText, onRemove }) => {
  const selected = useSelected()
  const focused = useFocused()
  const { italic, bold, underline } = element
  return (
    <Tooltip
      text={tooltipText || ''}
      arrow
      show
      placement='top'
    >
      <$Variable
        {...attributes}
        className='variable'
        defaultVar={defaultVar}
        error={element.error}
        groupVariable={element.groupElement !== undefined}
        contentEditable={false}
        boxShadow={selected && focused}
        italic={italic}
        bold={bold}
        underline={underline}
        inline={children !== undefined}
        onRemove={!!onRemove && !defaultVar}
      >
        {children}
        {element.character}
        {onRemove && !defaultVar ?
          <ConfirmAction
            deletion
            title='Delete variable'
            description='After deletion, all occurences of this variable should be removed or updated in the editor below.'
            onConfirm={onRemove}
          >
            <$DeleteWrapper >
              <SvgIcon clickable name='close' size={16} />
            </$DeleteWrapper>
          </ConfirmAction>
          : null
        }
      </$Variable>
    </Tooltip>
  )
}
