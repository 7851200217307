import { Grid, GridProps } from '@material-ui/core'
import styled from 'styled-components'
import { NewButton, SvgIcon } from 'prace-common-components'

//TODO: Make a general modal container like this for all modals
export const $ModalContainer = styled.div<{ width?: number}>`
  width: ${({ width = 500 }) => `${width}px`};
`

export const $ButtonContainer = styled(Grid)<GridProps>`
  padding: ${({ theme }) => `${theme.spacing(2)} 0px`};
`

export const $Container = styled.div`
  padding: ${({ theme }) => `0px ${theme.spacing(3)}`};
`

export const $FieldContainer = styled(Grid)`
  gap: ${({ theme }) => theme.spacing(2)};
  margin: ${({ theme }) => `${theme.spacing(2)} 0`};
`

export const $Timeframes = styled(Grid)`
  &.MuiGrid-root {
    margin: ${({ theme }) => `${theme.spacing(2)} auto`};
  }
`

export const $DeleteButton = styled(NewButton)`
  &.MuiButton-root {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`

export const $Grid = styled(Grid)`
  gap: 8px;
`

export const $ElementContainer = styled(Grid)`
  gap: ${({ theme }) => theme.spacing(2)};
`

export const $SvgIcon = styled(SvgIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`

export const $ActionContainer = styled(Grid)`
  position: relative;
`

export const $ConditionIcons = styled.div`
  display: flex;
  position: absolute;
  z-index: 99;
  top: ${({ theme }) => theme.spacing(0.5)};
  right: ${({ theme }) => theme.spacing(0.5)};
`

export const $WarningGrid = styled(Grid)`
  &.MuiGrid-container {
    width: auto;
  }
  height: 100%;
`

export const $Warning = styled.div`
  margin-right: ${({ theme }) => theme.spacing(1)};
  font-weight: bold;
`
