import styled from 'styled-components';
export const $Container = styled.div `
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};
  padding: ${({ theme }) => `0 ${theme.spacing(2)}`};
  @media (max-width: 768px) {
    max-width: 360px;
    margin: 0 auto;
  }
`;
export const $Slider = styled.div `
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: ${({ visibleSlides }) => (1 / visibleSlides) * 0.7 + '%'} 0;
`;
export const $SliderContainer = styled.div `
  display: flex;
  width: 100%;
  margin: 0 auto;
  transition: transform 500ms ease;
`;
export const $SliderElement = styled.div `
  display: flex;
  justify-content: center;
  flex: ${({ visibleSlides, slideMargin }) => `0 0 calc(100% / ${visibleSlides} - ${(slideMargin || 16) * 2}px)`};
  box-sizing: border-box;
  transform: scale(1);
  user-select: none;
  margin: ${({ slideMargin }) => `0 ${slideMargin || 16}px`};
  transition: transform 500ms ease;
  cursor:${({ onClick }) => onClick && 'pointer'};
  width: 100%;
  height: 100%;
  &>*{
    width: 100% !important;
    max-width: 300px;
  }
`;
export const $SliderButton = styled.div `
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 100%;
  top: 0;
  left: ${({ next }) => !next && '0px'};
  right: ${({ next }) => next && '0px'};
  svg {
    width: 20px;
    height: 33px;
  }
`;
