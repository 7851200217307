import { withLoading } from 'prace-common-components'
import { clientApi } from '..'
import { CreateTemplateApiPayload, GetTemplatesApiPayload, GetTemplatesSuccess, SendExampleEmailApiPayload, Template, UpdateTemplateApiPayload } from './types'

export const templateApi = clientApi.injectEndpoints({
  endpoints: (build) => ({
    getTemplate: build.query<Template, Template['id']>({
      ...withLoading,
      query: (id) => ({
        url: `/v2/notifications/${id}`,
      }),
    }),
    getTemplates: build.mutation<GetTemplatesSuccess, GetTemplatesApiPayload>({
      query: ({ limit, offset, callId, ...body }) => ({
        url: `/v2/notifications?limit=${limit}&offset=${offset}${callId ? `&call=${callId}` : ''}`,
        method: 'POST',
        body,
      }),
      transformResponse: (res: GetTemplatesSuccess, _meta, args) => ({ ...args, ...res }),
    }),
    createTemplate: build.mutation<Template, CreateTemplateApiPayload>({
      ...withLoading,
      query: (body) => ({
        url: '/v2/notifications',
        method: 'PUT',
        body,
      }),
    }),
    updateTemplate: build.mutation<Template, UpdateTemplateApiPayload>({
      ...withLoading,
      query: ({ id, ...body }) => ({
        url: `/v2/notifications/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteTemplate: build.mutation<Template, Template['id']>({
      ...withLoading,
      query: (id) => ({
        url: `/v2/notifications/${id}`,
        method: 'DELETE',
      }),
    }),
    sendExampleEmail: build.mutation<void, SendExampleEmailApiPayload>({
      ...withLoading,
      query: ({ id, ...body }) => ({
        url: `/templateManager/createEmail/${id}`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGetTemplateQuery,
  useGetTemplatesMutation,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
  useSendExampleEmailMutation,
} = templateApi
