import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
export const $Menu = styled(Menu) `
  .MuiList-root {
    color: ${({ theme }) => theme.colors.text.grey};
    font-size: 0.9em;
  }
`;
export const $MenuItem = styled(MenuItem) `
  &.MuiMenuItem-root {
    background-color: ${({ open }) => open ? 'rgba(118, 140, 224, 0.15)' : 'rgba(0,0,0,0)'};
  }
`;
export const $Modal = styled(Modal) `
  z-index: 90 !important;
  padding-top: 60px;
`;
export const $MobileModalContainer = styled.div `
  width: 100%;
  height: 100%;
  font-size: 1.5em;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
`;
export const $MenuContainer = styled(Grid) ``;
export const $MenuContainerBottom = styled(Grid) `
  &.MuiGrid-item {
    position: absolute;
    bottom: 0;
    padding-bottom: ${({ theme }) => theme.spacing(5)};
  }
`;
export const $MenuRow = styled(Grid) `
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(4)};
`;
export const $BottomItem = styled.div `
  display: flex;
  width: 100%;
`;
