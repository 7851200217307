import { FORM_ELEMENT_TYPES } from '../';
import { FLOW_STATUS } from '../types/Applications';
import { EFFECTS, VALIDATION_TYPES } from '../types/Calls';
import { getFlowElement } from './getFlowElement';
const getDependencies = (element) => {
    const elementEffects = {
        visible: [],
        valid: [],
        required: [],
        editable: [],
        sum: [],
    };
    element.effects?.forEach((condition) => {
        const { effect, validation } = condition;
        elementEffects[effect].push(validation);
    });
    return elementEffects;
};
const testConditionValue = (type, elementType, values, errorCount, value) => {
    const comparingValue = elementType == FORM_ELEMENT_TYPES.NUMBER ? (values[0] || '').replace(/[,]/g, '.') : values[0];
    const valueArray = Array.isArray(value);
    let result = false;
    switch (type) {
        case VALIDATION_TYPES.EQUAL:
            result = valueArray ? value.includes(comparingValue) : (value == comparingValue);
            break;
        case VALIDATION_TYPES.NOT_EQUAL:
            result = valueArray ? !value.includes(comparingValue) : (value != comparingValue);
            break;
        case VALIDATION_TYPES.MIN_LENGTH:
            result = !!value && (String(value).length >= Number(comparingValue));
            break;
        case VALIDATION_TYPES.MAX_LENGTH:
            result = !!value && (String(value).length <= Number(comparingValue));
            break;
        case VALIDATION_TYPES.LENGTH_RANGE:
            result = !!value && (String(value).length >= Number(values[0])) && (String(value).length <= Number(values[1]));
            break;
        case VALIDATION_TYPES.NON_EMPTY:
            result = valueArray ? (value || []).some((v) => !!v) : !!value;
            break;
        case VALIDATION_TYPES.REQUIRED:
            result = valueArray ? !!value.length : !!value;
            break;
        case VALIDATION_TYPES.GREATER:
            result = Number(value) > Number(comparingValue);
            break;
        case VALIDATION_TYPES.LESSER:
            result = Number(value) < Number(comparingValue);
            break;
        default: return [true, errorCount + 1];
    }
    return [result, errorCount];
};
export const getConditionsValues = (call, element, stepId, groupInstance) => {
    const dependencies = getDependencies(element);
    const defaultConditionValues = {
        visible: true,
        valid: true,
        required: false,
        editable: true,
        sum: null,
    };
    const effectsValues = defaultConditionValues;
    let sumResult = 0;
    let errorCount = 0;
    const conditionKeys = Object.keys(dependencies);
    conditionKeys?.forEach((dependencyType) => {
        if (!dependencies[dependencyType].length)
            return;
        errorCount = 0;
        effectsValues[dependencyType] = dependencies[dependencyType].some((conditionValidation) => {
            const { elements, type, values } = conditionValidation;
            return elements.every((flowElement) => {
                const { element, step } = getFlowElement(call, flowElement.id);
                if (!element) {
                    errorCount = errorCount + 1;
                    return true;
                }
                const { id, elementType } = element;
                if (step?.id && stepId !== step.id) {
                    return step.assignments.some((assignment) => {
                        if (![FLOW_STATUS.SUBMITTED,
                            FLOW_STATUS.RESUBMITTED,
                            FLOW_STATUS.CHANGES_REQUESTED,
                            FLOW_STATUS.APPROVED].includes(assignment.status))
                            return false;
                        const found = assignment.data.find((data) => data.flowElementId == id);
                        if (found) {
                            const value = found?.input?.values[0];
                            if (dependencyType == EFFECTS.SUM) {
                                sumResult = sumResult + Number(value || 0);
                                return true;
                            }
                            const testResults = testConditionValue(type, elementType, values, errorCount, value);
                            if (testResults[0])
                                errorCount = testResults[1];
                            return testResults[0];
                        }
                        return false;
                    });
                }
                let value = element.value;
                if (groupInstance) {
                    const found = groupInstance.childInstances.find((child) => child.flowElementId == id);
                    if (found)
                        value = found.value;
                }
                if (dependencyType == EFFECTS.SUM) {
                    sumResult = sumResult + Number(value || 0);
                    return true;
                }
                else {
                    const testResults = testConditionValue(type, elementType, values, errorCount, value);
                    errorCount = testResults[1];
                    return testResults[0];
                }
            });
        });
        if (errorCount > 0 && (errorCount == dependencies[dependencyType].length)) {
            effectsValues[dependencyType] = defaultConditionValues[dependencyType];
        }
    });
    if (dependencies.sum.length)
        effectsValues.sum = sumResult;
    return effectsValues;
};
