import React, { FC } from 'react'
import { PartitionsProps as Props } from './types'
import { ActionsColumn, NewButton, Title, FILTER_TYPE, DataTable, DataRow, NestedMenu, ConfirmModal, Switch } from 'prace-common-components'
import { $Grid, $SvgIcon, $SwitchContainer } from './styles'

const menuItems = [
  {id: 1, label: <><$SvgIcon name='altEdit' size={16} />Edit</>},
  {id: 2, label: <><$SvgIcon name='delete' size={16} />Delete</>},
]

export const Partitions: FC<Props> = (
  { partitions = [], handleAddPartition, handleSelectPartition, handleDeletePartition, handleDisablePartition },
) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [openDelete, setOpenDelete] = React.useState<boolean>(false)
  const [selectedId, setSelectedId] = React.useState<number>()

  const handleItemClick = (id: StringNumber) => {
    setAnchorEl(null)
    if(selectedId === undefined) return
    switch(id) {
      case 1:
        handleSelectPartition(selectedId)
        break
      default: //TODO: Do DeletePartition
        setOpenDelete(true)
    }
  }

  const handleClick = (e: React.MouseEvent<any>, id: StringNumber) => {
    setAnchorEl(e.currentTarget)
    setSelectedId(Number(id))
  }

  const handleDelete = () => {
    if(selectedId === undefined) return
    handleDeletePartition(selectedId)
    setOpenDelete(false)
  }

  const columns = [
    { key: 'name', noSort: true, type: FILTER_TYPE.NONE, name: 'Name' },
    { key: 'disabled', noSort: true, type: FILTER_TYPE.NONE, name: 'Enabled', width: 35,
      formatter(props: { row: DataRow }) {
        const { disabled, id } = props.row
        return <$SwitchContainer container alignItems='center' justifyContent='center'>
          <Switch noMargin name='disabled' value={!disabled} title='' onChange={(_name: string, value: boolean) => handleDisablePartition(id, !value)} />
        </$SwitchContainer>
      },
    },
    { key: 'actions', noSort: true, type: FILTER_TYPE.NONE, name: 'Actions', width: 35 },
  ]

  const rows = partitions.map((partition) => {
    return {
      ...partition,
      actions: <ActionsColumn handleClick={(e: React.MouseEvent<any>) => handleClick(e, partition.id)}/>,
    }
  })

  return (
    <$Grid container>
      <Title>Partitions</Title>
      <DataTable
        showEmptyImg={false}
        noFilters
        rows={rows}
        columns={columns}
      />
      <NewButton creator onClick={handleAddPartition}>Add Partition</NewButton>
      <NestedMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        items={menuItems}
        handleItemClick={handleItemClick}
      />
      {openDelete &&
        <ConfirmModal
          open
          title='Delete Partition'
          onConfirm={handleDelete}
          onClose={() => setOpenDelete(false)}
        />
      }
    </$Grid>
  )
}
