export function replaceURLs(message) {
    if (!message)
        return '';
    return message.replace(/(((https?:\/\/)|(www\.))[^\s]+)/g, function (url) {
        let hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) {
            hyperlink = 'http://' + hyperlink;
        }
        return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
    });
}
