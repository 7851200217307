import styled, { css } from 'styled-components';
const defaultCard = (selected) => css `
  border: ${({ theme }) => selected ? `1px solid ${theme.colors.primary}` : `1px solid ${theme.colors.cards.border}`};
  background-color: ${({ theme }) => selected ? theme.colors.cards.background : theme.colors.white};
`;
const alternativeCard = (selected) => css `
  border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
  background-color: ${({ theme }) => selected ? theme.colors.primary : theme.colors.white};
`;
const stackCard = (selected, alternative) => css `
  &:after{
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    ${alternative ? alternativeCard(selected) : defaultCard(selected)};
    border-radius: ${({ theme }) => theme.dimensions.borderRadius};
    transition: transform 0.5s;
    transform: ${({ theme }) => `translate(${theme.spacing(1)}, -${theme.spacing(1)})`};
  }
`;
export const $Card = styled.div `
  margin: ${({ theme }) => `${theme.spacing(1)} 0px`};
  width: 100%;
  text-align: center;
  height: fit-content;
  padding: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  cursor: pointer;
  ${({ alternative, selected }) => alternative ? alternativeCard(selected) : defaultCard(selected)};
  position: relative;
  ${({ stacked, selected, alternative }) => stacked && stackCard(selected, alternative)};
`;
const defaultTexts = (selected) => css `
  padding-top: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => selected ? theme.colors.primary : '#757778'};
`;
const alternativeTexts = (selected) => css `
  padding-top: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => selected ? theme.colors.white : theme.colors.primary};
`;
export const $CardTexts = styled.div `
  ${({ alternative, selected }) => alternative ? alternativeTexts(selected) : defaultTexts(selected)};
`;
