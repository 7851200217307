import styled from 'styled-components';
import { Tab, Tabs } from '@material-ui/core';
export const $Tabs = styled(Tabs) `
  position:relative;
  border-radius: 0px 5px;
  &.MuiTabs-root {
    align-items: end;
    overflow: visible;
    .MuiTabs-scroller{
      overflow: visible !important;
    }
  }
`;
export const $Tab = styled(Tab) `
  &.MuiTab-root{
    color: ${({ theme }) => theme.colors.greyScales[800]};
    opacity: 1;
    z-index:2;
    min-height:40px;
    text-transform: none;
    font-family: ${({ selected }) => selected ? 'roboto-medium, sans-serif' : 'inherit'};
    border: 1px solid ${({ theme }) => theme.colors.greyScales[350]};
    border-radius: 6px 6px 0px 0px;
    font-size: 1em;
    border-bottom: 1px solid ${({ selected }) => selected ? 'white' : 'none'};
    background-color: ${({ selected, theme }) => selected ? 'transparent' : theme.colors.greyScales[50]};
    margin-right: 15px;
  }
`;
export const $BotomLine = styled.div `
  position: absolute;
  width: 200%;
  height: 1px;
  bottom: 0;
  left: -50%;
  background-color: ${({ theme }) => theme.colors.greyScales[350]};
`;
