import React, { useEffect, useMemo } from 'react'
import { ELEMENT_TYPES, FlowFormElement, insertSelectOptionGroups } from 'prace-common-components'
import { EditElement } from './EditElement'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { RootState } from 'store'

export const EditElementController: React.FC = () => {
  const dispatch = useAppDispatch()
  const call = useAppSelector((state: RootState) => state.calls.call)
  const editorSelector = useAppSelector((state: RootState) => state.calls.editorSelector)
  const optionsGroups = useAppSelector((state: RootState) => state.calls.optionGroups)
  const [tab, setTab] = React.useState<number>(0)
  const [open, setOpen] = React.useState<boolean>(true)
  const { step, form, element, elements, lastSelection } = editorSelector

  const selectedStep = call?.flow[step]
  const forms = selectedStep?.flow
  const selectedForm = forms[form]
  const formElements = selectedForm?.flow ? selectedForm.flow : []
  const selectedElement = formElements.length ? formElements[element] : null
  const cutoffs = call?.cutoffs || []

  useEffect(() => {
    handleChangeTab(lastSelection === ELEMENT_TYPES.ELEMENT ? 1 : 0)
  }, [lastSelection, step, form, element])

  useEffect(() => {
    if (lastSelection === ELEMENT_TYPES.ELEMENT) {
      setOpen(true)
    }
  }, [lastSelection, element])

  // TODO: Try to find a way of doing this in a different place and way
  const elementWithOptions: Nullable<FlowFormElement> = useMemo(() => {
    /**
     * We had the option groups here so that it is always updated
     * according to the new ones created by the user
     * */
    if (selectedElement) {
      return insertSelectOptionGroups([selectedElement], optionsGroups, false)[0]
    }
    
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedElement, optionsGroups])

  
  const handleChangeTab = (newTab: number) => setTab(newTab)

  const handleChangeDrawer = () => setOpen(!open)

  const handleCrumbClick = (id?: StringNumber) => {
    dispatch({ type: 'selectElement', payload: { order: id === 1 ? step : form, type: id === 1 ? ELEMENT_TYPES.STEP : ELEMENT_TYPES.FORM }})
  }
  
  const handleEndAddDrag = () => {
    dispatch({ type: 'updateCall', payload: {} })
  }

  return (
    <>
      <EditElement
        selectedType={lastSelection}
        selectedElement={elementWithOptions}
        selectedForm={selectedForm}
        selectedStep={selectedStep}
        cutoffs={cutoffs}
        selectedElements={elements}
        tab={tab}
        open={open}
        onChangeDrawer={handleChangeDrawer}
        onChangeTab={handleChangeTab}
        onEndAddDrag={handleEndAddDrag}
        handleCrumbClick={handleCrumbClick}
      />
    </>
  )
}
