import styled from 'styled-components';
export const $DropBox = styled.div `
  border: ${({ theme }) => `2px dashed ${theme.colors.primary}`};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  text-align: center;
  color: rgba(54, 77, 156, 0.5);
  font-size: 1.2em;
  padding: 50px;
  cursor: ${({ clickable }) => clickable && 'pointer'};
`;
