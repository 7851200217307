import React, { FC } from 'react'
import { AddPartitionProps as Props } from './types'
import { NewButton, Input, Title } from 'prace-common-components'
import { $Grid } from './styles'

export const AddPartition: FC<Props> = ({ partition, onBackClick, handleUpdatePartition }) => {

  const handleUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = new FormData(e.target as HTMLFormElement)
    const values = Object.fromEntries(data.entries())
    const { name } = values
    if(name) handleUpdatePartition({ ...partition, name: name as string })
  }

  return (
    <form onSubmit={handleUpdate}>
      <$Grid container>
        <Title
          hasBack
          onBackClick={onBackClick}
        >
          {partition.name ? 'Edit Partition' : 'Add Partition'}
        </Title>
        <Input
          type='text'
          title='Partition Name'
          name='name'
          defaultValue={partition.name}
          hideItalic
        />
        <NewButton type='submit'>{partition.name ? 'Edit Partition' : 'Add Partition'}</NewButton>
      </$Grid>
    </form>
  )
}
