import { useMemo, useState, useEffect } from 'react'
import { FORM_ELEMENT_TYPES, Item, FlowForm, FlowFormElement, FlowStep } from 'prace-common-components'
import { FormElement } from 'components/FormModal/types'

export default function useElementSelects(
  steps: FlowStep[] = [],
  state: {
    [key: string]: any,
    step: Nullable<FlowStep['id']>,
    form: Nullable<FlowForm['id']>,
    element: Nullable<FlowFormElement['id']>,
    groupElement: Nullable<FlowFormElement['id']>,
  },
  title?: string,
  onlyStep?: boolean,
  optional?: boolean,
  showGroupElements?: boolean,
) {
  const { step = null, form = null, element = null, groupElement = null } = state
  const [selectValues, setSelectValues] = useState({
    selectStep: step, selectForm: form, selectElement: element, selectGroup: groupElement,
  })
  const { selectStep, selectForm, selectElement, selectGroup } = selectValues
  const displayGroupElement = showGroupElements || (groupElement !== null)
  const differences = Object.values({
    step: step != selectStep,
    form: form != selectForm,
    element: element != selectElement,
    groupElement: groupElement !== selectGroup,
  })

  useEffect(() => {
    const validIds = {
      step: step !== null, form: form !== null, element: element !== null, groupElement: groupElement !== null,
    }
    const countDifferences = differences.filter((value) => !!value).length
    const flowStep = validIds.step ? steps.find((arrayStep: FlowStep) => arrayStep.id == step) : undefined
    const flowForm = (flowStep && validIds.form) ?
      flowStep?.flow.find((arrayForm: FlowForm) => arrayForm.id == form) : undefined
    const flowElement = (flowForm && validIds.element) ?
      flowForm?.flow.find((arrayElement: FlowFormElement) => arrayElement.id == element)
      : undefined
    const groupFlowElement = (flowElement && validIds.groupElement) ?
      flowElement?.flow.find((arrayElement: FlowFormElement) => arrayElement.id == groupElement)
      : undefined
    /* If there are no changes, then do nothing */
    if(!countDifferences) return
    /* Multiple things changed, update every value */
    if(countDifferences > 1) {
      setSelectValues((prevSelectValues) => ({
        ...prevSelectValues,
        selectStep: flowStep ? flowStep.id : null,
        selectForm: flowForm ? flowForm.id : null,
        selectElement: flowElement ? flowElement.id : null,
        selectGroup: groupFlowElement ? groupFlowElement.id : null,
      }))
    } else {
      setSelectValues((prevSelectValues) => ({
        ...prevSelectValues,
        selectStep: flowStep ? flowStep.id : null,
        selectForm: !differences[0] && flowForm ? flowForm.id : null ,
        selectElement: !differences[0] && !differences[1] && flowElement ? flowElement.id : null,
        selectGroup: !differences[0] && !differences[1] && !differences[2] && groupFlowElement ?
          groupFlowElement.id : null,
      }))
    }
  }, [step, form, element, groupElement, steps, displayGroupElement, differences])

  const stepSelect = useMemo(() => {
    return { id: 0, name: 'step', value: selectStep,
      elementType: FORM_ELEMENT_TYPES.SELECT,
      title,
      items: steps.map((s) => { return { value: s.id, label: s.title }}),
      options: {
        placeholder: 'Choose a step',
      },
    }
  }, [selectStep, steps, title])

  const formSelect = useMemo(() => {
    let items: Item[] = []
    if(selectStep !== null) {
      const flowStep = steps.find((arrayStep: FlowStep) => arrayStep.id == selectStep)
      items = flowStep?.flow.map((form: FlowForm) => { return { value: form.id, label: form.title }}) || []
    }
    return { id: 1, name: 'form', value: selectForm,
      elementType: FORM_ELEMENT_TYPES.SELECT,
      title: '',
      items,
      options: {
        placeholder: `Choose a form ${optional ? ' (Optional)' : ''}`,
      },
    }
  }, [selectStep, selectForm, optional, steps])

  const elementSelect = useMemo(() => {
    let items: Item[] = []
    if(selectStep !== null && selectForm !== null) {
      const flowStep = steps.find((arrayStep: FlowStep) => arrayStep.id == selectStep)
      const flowForm = flowStep?.flow.find((arrayForm: FlowForm) => arrayForm.id == selectForm)
      items = flowForm?.flow.map((e: FlowFormElement) => { return { value: e.id, label: e.title }}) || []
    }
    return { id: 2, name: 'element', value: selectElement,
      elementType: FORM_ELEMENT_TYPES.SELECT,
      title: '',
      items,
      options: {
        placeholder: `Choose a flow element ${optional ? ' (Optional)' : ''}`,
      },
    }
  }, [selectStep, selectForm, selectElement, optional, steps])

  const groupSelect = useMemo(() => {
    let items: Item[] = []
    if(selectStep !== null && selectForm !== null && selectElement !== null) {
      const flowStep = steps.find((arrayStep: FlowStep) => arrayStep.id == selectStep)
      const flowForm = flowStep?.flow.find((arrayForm: FlowForm) => arrayForm.id == selectForm)
      const flowElement = flowForm?.flow.find(
        (arrayElements: FlowFormElement) => arrayElements.id == selectElement,
      )
      items = flowElement?.flow.map((e: FlowFormElement) => { return { value: e.id, label: e.title }}) || []
    }
    return { id: 3, name: 'groupElement', value: selectGroup,
      elementType: FORM_ELEMENT_TYPES.SELECT,
      title: '',
      items,
      options: {
        placeholder: `Choose a group flow element ${optional ? ' (Optional)' : ''}`,
      },
    }
  }, [selectStep, selectForm, selectElement, selectGroup, optional, steps])

  const selects = onlyStep ?
    [stepSelect] : displayGroupElement ?
      [stepSelect, formSelect, elementSelect, groupSelect] : [stepSelect, formSelect, elementSelect]

  return [{
    ...state, step: selectStep, form: selectForm, element: selectElement, groupElement: selectGroup,
  }, selects as FormElement[]]
}
