import React, { useEffect } from 'react';
import { $Input, $InputLabel } from '../newInput';
import { $FormControl, $Chip, $ChipContainer } from './styles';
import { SvgIcon } from '../SvgIcon';
import { $FormHelperText } from '../newInput/styles';
import { $Italic } from '../newTypography';
export const EditableMultiSelect = ({ error, id = '', errorMsg, placeholder = '', ariaLabel = '', description = null, value = [], name, title, disabled, hideItalic = false, onChange = () => null, ...rest }) => {
    const filteredValue = Array.isArray(value) ? value.filter((item) => !!item) : [value].filter((item) => !!item);
    const [inputValue, setInputValue] = React.useState('');
    const [selectedItems, setSelectedItems] = React.useState(filteredValue);
    const { required } = rest;
    useEffect(() => {
        if (JSON.stringify(selectedItems) !== JSON.stringify(filteredValue))
            setSelectedItems(filteredValue);
    }, [filteredValue]);
    const handleAddSelectItems = (inputValue) => {
        setSelectedItems((selectedItems) => {
            const newSelectedItems = [...selectedItems];
            const duplicatedValues = newSelectedItems.indexOf(inputValue.trim());
            if (duplicatedValues !== -1)
                return selectedItems;
            if (!inputValue.replace(/\s/g, '').length)
                return selectedItems;
            newSelectedItems.unshift(inputValue.trim());
            onChange(name, newSelectedItems);
            return newSelectedItems;
        });
    };
    const handleRemoveSelectItems = (inputValue) => {
        let deleteItem = false;
        setSelectedItems((selectedItems) => {
            if (selectedItems.length && !inputValue.length) {
                deleteItem = true;
                const newSelectedItems = selectedItems.slice(0, selectedItems.length - 1);
                onChange(name, newSelectedItems);
                return newSelectedItems;
            }
            else {
                return selectedItems;
            }
        });
        return deleteItem;
    };
    useEffect(() => {
        const handlekeyPress = (e) => {
            if (e.key === 'Enter' || e.key === ',') {
                setInputValue((inputValue) => {
                    handleAddSelectItems(inputValue);
                    return '';
                });
            }
            if (e.key === 'Backspace') {
                setInputValue((inputValue) => (handleRemoveSelectItems(inputValue) ? '' : inputValue));
            }
        };
        if (id || name) {
            const multiElement = document.getElementById(id || name);
            if (multiElement)
                multiElement.addEventListener('keydown', handlekeyPress);
        }
        return () => {
            const multiElement = document.getElementById(id || name);
            if (multiElement)
                multiElement.removeEventListener('keydown', handlekeyPress);
        };
    }, [name, id]);
    const handleInputChange = (e) => {
        setInputValue(e.target.value.replace(/,/g, ''));
    };
    const handleDelete = (e, val) => {
        e.preventDefault();
        setSelectedItems((selectedItems) => {
            const foundIdx = selectedItems.findIndex((item) => item == val);
            selectedItems.splice(foundIdx, 1);
            onChange(name, selectedItems);
            return selectedItems;
        });
    };
    const onBlur = () => {
        setInputValue((inputValue) => {
            if (inputValue)
                handleAddSelectItems(inputValue);
            return '';
        });
    };
    return (React.createElement($FormControl, null,
        React.createElement($InputLabel, { disableAnimation: true, shrink: false },
            title,
            required && '*',
            !hideItalic &&
                React.createElement($Italic, null, "multitext")),
        React.createElement($Input, { ...rest, id: `${id || name}`, type: 'text', name: name, autoComplete: 'new-password', value: inputValue, disabled: disabled, onChange: handleInputChange, error: error, placeholder: placeholder, inputProps: { 'aria-label': ariaLabel, 'aria-autocomplete': 'none' }, onBlur: onBlur, withScroll: true, startAdornment: disabled ? React.createElement(React.Fragment, null, selectedItems.join(', ')) :
                React.createElement($ChipContainer, null, selectedItems.map((item, i) => (React.createElement($Chip, { key: `${i}${item}`, tabIndex: -1, label: item.length > 15 ? (item.slice(0, 15) || '') + '...' : item, deleteIcon: React.createElement(SvgIcon, { name: 'cancel', onMouseDown: (e) => e.stopPropagation() }), onDelete: (e) => { if (!disabled)
                        handleDelete(e, item); } })))) }),
        (description || errorMsg) &&
            React.createElement($FormHelperText, { error: error }, errorMsg || description)));
};
