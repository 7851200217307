import * as React from 'react';
import { SvgIcon } from '../SvgIcon';
import { $ProgressBar, $Snackbar, $Notification, $NotificationText, $NotificationIcon } from './styles';
export const Notification = ({ type, msg, notificationNumber, timer, closeNotification, }) => {
    const typeIcon = () => {
        switch (type) {
            case 'success':
                return React.createElement(SvgIcon, { name: 'check-filled', size: 26 });
            case 'warning':
                return React.createElement(SvgIcon, { name: 'warning', size: 26 });
            case 'info':
            case 'error':
                return React.createElement(SvgIcon, { name: 'info', size: 26 });
            default:
                return null;
        }
    };
    const handleClose = (_e, reason) => {
        if (reason === 'clickaway')
            return;
        closeNotification(notificationNumber);
    };
    React.useEffect(() => {
        if (notificationNumber) {
            const timeout = setTimeout(() => closeNotification(notificationNumber), timer);
            return () => clearTimeout(timeout);
        }
        else {
            return () => null;
        }
    }, []);
    if (msg === 'Unauthorized')
        return null;
    return (React.createElement($Snackbar, { timer: timer - 500 },
        React.createElement($Notification, { type: type },
            React.createElement($NotificationIcon, null, typeIcon()),
            React.createElement($NotificationText, { type: (type === 'success' || type === 'error') ? type : undefined, "data-test-id": 'notification' }, msg),
            React.createElement(SvgIcon, { name: 'close', size: 20, clickable: true, onClick: handleClose, color: '#fff' }),
            React.createElement($ProgressBar, { duration: timer }))));
};
