import { Permissions } from 'prace-common-components'

export const getMultiSelectProps = (isStep: boolean, permissions: Permissions, step?: number) => {
  const { visibility, dataVisibility, submittedVisibility, assign, assignable } = permissions

  if (isStep) {
    if(step === 0) {
      return [
        {
          value: visibility,
          name: 'visibility',
          title: 'Step visible to',
          description: 'Select roles this step should be visible to',
        },
        {
          value: dataVisibility,
          name: 'dataVisibility',
          title: 'Visible after all assignments are submitted',
          description: 'Select roles this step\'s submitted assignments should be visible to',
        },
        {
          value: permissions.hideOwner,
          name: 'hideOwner',
          title: 'Hide owner and comments from',
          description: 'These roles can\'t see the owner or the review comments',
        },
        {
          value: permissions.export,
          name: 'export',
          title: 'Exportable by',
        },
      ]
    } else {
      return [
        {
          value: visibility,
          name: 'visibility',
          title: 'Visible to',
          description: 'Select roles this step should be visible to',
        },
        {
          value: dataVisibility,
          name: 'dataVisibility',
          title: 'Visible after all assignments are submitted',
          description: 'Select roles this step\'s submitted assignments should be visible to',
        },
        {
          value: submittedVisibility,
          name: 'submittedVisibility',
          title: 'Step\'s submitted assignments are always visible',
          description: 'Roles this step\'s submitted assignments are always visible to',
        },
        {
          value: permissions.hideOwner,
          name: 'hideOwner',
          title: 'Hide owner and comments from',
          description: 'These roles can\'t see the owner or the review comments',
        },
        {
          value: assign,
          name: 'assign',
          title: 'Assigner',
          description: 'Select roles that can assign in this step',
        },{
          value: assignable,
          name: 'assignable',
          title: 'Assignee',
        },
        {
          value: permissions.export,
          name: 'export',
          title: 'Exportable by',
        },
      ]
    }
  }

  return [
    {
      value: visibility,
      name: 'visibility',
      title: 'Visible to',
      description: 'Select roles this form should be visible to',
    },
    {
      value: permissions.export,
      name: 'export',
      title: 'Exportable by',
    },
  ]
}
