import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { SvgIcon, NewDatePicker } from '../../';
export const $DatePicker = styled(NewDatePicker) `
  .MuiOutlinedInput-adornedEnd {
    padding-right: ${({ theme }) => theme.spacing(1)};
  }

  fieldset {
    border: 1px solid #e4e3e2;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiIconButton-root {
    padding: 0;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  input {
    padding: 0;
    font-size: 14px;
    height: 100%;
  }

`;
export const $FilterColumn = styled.div `
  line-height: 20px;
  margin-bottom: 0px;
  white-space: pre-line;
`;
export const $SvgIcon = styled(SvgIcon) `
  margin-top: 3px;
`;
export const $NumberInput = styled.input `
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
`;
export const $FilterContainer = styled(Grid) `
  padding: 0px 2px;
  .filterInput {
    border-radius: 4px !important;
  }
`;
export const $GridColumnFilter = styled(Grid) `
  &.MuiGrid-root {
    /* align-self: end; */
    /* margin-top: 6px; */
    margin-bottom: 2px;
  }
`;
export const $SortRenderer = styled(Grid) `
  &.MuiGrid-root {
    cursor: pointer;
    flex-wrap: nowrap;
  }
`;
export const $HeadGrid = styled(Grid) `
  height: 100%;
`;
