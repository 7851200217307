import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { SvgIcon } from 'prace-common-components'


//TODO: Repeated code from FormModal
export const $ModalContainer = styled.div`
  min-width: 500px;
`

export const $ActionsContainer = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`

export const $ElementContainer = styled(Grid)`
  margin: ${({ theme }) => `${theme.spacing(1)} 0px`};
`

export const $NotifySupportContainer = styled(Grid)`
  margin: ${({ theme }) => `${theme.spacing(2)} 0`};
`

export const $KeepUserRolesContainer = styled(Grid)`
  margin-bottom: ${({ theme }) => `${theme.spacing(2)}`};
`

export const $SvgIcon = styled(SvgIcon)``
