import styled from 'styled-components';
import { Link } from '@material-ui/core';
export const $Footer = styled.footer `
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  grid-area: foot;
`;
export const $FooterContainer = styled.div `
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  gap:30px;
  width: 100%;
  min-height: 120px;
  align-items: center;
  justify-content: space-between;
  padding:20px;
  flex-direction: row;
`;
export const $Description = styled.p `
  margin: 0;
  padding-top: ${({ theme }) => theme.spacing(2)};
  font-size: 0.8em;
  color: ${({ theme }) => theme.colors.white};
`;
export const $Link = styled(Link) `
  &.MuiTypography-colorPrimary{
    display: flex;
    color:white
  }
`;
export const $Name = styled.p `
  margin-left: ${({ theme }) => theme.spacing(1)};
  max-width: 200px;
  padding: 0;
  font-size: 0.8em;
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: 768px) {
    display: none;
  }
`;
export const $FooterLinks = styled.div `
  display:flex;
  gap: 50px;
`;
