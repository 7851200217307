import React from 'react';
import { $Landing, $LogoContainer, $Form, $FormSide, $ImageSide, $Logo, $Title, $Description, $Selector } from './styles';
import { useCheckMobileScreen } from '../';
export const Landing = ({ title = 'Welcome', description = '', logo, image, children }) => {
    const isMobile = useCheckMobileScreen();
    return (React.createElement($Landing, null,
        React.createElement($FormSide, null,
            React.createElement($LogoContainer, null,
                React.createElement($Logo, { src: logo })),
            React.createElement($Form, null,
                React.createElement($Title, null, title),
                React.createElement($Description, null, description),
                React.createElement($Selector, null, children))),
        !isMobile && React.createElement($ImageSide, { image: image })));
};
