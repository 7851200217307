import React, { FC, useState, useRef, ChangeEvent } from 'react'
import { AddCenterProps as Props } from './types'
import { Input, AutocompleteInput, NewButton, Item, NotificationType } from 'prace-common-components'
import { $Grid } from './styles'
import { Partitions } from './Partitions'
import { AddPartition } from './AddPartition'
import { useCreatePartitionMutation, useDeletePartitionMutation, useDisablePartitionsMutation, useGetPartitionsQuery, useUpdatePartitionMutation } from 'store/api/partitions'
import { Partition } from 'store/api/partitions/types'
import { useSearchUsersMutation } from 'store/api/users'
import { useAppDispatch } from 'store/hooks'
import { UserSuggestion } from 'store/api/users/types'

export const AddCenter: FC<Props> = ({ center, open, handleUpdateCenter, handleAddCenter }) => {
  const dispatch = useAppDispatch()
  const searchTimer = useRef<number>()
  const [suggestions, setSuggestions] = useState<{ data: UserSuggestion[]; page: number; pageCount: number}>()
  const { data: partitionsData, refetch } = useGetPartitionsQuery(
    { centerId: center?.id, page: 1 },
    { skip: !center || !open, refetchOnMountOrArgChange: true })
  const centerPartitions = partitionsData?.partitions || []
  const [searchUsers] = useSearchUsersMutation()
  const [createPartition] = useCreatePartitionMutation()
  const [updatePartition] = useUpdatePartitionMutation()
  const [deletePartition] = useDeletePartitionMutation()
  const [disablePartition] = useDisablePartitionsMutation()
  const ccr = center?.user
  const [selectedCCR, setSelectedCCR] = useState<Item>(ccr ? { value: ccr.id, label: `${ccr.firstName} ${ccr.lastName} - ${ccr.email}` } : { value: 0, label: '' })
  const [selectedPartition, setSelectedPartition] = useState<Partition | undefined>(undefined)

  React.useEffect(() => {
    const getUsers = async () => {
      try {
        const { users } = await searchUsers({
          search: center?.user.email || '',
          page: 0,
          pageSize: 999,
        }).unwrap()
        setSuggestions({ data: users as unknown as UserSuggestion[], page: 1, pageCount: users.length })
      } catch (err) {
        console.log(err)
      }
    }
    if(open) getUsers().catch((err) => console.log(err))
  }, [center?.user.email, searchUsers, open])

  const CCRItems: Item[] = suggestions?.data
    .map((suggestion) => ({
      label: `${suggestion.firstName} ${suggestion.lastName} - ${suggestion.email}`,
      value: suggestion.id,
    })) || []

  const handleCenter = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = new FormData(e.target as HTMLFormElement)
    const values = Object.fromEntries(data.entries())
    const { name, ccr } = values
    //TODO: Do error messages
    if(!name || !ccr) return
    if(center) handleUpdateCenter(center.id, name as string, Number(selectedCCR.value))
    else handleAddCenter(name as string, Number(selectedCCR.value))
  }

  const onInputChange = (_: any, value: string) => {
    clearTimeout(searchTimer.current)
    searchTimer.current =  window.setTimeout(async () => {
      const { users } = await searchUsers({
        search: value,
        page: 0,
        pageSize: 999,
      }).unwrap()
      setSuggestions({ data: users as unknown as UserSuggestion[], page: 1, pageCount: users.length })
    }, 500)
  }

  const handleUpdatePartition = async (partition: Partition, centerId?: number) => {
    if(!centerId) return
    if(partition.id) {
      try {
        await updatePartition(partition).unwrap()
        dispatch({
          type: 'notification',
          payload: { type: NotificationType.success, msg: 'Partition updated' } })
        refetch()
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        await createPartition({ centerId, ...partition }).unwrap()
        dispatch({
          type: 'notification',
          payload: { type: NotificationType.success, msg: 'Partition created successfully' } })
        refetch()
      } catch (err) {
        console.log(err)
      }
    }
    setSelectedPartition(undefined)
  }

  const handleDisablePartition = async (id: number, disabled: boolean) => {
    try {
      await disablePartition({ ids: [id] }).unwrap()
      dispatch({
        type: 'notification',
        payload: { type: NotificationType.success, msg: `Partition ${disabled ? 'Disabled' : 'Enabled'} successfully` } })
      refetch()
    } catch (err) {
      console.log(err)
    }
  }

  const handleDeletePartition = async (id: number) => {
    try {
      await deletePartition(id).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Partition deleted successfully' } })
      refetch()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    selectedPartition ?
      <AddPartition
        onBackClick={() => setSelectedPartition(undefined)}
        partition={selectedPartition}
        handleUpdatePartition={(partition: Partition) => handleUpdatePartition(partition, center?.id)}
      />
      :
      <form onSubmit={handleCenter}>
        <$Grid container>
          <Input
            type='text'
            required
            title='Center Name'
            name='name'
            defaultValue={center?.name || null}
            hideItalic
          />
          <AutocompleteInput
            title='Computer Center Representative'
            name='ccr'
            required
            items={CCRItems}
            value={selectedCCR}
            onChange={(_: ChangeEvent<unknown>, ccr: unknown) => setSelectedCCR(ccr as Item)}
            onInputChange={onInputChange}
          />
          <NewButton type='submit'>{center ? 'Save Changes'  : 'Add Center'}</NewButton>
          {center &&
            <Partitions
              partitions={centerPartitions}
              handleSelectPartition={(id: number) =>
                setSelectedPartition(centerPartitions.find((partition) => partition.id == id))}
              handleAddPartition={() => setSelectedPartition({id: 0, name: '', disabled: true, resources: []})}
              handleDeletePartition={handleDeletePartition}
              handleDisablePartition={handleDisablePartition}
            />
          }
        </$Grid>
      </form>
  )
}
