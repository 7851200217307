import { fetchBaseQuery, } from '@reduxjs/toolkit/query';
import { NotificationType } from '../Notification';
import { Mutex } from 'async-mutex';
export class ErrorReason {
    constructor(status, statusText, code = '', message = '', param = '', data = undefined) {
        this.status = status,
            this.statusText = statusText,
            this.code = code,
            this.message = message;
        this.param = param;
        this.data = data;
    }
}
const NO_RETRY_ROUTES = [
    '/auth/login',
    '/auth/forgot',
    '/auth/forgot/sent',
    '/auth/reset/:token',
    '/auth/register',
    '/auth/register/confirmation',
    '/auth/confirm/:token',
];
const showExpiredNotice = () => {
    if (!window.location.pathname.includes('auth'))
        window.document.body.dispatchEvent(new Event('expired', {
            bubbles: true,
            cancelable: false,
            composed: false,
        }));
};
const isRefreshRequest = (url) => {
    return url === '/users/refresh';
};
const mutex = new Mutex();
export const apiBaseQuery = (app, baseUrl, noToken) => async (args, api, extraOptions) => {
    await mutex.waitForUnlock();
    const baseQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const { token } = getState().auth;
            if (token && !noToken)
                headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
        responseHandler: async (res) => res.json().catch(() => null),
    });
    let result = await baseQuery(args, api, extraOptions);
    const resultRes = result.meta?.response;
    const errorHandler = (err) => {
        if (!err)
            return;
        console.log(err);
        const errorMessage = err.data?.message || null;
        let msg = err?.status == 404 ? 'Not found' : 'Something went wrong';
        if (errorMessage) {
            msg = typeof errorMessage === 'string' ? errorMessage : errorMessage.join('. ');
        }
        api.dispatch({ type: 'notification', payload: { type: NotificationType.error, msg } });
    };
    const forbiddenError = (result?.error?.status === 403 || result?.error?.originalStatus === 403);
    if (forbiddenError && app.includes('admin')) {
        window.location.href = '/auth/login';
    }
    const expireError = (result?.error?.status === 401 || result?.error?.originalStatus === 401);
    try {
        if (result?.error && !expireError)
            errorHandler(result.error);
        if (isRefreshRequest(args?.url) && expireError) {
            showExpiredNotice();
        }
        else {
            if (expireError && !(NO_RETRY_ROUTES.includes(window.location.pathname))) {
                if (!mutex.isLocked()) {
                    const release = await mutex.acquire();
                    try {
                        const refreshResult = await baseQuery({
                            url: `${baseUrl}/users/refresh`,
                            method: 'POST',
                            credentials: 'include',
                            body: { app },
                        }, api, extraOptions);
                        if (refreshResult.error) {
                            window.localStorage.removeItem('usr');
                            if (refreshResult.error?.data && refreshResult.error?.status !== 401)
                                errorHandler(refreshResult.error);
                            showExpiredNotice();
                        }
                        else {
                            const token = refreshResult.data.token;
                            api.dispatch({ type: 'refresh', payload: { token } });
                            window.localStorage.setItem('usr', 'true');
                            const updatedBaseQuery = fetchBaseQuery({ baseUrl,
                                prepareHeaders: (headers) => {
                                    headers.set('Authorization', `Bearer ${token}`);
                                    return headers;
                                },
                                responseHandler: async (res) => res.json().catch(() => null),
                            });
                            result = await updatedBaseQuery(args, api, extraOptions);
                        }
                    }
                    finally {
                        release();
                    }
                }
                else {
                    await mutex.waitForUnlock();
                    result = await baseQuery(args, api, extraOptions);
                }
                if (!(resultRes?.ok || resultRes?.status === 302)) {
                    errorHandler(result.error);
                }
            }
        }
        return result;
    }
    catch (err) {
        errorHandler(result.error);
        return result;
    }
};
