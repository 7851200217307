import styled from 'styled-components';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
export const $StepConnector = styled(StepConnector) `
  .MuiStepConnector-line {
    width: 1px;
    border: 0;
    border-style: dashed;
    border: none;
    border-left: ${({ theme }) => '1px dashed ' + theme.colors.secondary};
    border-radius: 1px;
  }
  &.MuiStepConnector-vertical {
    padding: 0;
    margin-left: 5px;
  }
  &.MuiStepConnector-active {
    .MuiStepConnector-line {
      border: 0;
      background: ${({ theme }) => theme.colors.secondary};
    }
  }
  &.MuiStepConnector-completed {
    .MuiStepConnector-line {
      border: 0;
      background: ${({ theme }) => theme.colors.secondary};
    }
  }
`;
export const $StepIcon = styled.div `
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ theme, completed, working, activeForm }) => (completed || working || activeForm) ? theme.colors.secondary : theme.colors.white};
  border: ${({ theme }) => '1px solid ' + theme.colors.secondary};
  z-index: 1;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const $StepLabel = styled(StepLabel) `
  .MuiStepLabel-label {
    color: ${({ theme, error }) => error && theme.colors.feedback.error};
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
    &.MuiStepLabel-completed {}
    &.MuiStepLabel-active {
      font-weight: ${({ active, theme }) => active ? theme.fontWeights.heavier : 'initial'};
    }
  }
`;
