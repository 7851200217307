import styled from 'styled-components';
import { $Badge, hex2rgba } from '../';
export const $CallCard = styled.div `
  padding: ${({ theme }) => theme.spacing(3)};
  width: 300px;
  height: 190px;
  margin: 0px;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  ${({ theme }) => theme.elevations[2]};
  background-color: ${({ theme }) => theme.colors.white};
  &:hover {
    ${({ theme }) => theme.elevations[4]};
  }
`;
export const $CardTop = styled.div `
  display: flex;
  justify-content: space-between;
`;
export const $CardImage = styled.div `
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: flex;
  background: ${({ src, theme }) => src ? 'no-repeat center/100% url(' + src + ')' :
    `linear-gradient(45deg, ${theme.colors.secondary} 50%, ${hex2rgba(theme.colors.secondary, 0.88)} 50%)`};
  background-size: cover;
`;
export const $CardCutOffText = styled.p `
  color: ${({ theme }) => theme.colors.text.label};
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;
export const $CardCutOff = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const $Chip = styled($Badge) `
  margin: 0;
  font-size: 0.85em;
  max-height: 20px;
  border-radius: 15px;
  padding: ${({ theme }) => `0 ${theme.spacing(1)}`};
`;
export const $CardTitle = styled.h4 `
  color: ${({ theme }) => theme.colors.text.title};
  margin: ${({ theme }) => `${theme.spacing(1)} 0`};
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const $CardDescription = styled.p `
  color: ${({ theme }) => theme.colors.text.label};
  margin: 8px 0 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
export const $CardStatus = styled.div `
  display: flex;
  align-items: baseline;
`;
export const $StatusColor = styled.div `
  width: 10px;
  height: 10px;
  display: flex;
  border-radius: 50%;
  background-color: ${({ open, theme }) => open ? theme.colors.feedback.success : theme.colors.feedback.error};
`;
export const $StatusText = styled.div `
  margin-left: ${({ theme }) => theme.spacing(1)};
`;
