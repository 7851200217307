import React, { FC, PropsWithChildren, RefObject } from 'react'
import { useFocused, useSelected } from 'slate-react'
import { LinkElement } from '../custom-types'
import { $Link, $LinkIcon, $SvgIcon } from './styles'
import { Tooltip } from 'prace-common-components'

// TODO: Improve the link detection and always consider absolute urls

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    style={{fontSize: 0}}
  >
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)

export const Link: FC<PropsWithChildren<{
  attributes: {
    'data-slate-node': 'element',
    'data-slate-inline'?: true,
    'data-slate-void'?: true,
    dir?: 'rtl',
    ref: RefObject<HTMLAnchorElement>,
  },
  element: LinkElement,
  onEditLink: () => void,
}>> = ({ attributes, children, element, onEditLink }) => {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <$Link
      {...attributes}
      href={element.url}
      selected={selected}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
      <Tooltip
        text={element.url || ''}
        arrow
        show
        placement='right-end'
      >
        {(selected && focused) ? <$LinkIcon contentEditable={false}>
          <$SvgIcon btn name='altEdit' onClick={onEditLink} variant='primary' size={16} />
        </$LinkIcon> : <></>}
      </Tooltip>
    </$Link>
  )
}
