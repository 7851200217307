import * as React from 'react'
import { Input, ElementValue, CallPartition, NewButton, SvgIcon, Switch, Select, NewMultiSelect } from 'prace-common-components'
import { $PartitionsContainer, $Button, $Label, $CutOffLabels, $AllocationsGrid, $AllocationsGridItem, $AllocationsCutOffs, $AddAllocation } from './styles'
import Grid from '@material-ui/core/Grid'
import { EditPartitionsProps } from './types'

export const EditPartitions: React.FC<EditPartitionsProps> = ({
  cutoffs = [],
  partitions = [],
  resources,
  onAddAllocation,
  onAddPartition,
  onDeleteAllocation,
  onDeletePartition,
  onChangePartition,
  onChangeAllocation,
  onSave,
}) => {
  /*  Group allocations by Partition */
  const groupedPartitionIds = [...new Set(resources.map((resource) => resource.partition.id) as number[])] || []
  const partitionOptions = partitions.map((partition) => ({ value: partition.id, label: partition.name }))
  /* Sort partitions by name */
  const sortedPartitionOptions = partitionOptions?.sort((a, b) => {
    if (a.label < b.label) return -1
    if (a.label > b.label) return 1
    return 0
  }) || []
  const cutoffOptions = cutoffs.map(
    (cutoff) => ({value: cutoff.id ? String(cutoff.id) : cutoff.title as string, label: cutoff.title as string }))
  /* If there are unfinished partitions, do not let the user add more */
  const canAddPartition = !resources.find((resource) => resource.partition?.id === 0)

  const handleChangePartition = (oldPartition: CallPartition) =>
    (_name: string, newPartitionId: Nullable<ElementValue>) => {
      /* Do not let the user select the same partition multiple times */
      if(!newPartitionId || groupedPartitionIds.includes(newPartitionId as number)) return
      const partition = partitions.find((partition) => partition.id == newPartitionId)
      if(!partition) return
      onChangePartition(oldPartition, partition)
    }

  return <>
    <Grid container>
      <Grid item xs={3}>
        <$Label shrink>Select Partition</$Label>
      </Grid>
      <Grid item xs={1}>
        <$Label shrink>Fixed</$Label>
      </Grid>
      <Grid item xs={2}>
        <$Label shrink>Min. Resources</$Label>
      </Grid>
      <Grid item xs={2}>
        <$Label shrink>Max. Resources</$Label>
      </Grid>
      <Grid item xs={2}>
        <$Label shrink>Offer</$Label>
      </Grid>
      <Grid item xs={2}>
      </Grid>
    </Grid>
    <$PartitionsContainer container>
      {groupedPartitionIds.map((partitionId: CallPartition['id'], idx: number) => {
        const canAddAllocation = !!partitionId
        const resource = resources.find((resource) => resource.partition.id == partitionId)
        const partition = resource?.partition
        const partitionResources = canAddAllocation ?
          resources.filter((resource) => resource.partition.id == partitionId) : []
        return <Grid key={idx} container spacing={2} alignItems='center'>
          <Grid item xs={3}>
            <Select
              title=''
              name='partition'
              items={sortedPartitionOptions}
              onChange={handleChangePartition(partition!)}
              value={partitionId}
            />
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={1}>
            <SvgIcon name='delete' size={18} color='#757778' clickable onClick={() => onDeletePartition(partitionId)}/>
          </Grid>
          {canAddAllocation && <$CutOffLabels>
            <$Label shrink>Select Cut-Offs</$Label>
          </$CutOffLabels>}
          {partitionResources.map((partitionResource) =>
            <$AllocationsGrid key={partitionResource.id} container alignItems='center'>
              <$AllocationsCutOffs item xs={3}>
                <NewMultiSelect
                  title=''
                  hideItalic
                  name='cutoffs'
                  items={cutoffOptions}
                  onChange={onChangeAllocation(partitionResource.id)}
                  value={partitionResource.cutoffs}
                />
              </$AllocationsCutOffs>
              <$AllocationsGridItem item xs={1}>
                <Switch
                  noMargin
                  name='fixedAllocation'
                  value={!!partitionResource.fixedAllocation || !!partitionResource.fixed}
                  onChange={onChangeAllocation(partitionResource.id)}
                  title=''
                />
              </$AllocationsGridItem>
              <$AllocationsGridItem item xs={2}>
                {!!partitionResource.fixedAllocation || !!partitionResource.fixed ?
                  <Input
                    placeholder='Fixed Allocation...'
                    type='number'
                    title=''
                    hideItalic
                    name='fixed'
                    value={partitionResource.fixed}
                    onChange={onChangeAllocation(partitionResource.id)}
                  />
                  : <Input
                    placeholder='Minimum...'
                    type='number'
                    title=''
                    hideItalic
                    name='min'
                    value={partitionResource.min}
                    onChange={onChangeAllocation(partitionResource.id)}
                  />}
              </$AllocationsGridItem>
              <$AllocationsGridItem item xs={2}>
                {!(!!partitionResource.fixedAllocation || !!partitionResource.fixed) && <Input
                  placeholder='Maximum...'
                  type='number'
                  title=''
                  hideItalic
                  name='max'
                  value={partitionResource.max}
                  onChange={onChangeAllocation(partitionResource.id)}
                />}
              </$AllocationsGridItem>
              <$AllocationsGridItem item xs={2}>
                <Input
                  placeholder='Offer'
                  type='number'
                  title=''
                  hideItalic
                  name='offer'
                  value={partitionResource.offer}
                  onChange={onChangeAllocation(partitionResource.id)}
                />
              </$AllocationsGridItem>
              <Grid item xs={1}>
                <SvgIcon name='delete' size={18} color='#757778' clickable onClick={() => onDeleteAllocation(partitionResource.id)}/>
              </Grid>
            </$AllocationsGrid>,
          )}
          <$AddAllocation item xs={12}>
            <NewButton creator disabled={!canAddAllocation} variant='text' onClick={() => onAddAllocation(partition!)}>
              Add Allocation
            </NewButton>
          </$AddAllocation>
        </Grid>
      },
      )}
      <Grid item xs={12}>
        <NewButton creator variant='text' onClick={() => {
          if(canAddPartition) onAddPartition()
        }}>
          Add Partition
        </NewButton>
      </Grid>
    </$PartitionsContainer>
    <Grid container>
      <$Button onClick={() => onSave()}>
        Save Changes
      </$Button>
    </Grid>
  </>
}
