import * as React from 'react';
import { $ActivityDates, $Date, $Deadline } from './styles';
export const ActivityDates = ({ assign = '', accept = '', submit = '', deadline = '' }) => {
    const validSubmission = submit ? (new Date(submit) > new Date('2000-01-01')) : false;
    const validDeadline = deadline ? (new Date(deadline) > new Date('2000-01-01')) : false;
    if (!assign && !accept && !validSubmission && !validDeadline)
        return null;
    return (React.createElement($ActivityDates, { container: true, justifyContent: 'center', alignItems: 'center' },
        assign && React.createElement($Date, null,
            "Assigned at: ",
            `${new Date(assign).toLocaleDateString()}`),
        accept && React.createElement($Date, null,
            "Accepted at: ",
            `${new Date(accept).toLocaleDateString()}`),
        submit && validSubmission && React.createElement($Date, null,
            "Submitted at: ",
            `${new Date(submit).toLocaleDateString()}`),
        deadline && validDeadline && React.createElement($Deadline, null, "Deadline"),
        deadline && validDeadline && React.createElement($Date, null, deadline ? `${new Date(deadline).toLocaleDateString()} ${new Date(deadline).toLocaleTimeString(undefined, { timeZoneName: 'short' })}` : '')));
};
