import styled from 'styled-components';
export const $Snackbar = styled.div `
  display: flex;
  position: relative;
  margin: ${({ theme }) => theme.spacing(1)};
  animation: fadeOut 750ms;
  animation-fill-mode: forwards;
  animation-delay: ${({ timer }) => `${timer}ms`};
  @keyframes fadeOut {
    from {opacity: 100%;}
    to {opacity: 0%;}
  }
`;
export const $TopBanner = styled.div `
  width: 100%;
  height: ${({ top }) => top ? '40px' : '30px'};
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  padding: 0 ${({ theme }) => theme.spacing(2)};
  align-items: center;
  justify-content: center;
  color: white;
  position: fixed;
  top: ${({ top }) => top ? '0' : 'auto'};
  bottom: ${({ top }) => top ? 'auto' : '0'};
  z-index: 100000;
  font-size: 12px;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 10px;
    height: ${({ top }) => top ? '45px' : '20px'};
  }
`;
export const $Notification = styled.div `
  display: flex;
  position: relative;
  flex-direction: row;
  min-width: 340px;
  justify-content: space-between;
  ${({ theme }) => theme.elevations[2]};
  padding: ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  background-color: ${({ theme, type }) => theme.colors.feedback[type]};
  color: white;
`;
export const $NotificationIcon = styled.div `
  display: flex;
  align-items: center;
  font-size: 24px;
  margin: 0 10px;
`;
export const $NotificationText = styled.div `
  width: 100%;
  margin: 0;
  padding: ${({ theme }) => `${theme.spacing(2)} 0`};
  background-color: ${({ theme, type }) => type && theme.colors.feedback[type]};
  color: white;
`;
export const $ProgressBar = styled.div `

  @keyframes progress {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }

  background: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ theme }) => theme.spacing(1)};
  opacity: 0.6;
  transform-origin: left;
  border-radius: ${({ theme }) => `0 0 ${theme.dimensions.borderRadius} ${theme.dimensions.borderRadius}`};

  animation: progress linear 1 forwards;
  animation-duration: ${({ duration }) => `${duration}ms`};
  animation-play-state: running;
`;
