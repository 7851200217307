import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { $InputLabel, NewButton } from 'prace-common-components'

export const $PartitionsContainer = styled(Grid)`
  &.MuiGrid-container {
    padding-top: ${({ theme }) => theme.spacing(1)};
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
`

export const $Label = styled($InputLabel)`
  &.MuiFormLabel-root {
    font-size: 1em;
  }
`

export const $AllocationsGrid = styled(Grid)``

export const $AllocationsCutOffs = styled(Grid)`
  padding-left: ${({ theme }) => theme.spacing(4)};
  &.MuiGrid-item {
    padding-right: ${({ theme }) => theme.spacing(1)};
  }
`

export const $CutOffLabels = styled.div`
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  padding-left: ${({ theme }) => theme.spacing(4)};
`

export const $AllocationsGridItem = styled(Grid)`
  &.MuiGrid-item {
    padding-right: ${({ theme }) => theme.spacing(1)};
  }
  .MuiFormControlLabel-root {
    margin: 0 auto;
  }
`

export const $AddAllocation = styled(Grid)`
  &.MuiGrid-item {
    padding: 0;
    margin-left: ${({ theme }) => theme.spacing(4)};
  }
`

//TODO: See if some of these styles can be merged with others (from EditCallModal)
export const $Button = styled(NewButton)`
  &.MuiButtonBase-root {
    margin-left: auto;
  }
`
