import { validateFlowElement, FORM_ELEMENT_TYPES } from '../';
const isPreviousOrCurrentForm = (formIdx, form) => formIdx <= form;
export const validateAssignment = (callState, step, form, resources) => {
    const newCallState = JSON.parse(JSON.stringify(callState));
    const flowStep = newCallState.flow[step];
    let anyError = false;
    let currentFormError = false;
    flowStep.flow.forEach((flowForm, i) => {
        let formError = false;
        const currentForm = i === form;
        if (isPreviousOrCurrentForm(i, form)) {
            flowForm.flow.forEach((element, j) => {
                if (element.elementType === FORM_ELEMENT_TYPES.GROUP) {
                    const { error, errorMsg, condition } = validateFlowElement(newCallState, element, flowStep.id, resources, element.instances?.length);
                    if (!(condition.visible && condition.editable))
                        return;
                    if (error) {
                        formError = true;
                        if (!anyError)
                            anyError = element?.id || true;
                        if (currentForm && !currentFormError)
                            currentFormError = element?.id || true;
                    }
                    flowStep.flow[i].flow[j].error = error;
                    flowStep.flow[i].flow[j].errorMsg = errorMsg;
                    element.instances?.forEach((instance, k) => {
                        instance.childInstances?.forEach((childInst, l) => {
                            const groupElement = element.flow.find((el) => el.id == childInst.flowElementId);
                            const { error, errorMsg } = validateFlowElement(newCallState, {
                                ...groupElement,
                                value: childInst?.value,
                            }, flowStep.id, resources, 0, instance, childInst?.value);
                            flowStep.flow[i].flow[j]
                                .instances[k].childInstances[l].error = error;
                            flowStep.flow[i].flow[j]
                                .instances[k].childInstances[l].errorMsg = errorMsg;
                            if (error) {
                                formError = true;
                                if (!anyError)
                                    anyError = groupElement?.id ? `${groupElement?.id}-${k}` : true;
                                if (currentForm && !currentFormError)
                                    currentFormError = groupElement?.id ? `${groupElement?.id}-${k}` : true;
                            }
                        });
                    });
                    return;
                }
                const { error, errorMsg } = validateFlowElement(newCallState, element, flowStep.id, resources);
                if (error) {
                    formError = true;
                    if (!anyError)
                        anyError = element?.id || true;
                    if (currentForm && !currentFormError)
                        currentFormError = element?.id || true;
                }
                flowStep.flow[i].flow[j].error = error;
                flowStep.flow[i].flow[j].errorMsg = errorMsg;
            });
            flowStep.flow[i].error = formError;
        }
    });
    flowStep.error = anyError;
    return { callState: newCallState, anyError, currentFormError };
};
