import * as React from 'react'
import { EditElementProps } from './types'
import { AddElement } from './AddElement'
import { EditStep } from './EditStep'
import { EditForm } from './EditForm'
import { Drawer, Breadcrumbs, NewTabs, ELEMENT_TYPES } from 'prace-common-components'
import { $GridDrawer } from '../styles'
import { $Container } from './styles'
import { EditFormElement } from './EditFormElement'
import { Actions } from './Actions'

export const EditElement: React.FC<EditElementProps> = ({
  selectedStep,
  selectedForm,
  selectedElement,
  selectedElements,
  selectedType,
  cutoffs,
  tab,
  open,
  onChangeTab,
  onChangeDrawer,
  onEndAddDrag,
  handleCrumbClick,
}) => {
  const stepSelected = selectedType === ELEMENT_TYPES.STEP
  const elementSelected = selectedType === ELEMENT_TYPES.ELEMENT

  const crumbs = [{
    id: 1,
    text: selectedStep?.title || '',
    handleClick: handleCrumbClick,
  }]

  if(!stepSelected)
    crumbs.push({
      id: 2,
      text: selectedForm?.title || '',
      handleClick: handleCrumbClick,
    })

  if(elementSelected)
    crumbs.push({
      id: 3,
      text: selectedElement?.title || '',
      handleClick: () => null,
    })

  const tabs = [
    {
      id: 0,
      label: stepSelected ? 'Step Settings' : 'Form Settings',
    },
    {
      id: 1,
      label: elementSelected ? 'Element Settings' : 'Elements',
    },
  ]

  if(stepSelected)
    tabs.push({id: 2, label: 'Actions'})

  const handleAddElements = () => handleCrumbClick(2) // Go to Form

  const handleTab = (newTab: number) => {
    if(tab === 1 && newTab === 1 && elementSelected) handleAddElements()
    onChangeTab(newTab)
  }

  const content = () => {
    switch(tab) {
      case 1:
        return elementSelected && selectedElement ?
          <EditFormElement
            element={selectedElement}
            selectedElements={selectedElements}
            selectAddElements={handleAddElements}
          /> :
          <AddElement onEndDrag={onEndAddDrag}/>
      case 2:
        return <Actions step={selectedStep} />
      default:
        return stepSelected ? <EditStep callCutoffs={cutoffs} step={selectedStep} /> : <EditForm form={selectedForm} />
    }
  }

  return (
    <$GridDrawer item xs='auto'>
      <Drawer open={open} noHorizPadding noTopPadding onChange={onChangeDrawer}>
        <$Container>
          <Breadcrumbs crumbs={crumbs} />
        </$Container>
        <NewTabs tabs={tabs} value={tab} onChange={handleTab} />
        <$Container>
          {content()}
        </$Container>
      </Drawer>
    </$GridDrawer>
  )
}
