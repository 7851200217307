import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

export const $AddedElementsContainer = styled.div`
  max-height: 70px;
  overflow-y: auto;
`

export const $ElementItem = styled.div`
  cursor: pointer;
`

export const $ActionsContainer = styled(Grid)`
  &>button {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`

export const $Summary = styled.div`
  position: absolute;
  bottom: 0;
  line-height: 30px;
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.colors.cards.background};
`

export const $TitleSpan = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`

export const $ElementSpan = styled.span`
  font-weight: bold;
  padding: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.cards.backgroundDark};
`
