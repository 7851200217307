import * as React from 'react'
import update from 'immutability-helper'
import { FlowFormElement, OnMove, ELEMENT_TYPES, FORM_ELEMENT_TYPES, NewButton, EditTable, Input, RowElement, NotificationType } from 'prace-common-components'
import { $ModalContainer, $ButtonContainer } from '../styles'
import { EditGroupElementProps } from './types'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getElementUsage } from 'util/getElementUsage'
import { RootState } from 'store'

const defaultElement = {
  type: ELEMENT_TYPES.ELEMENT,
  elementType: FORM_ELEMENT_TYPES.TEXT,
  description: '',
  required: false,
  title: 'Label',
  permissions: {
    inherit: false,
    export: [],
    visibility: [],
  },
  value: '',
  flow: [],
  effects: [],
  automations: [],
}

export const EditGroupElement: React.FC<EditGroupElementProps> = (
  { call, element, saveHandler, cancelHandler, loading },
) => {
  const dispatch = useAppDispatch()
  const storeTemplates = useAppSelector((state: RootState) => state.calls.templates)
  const [groupName, setGroupName] = React.useState<string>(element.title)
  const [elements, setElements] = React.useState<FlowFormElement[]>(element.flow)

  const rows: RowElement[] = elements as RowElement[]

  // TODO: Add debounce
  const onMove = ({
    dragIndex,
    hoverIndex,
  }: OnMove) => {
    setElements((prevElements) => {
      const newItems =  update(prevElements, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevElements[dragIndex]],
        ]}).map((el: FlowFormElement, i: number) => ({ ...el, order: i }))
      return newItems
    },
    )
  }

  const onDeleteElement = (ids: string[]) => {
    if(ids.some((elementId) => {
      const {
        automations,
        conditions,
        templates,
      } = getElementUsage(Number(elementId), call, storeTemplates || [])
      return automations.length || conditions.length || templates.length
    })) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'There are group elements with associated templates, conditions or automations' } })
    } else {
      setElements((prevElements) => prevElements.filter((el) => !ids.includes(String(el.id))))
    }
  }

  const onAddElement = () => {
    setElements((prevElements) => update(prevElements, {
      $push: [
        {...defaultElement, parentId: element.id,id: prevElements.length,order: prevElements.length},
      ],
    }))
  }

  const onChangeElement = React.useCallback((name: string, value: Nullable<StringNumber>, rowId: string | number) => {
    setElements((prevElements) => {
      const index = prevElements.findIndex((element) => element.id == rowId)
      return update(prevElements, {
        [index]: {
          [name]: { $set: value as string | FORM_ELEMENT_TYPES },
        }})})
  }, [])

  const onSave = () => {
    if(!elements.length || !groupName) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Groups must have elements and a name' } })
      return
    }
    saveHandler(elements, groupName)
  }

  const groupNameHandler = (_name: string, value: Nullable<StringNumber>) => {
    setGroupName(value as string)
  }

  return (
    <$ModalContainer width={800}>
      <Input title='Group name' value={groupName} onChange={groupNameHandler} />
      <EditTable
        rows={rows}
        onMove={onMove}
        onAddRow={onAddElement}
        onRemoveRow={onDeleteElement}
        onChangeRow={onChangeElement}
      />
      <$ButtonContainer container justifyContent='space-between'>
        <NewButton variant='contained' loading={loading} onClick={onSave}>
          Save Changes
        </NewButton>
        <NewButton variant='outlined' error disabled={loading} onClick={cancelHandler}>
          Cancel
        </NewButton>
      </$ButtonContainer>
    </$ModalContainer>
  )
}
