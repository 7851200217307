import styled from 'styled-components';
import { Grid } from '@material-ui/core';
export const $Grid = styled(Grid) `
  &.MuiGrid-root {
    margin: 0;
    width: 100%;
  }
  &.MuiGrid-root > .MuiGrid-item {
    padding-left: 0;
    padding-right: 0;
  }
`;
