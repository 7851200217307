import styled from 'styled-components'
import { NewButton } from 'prace-common-components'
import Grid from '@material-ui/core/Grid'

//TODO: See if some of these styles can be merged with others (from EditCallModal)
export const $Button = styled(NewButton)`
  &.MuiButtonBase-root {
    margin-left: auto;
  }
`

export const $CutOffContainer = styled(Grid)`
  gap: ${({ theme }) => theme.spacing(2)};
  &.MuiGrid-container {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
`

export const $ButtonTimeframes = styled(NewButton)`
  &.MuiButtonBase-root {
    margin-top: ${({ theme }) => theme.spacing(2)};
    margin-left: auto;
  }
`

export const $TimeframeContainer = styled(Grid)`
  gap: ${({ theme }) => theme.spacing(2)};
  &.MuiGrid-container {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: ${({ theme }) => theme.spacing(1)};
  }
`

export const $Propagate = styled.div`
 margin-top: ${({ theme }) => theme.spacing(2)};
`
