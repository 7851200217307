import React, { useState, useEffect } from 'react';
import { $Search, $InputBase } from './styles';
import { SvgIcon } from '../SvgIcon';
import { useDebounce } from '../';
export const Search = ({ onChange, placeholder, open }) => {
    const [expand, setExpand] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearch = useDebounce(searchValue, 300);
    useEffect(() => {
        onChange(debouncedSearch);
    }, [debouncedSearch, onChange]);
    const handleChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        setExpand(!!value);
    };
    return (React.createElement($Search, null,
        React.createElement($InputBase, { open: open !== undefined ? open : expand, autoComplete: 'new-password', placeholder: placeholder, onChange: handleChange, startAdornment: React.createElement(SvgIcon, { name: 'search', size: 20, color: '#757778' }), inputProps: { 'aria-label': 'search', 'aria-autocomplete': 'none' } })));
};
