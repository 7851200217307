import { applicationCutOff } from '../';
export const stepTimeFrame = (cutoffs, cutoffId, flowStep) => {
    if (!cutoffId || !cutoffs.length || !flowStep?.timeframes.length)
        return { timeframe: undefined, validTimeframe: false };
    const timeframe = flowStep.timeframes.find((timeframe) => timeframe.cutoffId == cutoffId);
    if (!timeframe)
        return { timeframe: undefined, validTimeframe: false };
    let validTimeframe = false;
    const today = new Date();
    const timeframeStart = new Date(timeframe.start);
    if (timeframe.end) {
        const timeframeEnd = new Date(timeframe.end);
        validTimeframe = (timeframeStart.getTime() <= today.getTime() && timeframeEnd.getTime() >= today.getTime());
    }
    else {
        const currentAndNextCutoffs = applicationCutOff(cutoffs, cutoffId);
        const nextCutoff = currentAndNextCutoffs.length ? currentAndNextCutoffs[1] : undefined;
        if (nextCutoff?.start) {
            const cutoffEnd = new Date(nextCutoff.start);
            validTimeframe = (timeframeStart.getTime() <= today.getTime() && cutoffEnd.getTime() >= today.getTime());
        }
        else {
            validTimeframe = (timeframeStart.getTime() <= today.getTime());
        }
    }
    return { timeframe, validTimeframe };
};
