import React, { MouseEvent, PropsWithChildren } from 'react'
import { useSlate } from 'slate-react'
import { isLinkActive } from '../plugins/withInlines'
import { $ToggleButton } from './styles'

export const InlineButton: React.FC<PropsWithChildren<{
  onMouseDown: (e: MouseEvent<HTMLButtonElement>, isActive: boolean) => void,
}>> = ({ children, onMouseDown }) => {
  const editor = useSlate()
  const isActive = isLinkActive(editor)
  return (
    <$ToggleButton
      selected={isActive}
      onMouseDown={(e) => onMouseDown(e, isActive)}
    >
      {children}
    </$ToggleButton>
  )
}
