import * as React from 'react'
import { capitalizeFirstChar, FILTER_TYPE, NewDatePicker, FORM_ELEMENT_TYPES, Input, FlowFormElement, NewButton, GroupTable, NotificationType } from 'prace-common-components'
import { $Container, $ModalContainer, $Grid, $Paragraph } from './styles'
import { RequestChangesProps } from './types'
import Grid from '@material-ui/core/Grid'
import { useAppDispatch } from 'store/hooks'

const dumbComponents = [
  FORM_ELEMENT_TYPES.TITLE,
  FORM_ELEMENT_TYPES.INSTRUCTIONS,
  FORM_ELEMENT_TYPES.SPACE,
  FORM_ELEMENT_TYPES.DIVIDER,
]

const columns = [
  {
    key: 'form',
    type: FILTER_TYPE.NONE,
    name: 'Form',
    noSort: true,
    width: 170,
  },
  {
    key: 'name',
    type: FILTER_TYPE.NONE,
    name: 'Element',
    noSort: true,
  },
  {
    key: 'type',
    type: FILTER_TYPE.NONE,
    name: 'Type',
    noSort: true,
    width: 85,
  },
]

export const RequestChanges: React.FC<RequestChangesProps> = (
  { call, selectedStep = 0, handleRequestChanges, handleCancel },
) => {
  const dispatch = useAppDispatch()
  const [data, setData] = React.useState<{deadline: Nullable<string>; reason: Nullable<string>}>(
    { deadline: null, reason: null},
  )
  const [selectedRows, setSelectedRows] = React.useState<Set<number>>(new Set())
  const [expandedGroupIds, setExpandedGroupIds] = React.useState<ReadonlySet<unknown>>(new Set())

  const rows = React.useMemo(() => {
    const step = call.flow?.length ? call.flow[selectedStep] : {flow: []}
    const allElements: (FlowFormElement & {form: string})[] = []
    step.flow?.forEach((form) => {
      allElements.push(...((form?.flow || []).map((el) => ({ ...el, form:
        form.title.length > 20 ? form.title.slice(0, 17) + '...' : form.title,
      })) || []))
    })

    return allElements.filter(
      (element) => !dumbComponents.includes(element.elementType as FORM_ELEMENT_TYPES))
      .map((el) => ({
        id: el.id,
        form: el.form,
        formId: el.parentId,
        name: el.title.length > 44 ? el.title.slice(0, 41) + '...' : el.title,
        type: capitalizeFirstChar(el.elementType.length > 12 ? el.elementType.slice(0, 9) : el.elementType),
      })) || []
  }, [call.flow, selectedStep])

  const onChange = (name: string, value: Nullable<StringNumber>) => setData((data) => ({...data, [name]: value}))

  const handleSelectRows = (rowsSelected: Set<number>) => {
    setSelectedRows(rowsSelected)
  }

  const handleRequest = () => {
    const elementRows = [...selectedRows]
    if(!elementRows.length) {
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Select at least one element' } })
      return
    }
    if(!data.deadline || !data.reason) dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Fill all required data' } })
    if(elementRows.length && data.deadline && data.reason) {
      handleRequestChanges(elementRows, data.deadline, data.reason)
    }
  }

  const today = new Date()
  today.setDate(today.getDate() + 1)

  return (
    <$ModalContainer>
      <>
        <$Grid container>
          <Grid item xs={12}>
            <$Paragraph>Select the elements you want to request changes for:</$Paragraph>
            <GroupTable
              showEmptyImg={false}
              rowsPerPage={8}
              noFilters
              rows={rows}
              columns={columns}
              selectedRows={selectedRows}
              groupBy={['form']}
              setSelectedRows={handleSelectRows}
              expandedGroupIds={expandedGroupIds}
              onExpandedGroupIdsChange={(expanded) => setExpandedGroupIds(expanded)}
            />
          </Grid>
          <Grid item xs={12}>
            <Input onChange={onChange} title='Add a Reason' name='reason' required multiline minRows={3} type='text' hideItalic/>
          </Grid>
          <Grid item xs={12}>
            <NewDatePicker withTime min={today} title='Deadline' required value={data.deadline} name='deadline' onChange={onChange} />
          </Grid>
        </$Grid>
        <$Container container justifyContent='space-between'>
          <Grid item xs={3}>
            <NewButton variant='outlined' onClick={handleCancel}>Cancel</NewButton>
          </Grid>
          <Grid item xs container justifyContent='flex-end'>
            <NewButton onClick={handleRequest}>Request Changes</NewButton>
          </Grid>
        </$Container>
      </>
    </$ModalContainer>
  )
}
