import styled from 'styled-components';
import { SvgIcon } from '../SvgIcon';
export const $Modal = styled.div `
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: max-content;
  height: max-content;
  background-color: ${({ theme }) => theme.colors.greyScales[20]};
  box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.4);
  border-radius: 6px;
  z-index: 100;
`;
export const $Close = styled(SvgIcon).attrs(() => ({ name: 'close', size: 26 })) `
  position: absolute;
  right: 12px;
  top: 12px;
  color: ${({ theme }) => theme.colors.greyScales[500]};
    :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.greyScales[900]};
  }
`;
