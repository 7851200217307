export const getSearchQuery = (query, fields) => {
    let searchByQuery = {};
    Object.keys(fields).forEach((key) => {
        const column = fields[key];
        const value = query.get(column) || '';
        if (value)
            searchByQuery = { ...searchByQuery, [column]: value };
    });
    return searchByQuery;
};
