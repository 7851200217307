export function downloadFile(fileName, data, uid, elementName) {
    const downloadLink = document.createElement('a');
    let newFilename = fileName;
    const auxNames = fileName.split('_');
    if (uid && elementName && auxNames.length > 2) {
        const piLastName = auxNames[1];
        newFilename = `${uid.trim()}_${piLastName.trim()}_${elementName.trim()}.pdf`;
    }
    downloadLink.download = newFilename;
    const url = URL.createObjectURL(data);
    downloadLink.href = url;
    downloadLink.click();
    URL.revokeObjectURL(url);
}
