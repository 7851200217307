import React, { useEffect } from 'react'
import update from 'immutability-helper'
import { Input, NewButton, SvgIcon, ConfirmAction, alphabeticalSort, Sortable, OnMove, Option, newTheme } from 'prace-common-components'
import { $ModalContainer, $Form, $Grid, $Notice, $ActionButtons, $OptionContainer, $OptionRow, $Label, $LabelContainer } from './styles'
import { LockedTag, lockedTags, MutableOption, OptionsGroupProps } from './types'
import Grid from '@material-ui/core/Grid'

const createEmptyOption = (params: Partial<Option>): Option => ({
  value: '',
  label: '',
  id: 0,
  order: 1,
  ...params,
})

const isLockedTag = (tag: unknown): tag is LockedTag => {
  return lockedTags.includes(tag as LockedTag)
}

const transformOptionsGroup = (optionsGroup: OptionsGroupProps['optionGroup']): MutableOption<Option>[] => {
  return optionsGroup?.options.map((option, index) => ({
    id: option.id || index,
    option: option,
    state: 'unchanged',
  })) || [
    {
      id: 1,
      option: createEmptyOption({
        order: 1,
      }),
      state: 'added',
    },
  ]
}

const addMutableOption = (mutableOptions: MutableOption<Option>[]): MutableOption<Option>[] => {
  return [
    ...mutableOptions,
    {
      id: mutableOptions.length + 1,
      option: createEmptyOption({
        order: mutableOptions.length,
      }),
      state: 'added',
    },
  ]
}

export const OptionsGroup: React.FC<OptionsGroupProps> = ({ isWorking, optionGroup, onSubmit, onDelete }) => {
  const [mutableOptions, setMutableOptions] = React.useState<MutableOption<Option>[]>([])

  useEffect(() => {
    if (isWorking) return
    
    setMutableOptions(
      transformOptionsGroup(optionGroup),
    )
  }, [optionGroup, isWorking])
  

  const addOption = () => {
    setMutableOptions((mutableOptions) => addMutableOption(mutableOptions))
  }

  const deleteOption = (id: number) => {
    if (mutableOptions.length < 2) return
    setMutableOptions((mutableOptions) => mutableOptions.filter((mutableOption) => mutableOption.id !== id))
  }

  const deleteOptionGroup = () => {
    if(optionGroup && !!onDelete) {
      onDelete(optionGroup)
    }
  }

  const submitHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    const target = e.target as typeof e.target & {
      [key: string]: { value: string },
    }
    
    if(!target.tag.value) return

    const tag = optionGroup?.tag
    
    /* TODO: This is hardcoded for now to prevent breaking existing groups, the user cannot change these tags */
    if(isLockedTag(tag) && target.tag.value != tag) {
      return
    }
    /* Remove options that the user left blank */
    const typedMutableOptions = mutableOptions.filter((mutableOption) => target[`opt_${mutableOption.id}`].value !== '')
    
    /* Prevent saving empty option groups */
    if(!typedMutableOptions.length) {
      return
    }
    
    const optionsGroup = {
      id: optionGroup ? optionGroup.id : 0,
      tag: target.tag.value,
      options: typedMutableOptions.map((mutableOption, order) => {
        return {
          value: target[`opt_${mutableOption.id}`].value,
          label: target[`opt_${mutableOption.id}`].value,
          order: order,
          id: mutableOption.state === 'added' ? undefined : mutableOption.option.id,
        }
      }),
    }
    
    onSubmit(optionsGroup)
  }

  const handleChange = (id: number, value: string) => {
    setMutableOptions((mutableOptions) => mutableOptions.map((mutableOption) => {
      if (mutableOption.id === id) {
        return {
          ...mutableOption,
          option: {
            ...mutableOption.option,
            value,
          },
          state: mutableOption.state === 'unchanged' ? 'changed' : mutableOption.state,
        }
      }
      return mutableOption
    }))
  }

  const onMove = ({
    dragIndex,
    hoverIndex,
  }: OnMove) => {
    setMutableOptions((options) => [
      ...update(options, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, options[dragIndex]],
        ],
      }),
    ])
  }

  const handleSort = () => {
    setMutableOptions((mutableOptions) => {
      const options = mutableOptions.map((mutableOption) => ({
        ...mutableOption.option,
        mutableId: mutableOption.id,
        mutableState: mutableOption.state,
      }))
      
      const sortedOptions = alphabeticalSort(options, 'value')
      return sortedOptions.map((option, index) => ({
        id: option.mutableId,
        option: {
          value: option.value,
          label: option.label,
          id: option.id,
          order: index,
        },
        state: option.mutableState === 'unchanged' ? 'changed' : option.mutableState,
      }))
    })
  }

  return (
    <$ModalContainer>
      <$Form onSubmit={submitHandle}>
        <$Grid container spacing={2}>
          {optionGroup !== undefined &&
            <Grid item xs={11}>
              <$Notice>
                WARNING <br/>
                Any changes made to this options group are applied to every element using it.
              </$Notice>
            </Grid>
          }
          <Grid item xs={11}>
            <Input hideItalic title='Options Group Name' name='tag' defaultValue={optionGroup?.tag} />
          </Grid>
          <$LabelContainer item xs={12} container alignItems='center'>
            <Grid item xs={10}>
              <$Label shrink>Available options</$Label>
            </Grid>
            <ConfirmAction title='Alphabetical Sort' description='This action will change the position of every option.' onConfirm={handleSort}>
              <SvgIcon name='sort' size={24} color='#757778' clickable />
            </ConfirmAction>
          </$LabelContainer>
          {isWorking && (
            <Grid item xs={10}>
              <$Label shrink>Loading options...</$Label>
            </Grid>
          )}
          {mutableOptions.map((mutableOption, order: number) => <$OptionRow key={mutableOption.id}>
            <Sortable
              item={{ id: mutableOption.id, type: 'option', order }}
              type={'option'}
              dropType={['option']}
              onMove={onMove}
            >
              <$OptionContainer container item alignItems='flex-end' xs={12}>
                <Grid item xs={11}>
                  <Input hideItalic onChange={(_name, value) => handleChange(mutableOption.id, value as string)} defaultValue={mutableOption.option.value} title='' name={'opt_' + mutableOption.id} />
                </Grid>
                {mutableOptions.length > 1 &&
              <SvgIcon name='delete' size={26} color={newTheme.colors.text.grey} clickable onClick={() => deleteOption(mutableOption.id)}/>
                }
              </$OptionContainer>
            </Sortable>
          </$OptionRow>,
          )}
          <Grid item xs={12}>
            <NewButton variant='outlined' disabled={isWorking} onClick={() => addOption()}>
              Add Option
            </NewButton>
          </Grid>
        </$Grid>
        <$ActionButtons container justifyContent='flex-end'>
          {optionGroup && !!onDelete ?
            <ConfirmAction deletion title='Delete options group' description='Deleting this options group will affect every select that uses it.' onConfirm={deleteOptionGroup}>
              <NewButton error onClick={deleteOptionGroup} disabled={isWorking} >
              Delete Options Group
              </NewButton>
            </ConfirmAction>
            : null
          }
          <NewButton type='submit' disabled={isWorking}>
            Save
          </NewButton>
        </$ActionButtons>
      </$Form>
    </$ModalContainer>
  )
}
