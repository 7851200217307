import { FORM_ELEMENT_TYPES } from '../newFormElement/types';
export const NOTIFICATION_TIME = 3000;
export var ROLES;
(function (ROLES) {
    ROLES["USER"] = "user";
    ROLES["ADMIN"] = "admin";
    ROLES["SUPER_ADMIN"] = "super_admin";
    ROLES["EUROHPC_ADMIN"] = "eurohpc_admin";
    ROLES["PRO"] = "pro";
    ROLES["APPLICANT"] = "applicant";
    ROLES["AC_MEMBER"] = "ac_member";
    ROLES["AC_CHAIR"] = "ac_chair";
    ROLES["PANEL_CHAIR"] = "panel_chair";
    ROLES["RAPPORTEUR"] = "rapporteur";
    ROLES["SCIENTIFIC_REVIEWER"] = "scientific_reviewer";
    ROLES["TECHNICAL_REVIEWER"] = "technical_reviewer";
    ROLES["COMPUTE_CENTRE_REPRESENTATIVE"] = "compute_centre_representative";
    ROLES["PROJECT_MANAGER"] = "project_manager";
    ROLES["FINANCE_MANAGER"] = "finance_manager";
})(ROLES || (ROLES = {}));
export var ROLE_NAME;
(function (ROLE_NAME) {
    ROLE_NAME["user"] = "User";
    ROLE_NAME["admin"] = "Admin";
    ROLE_NAME["super_admin"] = "Super Admin";
    ROLE_NAME["eurohpc_admin"] = "EuroHPC Admin";
    ROLE_NAME["pro"] = "Peer Review Officer";
    ROLE_NAME["applicant"] = "Applicant";
    ROLE_NAME["ac_member"] = "AC Member";
    ROLE_NAME["ac_chair"] = "AC Chair";
    ROLE_NAME["panel_chair"] = "Panel Chair";
    ROLE_NAME["rapporteur"] = "Rapporteur";
    ROLE_NAME["scientific_reviewer"] = "Scientific reviewer";
    ROLE_NAME["technical_reviewer"] = "Technical reviewer";
    ROLE_NAME["compute_centre_representative"] = "Computing center representative";
    ROLE_NAME["project_manager"] = "Project Manager";
    ROLE_NAME["finance_manager"] = "Finance Manager";
})(ROLE_NAME || (ROLE_NAME = {}));
export var ROLE_NAME_INITIALS;
(function (ROLE_NAME_INITIALS) {
    ROLE_NAME_INITIALS["user"] = "User";
    ROLE_NAME_INITIALS["admin"] = "Admin";
    ROLE_NAME_INITIALS["super_admin"] = "Super Admin";
    ROLE_NAME_INITIALS["pro"] = "PRO";
    ROLE_NAME_INITIALS["eurohpc_admin"] = "EuroHPC PRO";
    ROLE_NAME_INITIALS["applicant"] = "Applicant";
    ROLE_NAME_INITIALS["ac_member"] = "ACM";
    ROLE_NAME_INITIALS["ac_chair"] = "ACC";
    ROLE_NAME_INITIALS["panel_chair"] = "PC";
    ROLE_NAME_INITIALS["rapporteur"] = "Rapporteur";
    ROLE_NAME_INITIALS["scientific_reviewer"] = "SR";
    ROLE_NAME_INITIALS["technical_reviewer"] = "TR";
    ROLE_NAME_INITIALS["compute_centre_representative"] = "CCR";
    ROLE_NAME_INITIALS["project_manager"] = "PM";
    ROLE_NAME_INITIALS["finance_manager"] = "FM";
})(ROLE_NAME_INITIALS || (ROLE_NAME_INITIALS = {}));
export const ADMIN_PORTAL_ROLES = [
    ROLES.ADMIN,
    ROLES.EUROHPC_ADMIN,
    ROLES.PRO,
];
export const USER_PORTAL_ROLES = Object.values(ROLES).filter((role) => !ADMIN_PORTAL_ROLES.includes(role));
export var ROLE_ORIGIN;
(function (ROLE_ORIGIN) {
    ROLE_ORIGIN["ASSIGNMENT"] = "assignment";
    ROLE_ORIGIN["MANUAL"] = "manual";
})(ROLE_ORIGIN || (ROLE_ORIGIN = {}));
export const APPLICATION_DRAFT_TEXT = 'DRAFT';
export const selectTypes = [FORM_ELEMENT_TYPES.SELECT, FORM_ELEMENT_TYPES.MULTISELECT, FORM_ELEMENT_TYPES.TOGGLE];
export const BIGINT_MAX = 9223372036854775807;
export const ADMIN_ID = -1;
export var USER_INFO;
(function (USER_INFO) {
    USER_INFO["Title"] = "title";
    USER_INFO["FirstName"] = "firstName";
    USER_INFO["LastName"] = "lastName";
    USER_INFO["Email"] = "email";
    USER_INFO["Gender"] = "gender";
    USER_INFO["DateOfBirth"] = "dateOfBirth";
    USER_INFO["Fields"] = "fields";
    USER_INFO["Nationality"] = "nationality";
    USER_INFO["PhoneNumber"] = "phoneNumber";
    USER_INFO["Website"] = "website";
    USER_INFO["ResearchGate"] = "researchgate";
    USER_INFO["Scholar"] = "scholar";
    USER_INFO["Scopus"] = "scopus";
})(USER_INFO || (USER_INFO = {}));
export const USER_INFO_ITEMS = [
    { value: USER_INFO.Title, label: 'Title' },
    { value: USER_INFO.FirstName, label: 'First name' },
    { value: USER_INFO.LastName, label: 'Last name' },
    { value: USER_INFO.Email, label: 'Email' },
    { value: USER_INFO.Gender, label: 'Gender' },
    { value: USER_INFO.DateOfBirth, label: 'Date of birth' },
    { value: USER_INFO.Fields, label: 'Fields' },
    { value: USER_INFO.Nationality, label: 'Nationality' },
    { value: USER_INFO.PhoneNumber, label: 'Phone number' },
    { value: USER_INFO.Website, label: 'Website' },
    { value: USER_INFO.ResearchGate, label: 'ResearchGate' },
    { value: USER_INFO.Scholar, label: 'Scholar' },
    { value: USER_INFO.Scopus, label: 'Scopus' },
];
