import React, { FC, PropsWithChildren } from 'react'
import {
  $Container,
  $Details,
  $Background,
} from './styles'

export const Banner: FC<PropsWithChildren<{small?: boolean; isMobile?: boolean}>> = ({ small, children, isMobile }) => {
  return <$Background small={small}>
    <$Container small={small}>
      <$Details isMobile={isMobile}>
        {children}
      </$Details>
    </$Container>
  </$Background>
}
