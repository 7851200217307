import styled from 'styled-components'
import { NewButton, $InputLabel } from 'prace-common-components'
import Grid from '@material-ui/core/Grid'

//TODO: Repeated code:
export const $ModalContainer = styled.div`
  min-width: 800px;
  min-height: 300px;
`

export const $Button = styled(NewButton)`
  &.MuiButtonBase-root {
    margin-left: auto;
  }
`

export const $CallIdLabel = styled($InputLabel)`
  &.MuiFormLabel-root {
    font-size: 1.2em;
  }
`

export const $CallId = styled.div`
  margin-left: 4px;
`

export const $Grid = styled(Grid)`
  gap: ${({ theme }) => theme.spacing(2)};
`

export const $GridContainer = styled($Grid)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`

export const $InputFileHidden = styled.input`
  position: absolute;
  display: hidden;
  pointer-events: none;
  opacity: 0;
  height: 0;
  width: 0;
`

export const $LogoContainer = styled.div`
  position: relative;
`

export const $DeleteWrapper = styled.div`
  display: block;
  position: absolute;
  top: 4px;
  right: 8px;

  &>div, svg {
    padding: 0;
    margin: 0;
  }
`

export const $NotificationClone = styled.div`
 margin: ${({ theme }) => `${theme.spacing(2)} 0`};
`
