import styled from 'styled-components';
import { Chip } from '@material-ui/core';
import { $FormElementControl } from '../newFormElement/styles';
export const $FormControl = styled($FormElementControl).attrs(() => ({ variant: 'outlined' })) `
  min-width: 120px;
  input {
    max-width: 20%;
  }
`;
export const $Chips = styled.div `
  display:flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 2px;
`;
export const $ChipContainer = styled.div `
  display: flex;
  overflow: auto;
  max-width: 500px;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;
export const $Chip = styled(Chip) `
  margin: 2px;
  &.MuiChip-root {
    height: 24px;
    background-color: ${({ theme, disabled, colorScheme = 'primary' }) => disabled ? theme.colors.disable : colorScheme === 'secondary' ? theme.colors.secondaries.light : theme.colors.cards.background};
    border: ${({ theme, colorScheme = 'primary' }) => `1px solid ${theme.colors[colorScheme]}`};
    svg {
      display: none;
    }
    &:hover {
      svg {
        display: block;
      }
    }
  }
`;
