import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'

export const $FieldContainer = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`

export const $Grid = styled(Grid)`
  margin: ${({ theme }) => `${theme.spacing(2)} 0px`};
`

export const $MultipleActions = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`
