import React, { MouseEvent, useMemo } from 'react'
import { useSlate } from 'slate-react'
import { MarkButton } from './components/MarkButton'
import { BlockButton, isBlockActive, toggleBlock } from './components/BlockButton'
import { InlineButton } from './components/InlineButton'
import { $Badge, $Toolbar, $GroupButton } from './styles'
import { FlowCall, NestedMenu, SvgIcon, Tooltip, FORM_ELEMENT_TYPES, removeHiddenElements } from 'prace-common-components'
import { unwrapLink } from './plugins/withInlines'
import { $ToggleButton } from './components/styles'
import { TemplateElement } from 'store/api/templates/types'

export const Toolbar: React.FC<{
  call: FlowCall,
  handleGroupCreation: (groupElement: TemplateElement) => void,
  handleConditionButton: (e: MouseEvent<HTMLButtonElement>) => void,
  openLink: () => void,
}> = (
  { call, handleGroupCreation, handleConditionButton, openLink },
) => {
  const editor = useSlate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleConditionClick = (e: MouseEvent<HTMLButtonElement>) => {
    handleConditionButton(e)
  }

  const handleGroupClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if(isBlockActive(editor, 'group')) return
    setAnchorEl(e.currentTarget)
  }

  const handleItemClick = (step: number, form: number, element: number) => {
    setAnchorEl(null)
    handleGroupCreation({ step, form, element })
    toggleBlock(editor, 'group', isBlockActive(editor, 'group'), { step, form, element })
  }

  const menuItems = useMemo(() => {
    return removeHiddenElements(call).flow.map((step) => {
      return {
        id: step.id,
        label: step.title,
        items: step.flow?.map((form) => {
          const groupElements = form.flow.filter((element) => element.elementType === FORM_ELEMENT_TYPES.GROUP)
          return {
            id: form.id,
            label: form.title,
            items: groupElements.map((element) => {
              return {
                id: element.id,
                label: element.title,
                onItemClick: () => handleItemClick(step.id, form.id, element.id),
              }
            }),
          }
        }) || [],
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call?.flow])

  const handleLinkButton = (e: MouseEvent<HTMLButtonElement>, isActive: boolean) => {
    e.preventDefault()
    if(isActive) {
      unwrapLink(editor)
    } else {
      openLink()
    }
  }

  return (
    <>
      <$Toolbar>
        <div>
          <MarkButton format='bold'>
            <SvgIcon name='bold' size={24} />
          </MarkButton>
          <MarkButton format='italic'>
            <SvgIcon name='italic' size={24} />
          </MarkButton>
          <MarkButton format='underline'>
            <SvgIcon name='underline' size={24} />
          </MarkButton>
          <BlockButton format='bulleted-list'>
            <SvgIcon name='list-bullet' size={24} />
          </BlockButton>
          <BlockButton format='numbered-list'>
            <SvgIcon name='list-number' size={24} />
          </BlockButton>
          <InlineButton onMouseDown={handleLinkButton}>
            <SvgIcon name='link' size={24} />
          </InlineButton>
          <BlockButton format='left'>
            <SvgIcon name='align-left' size={24} />
          </BlockButton>
          <BlockButton format='center'>
            <SvgIcon name='align-center' size={24} />
          </BlockButton>
          <BlockButton onMouseDown={handleGroupClick} format='group'>
            <Tooltip
              text='You can select which variables from the chosen group you can use and edit the text in the highlighted space'
              arrow
              show
              placement='top'
            >
              <$GroupButton>
                <SvgIcon name='hive' size={24} />
                <SvgIcon name='chevron-down' size={16} color='#5C5F60' />
              </$GroupButton>
            </Tooltip>
          </BlockButton>
          <$ToggleButton
            selected={false}
            onMouseDown={() => editor.undo()}
          >
            <SvgIcon name='undo' size={24} />
          </$ToggleButton>
          <$ToggleButton
            selected={false}
            onMouseDown={() => editor.redo()}
          >
            <SvgIcon name='redo' size={24} />
          </$ToggleButton>
        </div>
        <BlockButton format='condition' withPadding={false} onMouseDown={handleConditionClick}>
          <$Badge>
            <SvgIcon name='condition' size={24} viewBox='0 0 896 640' />
            <span>Add condition</span>
          </$Badge>
        </BlockButton>
      </$Toolbar>
      <NestedMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        items={menuItems}
      />
    </>
  )
}
