import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
export const $Grid = styled(Grid) `
  gap: ${({ theme }) => theme.spacing(2)};
`;
export const $TableButton = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
