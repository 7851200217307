import React, { FC } from 'react'
import { NotesProps as Props } from './types'
import { Button, ConfirmModal, EditableMultiSelect, Input, ROLES, Switch, Title, USER_STATUS } from 'prace-common-components'
import { $GridContainer, $IconButton, $Message } from './styles'
import Grid from '@material-ui/core/Grid'
import { Box, FormControl, FormHelperText, Paper, Popper, Typography } from '@material-ui/core'
import { $LockIcon, $UnlockIcon } from './icons'

export const Notes: FC<Props> = ({ user, onChangeUserRemarks, onChangeUserTags, onChangeStatus }) => {
  const { status, role } = user
  const [open, setOpen] = React.useState(false)
  const anchorEl = React.useRef<HTMLButtonElement>(null)
  const [popperOpen, setPopperOpen] = React.useState(false)

  const confirmBlackList = () => {
    onChangeStatus('status', status !== USER_STATUS.Active ? USER_STATUS.Active : USER_STATUS.Blocked)
    setOpen(false)
  }

  return <>
    <Title subTitle>Notes</Title>
    <$GridContainer container>
      <Grid item xs={12}>
        <Input
          debounce
          hideLabel
          title=''
          name='userRemarks'
          value={user.userRemarks}
          onChange={(_, value) => onChangeUserRemarks('userRemarks', value as string)}
          multiline
          minRows={8}
          type='text'
          hideItalic
        />
      </Grid>
      <Grid item xs={12}>
        <EditableMultiSelect
          name='tags'
          value={user.tags ? user.tags.filter((tag) => !!tag) : []}
          title='User Tags'
          placeholder='Separate tags by a comma: tag1, tag2, tag3...'
          hideItalic
          onChange={(_, value) => onChangeUserTags('tags', value as string[])}
        />
      </Grid>
      <Grid item xs={12}>
        {/* //TODO: Make sure this is the same as disable user? */}
        <FormControl>
          {status !== USER_STATUS.Locked && ![ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(role) && (
            <Switch
              name='blacklist'
              title={'Blocked User'}
              value={status === USER_STATUS.Blocked}
              onChange={() => setOpen(true)}
            />
          )}

          {status === USER_STATUS.Locked && (
            <Popper
              open={popperOpen}
              anchorEl={anchorEl?.current}
              placement='top-start'
              disablePortal={false}
              modifiers={{
                flip: {
                  enabled: true,
                },
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'scrollParent',
                },
                arrow: {
                  enabled: true,
                  element: anchorEl?.current,
                },
              }}
              title={'Unlock User'}
              style={{ zIndex: 9999 }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', p: 2 }} component={Paper}>
                <Grid item>
                  <Typography variant={'h6'}>
                    {'Unlock User'}
                  </Typography>
                  <$Message variant={'body1'}>
                    {'Are you sure you want to unlock this user?'}
                  </$Message>
                </Grid>
                <Grid container justifyContent='space-between'>
                  <Button variant={'text'} onClick={() => setPopperOpen(false)}>
                    {'Cancel'}
                  </Button>
                  <$IconButton
                    aria-label='unlock user'
                    onClick={() => {
                      onChangeStatus('status', USER_STATUS.Active)
                      setPopperOpen(false)
                    }}
                  >
                    <$UnlockIcon/>
                  </$IconButton>
                </Grid>
              </Box>
            </Popper>
          )}

          {status === USER_STATUS.Locked && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ mr: 1 }}>
                <$IconButton
                  ref={anchorEl}
                  aria-label='unlock user'
                  onClick={() => setPopperOpen(true)}
                >
                  <$LockIcon/>
                </$IconButton>
              </Box>
              <FormHelperText>
                {'User is locked.'}
              </FormHelperText>
            </Box>
          )}

        </FormControl>
      </Grid>
    </$GridContainer>
    {open &&
      <ConfirmModal
        open
        title={status !== USER_STATUS.Active ? 'Activate user' : 'Block User'}
        onConfirm={confirmBlackList}
        onClose={() => setOpen(false)}
      />
    }
  </>
}
