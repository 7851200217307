import { FlowCall } from 'prace-common-components'
import { TableCellProps } from '@material-ui/core'

export interface CallsTableColumns {
  id: number,
  label: string,
  minWidth: number,
  align?: TableCellProps['align'],
  format?: (val: StringNumber | boolean) => StringNumber | boolean,
}

export interface NewCallProps {
  title: FlowCall['title'],
  description: FlowCall['description'],
  logo: FlowCall['logo'],
}

interface FormElements extends HTMLFormControlsCollection {
  selectRoles: HTMLInputElement,
}

export interface CallBundleFormElement extends HTMLFormElement {
  readonly elements: FormElements,
}

export enum TABLE_CALL_FIELDS {
  UID = 'uid',
  TITLE = 'title',
  STATUS = 'status',
  CUTOFF_START = 'cutoff-start',
  SUBMITTED = 'submitted',
  ACCEPTED = 'accepted',
}

