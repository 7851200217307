import { FlowCall } from 'prace-common-components'

export const validateEditCall = (call: FlowCall) => {
  const steps = call.flow || []
  /* Check if every step has assignees */
  const stepAssigneeError = steps.find((step) => !step.permissions.assignable?.length)
  if(stepAssigneeError)
    return `Step ${stepAssigneeError.title} does not have assignees`

  /* Check if every step that has assignees also has assigners (except first step) */
  const stepAssignError = steps.find(
    (step) => step.order && step.permissions.assignable?.length && !step.permissions.assign?.length)
  if(stepAssignError)
    return `Step ${stepAssignError.title} does not have assigners`

  /* Check if every step has forms */
  const stepElementsError = steps.find((step) => !step.flow?.length)
  if(stepElementsError)
    return `Step ${stepElementsError.title} does not have any form`

  /* Check if every form has elements */
  const formElementsError = steps.find((step) => !!step?.flow.find((form) => !form.flow?.length))
  if(formElementsError)
    return `Step ${formElementsError.title} has a form without elements`

  return ''
}
