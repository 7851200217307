import React from 'react';
import { Button } from '../NewButton';
import { SortableRow } from '../DnD';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { Input, Select, FORM_ELEMENT_TYPES, SvgIcon, capitalizeFirstChar, Tooltip } from '../';
import { $EditTable, $TableHeadActions, $Table, $TableHead, $TableContainer } from './styles';
import { $Checkbox } from '../newCheckbox';
const selectTypes = Object.values(FORM_ELEMENT_TYPES)
    .map((elementType) => ({ value: elementType, label: capitalizeFirstChar(elementType) }))
    .filter((type) => type.value != FORM_ELEMENT_TYPES.GROUP);
const headCells = [
    { id: 'label', numeric: false, disablePadding: false, label: 'Label' },
    { id: 'type', numeric: false, disablePadding: false, label: 'Element type' },
];
function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount, handleRemove } = props;
    return (React.createElement($TableHead, null,
        React.createElement(TableRow, null,
            React.createElement(TableCell, { padding: 'checkbox' }),
            React.createElement(TableCell, { padding: 'checkbox' },
                React.createElement($Checkbox, { alternate: true, indeterminate: numSelected > 0 && numSelected < rowCount, checked: rowCount > 0 && numSelected === rowCount, onChange: onSelectAllClick, inputProps: { 'aria-label': 'select all elements', 'aria-autocomplete': 'none' } })),
            numSelected > 0 ?
                React.createElement(React.Fragment, null,
                    React.createElement(TableCell, { align: 'left', padding: 'normal' },
                        React.createElement($TableHeadActions, null,
                            React.createElement(Typography, { color: 'textSecondary', variant: 'subtitle1', component: 'div' },
                                numSelected,
                                " selected"),
                            React.createElement(Tooltip, { show: true, text: 'Delete', arrow: true, placement: 'top' },
                                React.createElement(SvgIcon, { name: 'delete', clickable: true, onClick: handleRemove })),
                            React.createElement(Typography, { color: 'primary', variant: 'subtitle1', component: 'div' }, "Delete Selected"))),
                    React.createElement(TableCell, { align: 'left', padding: 'normal' }, "Element type"))
                :
                    headCells.map((headCell) => (React.createElement(TableCell, { key: headCell.id, align: headCell.numeric ? 'right' : 'left', padding: headCell.disablePadding ? 'none' : 'normal' }, headCell.label))))));
}
export const EditTable = ({ rows, onMove = () => null, onAddRow, onRemoveRow, onChangeRow, }) => {
    const [selected, setSelected] = React.useState([]);
    const handleSelectAllClick = (e) => {
        if (e.target.checked) {
            const newSelecteds = rows.map((n) => String(n.id));
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    const handleClick = (_e, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        }
        else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        }
        else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        }
        else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };
    const isSelected = (id) => selected.indexOf(id) !== -1;
    const onChange = (name, value, rowId) => {
        onChangeRow(name, value, rowId);
    };
    const handleRemove = () => {
        onRemoveRow(selected);
        setSelected([]);
    };
    return (React.createElement($EditTable, null,
        React.createElement($TableContainer, null,
            React.createElement($Table, { stickyHeader: true, "aria-labelledby": 'tableTitle', size: 'medium' },
                React.createElement(EnhancedTableHead, { handleRemove: handleRemove, numSelected: selected.length, onSelectAllClick: handleSelectAllClick, rowCount: rows.length }),
                React.createElement(TableBody, null, rows.map((row, order) => {
                    const isItemSelected = isSelected(String(row.id));
                    return (React.createElement(SortableRow, { key: row.id, rowProps: {
                            hover: true,
                            role: 'checkbox',
                            tabIndex: -1,
                            selected: isItemSelected,
                        }, item: { id: row.id, type: 'TABLE_TYPE', order }, type: 'TABLE_TYPE', dropType: ['TABLE_TYPE'], onMove: onMove, onDrop: () => null, onEndDrag: () => null },
                        React.createElement(TableCell, { padding: 'checkbox' },
                            React.createElement($Checkbox, { alternate: true, onClick: (event) => handleClick(event, String(row.id)), checked: isItemSelected })),
                        React.createElement(TableCell, { component: 'th', scope: 'row', padding: 'normal' },
                            React.createElement(Input, { debounce: true, title: row.title, name: 'title', value: row.title, hideLabel: true, onChange: (name, value) => onChange(name, value, row.id) })),
                        React.createElement(TableCell, { component: 'th', scope: 'row', padding: 'normal' },
                            React.createElement(Select, { title: row.elementType, name: 'elementType', value: row.elementType, hideLabel: true, items: selectTypes, onChange: (name, value) => onChange(name, value, row.id) }))));
                })))),
        onAddRow &&
            React.createElement(Button, { creator: true, variant: 'text', onClick: onAddRow }, "Add Element")));
};
