import * as React from 'react'
import { NewModal, DataFilter, FORM_ELEMENT_TYPES, NotificationType, $Chip, ActionsColumn, ConfirmModal, DataRow, DataTable, FILTER_TYPE, Grid, NestedMenu, NewButton, Title, getSearchQuery, NoMobile } from 'prace-common-components'
import { FormModal } from 'components/FormModal'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'constants/global'
import { $Link, $SvgIcon, $Tags, $TemplateManager, $Templates, $TemplatesTop } from './styles'
import { useGetCallIdsQuery } from 'store/api/calls'
import { useCreateTemplateMutation, useDeleteTemplateMutation, useGetTemplatesMutation } from 'store/api/templates'
import { useAppDispatch } from 'store/hooks'
import { GetTemplatesSuccess, Template, Variable } from 'store/api/templates/types'
import { useQuery } from 'util/useQuery'

const menuItems = [
  {id: 0, label: <><$SvgIcon name='content-copy' size={16} />Duplicate</>},
  {id: 1, label: <><$SvgIcon name='altEdit' size={16} />Edit</>},
  {id: 2, label: <><$SvgIcon name='delete' size={16} />Delete</>},
]

enum TEMPLATE_FIELDS {
  TITLE = 'title',
  ID = 'uid',
  CALL = 'callName',
  TAGS = 'tags',
}

const columns = [
  {
    key: TEMPLATE_FIELDS.TITLE,
    type: FILTER_TYPE.TEXT,
    name: 'Template Name',
    noSort: true,
    width: 500,
    formatter(props: { row: DataRow }) {
      const { title, id, valid } = props.row
      return <div data-simplebar><$Link valid={valid} to={Routes.TEMPLATE(id)}>{title}</$Link></div>
    },
  },
  { key: TEMPLATE_FIELDS.ID, noSort: true, type: FILTER_TYPE.NONE, name: 'Call ID', width: 250 },
  { key: TEMPLATE_FIELDS.CALL, noSort: true, type: FILTER_TYPE.NONE, name: 'Call Name'},
  { key: TEMPLATE_FIELDS.TAGS, noSort: true, type: FILTER_TYPE.TEXT, name: 'Tags', width: 250,
    formatter(props: { row: DataRow }) {
      const { tags } = props.row
      const templateTags = tags ? tags.split(',') : []
      return <$Tags data-simplebar container wrap='nowrap'>
        {templateTags.map((tag: string) => <$Chip key={tag} size='small' colorScheme='secondary' label={tag} />)}
      </$Tags>
    }},
  { key: 'actions', type: FILTER_TYPE.NONE, noSort: true, name: 'Actions', width: 35 },
]

type AllFilterType = { page: number; orderBy: {[key: string]: 'ASC' | 'DESC'}; searchBy: {[key: string]: string}}

const limit = 10

//TODO: Add sorting to this table
export const TemplateManager: React.FC<{isMobile?: boolean}> = ({ isMobile }) => {
  const dispatch = useAppDispatch()
  const { query } = useQuery()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedId, setSelectedId] = React.useState<number>()
  const [open, setOpen] = React.useState<'create' | 'duplicate' | 'delete' | undefined>(undefined)
  const [duplicateId, setDuplicateId] = React.useState<number>()
  const pageQuery = parseInt(query.get('page') || '1')
  const [allFilters, setAllFilters] = React.useState<AllFilterType>({
    searchBy: getSearchQuery(query, TEMPLATE_FIELDS),
    orderBy: {},
    page: pageQuery,
  })
  const [templates, setTemplates] = React.useState<GetTemplatesSuccess>({
    data: [],
    total: 0,
  })
  const navigate = useNavigate()
  const { data: callsData } = useGetCallIdsQuery()
  const [searchTemplates] = useGetTemplatesMutation()
  const [deleteTemplate] = useDeleteTemplateMutation()
  const [createTemplate] = useCreateTemplateMutation()

  const calls = callsData?.data
  const emailTemplates = templates?.data

  React.useEffect(() => {
    const getTemplates = async () => {
      try {
        const offset = limit * (pageQuery - 1)
        const data = await searchTemplates({ limit, offset, ...allFilters }).unwrap()
        setTemplates(data)
      } catch (err) {
        console.log(err)
      }
    }
    getTemplates().catch((err) => console.log(err))
  }, [searchTemplates, allFilters, pageQuery])

  //TODO: Selects should update the items when there is a new call
  const duplicateSelects = React.useMemo(() => [
    { id: 0, index: 0, name: 'title', flow: [],
      elementType: FORM_ELEMENT_TYPES.TEXT,
      title: 'Template name',
      options: {
        placeholder: 'Name the new template',
        description: '',
        required: true,
      },
    },
    { id: 1, index: 1, name: 'callId', flow: [],
      elementType: FORM_ELEMENT_TYPES.SELECT,
      title: 'Which call will this template be duplicated for?',
      items: calls?.map((call) => {
        return {
          label: `${call.title} (${call.uid})${call.published ? '' : ' - Not Published'}`,
          value: call.id,
        }
      }),
      options: {
        placeholder: 'Call',
        description: '',
        required: false,
      },
    },
    { id: 2, index: 2, name: 'tags', flow: [],
      elementType: FORM_ELEMENT_TYPES.MULTITEXT,
      title: 'Tags',
      options: {
        description: 'Every tag should be separated by a comma. ',
      },
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [calls])

  const createSelects = React.useMemo(() => [
    { id: 0, index: 0, name: 'title', flow: [],
      elementType: FORM_ELEMENT_TYPES.TEXT,
      title: 'Template name',
      options: {
        placeholder: 'Name this template',
        description: '',
        required: true,
      },
    },
    { id: 1, index: 1, name: 'callId', flow: [],
      elementType: FORM_ELEMENT_TYPES.SELECT,
      title: 'Which call will this template be for?',
      items: calls?.map((call) => {
        return {
          label: `${call.title} (${call.uid})${call.published ? '' : ' - Not Published'}`,
          value: call.id,
        }
      }),
      options: {
        placeholder: 'Choose a call',
        description: '',
        required: true,
      },
    },
    { id: 2, index: 2, name: 'tags', flow: [],
      elementType: FORM_ELEMENT_TYPES.MULTITEXT,
      title: 'Tags',
      options: {
        description: 'Every tag should be separated by a comma. ',
      },
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [calls])

  const templateRows = React.useMemo(() =>
    (emailTemplates || [])?.map((t) => {
      const templateCall = calls?.find((call) => call.id === t.callId)
      return {
        id: t.id,
        valid: t.valid,
        title: t.title,
        tags: t.tags?.join(',') || '',
        uid: templateCall ? String(templateCall.uid) : '',
        callName: templateCall ? templateCall.title : '',
        actions: <ActionsColumn handleClick={(e: React.MouseEvent<any>) => handleClick(e, t.id)}/>,
      }
    })
  , [calls, emailTemplates])

  const handleCreate = async (e: React.FormEvent<HTMLFormElement>, dataValues: { tags: string[] }) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      title: { value: string },
      callId: { value: string },
    }
    const filteredTags = dataValues.tags?.filter((tag) => tag !== '') || []
    const template = {
      title: target.title.value,
      callId: Number(target.callId.value),
      subject: { data: [] },
      body: { data: [] },
      state: [],
      variables: [],
      tags: filteredTags,
      id: 1,
      error: false,
    }
    try {
      const newTemplate = await createTemplate(template).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Template created' } })
      setOpen(undefined)
      navigate(Routes.TEMPLATE(newTemplate.id))
    } catch (err) {
      console.log(err)
    }
  }

  const onDuplicate = (id: number) => {
    setOpen('duplicate')
    setDuplicateId(id)
  }

  const handleDuplicate = async (e: React.FormEvent<HTMLFormElement>, dataValues: { tags: string[] }) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      title: { value: string },
      callId: { value: number },
      tags: { value: string[] },
    }
    if(duplicateId) {
      const tags = dataValues.tags?.filter((tag) => tag !== '') || []
      try {
        const oldTemplate = emailTemplates?.find((t: Template) => t.id === duplicateId)
        if(!oldTemplate) return
        const newTemplate = {
          ...oldTemplate,
          id: undefined,
          variables: oldTemplate.variables.map((v) => { return {...v, id: undefined } as unknown as Variable}),
          error: false,
          title: target.title.value,
          callId: target.callId.value,
          tags,
        }
        const apiTemplate = await createTemplate(newTemplate).unwrap()
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Template created' } })
        setOpen(undefined)
        navigate(Routes.TEMPLATE(apiTemplate.id))
      } catch (err) {
        console.log(err)
      }
      /* dispatch(TEMPLATE_EVENTS.DUPLICATE_TEMPLATE_API, {
        templateId: duplicateId,
        title: target.title.value,
        callId: target.callId.value,
        tags: templateTags,
      }) */
    }
  }

  const handleEdit = (id: number) => {
    navigate(Routes.TEMPLATE(id))
  }

  const handleDelete = async (id?: number) => {
    try {
      if(id === undefined) return
      await deleteTemplate(id).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Template deleted' } })
      setOpen(undefined)
      setTemplates((prevTemplates) => {
        const newTemplates = prevTemplates.data.filter((t) => t.id !== id)
        return { data: newTemplates, total: prevTemplates.total - 1 }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const urlHandler = React.useCallback((
    order?: { [key: string]: 'ASC' | 'DESC' },
    search?: DataFilter,
    page = 1,
  ) => {
    setAllFilters((prevFilters) => {
      const newOrder = order ? order : prevFilters.orderBy
      let orderByField = ''
      Object.keys(newOrder).forEach((key: string) => {
        if(newOrder[key]) orderByField = orderByField + `${key}&direction=${newOrder[key]}`
      })
      const newSearch = search ? search : prevFilters.searchBy
      let searchStr = ''
      Object.keys(newSearch).forEach((key: string) => {
        searchStr = searchStr + `&${key}=${newSearch[key as keyof typeof newSearch]}`
      })
      navigate(Routes.TEMPLATE_SEARCH(page, searchStr, orderByField))
      return { page, searchBy: newSearch, orderBy: newOrder}
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, query])

  const handleChangePage = (newPage: number) => {
    if(newPage !== pageQuery) urlHandler(undefined, undefined, newPage)
  }

  const onSortColumn = (columnKey: string, direction?: 'ASC' | 'DESC') => {
    urlHandler({[columnKey]: direction || 'DESC'})
  }

  const onFilters = (search: DataFilter) => {
    urlHandler(undefined, search)
  }

  const handleClick = (e: React.MouseEvent<any>, id: StringNumber) => {
    setAnchorEl(e.currentTarget)
    setSelectedId(Number(id))
  }

  const handleItemClick = (id: StringNumber) => {
    setAnchorEl(null)
    if(selectedId === undefined) return
    switch(id) {
      case 0:
        onDuplicate(selectedId)
        break
      case 1:
        handleEdit(selectedId)
        break
      default:
        setOpen('delete')
    }
  }

  if(isMobile) return <NoMobile />

  return  (
    <$TemplateManager>
      <$Templates>
        <$TemplatesTop container alignItems='center' justifyContent='space-between'>
          <Grid item xs>
            <Title alternate fontSize={22}>
            Email Templates
            </Title>
          </Grid>
          <Grid item>
            <NewButton creator onClick={() => setOpen('create')}>
            Create New Template
            </NewButton>
          </Grid>
        </$TemplatesTop>
        <DataTable
          rows={templateRows || []}
          columns={columns}
          total={templates?.total || 0}
          page={pageQuery}
          rowsPerPage={limit}
          onChangePage={handleChangePage}
          onSortColumn={onSortColumn}
          onFilters={onFilters}
          initFilters={allFilters.searchBy}
          localFilter={false}
          noFilterType
          noMultipleSorting
          sortable
          resizable
        />
      </$Templates>
      {open === 'create' &&
        <NewModal open alternateTitle title='Create Template' onClose={() => setOpen(undefined)}>
          <FormModal
            columns={[{ elements: createSelects }]}
            submitText='Save'
            onSubmit={handleCreate}
          />
        </NewModal>
      }
      {open === 'duplicate' &&
        <NewModal open alternateTitle title='Duplicate Template' onClose={() => setOpen(undefined)}>
          <FormModal
            columns={[{ elements: duplicateSelects }]}
            submitText='Save'
            onSubmit={handleDuplicate}
          />
        </NewModal>
      }
      {open === 'delete' &&
        <ConfirmModal
          open
          title='Delete template'
          description='Deleting a template will have impact on call actions associated with it.'
          onConfirm={() => handleDelete(selectedId)}
          onClose={() => setOpen(undefined)}
        />
      }
      <NestedMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        items={menuItems}
        handleItemClick={handleItemClick}
      />
    </$TemplateManager>
  )
}
