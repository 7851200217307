import { useDrag } from 'react-dnd';
export function useDragComponent(itemType, item, specs) {
    const [{ isDragging, canDrag }, drag, preview] = useDrag({
        type: itemType,
        item: () => item,
        end: specs?.endCallback,
        canDrag: specs?.canDragAction,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            canDrag: monitor.canDrag(),
        }),
    }, specs?.dependencies);
    return ([{ isDragging, canDrag }, drag, preview]);
}
