import styled from 'styled-components';
export const $IconWrapper = styled.div `
  display: flex;
  justify-content: center;
  svg, img {
    fill: ${({ color }) => color};
    width: ${({ size }) => size + 'px'};
    height: ${({ size }) => size + 'px'};
  }
`;
