import * as React from 'react'
import update from 'immutability-helper'
import { ELEMENT_BASE_PARAMS, capitalizeFirstChar, Condition, ConfirmAction, Divider, ELEMENT_TYPES, ElementOptions, ElementValue, FlowFormElement, FORM_ELEMENT_TYPES, insertSelectOptionGroups, NewButton, NewCheckbox, NewModal, OptionGroup, SvgIcon, Title, NotificationType } from 'prace-common-components'
import { Grid } from '@material-ui/core'
import { EditGroupElement } from './EditGroupElement'
import { EditElementModal } from './EditElementModal'
import { EditFormElementProps } from './types'
import { $ButtonContainer } from '../styles'
import { EditGroupField } from './EditGroupField'
import { ElementConditions } from './ElementConditions'
import { ElementPermission } from 'components/ElementPermission'
import { OptionsGroup } from 'components/OptionsGroup'
import { ConditionEditor } from 'components/ConditionEditor'
import { $MultipleActions } from './styles'
import { MinMaxConfig } from '../MinMaxConfig'
import { DeleteElementModal } from 'components/DeleteElementModal'
import { getElementUsage } from 'util/getElementUsage'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { RootState } from 'store'
import { useAddEffectMutation, useCreateFlowElementsMutation, useDeleteEffectsMutation, useDeleteFlowElementsMutation, useUpdateElementMutation } from 'store/api/calls/elements'
import { groupElementDifferences } from 'util/groupElementDifferences'
import { useCreateOptionsGroupMutation, useDeleteOptionsGroupMutation, useLazyGetOptionsQuery, useUpdateOptionsGroupMutation } from 'store/api/options'

export const EditFormElement: React.FC<EditFormElementProps> = ({ element, selectedElements, selectAddElements }) => {
  const dispatch = useAppDispatch()
  const [updateElement] = useUpdateElementMutation()
  const [deleteElement] = useDeleteFlowElementsMutation()
  const [createElement] = useCreateFlowElementsMutation()
  const [addEffect] = useAddEffectMutation()
  const [deleteEffects] = useDeleteEffectsMutation()
  const [createOptionsGroup, { isLoading: creatingOptions }] = useCreateOptionsGroupMutation()
  const [deleteOptionsGroup, { isLoading: deletingOptions }] = useDeleteOptionsGroupMutation()
  const [updateOptionsGroup, { isLoading: updatingOptions }] = useUpdateOptionsGroupMutation()
  const [getOptions] = useLazyGetOptionsQuery()
  const call = useAppSelector((state: RootState) => state.calls.call)
  const step = useAppSelector((state: RootState) => state.calls.editorSelector.step)
  const form = useAppSelector((state: RootState) => state.calls.editorSelector.form)
  const elements = useAppSelector((state: RootState) => state.calls.editorSelector.elements)
  const optionsGroups = useAppSelector((state: RootState) => state.calls.optionGroups)
  const storeTemplates = useAppSelector((state: RootState) => state.calls.templates)
  const [selectedTag, setSelectedTag] = React.useState<string | undefined>(undefined)
  const [duplicateTag, setDuplicateTag] = React.useState<string | undefined>(undefined)
  const [open, setOpen] = React.useState<FORM_ELEMENT_TYPES.GROUP | 'condition' | undefined>(undefined)
  const [openedCondition, setOpenedCondition] = React.useState<number | undefined>()
  const [openEditElement, setOpenedEditElement] = React.useState<boolean>(false)
  const [groupElementIndex, setGroupElementIndex] = React.useState<number>()
  const [openDeleteElement, setOpenedDeleteElement] = React.useState<boolean>(false)
  const [customLoading, setCustomLoading] = React.useState<boolean>(false)
  const [openConditionElement, setOpenConditionElement] = React.useState<FlowFormElement | undefined>(undefined)
  const multipleElementsSelected = !!selectedElements.length
  const isGroup = element.elementType === FORM_ELEMENT_TYPES.GROUP && !multipleElementsSelected
  const notBlank = element.elementType !== FORM_ELEMENT_TYPES.SPACE && !multipleElementsSelected &&
    element.elementType !== FORM_ELEMENT_TYPES.DIVIDER
  const [openedOptionsGroup, setOpenedOptionsGroup] =
    React.useState<OptionGroup | FORM_ELEMENT_TYPES | 'DUPLICATE' | undefined>(undefined)
  const newOptionsGroup = openedOptionsGroup === FORM_ELEMENT_TYPES.SELECT
  const [openOptionsGroupModal, setOpenOptionsGroupModal] = React.useState<boolean>(false)
  const optionsLoading = creatingOptions || updatingOptions || deletingOptions

  // TODO: Try to find a way of doing this in a different place and way
  /* We had the option groups here so that it is always updated
   according to the new ones created by the user */
  const [elementState, setElementState] = React.useState<FlowFormElement>(
    insertSelectOptionGroups([element], optionsGroups)[0])

  React.useEffect(() => 
    setElementState(insertSelectOptionGroups([element], optionsGroups)[0]),
  [element, optionsGroups])

  const multipleElements = selectedElements.map((order) => call.flow[step].flow[form].flow[order])

  // This effect is used to open the modal of the options group
  // it happens when the user clicks to edit or duplicate the option group
  React.useEffect(() => {
    const optionsGroup = optionsGroups.find(
      ({ tag }: Partial<OptionGroup>) => (tag === selectedTag) || (tag === duplicateTag))
    setOpenedOptionsGroup(duplicateTag ? ({ ...optionsGroup, tag: `Copy of ${optionsGroup?.tag || ''}`}) as OptionGroup : optionsGroup)
    setOpenOptionsGroupModal(optionsGroup !== undefined)
  }, [optionsGroups, selectedTag, duplicateTag])

  const onChangeOptions = async (
    name: string,
    value: ElementValue,
    _type: FORM_ELEMENT_TYPES,
    index = 0,
    newOptionsGroup?: OptionGroup,
  ) => {
    try {
      const isOptionsGroup = name === 'options'
      let optionsGroup = elementState.optionsGroup
      if(isOptionsGroup) optionsGroup = 
        newOptionsGroup ?? optionsGroups.find((optGroup: OptionGroup) => optGroup.tag === value as string)
      const paramName = isOptionsGroup ? 'optionsGroup' : name
      const paramValue = isOptionsGroup ? optionsGroup as OptionGroup : value
      const element = isGroup ? elementState.flow[index] : elementState
      const newValues = ELEMENT_BASE_PARAMS.includes(paramName)
        ? { id: element.id, [paramName]: paramValue }
        : { id: element.id, config: { ...element.config, [paramName]: paramValue }}
      if(isGroup) {
        setElementState((prevState) => ({
          ...prevState,
          flow: prevState.flow.map((groupEl: FlowFormElement) =>
            groupEl.id === element.id ? { ...groupEl, ...newValues } : groupEl,
          ),
        } as FlowFormElement))
      } else {
        setElementState((prevState) => ({ ...prevState, ...newValues } as FlowFormElement))
      }
      await updateElement(newValues).unwrap()
      if(isGroup) {
        dispatch({ type: 'updateGroupElement', payload: { groupId: elementState.id, newValues } })
      } else {
        dispatch({ type: 'updateElement', payload: { newValues } })
      }
      setGroupElementIndex(undefined)
    } catch (err) {
      dispatch({ type: 'updateElement', payload: {} })
      console.log(err)
    }
  }

  /* FIXME: All of this should be done in the BE with one request and with a transaction */
  const saveGroupHandler = async (formElements: FlowFormElement[], groupName: string) => {
    try {
      setCustomLoading(true)
      const [addedElements, deletedIds] = groupElementDifferences(elementState.flow, formElements)
      const groupElement = {...elementState, title: groupName, flow: formElements}
      let newGroupFlow = groupElement.flow.map((element: FlowFormElement, i: number) => {
        return { ...element, order: i, permissionsId: undefined } as FlowFormElement
      })
      if(addedElements.length) {
        const newElements = await createElement({
          parentId: element.id,
          flowElements: addedElements as FlowFormElement[],
        }).unwrap()
        newGroupFlow = newGroupFlow.map((groupEl: FlowFormElement) => {
          //Need to update the id of the addedElements in the flow
          const found = newElements.find((newEl) => newEl.order === groupEl.order)
          return found ? { ...groupEl, id: found.id } : groupEl
        })
      }
      if(deletedIds.length) await deleteElement({ ids: deletedIds as number[] }).unwrap()
      await updateElement({ ...groupElement, flow: newGroupFlow, id: element.id }).unwrap()
      dispatch({ type: 'updateElement', payload: { newValues: { ...groupElement, flow: newGroupFlow } } })
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Group updated' } })
      setOpen(undefined)
      setCustomLoading(false)
    } catch (err) {
      dispatch({ type: 'updateElement', payload: {} })
      setCustomLoading(false)
      console.log(err)
    }
  }

  const saveElementHandler = async (type: string) => {
    try {
      const newElementParams = {
        config: {
          ...elementState.config,
          max: null,
          min: null,
        },
        items: [],
        elementType: type,
      }
      const newValues = { id: element.id, ...newElementParams }
      await updateElement(newValues).unwrap()
      dispatch({ type: 'updateElement', payload: { newValues } })
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Element updated' } })
      setOpenedEditElement(false)
    } catch (err) {
      dispatch({ type: 'updateElement', payload: {} })
      console.log(err)
    }
  }

  const handleDelete = async () => {
    try {
      await deleteElement({ ids: [element.id] }).unwrap()
      dispatch({ type: 'deleteElements', payload: { ids: [element.id] } })
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Element deleted' } })
    } catch (err) {
      console.log(err)
    }
  }

  const onConditionEdit = async (condition: Condition) => {
    try {
      /* Elements inside groups: */
      const groupElementId = openConditionElement?.id
      let flowElement = elementState
      if(groupElementId) {
        const groupFlowElement = flowElement.flow.find((groupEl: FlowFormElement) => groupEl.id == groupElementId)
        if(groupFlowElement) {
          flowElement = groupFlowElement
        } else {
          dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Group flow element not found' } })
          return
        }
      }
      const effects = [...flowElement.effects || []]
      if(openedCondition !== undefined) {
        setCustomLoading(true)
        const conditionIdx = effects?.findIndex((effect: Condition) => effect.id == openedCondition)
        effects.splice(conditionIdx, 1, condition) // Remove element from index and add new condition
        await updateElement({
          id: flowElement.id,
          effects: [condition], //Only affect this condition
        }).unwrap()
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Condition updated' } })
      } else {
        const newCondition = await addEffect({
          ...condition, order: effects ? effects.length : 0, id: flowElement.id,
        }).unwrap()
        effects.push(newCondition)
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Condition added' } })
      }
      const newValues = groupElementId ? {
        ...elementState,
        flow: elementState.flow.map((groupEl: FlowFormElement) => 
          groupEl.id === groupElementId ? { ...groupEl, effects } : groupEl,
        ), 
      } : { ...elementState, effects }
      dispatch({ type: 'updateElement', payload: { newValues } })
      setCustomLoading(false)
      setOpen(undefined)
      setOpenedCondition(undefined)
      setOpenConditionElement(undefined)    
    } catch (err) {
      setCustomLoading(false)
      console.log(err)
    }
  }

  const onConditionOpen = (idx?: number, groupElement?: FlowFormElement) => {
    if(groupElement) setOpenConditionElement(groupElement)
    else setOpen('condition')
    setOpenedCondition(idx)
  }

  const onDeleteCondition = async (conditionId: number, groupElementId?: FlowFormElement['id']) => {
    try {
      /* Elements inside groups: */
      let flowElement = elementState
      if(groupElementId) {
        const groupFlowElement = flowElement.flow.find((groupEl: FlowFormElement) => groupEl.id == groupElementId)
        if(groupFlowElement) {
          flowElement = groupFlowElement
        } else {
          dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Group flow element not found' } })
          return
        }
      }
      await deleteEffects(conditionId).unwrap()
      const effects = flowElement.effects.filter((effect: Condition) => effect.id !== conditionId)
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Condition deleted' } })
      const newValues = groupElementId ? {
        ...elementState,
        flow: elementState.flow.map((groupEl: FlowFormElement) => 
          groupEl.id === groupElementId ? { ...groupEl, effects } : groupEl,
        ), 
      } : { ...elementState, effects }
      dispatch({ type: 'updateElement', payload: { newValues } })
    } catch (err) {
      console.log(err)
    }
  }

  const onCreateOptionsGroup = async (groupOptions: OptionGroup) => {
    try {
      const newOptionsGroup = await createOptionsGroup({ tag: groupOptions.tag, options: groupOptions.options}).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Options group created' } })
      onChangeOptions('options', groupOptions.tag, FORM_ELEMENT_TYPES.SELECT, groupElementIndex, newOptionsGroup)
      setOpenedOptionsGroup(undefined)
      setDuplicateTag(undefined)
    } catch (err) {
      console.log(err)
    }
  }

  const onDeleteOptionsGroup = async (groupOptions: OptionGroup) => {
    try {
      await deleteOptionsGroup(groupOptions.id).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Options group deleted' } })
      setOpenedOptionsGroup(undefined)
    } catch (err) {
      console.log(err)
    }
  }

  const onChangeOptionsGroup = async (groupOptions: OptionGroup) => {
    try {
      await updateOptionsGroup({
        id: groupOptions.id,
        tag: groupOptions.tag,
        options: groupOptions.options,
      }).unwrap()
      setOpenedOptionsGroup(undefined)
      setSelectedTag(undefined)
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Options group updated' } })
    } catch (err) {
      console.log(err)
    }
  }

  const onEditOptionsGroup = async (tag: StringNumber) => {
    try {
      const optionsGroup = optionsGroups.find((optionsGroup: OptionGroup) => optionsGroup.tag === tag)
      if (optionsGroup) {
        await getOptions(optionsGroup.id).unwrap()
        setSelectedTag(tag as string)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onDuplicateOptionsGroup = async (tag: StringNumber) => {
    try {
      const optionsGroup = optionsGroups.find((optionsGroup: OptionGroup) => optionsGroup.tag === tag)
      if (optionsGroup) {
        await getOptions(optionsGroup.id).unwrap()
        setDuplicateTag(tag as string)
        setOpenedOptionsGroup('DUPLICATE')
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onChangeElementPermission = async (name: string, value: ElementValue) => {
    try {
      const newValues = { id: element.id, permissions: { ...elementState.permissions, [name]: value }}
      setElementState((prevState) => ({ ...prevState, permissions: { ...prevState.permissions, [name]: value }}))
      await updateElement(newValues).unwrap()
      dispatch({ type: 'updateElement', payload: { newValues } })
    } catch (err) {
      dispatch({ type: 'updateElement', payload: {} })
      console.log(err)
    }
  }

  const onChangeOption = async (name: string, value: ElementValue) => {
    try {
      const newValues = ELEMENT_BASE_PARAMS.includes(name)
        ? { id: element.id, [name]: value }
        : { id: element.id, config: { ...elementState.config, [name]: value }}
      setElementState((prevState) => ({ ...prevState, ...newValues } as FlowFormElement))
      await updateElement(newValues).unwrap()
      dispatch({ type: 'updateElement', payload: { newValues } })
    } catch (err) {
      dispatch({ type: 'updateElement', payload: {} })
      console.log(err)
    }
  }

  //TODO: This function has a lot in common with other functions that create elements
  const duplicateElement = async (toDuplicateElements: number[]) => {
    const flowForm = call.flow[step].flow[form]
    const numberElements = flowForm.flow.length
    const groupElements: FlowFormElement[] = []
    const duplicateElements = toDuplicateElements
      .map((duplicateElementIdx: number) => flowForm.flow[duplicateElementIdx])
    const newElements = duplicateElements.map((el: FlowFormElement, i: number) => {
      const type = el.elementType
      const isGroup = type === FORM_ELEMENT_TYPES.GROUP
      const newElement = {
        ...el,
        id: 0,
        parentId: flowForm.id,
        order: numberElements + i,
        effects: el.effects || [],
        config: { ...el.config, id: 0 },
        permissions: { ...el.permissions, id: undefined },
        automations: [],
        flow: el.flow.map((groupEl: FlowFormElement) => ({
          ...groupEl,
          automations: [],
          permissions: {...groupEl.permissions, id: undefined },
          config: { ...groupEl.config, id: 0 },
          effects: groupEl.effects || [],
        })),
      }
      if(isGroup) groupElements.push(newElement as FlowFormElement)
      return newElement
    })
    const withGroups = groupElements.length
    let newFlowElements = await createElement({
      parentId: flowForm.id,
      flowElements: newElements as FlowFormElement[],
    }).unwrap() as FlowFormElement[]
    newFlowElements = newFlowElements.map((el) => {
      let newFlow: FlowFormElement[] = []
      if(el.flow)
        newFlow = el.flow.map((flowEl: FlowFormElement) => {  return { ...flowEl, flow: flowEl.flow || [] }})
      return { ...el, flow: newFlow } as FlowFormElement
    })
    /* Create the deeper elements after (elements inside groups) */
    if(withGroups) {
      for (const groupElement of groupElements) {
        //Using order to discover the correct group element
        const foundElIdx = newFlowElements.findIndex((flowEl) => flowEl.order === groupElement.order)
        if(foundElIdx !== -1) {
          const parentId = newFlowElements[foundElIdx].id
          //Need the response because of the ids
          const newGroupElements = await createElement({
            parentId,
            flowElements: groupElement.flow,
          }).unwrap() as FlowFormElement[]
          newFlowElements = update(newFlowElements, {
            [foundElIdx]: {
              title:  { $set: groupElement.title },
              flow: {
                $set: newGroupElements.map((el) => { return { ...el, flow: [] } as FlowFormElement }),
              },
            }})
        }
      }
    }
    dispatch({ type: 'addElements', payload: { 
      newElements: newFlowElements, stepId: call.flow[step].id, formId: flowForm.id,
    }})
  }

  const handleSelectElement = (order: number) => {
    dispatch({ type: 'selectElements', payload: { order }})
  }

  const handleDuplicateElement = async (order?: number) => {
    try {
      if(order !== undefined) {
        await duplicateElement([order])
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Element duplicated' } })
      }
    } catch (err) {
      console.log(err)
    }
  }
  
  const handleDeleteMultiple = async () => {
    try {
      if(multipleElements.some((element) => {
        const {
          automations,
          conditions,
          templates,
        } = getElementUsage(element.id, call, storeTemplates || [])
        return automations.length || conditions.length || templates.length
      })) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'There are elements with associated templates, conditions or automations' } })
      } else {
        const ids = elements.map((elementIdx) => call.flow[step].flow[form].flow[elementIdx].id)
        await deleteElement({ ids }).unwrap()
        dispatch({ type: 'deleteElements', payload: { ids } })
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Elements deleted' } })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleDuplicateMultiple = async () => {
    try {
      await duplicateElement(elements)
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Elements duplicated' } }) 
    } catch (err) {
      console.log(err)
    }
  }

  const handleOpenedOptionsGroup = (type: FORM_ELEMENT_TYPES, index?: number) => {
    setOpenedOptionsGroup(type)
    if(index !== undefined) setGroupElementIndex(index)
    setOpenOptionsGroupModal(true)
  }

  return (
    <>
      <Grid container>
        <Title
          hasBack
          noWrap
          onBackClick={selectAddElements}
          type={elementState?.elementType}
          actionIcon={<SvgIcon name='altEdit' color='#A8ACAC' />}
          onAction={() => {
            if(isGroup) setOpen(FORM_ELEMENT_TYPES.GROUP)
            else setOpenedEditElement(true)
          }}
        >
          {capitalizeFirstChar(multipleElementsSelected ? 'Multiple Elements Selected' : elementState.title)}
        </Title>
        {multipleElementsSelected &&
          <Grid container spacing={1}>
            {multipleElements.map((element) =>
              <Grid item xs={12} key={element.id}>
                <NewCheckbox
                  title={element.title}
                  type={element.elementType}
                  onChange={() => handleSelectElement(element.order)}
                  value={true}
                />
              </Grid>,
            )}
          </Grid>
        }
        {isGroup ?
          <>
            {elementState.flow.map((groupEl: FlowFormElement, i: number) =>
              <EditGroupField
                key={groupEl.id}
                groupElement={groupEl}
                index={i}
                onChange={onChangeOptions}
                onCreate={(type) => handleOpenedOptionsGroup(type, i)}
                onEditOptionsGroup={onEditOptionsGroup}
                onDuplicateOptionsGroup={onDuplicateOptionsGroup}
                call={call}
                onEdit={(idx?: number) => onConditionOpen(idx, groupEl)}
                onDelete={(idx: number) => onDeleteCondition(idx, groupEl.id)}
              />,
            )}
            <MinMaxConfig
              title='Number of additions'
              minName='min'
              maxName='max'
              min={element?.config?.min || null}
              max={element?.config?.max || null}
              onChange={onChangeOption}
            />
            <Divider />
            <ElementPermission
              permissions={elementState.permissions}
              type={ELEMENT_TYPES.ELEMENT}
              id={element.id}
              onChange={onChangeElementPermission}
            />
            <Divider />
            <ElementConditions
              conditions={elementState.effects}
              call={call}
              onDelete={onDeleteCondition}
              onEdit={onConditionOpen}
            />
            <$ButtonContainer>
              <NewButton variant='outlined' onClick={() => setOpen('condition')}>
                Add Condition
              </NewButton>
            </$ButtonContainer>
          </>
          : notBlank ?
            <>
              <ElementOptions
                element={elementState}
                onChange={onChangeOptions}
                onCreate={handleOpenedOptionsGroup}
                onEditItem={onEditOptionsGroup}
                onDuplicateItem={onDuplicateOptionsGroup}
              />
              <Divider />
              <ElementPermission
                permissions={elementState.permissions}
                type={ELEMENT_TYPES.ELEMENT}
                id={element.id}
                onChange={onChangeElementPermission}
              />
              <Divider />
              <ElementConditions
                conditions={elementState.effects}
                call={call}
                onEdit={onConditionOpen}
                onDelete={onDeleteCondition}
              />
              <$ButtonContainer>
                <NewButton variant='outlined' onClick={() => setOpen('condition')}>
                Add Condition
                </NewButton>
              </$ButtonContainer>
            </>
            : null
        }
      </Grid>
      <Grid container spacing={1}>
        {!multipleElementsSelected &&
          <Grid item>
            <NewButton variant='outlined' onClick={() => handleDuplicateElement(elementState?.order)}>
              Duplicate element
            </NewButton>
          </Grid>
        }
        {multipleElementsSelected ?
          <$MultipleActions container>
            <ConfirmAction deletion title='Delete multiple elements' description='Deleting these elements will afect everything associated with them.' onConfirm={handleDeleteMultiple}>
              <NewButton variant='text' icon={<SvgIcon name='delete' />} onClick={handleDeleteMultiple}>Delete Multiple</NewButton>
            </ConfirmAction>
            <ConfirmAction title='Duplicate elements' description='The duplicated elements will not have conditions. These have to be made by the user.' onConfirm={handleDuplicateMultiple}>
              <NewButton variant='text' icon={<SvgIcon name='content-copy' />} onClick={handleDuplicateMultiple}>Duplicate Multiple</NewButton>
            </ConfirmAction>
          </$MultipleActions>
          : <Grid item>
            <NewButton error onClick={() => setOpenedDeleteElement(true)}>Delete Element</NewButton>
          </Grid>}
      </Grid>
      {open &&
        <NewModal alternateTitle title={open === FORM_ELEMENT_TYPES.GROUP ? `${elementState.title} Group` : 'Add Condition'} open
          onClose={() => {
            setOpen(undefined)
            setOpenedCondition(undefined)
          }}>
          {open === FORM_ELEMENT_TYPES.GROUP ?
            <EditGroupElement
              call={call}
              element={elementState}
              saveHandler={saveGroupHandler}
              cancelHandler={() => setOpen(undefined)}
              loading={customLoading}
            />
            :
            <ConditionEditor
              currentCondition={openedCondition !== undefined && elementState?.effects ?
                (elementState.effects.find((cond: Condition) => cond.id === openedCondition)) : undefined}
              onSubmit={onConditionEdit}
              flowElement={element}
              optionGroups={optionsGroups}
              loading={customLoading}
            />
          }
        </NewModal>
      }
      {openEditElement &&
        <NewModal open alternateTitle title={elementState.title}
          onClose={() => setOpenedEditElement(false)}>
          <EditElementModal
            element={elementState}
            saveHandler={saveElementHandler}
            cancelHandler={() => setOpenedEditElement(false)}
          />
        </NewModal>
      }
      {openConditionElement &&
        <NewModal alternateTitle title='Add Condition' open
          onClose={() => {
            setOpenConditionElement(undefined)
            setOpenedCondition(undefined)
          }}>
          <ConditionEditor
            currentCondition={openedCondition !== undefined && openConditionElement?.effects ?
              (openConditionElement.effects.find((cond: Condition) => cond.id === openedCondition)) : undefined}
            onSubmit={onConditionEdit}
            flowElement={openConditionElement}
            optionGroups={optionsGroups}
            insideGroup={element.id}
          />
        </NewModal>
      }
      {openOptionsGroupModal &&
        <NewModal open alternateTitle title={duplicateTag ? 'Clone Option Group' : newOptionsGroup ? 'Create Options Group' : 'Edit Options Group'}
          onClose={() => {
            setOpen(undefined)
            setOpenedOptionsGroup(undefined)
            setSelectedTag(undefined)
            setDuplicateTag(undefined)
            setOpenOptionsGroupModal(false)
            setGroupElementIndex(undefined)
          }}>
          <OptionsGroup
            isWorking={optionsLoading}
            optionGroup={newOptionsGroup && !duplicateTag ? undefined : openedOptionsGroup as OptionGroup}
            onSubmit={newOptionsGroup || duplicateTag ? onCreateOptionsGroup : onChangeOptionsGroup}
            onDelete={duplicateTag ? undefined : onDeleteOptionsGroup}
          />
        </NewModal>
      }
      {openDeleteElement &&
        <NewModal
          noTopMargin
          open
          title={`Delete ${elementState.title}`}
          onClose={() => setOpenedDeleteElement(false)}
        >
          <DeleteElementModal
            call={call}
            firstStep={!step}
            element={elementState}
            handleDelete={handleDelete}
            handleCancel={() => setOpenedDeleteElement(false)}
          />
        </NewModal>
      }
    </>
  )
}
