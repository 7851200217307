import React, { useState, useEffect, useCallback, useRef } from 'react';
import { $InputLabel, $Input, $FormHelperText } from './styles';
import { $Italic } from '../newTypography';
import { SvgIcon, useDebounce, newTheme as theme } from '../';
import { $FormElementControl } from '../newFormElement/styles';
import { NumberInput } from './NumberInput';
export const Input = ({ title, hideLabel = false, hideItalic = false, description = null, boldDescription, name = 'input', error, errorMsg, debounce, allowFormat, decimal, ariaLabel = '', onChange = () => null, value = null, whiteBackground, endAdornment, startAdornment, withAutocomplete, ...rest }) => {
    const { type, required, multiline } = rest;
    const didMount = useRef(false);
    const [showPass, setShowPass] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const debouncedValue = useDebounce(inputValue, 300);
    const isTel = type === 'tel';
    const isNumber = type === 'number' || isTel;
    const isPassword = type === 'password';
    useEffect(() => {
        if (didMount.current && debounce && (value !== debouncedValue))
            onChange(name, debouncedValue !== null ? debouncedValue : (isNumber ? null : ''));
        else
            didMount.current = true;
    }, [debouncedValue]);
    useEffect(() => {
        if (didMount.current && debounce && (value !== inputValue))
            setInputValue(value);
    }, [value]);
    const handleInputChange = useCallback((e) => {
        const value = e.target.value;
        const cleanValue = (value && isNumber && value.slice(-1) === '.') ? value.slice(0, -1) : value;
        if (debounce)
            setInputValue(cleanValue);
        else
            onChange(name, cleanValue);
    }, [isNumber, debounce, name, onChange]);
    const displayValue = (debounce && didMount.current) ? inputValue : value;
    const Component = isNumber ? NumberInput : $Input;
    return (React.createElement($FormElementControl, null,
        !hideLabel &&
            React.createElement($InputLabel, { shrink: true },
                title,
                required && '*',
                !hideItalic &&
                    React.createElement($Italic, null, multiline ? 'textarea' : (type || 'text'))),
        React.createElement(Component, { ...rest, type: isPassword && showPass ? 'text' : type, key: name, value: displayValue, name: name, onChange: handleInputChange, hideLabel: hideLabel, error: error, isTel: isTel, allowFormat: allowFormat, decimal: decimal, whiteBackground: whiteBackground, autoComplete: withAutocomplete ? undefined : 'new-password', inputProps: { 'aria-label': ariaLabel, 'aria-autocomplete': withAutocomplete ? undefined : 'none' }, startAdornment: startAdornment, endAdornment: endAdornment || (isPassword ? (showPass ? React.createElement(SvgIcon, { clickable: true, noPadding: true, color: theme.colors.icons.visibility, name: 'change_visibility', onClick: () => setShowPass(false), size: 20 }) :
                React.createElement(SvgIcon, { clickable: true, noPadding: true, color: theme.colors.icons.visibility, name: 'visibility-off', onClick: () => setShowPass(true), size: 20 })) : undefined) }),
        (description || errorMsg) &&
            React.createElement($FormHelperText, { error: error, boldDescription: boldDescription }, errorMsg || description)));
};
