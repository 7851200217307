import { downloadFile, getResFilename } from 'prace-common-components'
import { clientApi } from '..'

export const downloadsApi = clientApi.injectEndpoints({
  endpoints: (build) => ({
    downloadProposalFiles: build.query<void, number>({
      query: (elementId) => ({
        url: `/cines/proposal/${elementId}`,
        responseHandler: async (res) =>
          res.status < 300 ? downloadFile(getResFilename(res), await res.blob()) : undefined,
        cache: 'no-cache',
      }),
    }),
    downloadAssignmentFiles: build.query<void, number>({
      query: (assignmentId) => ({
        url: `/download/assignment/${assignmentId}`,
        responseHandler: async (res) =>
          res.status < 300 ? downloadFile(getResFilename(res), await res.blob()) : undefined,
        cache: 'no-cache',
      }),
    }),
    downloadPdf: build.query<void, string>({
      query: (uid) => ({
        url: `/download/call/pdf/${uid}`,
        responseHandler: async (res) =>
          res.status < 300 ? downloadFile(getResFilename(res), await res.blob()) : undefined,
        cache: 'no-cache',
      }),
    }),
    downloadFile: build.query<void, { uid?: string; uploadId: number; elementName?: string}>({
      query: ({ uid, uploadId, elementName }) => ({
        url: `/download/upload/${uploadId}`,
        responseHandler: async (res) =>
          res.status < 300 ? downloadFile(getResFilename(res), await res.blob(), uid, elementName) : undefined,
        cache: 'no-cache',
      }),
    }),
    downloadCallBundle: build.query<void, number>({
      query: (callId) => ({
        url: `/download/call/${callId}`,
        responseHandler: async (res) =>
          res.status < 300 ? downloadFile(getResFilename(res), await res.blob()) : undefined,
        cache: 'no-cache',
      }),
    }),
    downloadApplicationReport: build.query<void, { id: number; format: string }>({
      query: ({ id, format }) => ({
        url: `/download/call/${id}/applications/${format}`,
        cache: 'no-cache',
      }),
    }),
  }),
})

export const {
  useLazyDownloadAssignmentFilesQuery,
  useLazyDownloadFileQuery,
  useLazyDownloadCallBundleQuery,
  useLazyDownloadPdfQuery,
  useLazyDownloadProposalFilesQuery,
  useLazyDownloadApplicationReportQuery,
} = downloadsApi
