import styled from 'styled-components'
import { DropBox } from 'prace-common-components'
import { $GridColumnContainer } from '../styles'
import Grid from '@material-ui/core/Grid'

export const $FlowContainer = styled($GridColumnContainer)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1)};
`

export const $DropBox = styled(DropBox)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`

export const $ElementContainer = styled(Grid)`
  position: relative;
`

export const $ConditionIcons = styled.div`
  display: flex;
  position: absolute;
  z-index: 99;
  top: ${({ theme }) => theme.spacing(0.5)};
  right: ${({ theme }) => theme.spacing(0.5)};
`
