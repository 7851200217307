import { useDrop } from 'react-dnd';
export function useDropContainer(acceptType, specs) {
    const [{ isOver, canDrop, handlerId }, drop] = useDrop({
        accept: acceptType,
        canDrop: specs?.canDropCallback,
        drop: specs?.dropCallback,
        hover: specs?.hoverCallback,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            handlerId: monitor.getHandlerId(),
        }),
    }, specs?.dependencies);
    return ([{ isOver, canDrop, handlerId }, drop]);
}
