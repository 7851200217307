import React, { FC, useState } from 'react'
import { Box, Divider } from '@material-ui/core'
import { Title, SvgIcon, NewTabs, Button, UserStatus, USER_STATUS, ROLES } from 'prace-common-components'
import { MutableUser, OnChangeUserDetails, TabId, tabs, Tabs, TargetField, targetFields, UserDetailsProps as Props } from './types'
import { $Drawer, $DrawerTop, $Container, $Box } from './styles'
import { Details } from './Details'
import { Notes } from './Notes'
import { Bank } from './Bank'


const assert: <T,>(value: unknown, predicate: boolean, message: string) => asserts value is T =
  (_, predicate, message) => {
    if (!predicate) throw new Error(message)
  }

export const UserDetails: FC<Props> = ({
  open,
  handleClose,
  user,
  onChangeUserDetails,
}) => {
  const [selectedTab, setSelectedTab] = useState<TabId>(Tabs.USER_DETAILS)
  const [mutableUser, setMutableUser] = useState<MutableUser | null>(user)

  // Update mutableUser when user changes from props
  // This is needed because we need to update mutableUser when user changes from props
  // but we do not want to update the user before clicking save
  React.useEffect(() => {
    setMutableUser(user)
  }, [user])
  
  const handleChangeTab = (newTab: number) => {
    assert<TabId>(newTab, Object.values(Tabs).includes(newTab as TabId), `Invalid tab: ${newTab}`)
    setSelectedTab(newTab)
  }
  const handleChange: OnChangeUserDetails<string> = (name, value) => {
    assert<TargetField>(name, Object.values(targetFields).includes(name as TargetField), `Invalid field: ${name}`)
    
    switch (name) {
      case targetFields.USER_REMARKS:
        assert<string>(value, typeof value === 'string', `Invalid user remarks: ${value}`)
        changeUserRemarks(value)
        break
      case targetFields.TAGS:
        assert<string[]>(value, Array.isArray(value), `Invalid tags: ${value}`)
        changeUserTags(value)
        break
      case targetFields.STATUS:
        assert<UserStatus>(value, typeof value === 'string' && Object.values(USER_STATUS).includes(value as UserStatus), `Invalid status: ${value}`)
        changeStatus(value)
        break
    }
  }

  const changeUserTags: OnChangeUserDetails<'tags'> = (values) => {
    setMutableUser((prevState) => {
      if (!prevState) return prevState
      return { ...prevState, tags: values, touched: true }
    })
  }

  const changeUserRemarks: OnChangeUserDetails<'userRemarks'> = (value) => {
    setMutableUser((prevState) => {
      if (!prevState) return prevState
      return { ...prevState, userRemarks: value, touched: true }
    })
  }
  
  const changeStatus: OnChangeUserDetails<'status'> = (value) => {
    setMutableUser((prevState) => {
      if (!prevState) return prevState
      return { ...prevState, status: value, touched: true }
    })
  }

  const handleChangeRole = (role: ROLES) => {
    if (!mutableUser) return
    onChangeUserDetails({
      ...mutableUser,
      role,
    })
  }
  
  const handleSave = () => {
    if (!mutableUser) return

    onChangeUserDetails({
      id: mutableUser.id,
      role: mutableUser.role,
      userRemarks: mutableUser.userRemarks,
      tags: mutableUser.tags,
      status: mutableUser.status,
    })
  }
  
  const close = () => {
    handleClose()
  }
  
  return (
    <$Drawer open={open} anchor='right' onClose={close}>
      {!mutableUser && <Box sx={{ p: 4 }}>Loading...</Box>}
      {mutableUser && (
        <>
          <$DrawerTop>
            <Title>{`${mutableUser.firstName || ''} ${mutableUser.lastName || ''}`}</Title>
            <SvgIcon name='close' size={20} clickable onClick={close} />
          </$DrawerTop>
          <NewTabs tabs={tabs} value={selectedTab} onChange={handleChangeTab} />
          <$Container>
            <Box mb={2}>
              <>
                {selectedTab === Tabs.USER_DETAILS && (
                  <Details user={mutableUser} onChangeRole={handleChangeRole} />
                )}
                {selectedTab === Tabs.TAGS_AND_NOTES && (
                  <Notes
                    user={mutableUser}
                    onChangeUserTags={handleChange}
                    onChangeUserRemarks={handleChange}
                    onChangeStatus={handleChange}
                  />
                )}
                {selectedTab === Tabs.BANK_DETAILS && (
                  <Bank user={mutableUser} />
                )}
              </>
            </Box>
            <Divider />
            {selectedTab !== Tabs.USER_DETAILS && <$Box>
              <Button variant='outlined' color='primary' onClick={handleClose}>
                Cancel
              </Button>
              <Button color='primary' disabled={!mutableUser?.touched} onClick={handleSave}>
                Save
              </Button>
            </$Box>}
          </$Container>  
        </>      
      )}
    </$Drawer>
  )
}
