import styled from 'styled-components';
import { Switch, FormGroup, FormControlLabel } from '@material-ui/core';
export const $FormGroup = styled(FormGroup) `
  .MuiFormControlLabel-root {
    width: fit-content;
    margin-left: 0;
    margin-right: ${({ theme, noMargin }) => noMargin ? 0 : theme.spacing(2)};
  }
`;
export const $FormControlLabel = styled(FormControlLabel) `
  .MuiTypography-body1 {
    font-size: ${({ theme }) => theme.fontSizes.checkBox};
    color:  ${({ theme, error }) => error ? theme.colors.feedback.error : '#757778'};
    margin-left: 8px;
  }
`;
export const $Switch = styled(Switch) `
  &.MuiSwitch-root {
    width: 30px;
    height: 15px;
    padding: 0;
    display: flex;
  }

  .MuiSwitch-switchBase {
    padding: 2px;
    color: white;
    &.Mui-checked {
      transform: translateX(14px);
      color: #fff;
      & + .MuiSwitch-track{
        opacity: 1;
        background-color: ${({ theme, error }) => error ? theme.colors.feedback.error : theme.colors.secondary};
        border-color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }

  .MuiSwitch-thumb {
    width: 11px;
    height: 11px;
    box-shadow: none;
  }

  .MuiSwitch-track {
    border: 1px solid grey;
    border-radius: 16 / 2;
    opacity: 1;
    background-color: ${({ theme, error }) => error ? theme.colors.feedback.error : 'grey'};
  }
`;
