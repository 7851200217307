import * as React from 'react'
import { ADD_ELEMENT_TYPE, AddFormElement, Search, Title } from 'prace-common-components'
import { Grid } from '@material-ui/core'
import { addElements } from 'constants/global'
import { AddElementProps } from './types'

export const AddElement: React.FC<AddElementProps> = ({ onEndDrag }) => {
  const [search, setSearch] = React.useState<string>('')

  const searchElements = React.useMemo(() => {
    return addElements.filter((add) => add.text.toLowerCase().includes(search.toLowerCase()))
  }, [search])

  return (
    <Grid
      container
      spacing={2}
      direction='column'
      justifyContent='center'
    >
      <Grid item>
        <Title noMargin>
          Element types
        </Title>
      </Grid>
      <Grid item>
        <Search
          open
          placeholder='Search Elements/Groups'
          onChange={(val: string) => setSearch(val)}
        />
      </Grid>
      {searchElements.map((el, i) =>
        <Grid item key={el.id}>
          <AddFormElement
            itemType={ADD_ELEMENT_TYPE}
            text={el.text}
            item={{ order: i, id: el.id, type: el.elementType }}
            onlyHandleDrag={false}
            endDrag={onEndDrag}
          />
        </Grid>,
      )}
    </Grid>
  )
}
