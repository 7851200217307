import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

export const $ModalContainer = styled.div`
  min-width: 500px;
`

export const $UserList = styled.div`
  border: 1px solid lightgray;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 3px;
  min-height: 10rem;
`

export const $UserListItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
`

export const $Container = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`

export const $Paragraph = styled.p``

export const $List = styled.ul`
  padding-left: ${({ theme }) => theme.spacing(3)};
  li {
    padding: 2px 0;
  }
`

export const $Description = styled.p`
  margin-top: 0;
  margin-bottom: ${({theme}) => theme.spacing(2)};
  color: ${({ theme }) => theme.colors.text.disabled};
  font-size: 1.1em;
`
