import * as React from 'react'
import { CardButton, Search } from 'prace-common-components'
import { $ModalContainer, $Button, $Grid, $Counter, $CardContainer, $CounterIcon } from './styles'
import { AddElementModalProps } from './types'
import { addElements } from 'constants/global'
import { AddElement } from 'store/calls/types'

export const AddElementModal: React.FC<AddElementModalProps> = ({ onAddElements }) => {
  const [selected, setSelected] = React.useState<string[]>([])
  const [search, setSearch] = React.useState<string>('')

  const handleRemove = (id: string) => {
    /* Remove one occurrence of this Id */
    setSelected((prevSelected) => {
      const idx = prevSelected.findIndex((elementId) => elementId == id)
      prevSelected.splice(idx,1)
      return [...prevSelected]
    })
  }

  const handleAdd = (id: string) => {
    setSelected((prevSelected) => ([...prevSelected, id]))
  }

  const handleAddElements = () => {
    const elements = selected.map((selectedId) => (addElements.find((add) => add.id == selectedId)))
    onAddElements(elements as AddElement[])
  }

  const searchElements = React.useMemo(() => {
    return addElements.filter((add) => add.text.toLowerCase().includes(search.toLowerCase()))
  }, [search])

  return (
    <$ModalContainer>
      <Search
        placeholder='Search Elements/Groups'
        onChange={(val: string) => setSearch(val)}
        open
      />
      <$Grid container justifyContent='space-around'>
        {searchElements.map((el) => {
          const idCount = selected.filter((id) => id == el.id).length
          return <$CardContainer key={el.id} item xs={3}>
            {idCount > 0 && <$Counter>
              <$CounterIcon button onClick={() => handleRemove(el.id)}>-</$CounterIcon>
              <$CounterIcon onClick={() => handleAdd(el.id)}>{idCount}</$CounterIcon>
            </$Counter>
            }
            <CardButton
              stacked={!!(idCount > 1)}
              title={el.text}
              type={el.elementType}
              selected={!!idCount}
              onClick={() => handleAdd(el.id)}
            />
          </$CardContainer>
        })}
      </$Grid>
      <$Button onClick={handleAddElements}>
        Add Elements
      </$Button>
    </$ModalContainer>
  )
}
