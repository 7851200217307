import { Option, OptionGroup } from 'prace-common-components'

export interface OptionsGroupProps {
  isWorking: boolean,
  optionGroup?: OptionGroup,
  onSubmit: (opts: OptionGroup) => void,
  onDelete?: (optionGroup: OptionGroup) => void,
}

export type MutableOption<T extends Option> = {
  id: number,
  option: T,
  state: 'unchanged' | 'added' | 'changed',
}

export const lockedTags = ['Yes/No', 'Gender', 'Nationalities', 'Countries'] as const
export type LockedTag = typeof lockedTags[number]
