import styled from 'styled-components';
import { FormHelperText, InputLabel, InputBase } from '@material-ui/core';
export const $InputLabel = styled(InputLabel) `
  margin-left: 4px;
  &.MuiFormLabel-root {
    color: ${({ theme }) => theme.colors.feedback.disable};
    font-size: 0.9em;
  }
  &.MuiInputLabel-formControl {
    position: relative;
    transform: unset;
  }
  &.MuiFormLabel-root.Mui-focused {
    color:${({ theme }) => theme.colors.feedback.disable};
  }
`;
export const $Input = styled(InputBase) `
  margin-top: ${({ theme, hideLabel }) => hideLabel ? 0 : theme.spacing(1)};
  &.MuiInputBase-root {
    position: relative;
    background-color: ${({ theme, disabled, whiteBackground }) => (disabled && !whiteBackground) ? theme.colors.button.disabled : theme.colors.white};
    border: ${({ error, theme }) => `1px solid ${error ? theme.colors.feedback.error : '#A8ACAC'}`};
    border-radius: ${({ theme }) => theme.dimensions.borderRadius};
    width: auto;
    padding: ${({ theme }) => theme.spacing(1)};
  }
  &.MuiInputBase-root.Mui-focused {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  .MuiInputBase-input {
    padding: 0;
    -moz-appearance: textfield;
    overflow-x: ${({ withScroll }) => withScroll && 'auto'};
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      display: none;
    }
  }
`;
export const $FormHelperText = styled(FormHelperText) `
  &.MuiFormHelperText-root {
    color: ${({ error, theme }) => error && theme.colors.feedback.error};
    font-weight: ${({ boldDescription }) => boldDescription && 'bold'};
    ${({ noMargin }) => !noMargin && 'margin: 0 14px;'}
    margin-top: 3px;
  }
`;
