import React, { useEffect, useState }  from 'react'
import { Grid } from '@material-ui/core'
import { FILTER_TYPE, Title, DataTable, DataRow, Search, NewButton, newTheme, Loading } from 'prace-common-components'
import { $Centers, $Paper, $ChipContainer, $Chip, $CenterName } from './styles'
import { CenterDetails } from './CenterDetails'
import { useSearchCentersMutation } from 'store/api/partitions'

const limit = 30

export const PartitionManager = () => {
  const [page, setPage] = useState(1)
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [selectedCenter, setSelectedCenter] = useState<number | undefined>(-1)
  const [searchCenters, { data: centers = { centers: [], count: 0 }, isUninitialized}] = useSearchCentersMutation()

  const pagination = { count: centers?.count, page }
  const stateCenters = centers?.centers

  useEffect(() => { setPage(1) }, [debouncedSearch])
  useEffect(() => {
    const getCenters = async () => {
      try {
        await searchCenters({ search: debouncedSearch || '', page: page - 1, pageSize: limit }).unwrap()
      } catch (err) {
        console.log(err)
      }
    }
    getCenters().catch((err) => console.log(err))
  }, [searchCenters, debouncedSearch, page])

  /** change current table page */
  const handleChangePage = (page: number) => setPage(page)
  const handleSearch = (value: string) => setDebouncedSearch(value)
  const handleRowClick = (idx: number) => () => setSelectedCenter(idx)
  const handleCenterClose = async (fetchAgain?: boolean) => {
    setSelectedCenter(-1)
    if(fetchAgain) {
      try {
        await searchCenters({ search: debouncedSearch || '', page: page - 1, pageSize: limit })
      } catch (err) {
        console.log(err)
      }
    }
  }

  const columns = React.useMemo(() => [
    {
      key: 'name',
      type: FILTER_TYPE.TEXT,
      name: 'Center Name',
      formatter(props: { row: DataRow }) {
        const { name, idx } = props.row
        return <$CenterName data-simplebar onClick={handleRowClick(idx)}>{name}</$CenterName>
      },
    },
    { key: 'name', type: FILTER_TYPE.NONE, name: 'CCR',
      formatter(props: { row: DataRow }) {
        const { user } = props.row
        return <div data-simplebar>{user?.firstName || '-'} {user?.lastName || '-'}</div>
      },
    },
    { key: 'email', type: FILTER_TYPE.NONE, name: 'CCR email',
      formatter(props: { row: DataRow }) {
        const { user } = props.row
        return <div data-simplebar>{user?.email || '-'}</div>
      },
    },
    { key: 'disabled', type: FILTER_TYPE.NONE, name: 'Status',
      formatter(props: { row: DataRow }) {
        const { disabled } = props.row
        return <$ChipContainer data-simplebar>
          <$Chip color={disabled ? newTheme.colors.secondaries.light : newTheme.colors.neutral.light}>{disabled ? 'Disabled' : 'Active'}</$Chip>
        </$ChipContainer>
      },
    },
  ], [])

  const dataRows = React.useMemo(() => (stateCenters?.map((center, idx: number) => ({
    ...center,
    idx,
  }))), [stateCenters])

  if(isUninitialized) return <Loading />

  const center = (selectedCenter !== undefined && selectedCenter > -1) ? stateCenters[selectedCenter] : undefined

  return (
    <>
      <$Centers>
        <$Paper>
          <Grid container alignItems='center' justifyContent='space-between'>
            <Title alternate fontSize={22}>Partition Manager</Title>
            <NewButton creator onClick={() => setSelectedCenter(undefined)}>Add Center</NewButton>
          </Grid>
          <Search open onChange={handleSearch} placeholder='Search centers'/>
        </$Paper>
        <DataTable
          rows={dataRows}
          columns={columns}
          total={pagination.count}
          rowsPerPage={limit}
          page={page}
          noFilterType
          onChangePage={handleChangePage}
          showEmptyImg={false}
        />
      </$Centers>
      <CenterDetails
        open={selectedCenter !== -1}
        center={center}
        handleClose={handleCenterClose}
      />
    </>
  )
}
