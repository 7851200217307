export const formatNumber = (cleanedValue) => {
    if (!cleanedValue)
        return '';
    const float = parseFloat(cleanedValue.replace(/[,]/g, '.')) || 0;
    const numberFormat = new Intl.NumberFormat();
    const parts = numberFormat.formatToParts(float);
    const thousandSeparator = parts.find((p) => p.type == 'group')?.value;
    let formattedNumber = new Intl.NumberFormat().format(float) || '';
    if (thousandSeparator)
        formattedNumber = formattedNumber.replace(thousandSeparator, ' ');
    return formattedNumber;
};
