import styled from 'styled-components';
export const $Badge = styled.div `
  display: flex;
  align-items: center;
  width: ${({ fullWidth }) => fullWidth ? '100%' : 'max-content'};
  background-color: ${({ theme, color, alternate }) => alternate ? theme.colors.white : color ? color : theme.colors.primary};
  color: ${({ color, alternate }) => alternate && color};
  border: ${({ color, alternate }) => alternate ? `1px solid ${color}` : 'unset'};
  border-radius: 12px;
  padding-left: 8px;
  margin: 0 25px 0 25px;
`;
