export const ADD_ELEMENT_TYPE = 'addElement';
export const PREVIEW_TYPE = 'preview';
export var ELEMENT_TYPES;
(function (ELEMENT_TYPES) {
    ELEMENT_TYPES["STEP"] = "step";
    ELEMENT_TYPES["FORM"] = "form";
    ELEMENT_TYPES["ELEMENT"] = "element";
    ELEMENT_TYPES["TEXT"] = "text";
    ELEMENT_TYPES["TEXT_AREA"] = "textarea";
    ELEMENT_TYPES["TITLE"] = "title";
    ELEMENT_TYPES["EMAIL"] = "email";
    ELEMENT_TYPES["NUMBER"] = "number";
    ELEMENT_TYPES["DATE"] = "date";
    ELEMENT_TYPES["BIRTHDATE"] = "birthdate";
    ELEMENT_TYPES["PHONE_NUMBER"] = "phone-number";
    ELEMENT_TYPES["INSTRUCTIONS"] = "instructions";
    ELEMENT_TYPES["PARTITIONS"] = "partitions";
    ELEMENT_TYPES["SELECT"] = "select";
    ELEMENT_TYPES["CHECKBOX"] = "checkbox";
    ELEMENT_TYPES["TOGGLE"] = "toggle";
    ELEMENT_TYPES["SELECT_PARTITION"] = "select-partition";
    ELEMENT_TYPES["UPLOAD"] = "upload";
    ELEMENT_TYPES["GROUP"] = "group";
})(ELEMENT_TYPES || (ELEMENT_TYPES = {}));
