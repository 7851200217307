import React from 'react'
import * as Sentry from '@sentry/react'
import { Routes, Route } from 'react-router-dom'
import { Routes as Routing } from 'constants/global'
import { NotFound404 } from 'prace-common-components'
import { Home } from 'pages/Home'
import { EditCallWizard } from 'pages/NewEditCall'
import { Legacy, CinesApp } from 'pages/Legacy'
import { Landing } from 'pages/Landing'
import { Calls } from 'pages/Calls'
import { Users } from 'pages/Users'
import { PartitionManager } from 'pages/PartitionManager'
import { ForgotPassword } from 'pages/Landing/views/ForgotPassword'
import { EmailTemplate, TemplateManager } from 'pages/NewTemplateManager'
import { CallApplication as NewCallApplication } from 'pages/newCallApplication'
import { PrivateRoute } from './PrivateRoute'
import { ValidateAdmin } from 'pages/Landing/views/Validate'
import { Admins } from 'pages/Admins'

// Custom Sentry Route component to allow parameterized transaction names in error reporting
export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const DashRoutes: React.FC<{isMobile?: boolean}> = ({ isMobile }) => (
  <SentryRoutes>
    <Route path={Routing.CALL(':id')} element={<EditCallWizard isMobile={isMobile} />} />
    <Route path={Routing.TEMPLATE(':id')} element={<EmailTemplate />} />
    <Route path={Routing.TEMPLATE_MANAGER} element={<TemplateManager isMobile={isMobile} />} />
    <Route path={Routing.APPLICATION(':uid')} element={<NewCallApplication isMobile={isMobile} />} />
    <Route path={Routing.PARTITION_MANAGER} element={<PrivateRoute><PartitionManager /></PrivateRoute>} />
    <Route path={Routing.ADMINS} element={<PrivateRoute><Admins /></PrivateRoute>} />
    <Route path={Routing.USERS(':id')} element={<PrivateRoute><Users /></PrivateRoute>} />
    <Route path={Routing.USER_TABLE} element={<PrivateRoute><Users /></PrivateRoute>} />
    <Route path={Routing.CALLS} element={<PrivateRoute><Calls isMobile={isMobile} /></PrivateRoute>} />
    <Route path={Routing.LEGACY} element={<PrivateRoute><Legacy /></PrivateRoute>} />
    <Route path={Routing.CINES(':id')} element={<PrivateRoute><CinesApp /></PrivateRoute>} />
    <Route path={Routing.HOME} element={<PrivateRoute><Home /></PrivateRoute>} />
    <Route path={Routing.APPLICATIONS} element={<PrivateRoute><Home /></PrivateRoute>} />
    <Route path='*' element={<NotFound404 />} />
  </SentryRoutes>
)

export const routes = () => (
  <SentryRoutes>
    <Route path={Routing.LOGIN} element={<Landing />} />
    <Route path={Routing.EMAIL_VERIFY} element={<ValidateAdmin />} />
    <Route path={Routing.FORGOT_PASSWORD_RESET} element={<ForgotPassword />} />
    <Route path={Routing.FORGOT_PASSWORD} element={<ForgotPassword />} />
    <Route path={Routing.FORGOT_PASSWORD_SENT} element={<ForgotPassword />} />
    <Route path='*' element={<NotFound404 />} />
  </SentryRoutes>
)
