import { FILTER_TYPE } from '../HeadFilter/types';
export function getColumnComparator(sortColumn, type) {
    switch (type) {
        case FILTER_TYPE.DATE:
            return (a, b) => {
                if (!a[sortColumn])
                    return 0;
                const aDate = new Date(a[sortColumn]);
                const bDate = new Date(b[sortColumn]);
                return aDate.getTime() - bDate.getTime();
            };
        case FILTER_TYPE.NUMBER:
            return (a, b) => {
                if (!a[sortColumn])
                    return 0;
                return a[sortColumn] - b[sortColumn];
            };
        default:
            return (a, b) => {
                if (!a[sortColumn])
                    return 0;
                return a[sortColumn].localeCompare(b[sortColumn]);
            };
    }
}
