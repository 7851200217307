import styled, { css } from 'styled-components';
import { KeyboardDateTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { $FormElementControl } from '../newFormElement/styles';
export const $FormControl = styled($FormElementControl) ``;
const DateStyling = (error, disabled) => css `
  width: 100%;

  .MuiInputBase-root {
    background-color: ${({ theme }) => disabled ? theme.colors.button.disabled : theme.colors.white};
    padding-right: 0px;
  }

  .MuiInputBase-input {
    padding: ${({ theme }) => theme.spacing(1)};
  }

  .MuiOutlinedInput-notchedOutline {
    top: 0px;
  }

  label {
    font-size: 0.9em;
    position: relative;
    transform: unset !important;
    transition: unset;
    color: ${({ theme }) => theme.colors.feedback.disable};
    &.MuiFormLabel-root.Mui-focused {
      color: ${({ theme }) => theme.colors.feedback.disable};
    }
  }

  legend {
    display: none;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.primary};
    border-width: 1px;
  }

  &>div {
    margin-top: ${({ theme }) => theme.spacing(1)};
    border-radius: ${({ theme }) => theme.dimensions.borderRadius};
    border-color: ${({ theme }) => error && theme.colors.feedback.error};
  }
`;
export const $KeyboardDateTimePicker = styled(KeyboardDateTimePicker) `
  ${({ error, disabled }) => DateStyling(error, disabled)};
`;
export const $KeyboardDatePicker = styled(KeyboardDatePicker) `
  ${({ error, disabled }) => DateStyling(error, disabled)};
`;
