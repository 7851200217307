import React from 'react';
import { $DrawerModal, $DrawerModalTop, $DrawerModalContainer } from './styles';
import { SvgIcon } from '../SvgIcon';
import { NewTabs } from '../newTabs';
import { Title } from '../newTypography';
export const DrawerModal = ({ children, title, handleClose, tabs = [], tab = 0, onChangeTab = () => null, open = false }) => React.createElement($DrawerModal, { open: open, anchor: 'right', onClose: handleClose },
    React.createElement($DrawerModalTop, null,
        React.createElement(Title, null, title),
        React.createElement(SvgIcon, { name: 'close', size: 20, clickable: true, onClick: handleClose })),
    tabs.length ? React.createElement(NewTabs, { tabs: tabs, value: tab, onChange: onChangeTab }) : null,
    React.createElement($DrawerModalContainer, null, children));
