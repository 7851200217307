import * as React from 'react'
import { StepFlowProps } from './types'
import { Tooltip, SvgIcon, FlowFormElement, Title, Grid, Sortable, FormElement, ADD_ELEMENT_TYPE, ELEMENT_TYPES, FORM_ELEMENT_TYPES } from 'prace-common-components'
import { $FlowContainer, $DropBox, $ConditionIcons, $ElementContainer } from './styles'
import { conditionsToolTips } from 'util/conditionUtils'

export const StepFlow: React.FC<StepFlowProps> = ({
  call,
  elements,
  onSelectElement,
  onMove,
  onDrop,
  onEndDrag,
  onDropBox,
  onClickBox,
  selectedIdx = [],
}) => {

  return (
    <$FlowContainer
      container
      direction='column'
      justifyContent='center'
    >
      {/* // TODO: This should not use the order to select */}
      {elements.map((el: FlowFormElement, i: number) =>
        <$ElementContainer
          xs={12}
          key={el.id}
          item
          onClick={(e: React.MouseEvent<HTMLDivElement>) => onSelectElement(e, i)}
        >
          <$ConditionIcons>
            {conditionsToolTips(call, el.effects || []).map((conditionTip) => 
              <Tooltip
                key={conditionTip.icon}
                placement='top'
                noIcon
                show
                text={conditionTip.text}
              >
                <div>
                  <SvgIcon color='secondary' name={conditionTip.icon} size='20' />
                </div>
              </Tooltip>,
            )}
          </$ConditionIcons>
          <Sortable
            item={{ id: el.id, type: el.elementType, order: i }}
            type={ELEMENT_TYPES.ELEMENT}
            dropType={[ELEMENT_TYPES.ELEMENT, ADD_ELEMENT_TYPE]}
            selected={selectedIdx.includes(i)}
            onMove={onMove}
            onDrop={onDrop}
            onEndDrag={() => onEndDrag(i)}
            noPointerEvents
          >
            {el.elementType === FORM_ELEMENT_TYPES.GROUP ?
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Title noMargin bold={false} type='group'>{el.title}</Title>
                </Grid>
                {el.flow.map(((groupEl: FlowFormElement) =>
                  <$ElementContainer item xs={12} key={groupEl.id}>
                    <$ConditionIcons>
                      {conditionsToolTips(call, groupEl.effects || []).map((conditionTip) => 
                        <Tooltip
                          key={conditionTip.icon}
                          placement='top'
                          noIcon
                          show
                          text={conditionTip.text}
                        >
                          <div>
                            <SvgIcon color='secondary' name={conditionTip.icon} size='20' />
                          </div>
                        </Tooltip>,
                      )}
                    </$ConditionIcons>
                    <FormElement
                      whiteBackground
                      element={{// @ts-ignore
                        ...groupEl,
                        type: groupEl.type as ELEMENT_TYPES.ELEMENT,
                        elementType: groupEl.elementType as FORM_ELEMENT_TYPES,
                        //elementProps: { options: groupEl.options },
                        disabled: true,
                      }} />
                  </$ElementContainer>
                ))}
              </Grid>
              :
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormElement
                    whiteBackground
                    element={{ // @ts-ignore
                      ...el,
                      type: el.type as ELEMENT_TYPES.ELEMENT,
                      elementType: el.elementType as FORM_ELEMENT_TYPES,
                      //elementProps: { options: el.options },
                      disabled: true,
                    }} />
                </Grid>
              </Grid>
            }
          </Sortable>
        </$ElementContainer>,
      )}
      <Grid item>
        <$DropBox type={ADD_ELEMENT_TYPE} onDrop={onDropBox} onClick={onClickBox}>
          Add or edit a form here
        </$DropBox>
      </Grid>
    </$FlowContainer>
  )
}
