import { FORM_ELEMENT_TYPES } from '../';
export const transformForms = (flowStep, assignment) => {
    const assignmentDatas = flowStep?.assignments[assignment]?.data || [];
    return flowStep.flow?.map((flowForm) => {
        return {
            ...flowForm,
            flow: flowForm.flow?.map(((formElement) => {
                if (formElement.elementType === FORM_ELEMENT_TYPES.GROUP) {
                    const groupInstanceElements = assignmentDatas.filter((data) => data.flowElementId == formElement.id);
                    const hiddenChilds = [];
                    let instances = groupInstanceElements.map((groupInstance) => {
                        const childInstances = formElement.flow?.map(((child, idx) => {
                            const foundData = assignmentDatas.find((data) => (data.flowElementId == child.id) && (data.dataGroupId == groupInstance.id) &&
                                (!data.input || data.input?.elementType !== FORM_ELEMENT_TYPES.GROUP));
                            if (foundData) {
                                return {
                                    ...foundData,
                                    elementType: foundData.input?.elementType,
                                    upload: foundData?.upload,
                                    isEditable: foundData?.isEditable,
                                    value: foundData.input?.elementType === FORM_ELEMENT_TYPES.MULTISELECT ||
                                        foundData.input?.elementType === FORM_ELEMENT_TYPES.MULTITEXT ?
                                        foundData.input?.values : foundData.input?.values[0],
                                };
                            }
                            else {
                                if (child.config?.hidden)
                                    hiddenChilds.push(child.id);
                                return {
                                    id: idx,
                                    flowElementId: child.id,
                                    assignmentId: flowStep.assignments[assignment]?.id,
                                    dataGroupId: groupInstance.id,
                                    dataGroup: { flowElementId: formElement.id },
                                    elementType: child.elementType,
                                    value: undefined,
                                    noData: true,
                                    hidden: child.config?.hidden,
                                };
                            }
                        }) || []).filter((child) => !child?.hidden);
                        return {
                            id: 0,
                            ...groupInstance,
                            value: groupInstance?.coi,
                            coi: groupInstance?.coi,
                            comment: groupInstance?.comment,
                            isEditable: groupInstance?.isEditable,
                            upload: groupInstance?.upload,
                            childInstances,
                        };
                    });
                    if (!instances.length)
                        instances = [{
                                id: 0,
                                flowElementId: formElement.id,
                                assignmentId: flowStep.assignments[assignment]?.id,
                                childInstances: formElement.flow?.map((child, idx) => ({
                                    id: idx,
                                    flowElementId: child.id,
                                    assignmentId: flowStep.assignments[assignment]?.id,
                                    dataGroup: { flowElementId: formElement.id },
                                    elementType: child.elementType,
                                    value: undefined,
                                    noData: true,
                                })) || [],
                            }];
                    return {
                        ...formElement,
                        flow: formElement.flow.filter((childEl) => !hiddenChilds.includes(childEl.id)),
                        hidden: formElement.config?.hidden && !groupInstanceElements.length,
                        instances,
                    };
                }
                else {
                    const assignmentEl = assignmentDatas.find((data) => data.flowElementId == formElement.id);
                    if (assignmentEl)
                        return {
                            ...formElement,
                            upload: assignmentEl?.upload,
                            isEditable: assignmentEl?.isEditable,
                            value: formElement.elementType === FORM_ELEMENT_TYPES.MULTISELECT ||
                                formElement.elementType === FORM_ELEMENT_TYPES.MULTITEXT ?
                                assignmentEl.input?.values
                                : assignmentEl.input?.values[0],
                        };
                    else
                        return {
                            ...formElement,
                            hidden: formElement.config?.hidden,
                            value: undefined,
                            noData: true,
                        };
                }
            }) || []).filter((flowElement) => !flowElement?.hidden),
        };
    }) || [];
};
