import React, { useState, useCallback, useEffect } from 'react';
import { $Input } from './styles';
import { BIGINT_MAX } from '../constants/global';
import { formatNumber } from '../utils/formatNumber';
const forceRange = (value) => {
    if (!value)
        return '';
    let returnValue = value;
    const float = parseFloat(value) || 0;
    if (float > BIGINT_MAX)
        returnValue = String(BIGINT_MAX);
    if (float < 0)
        returnValue = '0';
    return String(returnValue) || '';
};
export const NumberInput = ({ name = 'input', value, error, onChange = () => null, hideLabel, min, max, allowFormat = true, decimal = false, isTel = false, ...rest }) => {
    const [formatValue, setFormatValue] = useState((value || value === 0) ? formatNumber(String(value)) : '');
    useEffect(() => {
        const newFormatted = (value || value === 0) ? formatNumber(String(value)) : '';
        if ((newFormatted !== formatValue))
            setFormatValue(newFormatted);
    }, [value]);
    const handleNumberChange = useCallback((e) => {
        const inputValue = e.target.value;
        const onlyDigits = inputValue.replace(/[^0-9.,]+/g, '');
        const cleanNumberFormat = decimal ? onlyDigits : onlyDigits.replace(/[.]/g, '').replace(/[,]/g, '');
        const lastDigit = cleanNumberFormat.slice(-1);
        const lastDigitDecimal = lastDigit === '.' || lastDigit === ',';
        const numberValue = lastDigitDecimal ? cleanNumberFormat.slice(0, -1) : cleanNumberFormat;
        const limited = forceRange(numberValue);
        const newFormatValue = formatNumber(limited) + (lastDigitDecimal ? lastDigit : '');
        setFormatValue(newFormatValue);
        onChange({ target: { value: limited.replace(/[,]/g, '.') || null } });
    }, [decimal]);
    return (React.createElement($Input, { ...rest, key: name, type: 'text', autoComplete: 'new-password', value: isTel ? value : allowFormat ? formatValue : formatValue?.replace(/\s/g, ''), name: name, onChange: handleNumberChange, hideLabel: hideLabel, error: error }));
};
