export const getElementName = (flowElementId, call) => {
    let title = '';
    call.flow.every((flowStep) => {
        if (flowStep.id === flowElementId) {
            title = flowStep.title;
            return false;
        }
        return flowStep.flow.every((flowForm) => {
            if (flowForm.id === flowElementId) {
                title = flowForm.title;
                return false;
            }
            return flowForm.flow.every((flowElement) => {
                const found = flowElement.id === flowElementId;
                if (found)
                    title = flowElement.title;
                return !found;
            });
        });
    });
    return title;
};
