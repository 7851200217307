import React, { useRef } from 'react';
import { $FormControl, $InputFileHidden } from './styles';
import { SvgIcon } from '../SvgIcon';
import { Button } from '../NewButton';
import { Input } from '../newInput';
import { theme } from '../newTheme';
export const Upload = ({ hideItalic = false, title, value, disabled, required, description, error, errorMsg, onChange = () => null, onDownloadClick = () => null, }) => {
    const uploadRef = useRef(null);
    const handleOpenUpload = () => {
        if (uploadRef.current)
            uploadRef.current.click();
    };
    const handleUploadChange = ({ target }) => {
        const file = target.files ? target.files[0] : null;
        if (file)
            onChange('file', file);
    };
    const onRemoveFile = (e) => {
        e.stopPropagation();
        onChange('file', null);
    };
    const downloadBtn = disabled || (!!value && value.length > 0);
    return (React.createElement($FormControl, { description: description || errorMsg },
        React.createElement($InputFileHidden, { ref: uploadRef, type: 'file', accept: '.pdf', onChange: handleUploadChange }),
        React.createElement(Input, { readOnly: true, onClick: disabled ? undefined : handleOpenUpload, title: title, hideItalic: hideItalic, value: value || '', required: required, disabled: disabled, error: error, errorMsg: errorMsg, type: 'upload', description: description, placeholder: 'Click to choose a pdf', endAdornment: downloadBtn && !disabled ? React.createElement(SvgIcon, { clickable: true, noPadding: true, color: theme.colors.icons.visibility, name: 'cancel', onClick: onRemoveFile, size: 20 }) : undefined }),
        React.createElement(Button, { disabled: (downloadBtn && (!value || !value.length)) || (disabled && !downloadBtn), variant: downloadBtn ? 'outlined' : undefined, onClick: () => {
                if (downloadBtn)
                    onDownloadClick(value);
                else
                    handleOpenUpload();
            } }, downloadBtn ? 'Download' : 'Browse')));
};
