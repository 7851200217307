import styled from 'styled-components';
import { Select, MenuItem } from '@material-ui/core';
import { $FormElementControl } from '../newFormElement/styles';
export const $FormControl = styled($FormElementControl) ``;
export const $Select = styled(Select) `
  margin-top: ${({ theme, hideLabel }) => hideLabel ? 0 : theme.spacing(1)};
  &.MuiPaper-root {
    transition: none !important;
  }
  input {
    display: none;
  }
  svg {
    margin-right: ${({ theme, multiline }) => multiline ? 0 : theme.spacing(1)};
  }

  &.MuiInputBase-root {
    border: ${({ noBorder }) => noBorder && 'unset'};
    border-color: ${({ error, theme }) => error && theme.colors.feedback.error};
    width: ${({ noBorder }) => noBorder ? 'fit-content' : 'auto'};
    min-height: 37px;
    padding: 0;
    &>.MuiSelect-root {
      padding: ${({ theme, multiline }) => multiline ? `0px ${theme.spacing(3)} 0px 0px` : `0px ${theme.spacing(0.75)}`};
    }
  }
`;
export const $MenuItem = styled(MenuItem) `
  &.MuiListItem-root {
    display: flex;
    justify-content: space-between;
  }
`;
export const $SelectPlaceholder = styled.span `
  color: ${({ noSelection }) => noSelection && '#A8ACAC'};
`;
