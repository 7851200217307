import styled from 'styled-components';
import { FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import { $FormElementControl } from '../newFormElement/styles';
export const $FormControl = styled($FormElementControl) `
  &.MuiFormControl-root {
    display: block;
  }
`;
export const $FormControlLabel = styled(FormControlLabel) `
  &.MuiFormControlLabel-root {
    margin-left: 0;
  }
  .MuiFormControlLabel-label {
    align-items: center;
    font-weight: 600;
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.54);
  }
  .MuiCheckbox-root {
    color:  ${({ theme, error }) => error && theme.colors.feedback.error};
    padding: ${({ theme }) => `0 ${theme.spacing(0.75)}`};
  }
`;
export const $Checkbox = styled(Checkbox) `
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color:  ${({ theme, error, disabled, alternate }) => error ? theme.colors.feedback.error : disabled ? theme.colors.feedback.disable : (alternate ? theme.colors.primary : theme.colors.secondary)};
  }
`;
export const $FormHelperText = styled(FormHelperText) `
  &.MuiFormHelperText-root {
    color: ${({ error, theme }) => error && theme.colors.feedback.error};
    margin: 0 24px;
  }
`;
