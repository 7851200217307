import styled from 'styled-components'
import { NewButton, SvgIcon } from 'prace-common-components'
import Grid from '@material-ui/core/Grid'

//TODO: See if some of these styles can be merged with others (from EditCallModal)
export const $Button = styled(NewButton)`
  &.MuiButtonBase-root {
    margin-left: auto;
  }
`


export const $RoleContainer = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(2)};
  &.MuiGrid-container {
    overflow-y: auto;
    overflow-x: hidden;
  }
`

export const $CutOffContainer = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(3)};
  gap: ${({ theme }) => theme.spacing(2)};
  &.MuiGrid-container {
    overflow-y: auto;
    overflow-x: hidden;
  }
`

export const $Container = styled(Grid)`
  &.MuiGrid-container {
    padding: ${({ theme }) => `0 ${theme.spacing(1)}`};
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
  h3 {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`

export const $ModalContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
`

export const $AssignmentList = styled.ul`
  max-height: 100px;
  overflow-y: auto;
`

export const $AdminsList = styled.ul`
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  max-height: 100px;
  overflow-y: auto;
`

export const $ListsContainer = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  &.MuiGrid-container {
    padding: ${({ theme }) => `0 ${theme.spacing(1)}`};
    overflow-y: auto;
    overflow-x: hidden;
  }
  & > div {
    margin: 0;
  }  
`

export const $GreyItem = styled.li`
  color: ${({ theme }) => theme.colors.text.disabled};
`

export const $SwitchContainer = styled(Grid)`
  height: 100%;
  & > div {
    width: fit-content;
  }
`

export const $ArrowIcon = styled(SvgIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`

export const $CopyRolesContainer = styled.div`
  h4 {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`

export const $NameColumn = styled.div<{ disabled?: boolean }>`
  ${({ theme, disabled }) => `
    color: ${disabled ? theme.colors.text.disabled : theme.colors.text.primary};
  `}
`
