import React from 'react';
import { $Navigation, $Tabs, $Tab, $MainNav, $ProfileTitle, $LoginContainer, $Login, $MenuWrapper, } from './styles';
import { ADMIN_PORTAL_ROLES, Badge, Dropdown, Logo, MobileMenu, NewButton, ROLES } from '../';
import { $SvgIcon } from '../Dropdown/styles';
export const Navigation = ({ scrolled, tabs, name, initialTab, selectedTab, selectTab, onTabChange, handleLogoClick, user, userMenuItems, showBackButton, backButtonLabel, onGoBackCLick, handleSignUp, handleLogin, isMobile, noMatch, logo, ...rest }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    React.useEffect(() => {
        if (!noMatch)
            selectTab(initialTab);
    }, [initialTab, noMatch, selectTab]);
    const handleTabClick = (route, idx) => (evt) => {
        if (evt.type === 'auxclick') {
            open(route, '_blank');
            return;
        }
        setMobileOpen(false);
        selectTab(idx);
        onTabChange(route);
    };
    const isAdmin = user ? ADMIN_PORTAL_ROLES.includes(user.role) : false;
    const mobileMenu = tabs.map((tab, idx) => ({
        label: tab.label, align: 'flex-end', onClick: handleTabClick(tab.route, idx),
    }));
    let mobileUserMenuItems = (userMenuItems || []).filter(((item) => item.label !== 'Profile'));
    if (user) {
        if (!isAdmin)
            mobileMenu.push(({ label: 'Profile', align: 'flex-end', onClick: handleTabClick('/profile', 0) }));
    }
    else {
        mobileUserMenuItems = (mobileUserMenuItems || []).filter(((item) => item.label !== 'Logout'));
        mobileUserMenuItems.push(({ label: 'Login', icon: 'account', align: 'flex-end', onClick: handleLogin }));
        mobileUserMenuItems.push(({ label: 'Register', icon: 'person-add', align: 'flex-end', onClick: handleSignUp }));
    }
    return (React.createElement($Navigation, { id: 'Navigation', ...rest },
        React.createElement($MainNav, null,
            React.createElement(Logo, { logo: logo, onClick: handleLogoClick }),
            !isMobile && React.createElement($Tabs, null, tabs.map((tab, idx) => (React.createElement($Tab, { key: `nav-tab-${idx}`, "data-testid": `nav-tab-${idx}`, selected: selectedTab === idx, onClick: handleTabClick(tab.route, idx), onAuxClick: handleTabClick(tab.route, idx) }, tab.label)))),
            isMobile ?
                React.createElement($MenuWrapper, null,
                    React.createElement($SvgIcon, { name: 'menu', size: 32, clickable: true, onClick: () => setMobileOpen((open) => !open) }))
                :
                    user ?
                        React.createElement(Badge, null,
                            React.createElement($ProfileTitle, null, isAdmin ? (user.role === ROLES.PRO ? 'PRO' : 'Admin')
                                : (user?.firstName || user?.email ? user?.firstName ?? user?.email : '')),
                            React.createElement(Dropdown, { icon: 'account', iconSize: 26, items: userMenuItems || [] }))
                        :
                            React.createElement($LoginContainer, null,
                                React.createElement($Login, { onClick: handleLogin }, "Login"),
                                React.createElement(NewButton, { noBackground: true, btncolor: '#fff', variant: 'outlined', onClick: handleSignUp }, "Sign Up"))),
        mobileOpen && React.createElement(MobileMenu, { open: true, onClose: () => null, mobileMenu: mobileMenu, userMenuItems: mobileUserMenuItems })));
};
