import { Grow } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useCallback } from 'react';
import { $Wrapper, $Dropdown, $Label, $Menu, $MenuItem, $SvgIcon } from './styles';
export const Dropdown = ({ icon = 'account', iconSize = 24, fontSize, items, label }) => {
    const [open, openMenu] = useState(false);
    const [showLabel, setShowLabel] = useState(false);
    const menuRef = useRef(null);
    const handleMenuClick = useCallback(() => openMenu(!open), [open]);
    const handleEventClick = useCallback(({ target }) => {
        const parent = menuRef.current;
        if (parent && target && !parent.contains(target)) {
            handleMenuClick();
        }
    }, [handleMenuClick]);
    useEffect(() => {
        if (open) {
            document.addEventListener('click', handleEventClick, false);
        }
        return () => {
            document.removeEventListener('click', handleEventClick, false);
        };
    }, [open, handleEventClick]);
    return (React.createElement($Dropdown, { ref: menuRef },
        React.createElement($Wrapper, { onClick: () => handleMenuClick(), onMouseEnter: () => setShowLabel(true), onMouseLeave: () => setShowLabel(false) },
            label &&
                React.createElement(Grow, { in: showLabel, mountOnEnter: true, unmountOnExit: true },
                    React.createElement($Label, { fontSize: fontSize }, label)),
            React.createElement($SvgIcon, { name: icon, size: iconSize, clickable: true })),
        React.createElement($Menu, { open: open }, items.map((item, idx) => React.createElement($MenuItem, { key: `${item}-${idx}`, onClick: item.onClick, align: item.align }, item.label)))));
};
