import styled, { css } from 'styled-components';
import InputBase from '@material-ui/core/InputBase';
import { $SvgWrapper } from '../SvgIcon';
export const $Search = styled.div `
  position: relative;
`;
const opened = () => css `
  width: 100%;
  .MuiInputBase-input {
    transition: padding 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    padding-left: ${({ theme }) => theme.spacing(2)};;
  }
`;
export const $InputBase = styled(InputBase) `
  padding: ${({ theme }) => theme.spacing(1)};
  padding-left: ${({ theme }) => `calc(1em + ${theme.spacing(4)}px)`};
  transition: width 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  justify-content: center;
  cursor: pointer;
  color: inherit;
  border: solid 1px #A8ACAC;
  ${({ open }) => open && opened()};
  &.Mui-focused {
    ${opened()};
  }
  ${$SvgWrapper} {
    pointer-events: none;
  }
`;
export const $SearchIconContainer = styled.div `
  padding: ${({ theme }) => `0 ${theme.spacing(2)}`};
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
