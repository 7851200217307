import React, { createElement, useCallback } from 'react';
import Flags from 'country-flag-icons/react/3x2';
import { hasFlag } from 'country-flag-icons';
import flagsConfig from './phone-codes.json';
import { Input } from '../newInput';
import { $Input } from '../newInput/styles';
import { $Label, $SvgIcon, $CountrySelect, $MenuItem } from './styles';
import { $SelectPlaceholder } from '../newSelect/styles';
import { UsCaFlag } from '../SvgIcon';
const flagsOptions = flagsConfig.reduce((result, flag, idx) => {
    if (idx === 0)
        result.push({
            value: 0,
            code: '+0',
            display: React.createElement(React.Fragment, null),
            label: '',
        });
    if (!hasFlag(flag.code) || !flag.name) {
        result.push({
            value: idx + 1,
            code: '+0',
            display: React.createElement(React.Fragment, null),
            label: '',
        });
        return result;
    }
    const flagComponent = createElement(['CA', 'US'].includes(flag.code) ? UsCaFlag :
        Flags[(flag.code)], { key: 'flag_' + idx + flag.code, title: flag.name });
    result.push({
        value: idx + 1,
        code: flag.dial_code,
        display: createElement($Label, { key: 'flag_label_' + idx + flag.code }, [flagComponent, `${flag.dial_code}`]),
        label: createElement($Label, { key: 'flag_label_' + idx + flag.code }, [flagComponent, `${flag.name} (${flag.dial_code})`]),
    });
    return result;
}, []);
export const PhoneInput = ({ title = '', value = '', placeholder, name = 'phone', hideItalic = false, onChange = () => null, error, errorMsg, disabled, ...rest }) => {
    const values = String(value).split('|');
    const dialCode = values.length > 1 ? values[0] : '+32';
    const initValue = values.length > 1 ? values[1] || '' : '';
    const countryIdx = flagsOptions.findIndex((country) => country.code == dialCode);
    const handleInputChange = useCallback((name, value) => {
        onChange(name, dialCode + '|' + (value || ''));
    }, [dialCode]);
    const handleCountryChange = (initValue) => (e) => {
        e.preventDefault();
        const newCountry = flagsOptions[Number(e.target.value)].code;
        onChange(name, newCountry + '|' + (initValue || ''));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Input, { title: title, name: name, type: 'tel', hideItalic: hideItalic, error: error, errorMsg: errorMsg, value: initValue, placeholder: placeholder, onChange: handleInputChange, required: rest.required, disabled: disabled, debounce: true, allowFormat: false, startAdornment: React.createElement($CountrySelect, { noBorder: true, hideLabel: true, disabled: disabled, value: countryIdx, onChange: handleCountryChange(initValue), input: React.createElement($Input, null), IconComponent: ({ ...rest }) => React.createElement($SvgIcon, { name: 'chevron-down', size: 24, ...rest }), renderValue: (v) => React.createElement($SelectPlaceholder, null, flagsOptions[v]?.display), MenuProps: {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                } }, flagsOptions.map((item, i) => React.createElement($MenuItem, { noPadding: !item.label, key: i + String(item.value), value: item.value }, item.label))) })));
};
