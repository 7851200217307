import { Automation, Condition, FlowCall, FlowElement, withLoading, withAutoSave } from 'prace-common-components'
import { clientApi } from '../..'
import { AddEffectPayload, CallElement, DeleteAutomationPayload, UpdateElementPayload, AddAutomationPayload } from './types'

export const elementsApi = clientApi.injectEndpoints({
  endpoints: (build) => ({
    getFlowElement: build.query<FlowElement, FlowElement['id']>({
      query: (id) => ({ url: `/v2/elements/${id}` }),
    }),
    createFlowElements: build.mutation<CallElement[], { callId?: FlowCall['id']; parentId?: FlowElement['id']; flowElements: CallElement[] }>({
      ...withLoading,
      query: (body) => ({
        url: '/v2/elements/',
        method: 'POST',
        body,
      }),
    }),
    addAutomation: build.mutation<AddAutomationPayload, AddAutomationPayload>({
      ...withLoading,
      query: ({ id, automation }) => ({
        url: `/v2/elements/${id}/automation`,
        method: 'POST',
        body: automation,
      }),
      transformResponse: (res: Automation, _meta, args) => ({
        id: args.id,
        automation: res,
      }),
    }),
    addEffect: build.mutation<Condition, AddEffectPayload>({
      ...withLoading,
      query: ({ id, ...body}) => ({
        url: `/v2/elements/${id}/effect`,
        method: 'POST',
        body,
      }),
    }),
    updateElement: build.mutation<FlowElement, UpdateElementPayload>({
      ...withAutoSave,
      query: ({ id, ...body}) => ({
        url: `/v2/elements/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteAutomation: build.mutation<DeleteAutomationPayload, DeleteAutomationPayload>({
      ...withLoading,
      query: ({ id }) => ({
        url: `/v2/elements/automations/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (_res, _meta, args) => args,
    }),
    deleteEffects: build.mutation<void, Condition['id']>({
      ...withLoading,
      query: (id) => ({
        url: `/v2/elements/effects/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteFlowElements: build.mutation<void, {ids: FlowElement['id'][]}>({
      ...withLoading,
      query: (body) => ({
        url: '/v2/elements/',
        method: 'DELETE',
        body,
      }),
    }),
  }),
})

export const {
  useGetFlowElementQuery,
  useCreateFlowElementsMutation,
  useAddAutomationMutation,
  useAddEffectMutation,
  useUpdateElementMutation,
  useDeleteAutomationMutation,
  useDeleteEffectsMutation,
  useDeleteFlowElementsMutation,
} = elementsApi
