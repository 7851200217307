import React, { FC, useState } from 'react'
import { Title, ActivityDates, AssignmentData, ApplicationDocuments, AssignmentActivity, FLOW_STATUS_NAMES, NewButton, Collapse, NewModal, DrawerModal, Divider, ConfirmAction, FlowAssignment, FLOW_STATUS, Tooltip, ROLES, ROLE_NAME_INITIALS } from 'prace-common-components'
import Grid from '@material-ui/core/Grid'
import { $ReviewContainer, $ReviewEntry, $Param, $Divider, $ParamName, $AddAssignment, $ApplicationActions, $AssignmentContainer, $CollapseContainer, $TimelineContainer, $TimelineEntry, $EmailTitle } from './styles'
import { DeadlineModal } from 'components/DeadlineModal'
import { ReviewModal } from 'components/ReviewModal'
import { RequestChanges } from 'components/RequestChanges'
import { ApplicationActionsProps } from './types'
import { Link } from 'react-router-dom'
import { Routes } from 'constants/global'
import { ChangeUserVisibilityModal } from 'components/ChangeUserVisibilityModal'
import { useSearchUsersMutation } from 'store/api/users'
import { UserSuggestion } from 'store/api/users/types'
import { useTheme } from 'styled-components'

export const ApplicationActions: FC<ApplicationActionsProps> = (
  {
    handleSelect,
    handleReview,
    assignments,
    handleDownloadClick,
    proposalId = '',
    applicationId,
    documents,
    handleDeadline,
    handleOpenAssign,
    handleOpenInvite,
    handleRevoke,
    setLeadAssignment,
    selectedAssignment,
    step,
    call,
    hasLead,
    assignmentPartition,
    handleRequestChanges,
    downloadConsolidated,
    onChangeUserVisibility,
  },
) => {
  const theme = useTheme() as { colors: { primary: string; secondary: string }}
  const [timelineOpen, setTimelineOpen] = useState<boolean>(false)
  const [deadlineOpen, setDeadlineOpen] = useState<FlowAssignment | undefined>(undefined)
  const [requestChangesOpen, setRequestChangesOpen] = useState<FlowAssignment | undefined>(undefined)
  const [reviewOpen, setReviewOpen] = useState<number | undefined>(undefined)
  const [documentsOpen, setDocumentsOpen] = useState<boolean>(false)
  const [changeVisibilityOpen, setChangeVisibilityOpen] = useState<boolean>(false)
  const [timelineUsers, setTimelineUsers] = React.useState<UserSuggestion[]>([])
  const [searchUsers] = useSearchUsersMutation()

  /* FIXME: THIS ACTIVITY INFO SHOULD COME FROM BE IN CASE OF ADMIN REQUESTING */
  /* TODO: This request can be done fewer times, one time for each step */
  /* Instead of doing it everytime the user changes step */
  React.useEffect(() => {
    /* 
        let search: string[] = []
    assignments.forEach((assignment) => {
      const assignmentUserIds: string[] = assignment.activities.map((activity) => String(activity.authorId))
      search = [...search, ...assignmentUserIds]
    })
    if(search.length) dispatch(USERS_SUGGESTIONS_EVENTS.LOAD_USER_SUGGESTIONS, { search, page: 1 })
    
    */
    const getActivityUsers = async () => {
      let search: string[] = []
      assignments.forEach((assignment) => {
        const assignmentUserIds: string[] = assignment.activities.map((activity) => String(activity.authorId))
        search = [...search, ...assignmentUserIds]
      })
      if(search.length) {
        const data = await searchUsers({
          search,
          applicationId,
          page: 0,
          pageSize: 20,
        }).unwrap()
        setTimelineUsers((data.users || []) as unknown as UserSuggestion[])
      }
    }
    getActivityUsers().catch((err) => console.log(err))
  }, [assignments, searchUsers])

  const handleSelectAssignment = (assignmentIdx: number) => {
    handleSelect(assignmentIdx, 'assignment')
  }

  const handleDeadlineSubmit = (deadlineOpenOd: number, deadline: string) => {
    handleDeadline(deadlineOpenOd, deadline)
    setDeadlineOpen(undefined)
  }

  const handleChangesRequest = (assignment: FlowAssignment, elementIds: number[],deadline?: string, reason?:string) => {
    const { data = [], id = 0 } = assignment
    let editableElementIds: number[] = []
    const editableNewElementIds: number[] = []
    elementIds.forEach((elementId) => {
      const foundDatas = data.filter(
        (assignmentData: AssignmentData) => elementId == Number(assignmentData.flowElementId) ||
          elementId == Number(assignmentData.dataGroupId) ||
          elementId == Number(assignmentData.dataGroup?.flowElementId || 0))
        .map((assignmentData: AssignmentData) => assignmentData.id as number)
      if(foundDatas.length) editableElementIds = editableElementIds.concat(foundDatas)
      else editableNewElementIds.push(elementId)
    })

    handleRequestChanges(id, editableElementIds, editableNewElementIds, deadline, reason)
    setRequestChangesOpen(undefined)
  }

  const handleAssignmentReview = (assignmentId: number, review: string, deadline?: string, reason?: string) => {
    handleReview(assignmentId, review, deadline, reason)
    setReviewOpen(undefined)
  }
 
  const handleChangeVisibility = async (ids: number[]) => {
    await onChangeUserVisibility(ids)
    setChangeVisibilityOpen(false)
  }

  const assignmentLength = assignments.length

  return (
    <$ApplicationActions container spacing={2}>
      {assignmentLength ?
        <Grid item xs={12}>
          <Title alternate noMargin>Assignments</Title>
          {assignments.map((assignment, assignmentIdx: number) => {
            const activityReasons = assignment.activities?.filter(
              (act) => (!!act?.reason && act?.status !== FLOW_STATUS.ACCEPTED)) || []
            return <$AssignmentContainer container key={assignmentIdx}>
              <Tooltip placement='top' arrow show={selectedAssignment != assignmentIdx} text={assignment.owner?.email}>
                <div>
                  <Title
                    subTitle
                    onClick={() => handleSelectAssignment(assignmentIdx)}
                    noMargin
                    icon='circle'
                    hasBack
                    multiline
                    bold={selectedAssignment == assignmentIdx}
                    iconProps={{color: theme.colors.secondary, size: 10, clickable: false}}
                    color={selectedAssignment == assignmentIdx ? theme.colors.secondary : undefined}>
                    {hasLead && assignment.isLead && 'Lead - '}
                    {assignment.owner?.firstName ?
                      `${assignment.owner?.firstName || ''} ${assignment.owner?.lastName || ''} (${assignment.owner?.role ? ROLE_NAME_INITIALS[assignment.owner.role as ROLES] : 'Admin Assignment'})`
                      : `#${assignmentIdx + 1} ${assignment.owner?.role ? ROLE_NAME_INITIALS[assignment.owner.role as ROLES] : 'Admin Assignment'}`}
                    {selectedAssignment == assignmentIdx && <$EmailTitle> {assignment.owner?.email} </$EmailTitle>}
                    <div>{FLOW_STATUS_NAMES[assignment.status]}</div>
                  </Title>
                </div>
              </Tooltip>
              {selectedAssignment == assignmentIdx &&
              <>
                {assignmentPartition &&
                  <$Param>Partition: <$ParamName>{assignmentPartition.name || ''}</$ParamName></$Param>
                }
                <$Param>ID: <$ParamName>{assignment?.id || ''}</$ParamName></$Param>
                <$CollapseContainer>
                  <Collapse
                    title='Timeline'
                    open={timelineOpen}
                    handleOpen={setTimelineOpen}
                    icon='circle'
                    iconProps={{color: theme.colors.secondary, size: 10, clickable: false}}
                  >
                    <$TimelineContainer container direction='column' spacing={1}>
                      {assignment.activities.map((activity: AssignmentActivity) => {
                        const author = timelineUsers.find((user) => user.id === activity.authorId)
                        return <Grid item xs key={activity.id}>
                          <$TimelineEntry>
                            {`${(new Date(activity.createdAt)).toLocaleDateString()} - ${FLOW_STATUS_NAMES[activity.status]} by `}
                            {author ?
                              <Link target='_blank' rel='noopener noreferrer' to={Routes.USERS(author?.id)}>{(author?.firstName || author?.lastName) ? `${author?.firstName} ${author?.lastName}` : author?.email}</Link>
                              : 'an action'}
                          </$TimelineEntry>
                        </Grid>
                      })}
                    </$TimelineContainer>
                  </Collapse>
                </$CollapseContainer>
                <Grid container direction='column' spacing={1}>
                  {!!activityReasons.length && <>
                    <$Divider />
                    <Title subTitle noMargin>Review Comments</Title>
                    <$ReviewContainer>
                      {activityReasons.map((activity) => <Grid item xs key={activity.id}>
                        <$ReviewEntry>
                          <b>{`${new Date(activity.createdAt).toLocaleDateString()} ${FLOW_STATUS_NAMES[activity.status]}: `}</b>{activity?.reason || ''}
                        </$ReviewEntry>
                      </Grid>)}
                    </$ReviewContainer>
                  </>}
                  <Grid item xs>
                    <NewButton fullWidth variant='outlined' onClick={() => setDeadlineOpen(assignment)}>Change Deadline</NewButton>
                  </Grid>
                  <Grid item xs>
                    <NewButton fullWidth variant='outlined' onClick={() => setRequestChangesOpen(assignment)}>Request Changes</NewButton>
                  </Grid>
                  <Grid item xs>
                    <NewButton fullWidth variant='outlined' onClick={() => setReviewOpen(assignment.id)}>Review</NewButton>
                  </Grid>
                  {assignment.status !== FLOW_STATUS.REFUSED && <Grid item xs>
                    <ConfirmAction
                      title={assignment.status === FLOW_STATUS.REVOKED ? 'Unrevoke assignment' : 'Revoke assignment'}
                      question={assignment.isLead ? 'You are revoking the lead assignment. There will be no lead assignment afther this action.' : undefined}
                      description={`This action will ${assignment.status === FLOW_STATUS.REVOKED ? 'add' : 'remove'} the user from the assignment.`}
                      onConfirm={() => handleRevoke(assignment.id)}
                    >
                      <NewButton fullWidth error variant='outlined'>{assignment.status === FLOW_STATUS.REVOKED ? 'Unrevoke' : 'Revoke'}</NewButton>
                    </ConfirmAction>
                  </Grid>}
                  {hasLead && !assignment.isLead &&
                    <Grid item xs>
                      <NewButton fullWidth variant='outlined' onClick={() => setLeadAssignment(assignment)}>Make Lead Assignment</NewButton>
                    </Grid>
                  }
                  <Grid item xs>
                    <NewButton
                      fullWidth
                      variant='outlined'
                      onClick={() => setChangeVisibilityOpen(true)}
                    >
                      Change Visibility
                    </NewButton>
                  </Grid>
                  {changeVisibilityOpen &&
                    <NewModal
                      noTopMargin
                      open={changeVisibilityOpen}
                      title={'Edit View Access'}
                      onClose={() => setChangeVisibilityOpen(false)}
                    >
                      <ChangeUserVisibilityModal
                        callTitle={call.title}
                        permissions={call.flow?.length ? call.flow[step]?.permissions : undefined}
                        assignment={assignment as FlowAssignment}
                        handleSave={(ids) => handleChangeVisibility(ids)}
                        handleCancel={() => setChangeVisibilityOpen(false)}
                      />
                    </NewModal>
                  }
                  {(assignment.submittedAt || assignment.deadline) && <Grid item xs>
                    <ActivityDates
                      deadline={
                        (assignment.submittedAt && (assignment.status !== FLOW_STATUS.CHANGES_REQUESTED)) 
                        || !assignment.deadline ? undefined : new Date(assignment.deadline)}
                      submit={assignment.submittedAt ? new Date(assignment.submittedAt) : undefined}
                    />
                  </Grid>}
                </Grid>
              </>
              }
            </$AssignmentContainer>
          })}
          <Divider />
        </Grid>
        : null }
      <$AddAssignment>
        <NewButton creator variant='text' onClick={handleOpenAssign}>
            Add Assignment
        </NewButton>
        <NewButton creator variant='text' onClick={handleOpenInvite}>
            Invite User
        </NewButton>
      </$AddAssignment>
      <Grid item xs={12}>
        <Title subTitle onClick={() => setDocumentsOpen(true)} color={theme.colors.primary} hasBack iconProps={{color: theme.colors.primary, clickable: false}} noMargin icon='documents'>Documents</Title>
      </Grid>
      {deadlineOpen &&
        <NewModal open alternateTitle title='Change Deadline' onClose={() => setDeadlineOpen(undefined)}>
          <DeadlineModal
            currentDeadline={deadlineOpen.deadline}
            handleDeadline={(deadline: string) => handleDeadlineSubmit(deadlineOpen.id, deadline)}
            handleCancel={() => setDeadlineOpen(undefined)}
          />
        </NewModal>
      }

      {/* Only appears if tthe assignment has been submitted? */}
      {requestChangesOpen &&
        <NewModal open alternateTitle title='Request Changes' onClose={() => setRequestChangesOpen(undefined)}>
          <RequestChanges
            call={call}
            selectedStep={step}
            handleRequestChanges={(elementIds: number[], deadline?: string, reason?: string) =>
              handleChangesRequest(requestChangesOpen, elementIds, deadline, reason)}
            handleCancel={() => setRequestChangesOpen(undefined)}
          />
        </NewModal>
      }

      {documentsOpen && <DrawerModal open title={proposalId} handleClose={() => setDocumentsOpen(false)}>
        <ApplicationDocuments isPRO
          call={call}
          uid={proposalId}
          handleDownloadClick={handleDownloadClick}
          documents={documents}
          downloadConsolidated={() => downloadConsolidated(proposalId)}
        />
      </DrawerModal>}
      {reviewOpen &&
        <NewModal open alternateTitle title='Review Assignment' onClose={() => setReviewOpen(undefined)}>
          <ReviewModal
            handleReview={(review: string, deadline?: string, reason?: string) =>
              handleAssignmentReview(reviewOpen, review, deadline, reason)}
            handleCancel={() => setReviewOpen(undefined)}
          />
        </NewModal>}
    </$ApplicationActions>
  )
}
