import * as React from 'react'
import { Select, ConfirmAction, capitalizeFirstChar, FORM_ELEMENT_TYPES, NewButton } from 'prace-common-components'
import { $ModalContainer, $ButtonContainer } from '../styles'
import { EditElementModalProps } from './types'

const selectTypes = Object.values(FORM_ELEMENT_TYPES)
  .map((elementType) => ({value: elementType, label: capitalizeFirstChar(elementType)}))
  .filter((type) => type.value != FORM_ELEMENT_TYPES.GROUP)

export const EditElementModal: React.FC<EditElementModalProps> = (
  { element, saveHandler, cancelHandler },
) => {
  const [type, setType] = React.useState<string>(element.elementType)

  const onSave = () => { saveHandler(type) }

  const typeHandler = (_name: string, value: Nullable<string>) => {
    setType(value as string)
  }

  return (
    <$ModalContainer>
      <Select items={selectTypes} name='elementType' title='Element type' value={type} onChange={typeHandler} />
      <$ButtonContainer container justifyContent='space-between'>
        <ConfirmAction title='Edit element type' description='This can invalidate existing conditions, actions or templates which use this element.' onConfirm={onSave}>
          <NewButton variant='contained' onClick={onSave}>
            Save Changes
          </NewButton>
        </ConfirmAction>
        <NewButton variant='outlined' error onClick={cancelHandler}>
            Cancel
        </NewButton>
      </$ButtonContainer>
    </$ModalContainer>
  )
}
