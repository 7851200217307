import React, { useEffect, useMemo, useRef, useState } from 'react';
import update from 'immutability-helper';
import { $Table, $TableButton, $RowContainer } from './styles';
import DataGrid, { SelectColumn } from 'react-data-grid';
import { getColumnComparator } from './util/getColumnComparator';
import { FilterContext, FILTER_TYPE, HeadFilter, CONDITIONS } from './HeadFilter';
import { Notice, EmptySearch, useDebounce, SvgIcon } from '../';
import { Pagination } from './Pagination/Pagination';
export const RESEARCH_FIELD_GROUPS = {
    'Biochemistry, Bioinformatics and Life sciences': ['LS1', 'LS2', 'LS8', 'LS9'],
    'Chemical Sciences and Materials': ['PE3', 'PE4', 'PE5'],
    'Earth System Sciences': ['PE10'],
    'Economics, Finance and Management': ['SH1', 'SH2'],
    Engineering: ['PE7', 'PE8'],
    'Fundamental Constituents of Matter': ['PE2'],
    'Linguistics, Cognition and Culture': ['SH3', 'SH4', 'SH5', 'SH6'],
    'Mathematics and Computer Sciences': ['PE1', 'PE6'],
    'Physiology and Medicine': ['LS3', 'LS4', 'LS5', 'LS6', 'LS7'],
    'Universe Science': ['PE9'],
};
const CustomSelectColumn = {
    ...SelectColumn,
    noSort: true,
    width: 35,
};
export const ActionsColumn = ({ handleClick }) => (React.createElement($TableButton, null,
    React.createElement(SvgIcon, { name: 'dots-vrtical', clickable: true, size: 24, onClick: (e) => handleClick(e) })));
export const DataTable = ({ rows, resizable, initialSort = [], showEmptyImg = true, sortable = true, localFilter = true, columns, page = 0, total, rowsPerPage = 10, noFilters = false, noFilterType, onChangePage, onSortColumn, onFilters, noMultipleSorting, selectedRows, setSelectedRows, initFilters = {}, }) => {
    const [sortColumns, setSortColumns] = useState(initialSort);
    const [filters, setFilters] = useState(initFilters);
    const [filterConditions, setFilterConditions] = useState({});
    const didMount = useRef(false);
    const debouncedFilters = useDebounce(filters, 1000);
    useEffect(() => {
        if (didMount.current && onFilters)
            onFilters(debouncedFilters);
        else
            didMount.current = true;
    }, [debouncedFilters]);
    const handleFilter = (key, val) => {
        setFilters((prevFilters) => ({ ...prevFilters, [key]: val }));
    };
    const handleSort = (columnKey, direction, noMultipleSorting) => {
        if (onSortColumn)
            onSortColumn(columnKey, direction);
        if (columnKey === 'actions')
            return;
        if (direction)
            setSortColumns((prevSortColumns) => {
                const colIdx = prevSortColumns.findIndex((sort) => sort.columnKey === columnKey);
                if (colIdx < 0) {
                    if (noMultipleSorting)
                        return [{ columnKey, direction }];
                    else
                        return ([...prevSortColumns, { columnKey, direction }]);
                }
                return update(prevSortColumns, {
                    [colIdx]: {
                        columnKey: { $set: columnKey },
                        direction: { $set: direction },
                    },
                });
            });
        else
            setSortColumns((prevSortColumns) => {
                const colIdx = prevSortColumns.findIndex((sort) => sort.columnKey === columnKey);
                if (colIdx < 0)
                    return prevSortColumns;
                return update(prevSortColumns, {
                    $splice: [[colIdx, 1]],
                });
            });
    };
    const enhancedColumns = useMemo(() => columns.map((col) => ({
        ...col,
        formatter: col.formatter ? col.formatter :
            (props) => React.createElement($RowContainer, { "data-simplebar": true }, props.row[col.key]),
    })), [columns]);
    const filterColumns = useMemo(() => enhancedColumns.map((col) => ({
        ...col,
        headerRenderer: (props) => React.createElement(HeadFilter, { colKey: col.key, props: props, noSort: col.noSort, type: col.type, noFilterType: noFilterType, width: col.width, selectOptions: col.selectOptions || [], initialSort: sortColumns?.find((sortCol) => sortCol.columnKey == col.key)?.direction, onSort: (d) => { if (!col.noSort)
                handleSort(col.key, d, noMultipleSorting); }, onChange: (val) => {
                if (onChangePage)
                    onChangePage(1);
                handleFilter(col.key, val);
            }, onClickMenu: (value) => setFilterConditions((prevConditions) => ({ ...prevConditions, [col.key]: value })) }),
    })), [noFilterType, sortColumns, noMultipleSorting]);
    const filterSortedRows = useMemo(() => {
        if (!localFilter)
            return rows;
        const filteredRows = rows.filter((row) => filterColumns.every((col) => {
            const colKey = col.key;
            const condition = filterConditions[colKey];
            const filter = filters[colKey];
            if (!row[colKey])
                return true;
            switch (col.type) {
                case FILTER_TYPE.NUMBER: {
                    if (filter === undefined)
                        return true;
                    const greater = row[colKey] >= filter;
                    return (condition === CONDITIONS.GREATER) || !condition ? greater : !greater;
                }
                case FILTER_TYPE.SELECT: {
                    if (!filter)
                        return true;
                    const equal = row[colKey] === filter;
                    return (condition === CONDITIONS.EQUAL) || !condition ? equal : !equal;
                }
                case FILTER_TYPE.DATE: {
                    if (!filter)
                        return true;
                    const greater = new Date(row[colKey]).getTime() >= new Date(filter).getTime();
                    return (condition === CONDITIONS.GREATER) || !condition ? greater : !greater;
                }
                default: {
                    if (!filter)
                        return true;
                    const contains = row[colKey].toLowerCase().includes(filter.toLowerCase());
                    return (condition === CONDITIONS.CONTAINS) || !condition ? contains : !contains;
                }
            }
        }));
        if (sortColumns.length === 0)
            return filteredRows;
        return [...filteredRows].sort((a, b) => {
            for (const sort of sortColumns) {
                const col = filterColumns.find((col) => col.key === sort.columnKey);
                if (!col)
                    return 0;
                const comparator = getColumnComparator(sort.columnKey, col.type);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, filters, filterConditions, sortColumns]);
    const gridElement = (React.createElement(DataGrid, { rowKeyGetter: (row) => row.id, headerRowHeight: noFilters ? 35 : 60, columns: setSelectedRows ? [CustomSelectColumn, ...filterColumns] : filterColumns, rows: filterSortedRows, selectedRows: selectedRows, onSelectedRowsChange: setSelectedRows, style: { userSelect: 'inherit' }, defaultColumnOptions: {
            sortable,
            resizable,
        }, rowHeight: 40, sortColumns: sortColumns, onSortColumnsChange: setSortColumns }));
    const displayLength = filterSortedRows.length;
    const rowNumber = displayLength < rowsPerPage ? displayLength : rowsPerPage;
    return (React.createElement(React.Fragment, null,
        React.createElement($Table, { noFilters: noFilters, rowNumber: rowNumber },
            React.createElement(FilterContext.Provider, { value: { filters } }, gridElement),
            showEmptyImg && !displayLength && (filters && Object.keys(filters).length) ?
                React.createElement(EmptySearch, null)
                : !rows.length && showEmptyImg ?
                    React.createElement(Notice, null)
                    : null),
        page > 0 && React.createElement(Pagination, { page: page, total: total, rowsPerPage: rowsPerPage, onChangePage: onChangePage })));
};
