import { Routes } from 'constants/global'
import { TabProps } from 'prace-common-components'

export const initTabs: TabProps[] = [
  { label: 'Calls', route: Routes.CALLS },
  { label: 'Submissions', route: Routes.APPLICATIONS },
  { label: 'Users', route: Routes.USER_TABLE },
  { label: 'Partition Manager', route: Routes.PARTITION_MANAGER },
  { label: 'Template Manager', route: Routes.TEMPLATE_MANAGER },
  { label: 'Legacy', route: Routes.LEGACY },
]

export const gobackConfig = [
  {
    path: Routes.CALL(':id'),
    label: 'Back to Calls',
  },
]

