import { activeCutOff } from '../';
export const applicationCutOff = (cutoffs, cutoffId, draft) => {
    if (!cutoffs?.length || !cutoffId)
        return [];
    const sortedCutoffs = [...cutoffs].sort((a, b) => (a.order || 0) - (b.order || 0));
    let cutoffIdx = -1;
    if (draft) {
        const cutoff = activeCutOff(cutoffs);
        if (cutoff)
            cutoffIdx = sortedCutoffs.findIndex((c) => c.id == cutoff.id);
    }
    else {
        cutoffIdx = sortedCutoffs.findIndex((c) => c.id == cutoffId);
    }
    return cutoffIdx > -1 ? [sortedCutoffs[cutoffIdx], sortedCutoffs[cutoffIdx + 1]] : [];
};
