import styled from 'styled-components';
import { SvgIcon } from '..';
export const $Dropdown = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
`;
export const $Wrapper = styled.div `
  display: flex;
  align-items: center;
`;
export const $SvgIcon = styled(SvgIcon) `
  :hover {
    cursor: pointer;
  }
`;
export const $Label = styled.div `
  font-size: ${({ fontSize }) => fontSize};
  margin-right: 5px;
`;
export const $Menu = styled.div `
  position: absolute;
  right: 0;
  top: 50px;
  display: ${({ open }) => open ? 'flex' : 'none'};
  flex-direction: column;
  min-width: 180px;
  height: max-content;
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.25);
  border-radius: 12px;
  z-index: 999;

  div:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  div:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;
export const $MenuItem = styled.div `
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align};
  min-width: 180px;
  height: max-content;
  padding: 14px;
  background-color: #FAFAFA;
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    background-color: lightgray;
    cursor: pointer;
  }
`;
