import React from 'react';
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
        fontFamily: 'system-ui, sans-serif',
    },
    errorInfo: {
        color: 'red',
    },
    btn: {
        border: '1px solid #25A6F7',
        backgroundColor: 'white',
        color: '#25A6F7',
        padding: '14px 28px',
        fontSize: '16px',
        cursor: 'pointer',
        borderRadius: 4,
    },
};
export const ErrorMonitor = ({ error, componentStack, env }) => {
    function renderError() {
        if (env === 'local') {
            return (React.createElement("div", { style: styles.errorInfo },
                React.createElement("div", { key: 'error' }, error.toString()),
                componentStack.split('\n').map((line, indx) => React.createElement("div", { key: indx }, line))));
        }
        return React.createElement("h4", { key: 'message' }, "Something went wrong! We are trying to fix it.");
    }
    return (React.createElement("div", { style: styles.container },
        React.createElement("h1", null, "Oh no!"),
        renderError(),
        React.createElement("br", null),
        React.createElement("button", { style: styles.btn, onMouseUp: () => location.reload() }, "RELOAD PAGE")));
};
