export var ReviewApproveState;
(function (ReviewApproveState) {
    ReviewApproveState["APPROVED"] = "approved";
    ReviewApproveState["REJECTED"] = "rejected";
    ReviewApproveState["REQUEST_CHANGES"] = "request_changes";
})(ReviewApproveState || (ReviewApproveState = {}));
export var FLOW_STATUS;
(function (FLOW_STATUS) {
    FLOW_STATUS["ASSIGNED"] = "assigned";
    FLOW_STATUS["ACCEPTED"] = "accepted";
    FLOW_STATUS["REJECTED"] = "rejected";
    FLOW_STATUS["REFUSED"] = "refused";
    FLOW_STATUS["DRAFT"] = "draft";
    FLOW_STATUS["CONFLICTED"] = "conflicted";
    FLOW_STATUS["SUBMITTED"] = "submitted";
    FLOW_STATUS["RESUBMITTED"] = "resubmitted";
    FLOW_STATUS["REVOKED"] = "revoked";
    FLOW_STATUS["CHANGES_REQUESTED"] = "changes_requested";
    FLOW_STATUS["APPROVED"] = "approved";
    FLOW_STATUS["TRIGGERED"] = "triggered";
})(FLOW_STATUS || (FLOW_STATUS = {}));
export var FLOW_STATUS_NAMES;
(function (FLOW_STATUS_NAMES) {
    FLOW_STATUS_NAMES["assigned"] = "Assigned";
    FLOW_STATUS_NAMES["accepted"] = "Accepted";
    FLOW_STATUS_NAMES["rejected"] = "Rejected";
    FLOW_STATUS_NAMES["refused"] = "Refused";
    FLOW_STATUS_NAMES["draft"] = "Draft";
    FLOW_STATUS_NAMES["conflicted"] = "Conflicted";
    FLOW_STATUS_NAMES["submitted"] = "Submitted";
    FLOW_STATUS_NAMES["resubmitted"] = "Resubmitted";
    FLOW_STATUS_NAMES["revoked"] = "Revoked";
    FLOW_STATUS_NAMES["changes_requested"] = "Changes requested";
    FLOW_STATUS_NAMES["approved"] = "Approved";
    FLOW_STATUS_NAMES["triggered"] = "Triggered";
})(FLOW_STATUS_NAMES || (FLOW_STATUS_NAMES = {}));
