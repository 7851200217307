import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { $StepConnector, $StepIcon, $StepLabel } from './styles';
export const VerticalStepper = ({ steps, activeStep, onChangeActiveStep, multipleForms = true, workingStep }) => {
    const stepIcon = (props, activeStep, activeForm = false, stepIndex) => {
        const { completed } = props;
        return (React.createElement($StepIcon, { active: activeStep === stepIndex, activeForm: activeForm, completed: completed }, ' '));
    };
    return multipleForms ? React.createElement(Stepper, { activeStep: workingStep, orientation: "vertical", connector: React.createElement($StepConnector, null) }, steps.map((step, index) => (React.createElement(Step, { key: step.title, onClick: () => onChangeActiveStep(index) },
        React.createElement($StepLabel, { error: step.error, active: activeStep === index, StepIconComponent: (props) => stepIcon(props, activeStep, step.workingStep, index) }, step.title))))) : null;
};
