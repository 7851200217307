import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { RootState } from 'store'
import { NotificationStack } from 'prace-common-components'

export const NotificationWrapper = () => {
  const dispatch = useAppDispatch()
  const notifications = useAppSelector((state: RootState) => state.notifications.notifications)

  const removeNotification = (notificationNumber: number) => {
    dispatch({ type: 'closeNotification', payload: { notificationNumber } })
  }

  return (
    <NotificationStack
      notifications={notifications}
      closeNotification={removeNotification}
    />
  )
}
