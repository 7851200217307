import React, { useRef } from 'react';
import { $Card, $Handler, $AddFormElementText } from './styles';
import { useDragComponent } from '../DnD';
import { SvgIcon } from '../';
import { Grid } from '@material-ui/core';
import { ElementIcon } from '../newElementIcon';
export const AddFormElement = ({ itemType, text, item, onlyHandleDrag = true, endDrag = () => null }) => {
    const dragRef = useRef(null);
    const previewRef = useRef(null);
    const [_, drag, preview] = useDragComponent(itemType, item, { endCallback: endDrag });
    if (onlyHandleDrag) {
        preview(previewRef);
        drag(dragRef);
    }
    else {
        drag(preview(previewRef));
    }
    return React.createElement($Card, { ref: previewRef, drag: !onlyHandleDrag },
        React.createElement(Grid, { container: true, alignItems: 'center' },
            React.createElement($Handler, { ref: onlyHandleDrag ? dragRef : undefined },
                React.createElement(SvgIcon, { name: 'drag', color: '#757778', size: 24 })),
            React.createElement(ElementIcon, { type: item.type, size: 28 }),
            React.createElement($AddFormElementText, null, text)));
};
