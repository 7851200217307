/* eslint-disable no-param-reassign */
import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { Routes } from 'constants/global'
import { authApi } from 'store/api/auth'
import { AuthState } from './types'
import { history } from '../'
import { ADMIN_PORTAL_ROLES, ROLES } from 'prace-common-components'

const initialState: AuthState = {
  token: '',
  user: undefined,
  isAuth: !!window.localStorage.getItem('usr'),
}

const refresh = createAction<{ token: string }, 'refresh'>('refresh')

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(refresh, (state, { payload }) => {
      state.token = payload.token
    })
    builder.addMatcher(isAnyOf(
      authApi.endpoints.login.matchFulfilled,
      authApi.endpoints.refresh.matchFulfilled,
    ),(state, { payload }) => {
      const { user, token, login, email } = payload
      if (ADMIN_PORTAL_ROLES.includes(user.role) || (user.role === ROLES.SUPER_ADMIN)) {
        window.localStorage.setItem('usr', 'true')
        if(email) window.localStorage.setItem('email', (email || '').split('@')[0])
        state.token = token
        if((state.user?.id !== user?.id) || (state.user?.role !== user?.role)) {
          // update user info for error reporting
          Sentry.setUser({ id: String(user.id), role: user.role })
          state.user = { ...user, email: email || '' }
        }
        if(!state.isAuth) state.isAuth = true
        if(login) {
          if(user.role === ROLES.SUPER_ADMIN) history.push(Routes.ADMINS)
          else history.push(Routes.CALLS)
        }
      }
    })
    builder.addMatcher(authApi.endpoints.validate.matchFulfilled,(state) => {
      state.token = initialState.token
      state.user = initialState.user
      state.isAuth = false
      history.push(Routes.LOGIN)
    })
    builder.addMatcher(authApi.endpoints.forgot.matchFulfilled,(state) => {
      state.token = initialState.token
      state.user = initialState.user
      history.push(Routes.FORGOT_PASSWORD_SENT)
    })
    builder.addMatcher(authApi.endpoints.reset.matchFulfilled, (state) => {
      history.push(Routes.LOGIN)
      state.token = initialState.token
      state.user = initialState.user
      state.isAuth = false
    })
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      window.localStorage.removeItem('usr')
      // clear user info for error reporting
      Sentry.configureScope((scope) => scope.setUser(null))
      history.push(Routes.LOGIN)
      state.token = initialState.token
    })
    builder.addMatcher(authApi.endpoints.confirmEmail.matchFulfilled,(state) => {
      state.token = initialState.token
      state.user = initialState.user
      state.isAuth = false
    })
  },
})

export const authReducer = slice.reducer
