import React from 'react';
export function useWait() {
    const [done, setDone] = React.useState(true);
    const [handler, setHandler] = React.useState(setTimeout(() => null));
    const wait = React.useCallback((delay) => {
        clearTimeout(handler);
        setDone(false);
        setHandler(setTimeout(() => setDone(true), delay));
    }, []);
    return { wait, done };
}
