import * as React from 'react'
import { AutocompleteInput, Item, Switch, ROLE_NAME, Title, $Text, ROLES, ROLE_ORIGIN, DataTable, FILTER_TYPE, DataRow, NewModal, NotificationType, NewCheckbox, ConfirmAction, Tooltip, SvgIcon, NewButton } from 'prace-common-components'
import { $Container, $ListsContainer, $ModalContainer, $NameColumn, $SwitchContainer } from './styles'
import Grid from '@material-ui/core/Grid'
import { EditCutoffRoleProps } from './types'
import { UsersListModal } from 'components/UsersListModal'
import { useCreateUsersGroupMutation, useUpdateUsersGroupMutation, useDeleteUsersGroupMutation } from 'store/api/users'
import { useAppDispatch } from 'store/hooks'
import { useCreateCutOffRolesMutation, useRemoveCutOffRolesMutation, useUpdateCutOffRoleMutation } from 'store/api/calls'

const NotEditableRoles = [ROLES.COMPUTE_CENTRE_REPRESENTATIVE, ROLES.APPLICANT]

export const EditCutoffRoleModal: React.FC<EditCutoffRoleProps> = ({
  refetch,
  cutOffId,
  callId,
  setCallRoles,
  getCutOffRoles,
  userGroups,
  roles,
  role,
  roleLists,
  cutoffRoleList,
  allCutoffRoles,
  cutOffRoles,
  userGroupItems,
  handleClose,
}) => {
  const dispatch = useAppDispatch()
  const [createUserGroup] = useCreateUsersGroupMutation()
  const [updateUserGroup] = useUpdateUsersGroupMutation()
  const [deleteUserGroup] = useDeleteUsersGroupMutation()
  const [createUserRoles] = useCreateCutOffRolesMutation()
  const [removeUserRoles] = useRemoveCutOffRolesMutation()
  const [updateCutOffRole] = useUpdateCutOffRoleMutation()

  const [createUserListOpen, setCreateUserListOpen] = React.useState<ROLES | undefined>()
  const [editUserListOpen, setEditUserListOpen] = React.useState<StringNumber | undefined>()

  const notEditable = NotEditableRoles.includes(role)
                                
  const hasRoles = cutOffRoles?.length
  const assignRoles = hasRoles ? cutOffRoles.filter((r) => r.origin == ROLE_ORIGIN.ASSIGNMENT) : []

  const forcedRoles = hasRoles ? 
    cutOffRoles.filter((r) => r.origin == ROLE_ORIGIN.MANUAL) : []

  /* Roles in the user list but not actively assumming the role */
  const notActiveRoles = (cutoffRoleList?.userGroup?.users.filter(
    (u) => ![...forcedRoles, ...assignRoles].map((r) => r.user).includes(u.id)) || [])
    .map((r) => ({ 
      ...r,
      user: r.id,
      hasAssignments: false,
    })) || []

  const uniqueRoles = [...new Set([
    ...forcedRoles.map((r) => r.id),
    ...assignRoles.map((r) => r.id),
    ...notActiveRoles.map((r) => r.id),
  ])]

  const userRows = uniqueRoles.map(
    (id) => [...forcedRoles, ...notActiveRoles, ...assignRoles].find((r) => r.id === id)!)

  const rows = userRows.map((userRole) => ({
    id: userRole.id,
    user: userRole.user,
    name: `${userRole.firstName} ${userRole.lastName}`,
    email: userRole.email || '',
    assignment: userRole.hasAssignments,
    locked: !!forcedRoles.find((r) => r.user === userRole.user),
    otherRole: roles.find((s) => (allCutoffRoles || {})[s]?.find((c) => (c.user === userRole.user) && s !== role)),
  }))

  /* If every user is forced, then the role is locked */
  const allLocked = (!!forcedRoles.length && forcedRoles.length >= (rows.length || 0))
  
  const editingUserGroup = userGroups?.find((group) => group.id === editUserListOpen)  

  const usersGroupName = cutoffRoleList?.userGroup?.name || ''
  const usersGroupId = cutoffRoleList?.userGroup?.id

  const handleCreateUserGroup = async (
    name: string, users: number[],
  ) => {
    try {
      if(!users.length) return
      const newUserGroup = await createUserGroup({ name, users }).unwrap()
      await onChangeUserGroup(newUserGroup.id)
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'User List created' } })
      setCreateUserListOpen(undefined)
    } catch (err) {
      console.log(err)
    }
  }

  const handleEditUserGroup = async (name: string, users: number[], id?: number) => {
    try {
      if(!id || !users.length) return
      await updateUserGroup({ id, name, users }).unwrap()
      await refetch()
      const callRoles = await getCutOffRoles()
      setCallRoles(callRoles)
      setEditUserListOpen(undefined)
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'User List updated' } })
    } catch (err) {
      console.log(err)
    }
  }

  const handleDeleteUserGroup = async (id: number) => {
    try {
      if(!id) return
      await deleteUserGroup(id).unwrap()
      await refetch()
      const callRoles = await getCutOffRoles()
      setCallRoles(callRoles)
      setEditUserListOpen(undefined)
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'User List removed' } })
    } catch (err) {
      console.log(err)
    }
  }

  const onChangeUserGroup = async (userGroupId?: number) => {
    try {
      /* Check if we can select this user group */
      if(userGroupId && roles.some((r) => {
        const cutoffRoleList = roleLists.find((list) => list.role === r)
        return cutoffRoleList?.usersGroupId === userGroupId && r !== role
      })) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'This user list is already selected for this cutoff' } })
        return
      }
      await updateCutOffRole(
        { id: cutOffId, roles: [{role, userGroupId: userGroupId ?? undefined}] }).unwrap()
      await refetch()
      const callRoles = await getCutOffRoles()
      setCallRoles(callRoles)
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Role updated' } })
    } catch (err) {
      console.log(err)
    }
  }

  const handleLockUserRole = async (userId: number, prevLocked: boolean, hasAssignments: boolean, roleId: number) => {
    try{
      /* Create or lock User role */
      if(!prevLocked) {
        await createUserRoles({
          cutOffId,
          roles: [{
            userId,
            callId,
            cutOffId,
            role,
            origin: ROLE_ORIGIN.MANUAL,
          }],
        }).unwrap()
      } else  { /* Previously locked */
        if(hasAssignments) { /* Update the role to origin Assignment */
          await createUserRoles({
            cutOffId,
            roles: [{
              userId,
              callId,
              cutOffId,
              role,
              origin: ROLE_ORIGIN.ASSIGNMENT,
            }],
          }).unwrap()
        } else { /* The user will be unlocked and has no assignments */
          await removeUserRoles([roleId]).unwrap()
        } 
      }
      const callRoles = await getCutOffRoles()
      setCallRoles(callRoles)
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: prevLocked ? 'User role unlocked' : 'User role locked' } }) 
    } catch (err) {
      console.log(err)
    }
  }

  const onChangeLockAll = async (allLocked: boolean, userIds: number[], roleIds: number[]) => {
    if(allLocked) {
      const roles = userIds.map((userId) => ({
        userId,
        callId,
        cutOffId,
        role,
        origin: ROLE_ORIGIN.MANUAL,
      }))
      await createUserRoles({
        cutOffId,
        roles,
      }).unwrap()
    } else {
      await removeUserRoles(roleIds).unwrap()
    }
    const callRoles = await getCutOffRoles()
    setCallRoles(callRoles)
    dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: allLocked ? 'User roles locked' : 'User roles unlocked' } }) 
  }

  const onClose = () => {
    setCreateUserListOpen(undefined)
    setEditUserListOpen(undefined)
  }


  const columns = [
    { key: 'name', noSort: true, type: FILTER_TYPE.NONE, name: 'Name',
      formatter(props: { row: DataRow }) {
        const { name, email, otherRole } = props.row
        return <$NameColumn disabled={otherRole}>
          <Tooltip show text={otherRole ? `${email} - This user is a ${otherRole}` : email} arrow placement='top'>
            <Grid item container alignItems='center'>
              {otherRole ? <SvgIcon name='warning' /> : null}
              {name}
            </Grid>
          </Tooltip>
        </$NameColumn>
      },
    },
    { key: 'assignment', noSort: true, type: FILTER_TYPE.NONE, name: 'Has assignment',
      formatter(props: { row: DataRow }) {
        const { assignment } = props.row
        return assignment ?
          <$SwitchContainer container alignItems='center' justifyContent='center'>
            <SvgIcon name='check' />
          </$SwitchContainer>
          : null
      },
    },
    { key: 'locked', noSort: true, type: FILTER_TYPE.NONE, name: 'Locked Role',
      formatter(props: { row: DataRow }) {
        const { assignment, locked, id, user, otherRole } = props.row
        const disabled = assignment || otherRole

        return <$SwitchContainer container alignItems='center' justifyContent='center'>
          {disabled && !locked ? 
            <NewCheckbox disabled name='locked' value={assignment} title='' hideItalic/>
            :
            <ConfirmAction 
              title={locked ? 'Unlock user role' : 'Lock user role'} description='' 
              onConfirm={() => handleLockUserRole(user, locked, assignment, id)}
            >
              <NewCheckbox disabled={locked ? false : disabled} name='locked' value={locked} title='' hideItalic />
            </ConfirmAction>
          }
        </$SwitchContainer>
      },
    },
  ]

  return <$ModalContainer>
    <Title hasBack
      icon='arrow-left'
      iconProps={{ size: '20px' }}
      onBackClick={handleClose}>{ROLE_NAME[role]}</Title>
    <$Container container>
      <Title noMargin alternate>
        Assignee user pool
      </Title>
      {role === ROLES.COMPUTE_CENTRE_REPRESENTATIVE ?
        <>
          <$Text>Compute Centre Representatives are defined in the Partition Manager.</$Text>
          <NewButton onClick={() => window.open('/pm', '_blank')}>
            Go to Partition Manager
          </NewButton>
        </>
        :
        <>
          <$Text>Select, create or edit a list of users that can be assigned to this role.</$Text>
          <Grid item xs={12}>
            <AutocompleteInput
              name='userGroup'
              withNone
              disabled={notEditable}
              title='Select user list'
              items={userGroupItems}
              onChange={(_, item) => onChangeUserGroup((item as Item).value as number)}
              value={{ label: usersGroupName, value: usersGroupId } as Item}
              onCreateItem={() => setCreateUserListOpen(role)}
              onEditItem={setEditUserListOpen}
            /> 
          </Grid>
        </>}
      <Title alternate>
        Assigned users
      </Title>
      {!notEditable && <Grid item>
        <ConfirmAction
          title={allLocked ? 'Unlock all user roles' : 'Lock all users to role'} description=''
          onConfirm={() => onChangeLockAll(!allLocked, userRows.map((r) => r.user), forcedRoles.map((r) => r.id))}
        >
          <Switch 
            name='lock'
            title='Lock all users to role'
            value={allLocked}
            onChange={() => null}
          />
        </ConfirmAction>
      </Grid>}
    </$Container>
    <$ListsContainer container alignItems='flex-start'>
      <DataTable
        showEmptyImg={false}
        noFilters
        rows={rows}
        columns={columns}
      />
    </$ListsContainer>
    {(createUserListOpen || editingUserGroup) &&
      <NewModal open title={createUserListOpen ? 'User list creation' : 'Edit User List'} onClose={onClose}>
        <UsersListModal
          name={createUserListOpen ? '' : editingUserGroup?.name || ''}
          userList={createUserListOpen ? [] : editingUserGroup?.users || []}
          handleDelete={editingUserGroup ? () => handleDeleteUserGroup(editingUserGroup.id) : undefined}
          handleSave={(name: string, userIds: number[]) => 
            createUserListOpen ? handleCreateUserGroup(name, userIds/* , createUserListOpen */) : 
              handleEditUserGroup(name, userIds, editingUserGroup?.id)}
        />
      </NewModal>
    }
  </$ModalContainer>
}
