import styled from 'styled-components';
export const $Card = styled.div `
  border: 1px solid #A8ACAC;
  width: 100%;
  cursor: ${({ drag }) => drag ? 'move' : 'inherit'};
  height: fit-content;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  &:hover {
    ${({ theme }) => theme.elevations[1]};
  }
`;
export const $Handler = styled.div `
  display: flex;
  align-items: center;
  cursor: move;
  margin-right: 8px;
  height: 100%;
  min-height: 40px;
  border-radius: 4px;
`;
export const $AddFormElementText = styled.h4 `
  color: #757778;
  margin-left: ${({ theme }) => theme.spacing(2)};
`;
