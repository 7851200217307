import React, { useEffect, useRef, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { $Select, ExpandIcon } from '../newSelect';
import { $Input, $InputLabel } from '../newInput';
import { $FormControl, $Chips, $Chip } from './styles';
import { SvgIcon } from '../SvgIcon';
import { $FormHelperText } from '../newInput/styles';
import { $Italic } from '../newTypography';
import { useDebounce } from '../';
export const NewMultiSelect = ({ items, error, errorMsg, debounce, description = null, value = [], name, title, required, disabled, hideItalic = false, onChange, }) => {
    const didMount = useRef(false);
    const [selectValue, setSelectValue] = useState(value);
    const debouncedValue = useDebounce(selectValue, 300);
    useEffect(() => {
        if (didMount.current && debounce)
            onChange(name, debouncedValue !== null ? debouncedValue : []);
        else
            didMount.current = true;
    }, [debouncedValue]);
    useEffect(() => {
        if (didMount.current && debounce && (value !== selectValue))
            setSelectValue(value);
    }, [value]);
    const handleOnChange = ({ target }) => {
        const value = target.value;
        const name = target.name;
        if (debounce)
            setSelectValue(value);
        else
            onChange(name, value);
    };
    const handleDelete = (e, val) => {
        e.preventDefault();
        const newValues = value.filter((item) => item !== val);
        if (debounce)
            setSelectValue(newValues);
        else
            onChange(name, newValues);
    };
    const displayValue = (debounce && didMount.current) ? selectValue : value;
    return (React.createElement($FormControl, null,
        React.createElement($InputLabel, { disableAnimation: true, shrink: false },
            title,
            required && '*',
            !hideItalic &&
                React.createElement($Italic, null, "multiselect")),
        React.createElement($Select, { id: 'multi-select', label: title, name: name, error: error, disabled: disabled, multiple: true, multiline: true, value: displayValue, onChange: handleOnChange, input: React.createElement($Input, { name: name, disabled: disabled, id: 'multi-select' }), IconComponent: ExpandIcon, MenuProps: {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
            }, renderValue: (value) => (React.createElement($Chips, null, value.map((value) => {
                const item = items.find((it) => it.value === value);
                if (!item)
                    return null;
                return React.createElement($Chip, { key: value, label: item.label, disabled: disabled, deleteIcon: disabled ? undefined :
                        React.createElement(SvgIcon, { name: 'cancel', onMouseDown: (e) => e.stopPropagation() }), onDelete: disabled ? undefined : (e) => handleDelete(e, value) });
            }))) }, items.map(({ label, value }) => (React.createElement(MenuItem, { key: value, value: value }, label)))),
        (description || errorMsg) &&
            React.createElement($FormHelperText, { error: error }, errorMsg || description)));
};
