import * as React from 'react'
import update from 'immutability-helper'
import { StepFlow } from './StepFlow'
import { $GridItem } from '../styles'
import { insertSelectItems, ADD_ELEMENT_TYPE, PREVIEW_TYPE, NewModal, OnMove, OnDrop, OnDropBox, DragItem, ELEMENT_TYPES, FORM_ELEMENT_TYPES, FlowFormElement, NotificationType, FlowElementTypes } from 'prace-common-components'
import { AddElementModal } from 'components/AddElementModal'
import { RootState } from 'store'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { AddElement } from 'store/calls/types'
import { useCreateFlowElementsMutation, useDeleteFlowElementsMutation, useUpdateElementMutation } from 'store/api/calls/elements'
import { getElement } from 'util/getElement'

type DragFlowFormElement = FlowFormElement & {origin?: number}

export const StepFlowController: React.FC = () => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = React.useState<boolean>(false)
  const [createFlowElements] = useCreateFlowElementsMutation()
  const [updateElement] = useUpdateElementMutation()
  const [deleteFlowElements] = useDeleteFlowElementsMutation()

  const call = useAppSelector((state: RootState) => state.calls.call)
  const editorSelector = useAppSelector((state: RootState) => state.calls.editorSelector)
  const optionGroups = useAppSelector((state: RootState) => state.calls.optionGroups)

  const { step, form, element, elements, lastSelection } = editorSelector
  const forms = call?.flow[step]?.flow
  const formElements = forms.length ? forms[form].flow : []
  const elementsLength = formElements.length

  const [stateElements, setStateElements] = React.useState<DragFlowFormElement[]>(formElements)

  React.useEffect(() => setStateElements(forms.length ? forms[form].flow : []), [forms.length, form, forms]) 

  // TODO: This should not use the index to select, use id in the future
  const onSelectElement = (e: React.MouseEvent<HTMLDivElement>, order: number) => {
    e.stopPropagation()
    /* Clicked an element while pressing the ctrl key */
    if (e.ctrlKey || e.metaKey) {
      dispatch({ type: 'selectElements', payload: { order }})
    } else {
      dispatch({ type: 'selectElement', payload: { type: ELEMENT_TYPES.ELEMENT, order }})
    }
  }

  const createElements = React.useCallback(
    async (parentId: FlowFormElement['parentId'],elements: FlowFormElement[], groupElements: FlowFormElement[]) => {
      let newElements = await createFlowElements({
        parentId,
        flowElements: elements,
      }).unwrap() as FlowFormElement[]
      newElements = newElements.map((el) => {
        let newFlow: FlowFormElement[] = []
        if(el.flow)
          newFlow = el.flow.map((flowEl: FlowFormElement) => ({...flowEl, flow: flowEl.flow || [] }))
        return { ...el, flow: newFlow } as FlowFormElement
      })
      /* Create the deeper elements after (elements inside groups) */
      if(groupElements.length) {
        for (const groupElement of groupElements) {
        //Using order to discover the correct group element
          const foundElIdx = newElements.findIndex((flowEl) => flowEl.order === groupElement.order)
          if(foundElIdx !== -1) {
            const groupParentId = newElements[foundElIdx].id
            //Need the response because of the ids
            try {
              const newGroupElements =  await createFlowElements(
                { parentId: groupParentId, flowElements: groupElement.flow }).unwrap()
              newElements = update(newElements, {
                [foundElIdx]: {
                  title:  { $set: groupElement.title },
                  flow: {
                    $set: newGroupElements.map((el) => { return { ...el, flow: [] } as FlowFormElement }),
                  },
                }})
            } catch (e) { // Error creating a child element, remove group element
              await deleteFlowElements({ ids: [groupParentId] }).unwrap()
              dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Error creating children elements' } })
            }
          }
        }
      }
      dispatch({ type: 'addElements', payload: { newElements, stepId: call.flow[step].id, formId: call.flow[step].flow[form].id }})
    }, [call.flow, createFlowElements, deleteFlowElements, dispatch, form, step])

  const onCreateElement = React.useCallback(async (type: FlowElementTypes, index: number, id: string | number) => {
    try {
      let newElement
      let groupElements: FlowFormElement[] = []
      const flowForm = call.flow[step].flow[form]
      const flow = flowForm.flow as FlowFormElement[]

      const partitionSelect = flow.find((element) => element.elementType === FORM_ELEMENT_TYPES.SELECT_PARTITION)
      /* Do not let the user add multiple select partition in the same form */
      if(type === FORM_ELEMENT_TYPES.SELECT_PARTITION && partitionSelect) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Select partition already on form' } })
        return
      }
      /* Partition Resources need to be conenct to one Select Partition in the same form */
      if(type === FORM_ELEMENT_TYPES.PARTITION_RESOURCES && !partitionSelect) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Select partition needed in form' } })
        return
      } else {
        const isGroup = type === FORM_ELEMENT_TYPES.GROUP
        //TODO: Change Id and type of element so that it is unique (and not mistaken by the move elements)
        newElement = getElement<FlowFormElement>(
          null,
          flowForm.id,
          index === undefined ? flow.length : index,
          isGroup ? id as string : type,
          { effects: [], automations: [] },
          optionGroups,
        )
        if(isGroup) groupElements = [newElement]
      }
      await createElements(newElement.parentId, [newElement], groupElements)
    } catch (e) {
      console.log(e)
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Error creating element(s)' } })
    }
  }, [call.flow, createElements, dispatch, form, optionGroups, step])

  const onDrop = async ({
    hoverIndex,
    type,
    dragType,
    dragItem,
  }: OnDrop) => {
    /* dispatch(EDIT_CALL_EVENTS.ADD_ELEMENT, {
        type: dragItem.type as FORM_ELEMENT_TYPES,
        index: hoverIndex,
        id: dragItem.id,
      }) */
    if(type !== dragType && dragItem)
      await onCreateElement(dragItem.type as FORM_ELEMENT_TYPES, hoverIndex, dragItem.id)
  }

  const onDropBox: OnDropBox = React.useCallback(async (element: DragItem)  => {
    /* dispatch(EDIT_CALL_EVENTS.ADD_ELEMENT, {
      type: element.type as ELEMENT_TYPES, index: elementsLength, id: element.id,
    }) */
    await onCreateElement(element.type as ELEMENT_TYPES, elementsLength, element.id)
  }, [elementsLength, onCreateElement])

  const onMove = ({
    dragIndex,
    hoverIndex,
    dragType,
    dragItem,
  }: OnMove) => {
    /* dispatch(EDIT_CALL_EVENTS.MOVE_ELEMENT, 
      { dragIndex, hoverIndex, dragType: dragType as FORM_ELEMENT_TYPES | 'addElement',
      element: dragItem as Omit<FlowElement, 'flow'> }) */
    if(dragType == ADD_ELEMENT_TYPE) {
      setStateElements((prevElements) => {
        /* Remove duplicate elements already in the flow or previews from previous moves */
        let newItems: FlowFormElement[] = prevElements.filter(
          (item) => (item.id !== dragItem.id) || (item.elementType !== PREVIEW_TYPE))
        newItems = [
          ...newItems.slice(0, hoverIndex),
          {...dragItem, title: 'preview', value: null, elementType: PREVIEW_TYPE, flow: [], parentId: 0},
          ...newItems.slice(hoverIndex),
        ] as FlowFormElement[]
        return newItems.map((element, i) => ({...element, order: i }))
      })
    } else {
      setStateElements((prevElements) => {
        const newElement = { ...prevElements[dragIndex], origin: dragIndex }
        const newItems = update(prevElements, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, newElement],
          ],
        }).map((el: FlowFormElement, i: number) => ({ ...el, order: i }))
        return newItems
      })
    }
  }

  const cleanPreviews = () => setStateElements((prevElements) => prevElements.map((el) => ({...el, origin: undefined})))

  const onEndDrag = async () => {
    //dispatch(EDIT_CALL_EVENTS.ELEMENT_MOVEMENT_API, {})
    try {
      const newElementSelect = stateElements.findIndex((el) => el.origin !== undefined)
      const newFlow = stateElements.map((element, i) => ({...element, order: i }))
      /* 
        store.dispatch(EDIT_CALL_EVENTS.UPDATE_ELEMENT_ORDERS_API, {
          element: newEditCall.call.flow[step].flow[form],
          oldEditCall: editCall,
        })
        */
      const ordersFlow = newFlow.map((flowElement: FlowFormElement) => ({
        id: flowElement.id,
        order: flowElement.order,
      }))
      const newValues = { ...call.flow[step].flow[form], flow: newFlow }
      await updateElement({
        id: newValues.id,
        flow: ordersFlow ? ordersFlow as FlowFormElement[] : [],
      }).unwrap()
      dispatch({ type: 'updateForm', payload: { newValues, newElementSelect } })
      cleanPreviews() // TODO: Is this needed?
    } catch (err) {
      dispatch({ type: 'updateCall', payload: {} })
      console.log(err)
    }
  }

  const onClickBox = () => setOpen(!open)

  const onAddElements = async (elements: AddElement[]) => {
    try {
      //dispatch(EDIT_CALL_EVENTS.ADD_ELEMENTS, { elements })
      const flowForm = call.flow[step].flow[form]
      const flow = flowForm.flow
      const numberElements = flow.length
      const groupElements: FlowFormElement[] = []
      /* Validate Partition rules */
      const existingPartitionSelect = flow
        .find((element: FlowFormElement) => element.elementType === FORM_ELEMENT_TYPES.SELECT_PARTITION)
      const newPartitionSelects =
        elements.filter((element) => element.elementType === FORM_ELEMENT_TYPES.SELECT_PARTITION)
      if(elements.find((el) => el.elementType === FORM_ELEMENT_TYPES.SELECT_PARTITION)
        && (existingPartitionSelect || newPartitionSelects.length > 1)) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Form can only have one Select Partition' } })
        return
      }
      if(elements.find((el) => el.elementType === FORM_ELEMENT_TYPES.PARTITION_RESOURCES)
        && !(existingPartitionSelect || newPartitionSelects.length)
      ) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Select partition needed in form' } })
        return
      }
      const newElements = elements.map((el: AddElement, i: number) => {
        const type = el.elementType
        const isGroup = type === FORM_ELEMENT_TYPES.GROUP
        //TODO: Change Id and type of element so that it is unique (and not mistaken by the move elements)
        const newElement = getElement<FlowFormElement>(
          null,
          flowForm.id,
          numberElements + i,
          isGroup ? el.id : type,
          undefined,
          optionGroups,
        )
        if(isGroup) groupElements.push(newElement)
        return newElement
      })
      await createElements(flowForm.id, newElements, groupElements)
      onClickBox()
    } catch (e) {
      console.log(e)
      dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Error adding elements' } })
    }
  }

  //TODO: Do this in a more light way/place
  /* Retrieves the options group of select elements */
  const enhancedElements = insertSelectItems(stateElements, optionGroups)
  const selectedIdx = lastSelection === ELEMENT_TYPES.ELEMENT ?
    elements.length ? elements
      : [element] : []

  return (
    <$GridItem item xs>
      <StepFlow
        call={call}
        elements={enhancedElements}
        onSelectElement={onSelectElement}
        onMove={onMove}
        onDrop={onDrop}
        onDropBox={onDropBox}
        onEndDrag={onEndDrag}
        onClickBox={onClickBox}
        selectedIdx={selectedIdx}
      />
      <NewModal title='Add Elements' alternateTitle open={open} onClose={onClickBox}>
        <AddElementModal onAddElements={onAddElements} />
      </NewModal>
    </$GridItem>
  )
}
