import { FlowCall, FlowCutOff } from 'prace-common-components'

/** forces a date to utc */
function dateUTC(date: string | Date) {
  const theDate = typeof date === 'string' ? new Date(date) : date

  const utc =  Date.UTC(
    theDate.getUTCFullYear(),
    theDate.getUTCMonth(),
    theDate.getUTCDate(),
    theDate.getUTCHours(),
    theDate.getUTCMinutes(),
    theDate.getUTCSeconds(),
  )

  return new Date(utc)
}

export function getCurrentCutoff(cutoffs: FlowCall['cutoffs'], date?: Date): FlowCutOff {
  const now = date ? dateUTC(date) : dateUTC(new Date())
  let result

  const sortedCutoffs = [...cutoffs].sort((c) => new Date(c.start).getTime())
  for(const cutoff of sortedCutoffs) {
    if (now >= dateUTC(new Date(cutoff.start)) && (!result || cutoff.start > result.start)) {
      result = cutoff
    }
  }
  return result ?? cutoffs[0]
}
