import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { AutocompleteInput, Item, NewButton, ROLES, ROLE_NAME, ROLE_NAME_INITIALS, SvgIcon, User } from 'prace-common-components'
import { $Container, $List, $ModalContainer, $Paragraph, $UserList, $UserListItem, $Description } from './styles'
import { ChangeUserVisibilityModalProps } from './types'
import { useSearchUsersMutation } from 'store/api/users'
import { UserSuggestion } from 'store/api/users/types'

type UserItemProps = {
  id: number,
  fullName: string,
  onRemoveClick?: (id: number) => void,
}

const UserItem : React.FC<UserItemProps> = ({ id, fullName, onRemoveClick }) => {
  const handleRemoveClick = React.useCallback(() => {
    if (onRemoveClick) onRemoveClick(id)
  }, [id, onRemoveClick])

  return (
    <$UserListItem>
      <SvgIcon
        clickable
        name='close'
        size={16}
        onClick={handleRemoveClick}
      />
      <label>{fullName}</label>
    </$UserListItem>
  )
}

const formatUserLabel = ({ firstName, lastName, email, role }: Partial<UserSuggestion>) => {
  return `${firstName || ''} ${lastName || ''} - ${email || ''} ${role ? `(${ROLE_NAME_INITIALS[role as ROLES || ROLES.USER]})` : ''}`
}

function useUserSuggestionWithSelectedUsers(
  visibility: number[] = [],
  assignerId?: Nullable<number>,
  applicationId?: number,
) {
  const [searchUsers] = useSearchUsersMutation()
  const [userSuggestions, setUserSugestions] = React.useState<User[]>([])
  const [users, setUsers] = React.useState<any[]>([])
  const [selectedUsers, setSelectedUsers] = React.useState<any[]>([])
  const [term, setTerm] = React.useState('')

  React.useEffect(() => {
    const getUsers = async () => {
      try {
        const data = await searchUsers({
          search: term,
          userIds: assignerId ? [...visibility, assignerId] : visibility, // To retrieve the assigner name
          applicationId,
          page: 0,
          pageSize: 50,
        }).unwrap()
        setUserSugestions(data.users || [])
      } catch (err) {
        console.log(err)
      }
    }
    getUsers().catch((err) => console.log(err))
  }, [term, visibility, searchUsers, assignerId, applicationId])
  
  React.useEffect(() => {
    const items: Item[] = []
    userSuggestions.forEach((userSuggestion: User) => {
      items.push({
        label: formatUserLabel(userSuggestion),
        value: userSuggestion.id,
      })
    })
    // Pre-populate the list with the users in the visibility list
    setSelectedUsers((prevState) => ([
      ...prevState,
      ...items
        // filter users included in the visibility list
        .filter((i) => visibility.includes(i.value as number) && (i.value != assignerId))
        .filter((i) => !prevState.map((s) => s.value).includes(i.value)) // filter users already in the previous list
        .map((i) => ({ ...i, visible: true })), // map those items as visible
    ]))
    
    setUsers(items)
  }, [assignerId, userSuggestions, visibility])

  return {
    selectedUsers,
    setSelectedUsers,
    setTerm,
    users,
  }
}

export const ChangeUserVisibilityModal: React.FC<ChangeUserVisibilityModalProps> = ({
  callTitle,
  permissions,
  assignment,
  handleSave,
  handleCancel,
}) => {
  const {
    users, setTerm, setSelectedUsers, selectedUsers,
  } = useUserSuggestionWithSelectedUsers(assignment.visibility, assignment.assignerId, assignment.applicationId)
  const searchTimer = React.useRef<number>()

  const SEARCH_TIMER_TIMEOUT = 500
  const handleInputChange = React.useCallback((_: any, value: string) => {
    clearTimeout(searchTimer.current)
    searchTimer.current = window.setTimeout(() => {
      setTerm(value)
    }, SEARCH_TIMER_TIMEOUT)
  }, [setTerm])

  const handleAutocompleteInput = React.useCallback((_: React.ChangeEvent<unknown>, value: unknown) => {
    const option = value as Item

    // skip when the user is already in the list (only the visible ones)
    if (selectedUsers.filter((u) => u.visible).find((u) => u.value === option.value as number)) {
      return
    }

    setSelectedUsers((prevState) => ([
      ...prevState.filter((u) => u.value !== option.value), // Remove the added one from the previous list
      {
        value: option.value as number,
        label: option.label as string,
        visible: true, // for added users we mark as visible
      },
    ]))
  }, [setSelectedUsers, selectedUsers])

  const handleRemoveUserItemClick = React.useCallback((id: number) => {
    // To Remove we just need to mark the item as `visible` true.
    // We need this to tackle the users in the visibility list, this way avoids the users from list list to appear
    // again in the list component
    setSelectedUsers((selectedUsers) => selectedUsers.map((u) => {
      if (u.value !== id) {
        return u
      }
      
      return {
        ...u,
        visible: false,
      }
    }))
  }, [setSelectedUsers])

  const handleSaveClick = React.useCallback(() => {
    // We must only send the id of the visible users
    handleSave(selectedUsers.filter((u) => u.visible).map((u) => u.value as number))
  }, [handleSave, selectedUsers])

  const assigner = users?.find((u) => u.value == assignment.assignerId)
  const usersNoAssigner = users?.filter((u) => u.value != assignment.assignerId)
  
  return (
    <$ModalContainer>
      <$Description>Give access to users to view this application</$Description>
      <Grid container>
        <Grid item xs={6}>
          <$Paragraph>This assignment is always visible to the following users and roles:</$Paragraph>
          <$List>
            <li>PRO Admins</li>
            {callTitle?.toLowerCase().includes('eurohpc') && <li>EuroHPC Admins</li>}
            <li>{`${assignment?.owner?.firstName || 'Admin'} ${assignment?.owner?.lastName || ''} (Owner)`}</li>
            {assigner ? <li>{`${assigner.label.split('-')[0]} (Assigner)`}</li> : null}
          </$List>
        </Grid>
        <Grid item xs={6}>
          <$Paragraph>After this assignment is submitted, it becomes visible to the following roles:</$Paragraph>
          <$List>
            {permissions?.dataVisibility?.map((role: ROLES) => 
              <li key={role}>{ROLE_NAME[role]}</li>,
            )}
          </$List>
        </Grid>
        <Grid item xs={12}>
          <$Paragraph>Give users view access to this application:</$Paragraph>
          <AutocompleteInput
            title='Search for user'
            name='user'
            required
            items={usersNoAssigner}
            onChange={handleAutocompleteInput}
            onInputChange={handleInputChange}
          />
        </Grid>
      </Grid>
      <Grid item>
        <$UserList>
          {selectedUsers.filter((u) => u.visible).map((selectedUser) => (
            <UserItem
              key={selectedUser.value}
              id={selectedUser.value}
              fullName={`${selectedUser.label}`}
              onRemoveClick={handleRemoveUserItemClick}
            />
          ))}
        </$UserList>
      </Grid>
      <$Container container justifyContent='space-between'>
        <Grid item xs container justifyContent='flex-start'>
          <NewButton variant='outlined' onClick={handleCancel}>Cancel</NewButton>
        </Grid>
        <Grid item xs={3} container justifyContent='flex-end'>
          <NewButton onClick={handleSaveClick}>
            Save
          </NewButton>
        </Grid>
      </$Container>
    </$ModalContainer>
  )
}
