import styled from 'styled-components';
import { Pagination } from '@material-ui/lab';
export const $Pagination = styled(Pagination) `
  .MuiPagination-ul{
    justify-content:center;

    .MuiPaginationItem-root{
      font-family: roboto-light, sans-serif;
      margin: 0px;
      width:40px;
      height:40px;
      border-radius: 0px;
      border-left-width: 0px;
    }

    li .MuiPaginationItem-ellipsis{
      border-width: 0px;
      border-right-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.greyScales[350]};
      justify-content: center;
      align-items: center;
      display: flex;
      padding-bottom: 8px;
    }

    li:first-child .MuiPaginationItem-root{
      border-radius: 5px 0px 0px 5px;
      border-left-width: 1px;
    }

    li:last-child .MuiPaginationItem-root{
      border-radius: 0px 5px 5px 0px;
    }


  }
  margin: 20px;
  flex: 1;
`;
