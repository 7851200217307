import { ELEMENT_TYPES } from '../../';
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["ASSIGN"] = "assign";
    ACTIONS["NOTIFY"] = "notify";
    ACTIONS["VISIBILITY"] = "change_visibility";
})(ACTIONS || (ACTIONS = {}));
export var ACTION_NAMES;
(function (ACTION_NAMES) {
    ACTION_NAMES["assign"] = "Assign";
    ACTION_NAMES["notify"] = "Notify";
    ACTION_NAMES["change_visibility"] = "Visibility";
})(ACTION_NAMES || (ACTION_NAMES = {}));
export var ACTION_EVENTS;
(function (ACTION_EVENTS) {
    ACTION_EVENTS["ASSIGNED"] = "assigned";
    ACTION_EVENTS["DRAFT"] = "draft";
    ACTION_EVENTS["ACCEPTED"] = "accepted";
    ACTION_EVENTS["REJECTED"] = "rejected";
    ACTION_EVENTS["SUBMITTED"] = "submitted";
    ACTION_EVENTS["LEAD_ASSIGNMENT_SUBMITTED"] = "lead_assignment_submitted";
    ACTION_EVENTS["NON_LEAD_ASSIGNMENT_SUBMITTED"] = "non_lead_assignment_submitted";
    ACTION_EVENTS["LEAD_ASSIGNED"] = "lead_assigned";
    ACTION_EVENTS["NON_LEAD_ASSIGNED"] = "non_lead_assigned";
    ACTION_EVENTS["RESUBMITTED"] = "resubmitted";
    ACTION_EVENTS["REVOKED"] = "revoked";
    ACTION_EVENTS["REFUSED"] = "refused";
    ACTION_EVENTS["CHANGES_REQUESTED"] = "changes_requested";
    ACTION_EVENTS["APPROVED"] = "approved";
    ACTION_EVENTS["ALL_SUBMITTED"] = "all_submitted";
    ACTION_EVENTS["INVITED"] = "invited";
    ACTION_EVENTS["UNLOCKED"] = "unlocked";
})(ACTION_EVENTS || (ACTION_EVENTS = {}));
export var EVENT_NAMES;
(function (EVENT_NAMES) {
    EVENT_NAMES["assigned"] = "User assigned";
    EVENT_NAMES["revoked"] = "Assignment revoked";
    EVENT_NAMES["accepted"] = "User accepted";
    EVENT_NAMES["refused"] = "User refused";
    EVENT_NAMES["rejected"] = "Admin rejected";
    EVENT_NAMES["submitted"] = "User submitted";
    EVENT_NAMES["lead_assignment_submitted"] = "Lead assignment submitted";
    EVENT_NAMES["non_lead_assignment_submitted"] = "Non-lead assignment submitted";
    EVENT_NAMES["lead_assigned"] = "Lead assigned";
    EVENT_NAMES["non_lead_assigned"] = "Non-lead assigned";
    EVENT_NAMES["resubmitted"] = "User resubmitted";
    EVENT_NAMES["changes_requested"] = "Changes requested";
    EVENT_NAMES["approved"] = "Admin approved";
    EVENT_NAMES["all_submitted"] = "All assignments submitted";
    EVENT_NAMES["invited"] = "User invited";
    EVENT_NAMES["unlocked"] = "Step unlocked";
    EVENT_NAMES["draft"] = "";
})(EVENT_NAMES || (EVENT_NAMES = {}));
export var FIRST_STEP_NAMES;
(function (FIRST_STEP_NAMES) {
    FIRST_STEP_NAMES["revoked"] = "";
    FIRST_STEP_NAMES["draft"] = "Application created";
    FIRST_STEP_NAMES["assigned"] = "";
    FIRST_STEP_NAMES["accepted"] = "";
    FIRST_STEP_NAMES["rejected"] = "Application rejected";
    FIRST_STEP_NAMES["submitted"] = "Application submitted";
    FIRST_STEP_NAMES["lead_assignment_submitted"] = "Lead assignment submitted";
    FIRST_STEP_NAMES["non_lead_assignment_submitted"] = "Non-lead assignment submitted";
    FIRST_STEP_NAMES["lead_assigned"] = "Lead assigned";
    FIRST_STEP_NAMES["non_lead_assigned"] = "Non-lead assigned";
    FIRST_STEP_NAMES["resubmitted"] = "Application resubmitted";
    FIRST_STEP_NAMES["changes_requested"] = "Changes requested";
    FIRST_STEP_NAMES["approved"] = "Application approved";
    FIRST_STEP_NAMES["all_submitted"] = "All assignments submitted";
    FIRST_STEP_NAMES["invited"] = "User invited";
    FIRST_STEP_NAMES["unlocked"] = "Step unlocked";
    FIRST_STEP_NAMES["refused"] = "";
})(FIRST_STEP_NAMES || (FIRST_STEP_NAMES = {}));
export var ACTION_TARGET_TYPES;
(function (ACTION_TARGET_TYPES) {
    ACTION_TARGET_TYPES["APPLICATION_OWNER"] = "application_owner";
    ACTION_TARGET_TYPES["ASSIGNMENT_OWNER"] = "owner";
    ACTION_TARGET_TYPES["ASSIGNER"] = "assigner";
    ACTION_TARGET_TYPES["LEAD_ASSIGNMENT_OWNER"] = "lead_assignment_owner";
    ACTION_TARGET_TYPES["NON_LEAD_ASSIGNMENT_OWNER"] = "non_lead_assignment_owner";
    ACTION_TARGET_TYPES["APPROVED_ASSIGNMENTS"] = "approved_assignments";
    ACTION_TARGET_TYPES["PARTITION_CCR"] = "partition_ccr";
    ACTION_TARGET_TYPES["FLOW_ELEMENT_EMAIL"] = "form_element_email";
    ACTION_TARGET_TYPES["ALL_ASSIGNMENT_OWNERS"] = "assignment_owners";
    ACTION_TARGET_TYPES["PROS"] = "admins";
})(ACTION_TARGET_TYPES || (ACTION_TARGET_TYPES = {}));
export var ACTION_TARGET_NAMES;
(function (ACTION_TARGET_NAMES) {
    ACTION_TARGET_NAMES["application_owner"] = "Application owner";
    ACTION_TARGET_NAMES["owner"] = "Triggering assignment owner";
    ACTION_TARGET_NAMES["assigner"] = "Triggering assignment assigner";
    ACTION_TARGET_NAMES["lead_assignment_owner"] = "Lead assignment owner";
    ACTION_TARGET_NAMES["non_lead_assignment_owner"] = "Non-lead assignment owners";
    ACTION_TARGET_NAMES["partition_ccr"] = "Partition CCR";
    ACTION_TARGET_NAMES["form_element_email"] = "User email";
    ACTION_TARGET_NAMES["assignment_owners"] = "All assignment owners";
    ACTION_TARGET_NAMES["approved_assignments"] = "Submitted assignment owners";
    ACTION_TARGET_NAMES["admins"] = "PROs";
})(ACTION_TARGET_NAMES || (ACTION_TARGET_NAMES = {}));
export const ELEMENT_BASE_PARAMS = [
    'id',
    'parentId',
    'callId',
    'type',
    'elementType',
    'deadline',
    'title',
    'description',
    'order',
    'effects',
    'automations',
    'permissions',
    'flow',
    'optionsGroup',
    'cutoffs',
    'items',
    'value',
    'timeframes',
];
export var EFFECTS;
(function (EFFECTS) {
    EFFECTS["VISIBLE"] = "visible";
    EFFECTS["REQUIRED"] = "required";
    EFFECTS["VALID"] = "valid";
    EFFECTS["EDITABLE"] = "editable";
    EFFECTS["SUM"] = "sum";
})(EFFECTS || (EFFECTS = {}));
export var VALIDATION_TYPES;
(function (VALIDATION_TYPES) {
    VALIDATION_TYPES["EQUAL"] = "equal";
    VALIDATION_TYPES["NOT_EQUAL"] = "not_equal";
    VALIDATION_TYPES["MIN_LENGTH"] = "min_length";
    VALIDATION_TYPES["MAX_LENGTH"] = "max_length";
    VALIDATION_TYPES["LENGTH_RANGE"] = "length_range";
    VALIDATION_TYPES["REQUIRED"] = "required";
    VALIDATION_TYPES["GREATER"] = "greater";
    VALIDATION_TYPES["LESSER"] = "lesser";
    VALIDATION_TYPES["UNCONDITIONAL"] = "unconditional";
    VALIDATION_TYPES["NON_EMPTY"] = "non_empty";
})(VALIDATION_TYPES || (VALIDATION_TYPES = {}));
export const USER_STATUS = {
    Blocked: 'blocked',
    Locked: 'locked',
    Active: 'active',
};
