import { FlowCall } from 'prace-common-components'
import { BaseEditor, Descendant } from 'slate'
import { HistoryEditor } from 'slate-history'
import { ReactEditor } from 'slate-react'
import { Template, TemplateElement, Variable as VariableType } from 'store/api/templates/types'
import { ConditionElement, LinkElement } from './custom-types'

export const LIST_TYPES = ['numbered-list', 'bulleted-list']
export const GROUP_TYPES = ['condition', 'group']
export const INLINE_TYPES = ['link', 'variable']
export const TEXT_ALIGN_TYPES = ['left', 'center']

export interface ValidatedVariable extends VariableType {
  error: boolean,
}

export interface EditorControllerProps {
  call: FlowCall,
  template: Template,
  onSave: (template: Template) => void,
  onExample: (id: string, applicationId: string, assignmentId: string) => void,
}

export interface BodySlateEditorProps {
  call: FlowCall,
  editor: BaseEditor & ReactEditor & HistoryEditor,
  value: Descendant[],
  chars: ValidatedVariable[],
  onChangeState: (state: Descendant[]) => void,
  onChangeSearch: (search: string) => void,
  focusEditor: (editor: BodySlateEditorProps['editor']) => void,
  handleGroupCreation:  (groupElement: TemplateElement) => void,
  handleConditionButton: (e: React.MouseEvent<HTMLButtonElement>) => void,
  onEditCondition: (element: ConditionElement) => void,
  onEditLink: (element: LinkElement) => void,
  onDeleteCondition: () => void,
  onDeleteGroup: () => void,
  openLink: () => void,
}

export type SubjectSlateEditorProps = Omit<BodySlateEditorProps, 'handleGroupCreation' | 'handleConditionButton' | 'onEditCondition' | 'onDeleteCondition' | 'onDeleteGroup' | 'onEditLink' | 'openLink'>
