import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { NewButton, Select, Input, FLOW_STATUS } from 'prace-common-components'
import { ReviewModalProps } from './types'
import { $ModalContainer, $Grid, $Container } from './styles'

const reviewItems = [
  { label: 'Approve', value: FLOW_STATUS.APPROVED },
  { label: 'Reject', value: FLOW_STATUS.REJECTED },
]

type ReviewType = FLOW_STATUS.APPROVED | FLOW_STATUS.REJECTED

const DEFAULT_REASON = 'There were no inconsistencies found during the Administrative Check.'

export const ReviewModal: React.FC<ReviewModalProps> = ({ handleReview, handleCancel }) => {
  const [data, setData] =
  React.useState<{review: Nullable<ReviewType>; deadline: Nullable<string>; reason: Nullable<string>}>(
    { review: null, deadline: null, reason: DEFAULT_REASON},
  )

  const onChange = (name: string, value: Nullable<StringNumber>) => setData((data) => ({...data, [name]: value}))

  const submitReview = () => {
    if(data.review === FLOW_STATUS.APPROVED || (data.review && data.reason))
      handleReview(data.review as string, data.deadline || undefined, data.reason || undefined)
  }

  return (
    <$ModalContainer>
      <>
        <$Grid container>
          <Grid item xs={12}>
            <Select title='Review Score' name='review' items={reviewItems} value={data.review} required onChange={onChange} />
          </Grid>
          <Grid item xs={12}>
            <Input onChange={onChange} defaultValue={DEFAULT_REASON} title='Add a Reason' name='reason' required={data.review !== FLOW_STATUS.APPROVED} multiline minRows={5} type='text' hideItalic/>
          </Grid>
        </$Grid>
        <$Container container justifyContent='space-between'>
          <Grid item xs={3}>
            <NewButton onClick={submitReview}>Review</NewButton>
          </Grid>
          <Grid item xs container justifyContent='flex-end'>
            <NewButton variant='outlined' onClick={handleCancel}>Cancel</NewButton>
          </Grid>
        </$Container>
      </>
    </$ModalContainer>
  )
}
