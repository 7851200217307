import React from 'react';
import { MenuItem } from '@material-ui/core';
import { NestedMenuItem } from './NestedMenuItem';
import { $Menu } from './styles';
export const NestedMenu = ({ anchorEl, setAnchorEl, items, handleItemClick, ...rest }) => {
    const handleNested = (menuItem) => {
        const { id, label, onItemClick = () => null, items } = menuItem;
        const handleClick = handleItemClick ? () => handleItemClick(id) : onItemClick;
        return items ?
            React.createElement(NestedMenuItem, { key: id, label: label, parentMenuOpen: !!anchorEl, onClick: handleClick }, items.map((it) => handleNested(it)))
            :
                React.createElement(MenuItem, { key: id, onClick: handleClick }, label);
    };
    return (React.createElement($Menu, { ...rest, open: !!anchorEl, onClose: () => setAnchorEl(null), getContentAnchorEl: null, anchorEl: anchorEl, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
        } }, items.map((item) => {
        return handleNested(item);
    })));
};
