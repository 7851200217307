import styled from 'styled-components';
import { newTheme as theme } from '..';
export const $StepLabel = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${theme.colors.text.headLine};
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  cursor: pointer;
  padding: 4px 12px;
  border-radius: 4px;
  transition: 0.3s;
  text-align: center;

  &:hover {
    background-color: ${({ hoverFeedback }) => hoverFeedback ? theme.colors.feedback.success : undefined};
    color: ${({ hoverFeedback }) => hoverFeedback ? 'white' : undefined};
  }
`;
export const $NumberBox = styled.span `
  background-color: ${theme.colors.feedback.success};
  border-radius: 2px;
  color: white;
  padding: 2px 6px;
  margin-left: 5px;
`;
export const $OptionsIndicator = styled.div `
  pointer-events: none;
  display: flex;
  align-items: center;
  margin-left: 10px
`;
