import styled from 'styled-components';
import { Button } from '@material-ui/core';
export const $Button = styled(Button) `
  width: max-content;
  min-height: 38px;
  cursor: pointer;

  &.MuiButton-root {
    font-size: 16px;
    font-family: roboto-medium, sans-serif;
    text-transform: none;
    letter-spacing: normal;
  }

  &.MuiButton-contained {
    background-color: ${({ theme, btncolor }) => btncolor || theme.colors.secondary};
    box-shadow: none;
    color: white;
  }

  &.MuiButton-outlined {
    border: 1px solid ${({ theme, btncolor }) => btncolor || theme.colors.secondary};
    box-shadow: none;
    color: ${({ theme, btncolor }) => btncolor || theme.colors.secondary};
  }

  :disabled {
    &.MuiButton-outlined.Mui-disabled,
    &.MuiButton-contained.Mui-disabled {
      box-shadow: none;
      background-color: ${({ theme }) => theme.colors.button.disabled};
      color: ${({ theme }) => theme.colors.text.primary};
      cursor: default;
    }
  }

  :hover {
    &.MuiButton-outlined,
    &.MuiButton-contained {
      box-shadow: none;
      background-color: ${({ theme, btncolor }) => btncolor || theme.colors.secondary};
      filter: brightness(110%);
      color: white;
    }
  }
`;
