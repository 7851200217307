import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
export const $ModalContainer = styled(Grid) ``;
export const $ActionQuestion = styled.p `
  font-size: 1.1em;
`;
export const $Description = styled.p `
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  color: #75767F;
  font-size: 1.1em;
`;
