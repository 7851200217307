import React from 'react';
import { $IconWrapper } from './styles';
import { FORM_ELEMENT_TYPES, SvgIcon } from '../';
export const ElementIcon = ({ size = 16, color = '#757778', type }) => {
    const icon = () => {
        switch (type) {
            case FORM_ELEMENT_TYPES.INSTRUCTIONS:
                return React.createElement(SvgIcon, { name: 'description-outlined', color: color, size: 24 });
            case FORM_ELEMENT_TYPES.MULTISELECT:
            case FORM_ELEMENT_TYPES.SELECT_PARTITION:
            case FORM_ELEMENT_TYPES.PARTITION_RESOURCES:
            case FORM_ELEMENT_TYPES.SELECT:
            case FORM_ELEMENT_TYPES.UPLOAD:
            case FORM_ELEMENT_TYPES.TOGGLE:
            case FORM_ELEMENT_TYPES.CHECKBOX:
                return React.createElement(SvgIcon, { name: 'category-outlined', color: color, size: 24 });
            case FORM_ELEMENT_TYPES.PHONE_NUMBER:
            case FORM_ELEMENT_TYPES.NUMBER:
            case FORM_ELEMENT_TYPES.DATE:
                return React.createElement(SvgIcon, { name: 'number', color: color, size: 24 });
            case FORM_ELEMENT_TYPES.GROUP:
                return React.createElement(SvgIcon, { name: 'hive', color: color, size: 24 });
            case FORM_ELEMENT_TYPES.SPACE:
                return React.createElement(SvgIcon, { name: 'space', color: color, size: 24 });
            case FORM_ELEMENT_TYPES.DIVIDER:
                return React.createElement(SvgIcon, { name: 'divider', color: color, size: 24 });
            default:
                return React.createElement(SvgIcon, { name: 'text-fields', color: color, size: 24 });
        }
    };
    return React.createElement($IconWrapper, { color: color, size: size }, icon());
};
