import React, { useCallback } from 'react'
import { Input, Title, ElementValue } from 'prace-common-components'
import { $ElementContainer, $FieldContainer } from './styles'
import { MinMaxConfigProps } from './types'
import Grid from '@material-ui/core/Grid'

export const MinMaxConfig: React.FC<MinMaxConfigProps> = ({ onChange, min, max, minName, maxName, title = '' }) => {

  const handleChange = useCallback((name: string, value: ElementValue) => {
    onChange(name, value as number)
  }, [onChange])

  return (
    <$FieldContainer container alignItems='center'>
      <Title subTitle>
        {title}
      </Title>
      <$ElementContainer container>
        <Grid item xs>
          <Input type='number' debounce hideItalic title='Minimum' name={minName} value={min} onChange={(name: string, value: ElementValue) => {
            if(min !== value) handleChange(name, value)
          }} />
        </Grid>
        <Grid item xs>
          <Input type='number' debounce hideItalic title='Maximum' name={maxName} value={max} onChange={(name: string, value: ElementValue) => {
            if(max !== value) handleChange(name, value)
          }} />
        </Grid>
      </$ElementContainer>
    </$FieldContainer>
  )
}
