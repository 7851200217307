import * as React from 'react'
import { ACTION_NAMES, NewButton, Tooltip, getElementName } from 'prace-common-components'
import { $Container, $List, $ModalContainer, $Paragraph, $Description, $LinkItem } from './styles'
import { DeleteElementModalProps } from './types'
import Grid from '@material-ui/core/Grid'
import { conditionText } from 'util/conditionUtils'
import { getActionSubTitle } from 'util/getActionSubTitle'
import { Routes } from 'constants/global'
import { getElementUsage } from 'util/getElementUsage'
import { RootState } from 'store'
import { useAppSelector } from 'store/hooks'

export const DeleteElementModal: React.FC<DeleteElementModalProps> = ({
  call,
  element,
  firstStep,
  handleDelete,
  handleCancel,
}) => {
  const storeTemplates = useAppSelector((state: RootState) => state.calls.templates)
  const {
    automations,
    conditions,
    templates,
  } = getElementUsage(element.id, call, storeTemplates || [])

  const enableDelete = !(automations.length || conditions.length || templates.length)
  
  return (
    <$ModalContainer>
      <$Description>{enableDelete ? 'There are no automations, conditions or email templates using this element.' : 'You need to stop using this element on automations, conditions and email templates before deleting it.'}</$Description>
      <Grid container>
        {!!(automations.length || conditions.length) && <Grid item xs={6}>
          {!!automations.length && 
          <>
            <$Paragraph>There are automations using this element:</$Paragraph>
            <$List>
              {automations.map((automation) => 
                <li key={automation.id}>{`${ACTION_NAMES[automation.type]}: ${getActionSubTitle(automation, storeTemplates, call, firstStep)}`}</li>,
              )}
            </$List>
          </>}
          {!!conditions.length && <>
            <$Paragraph>These elements have conditions using this element:</$Paragraph>
            <$List>
              {conditions.map((condition) => 
                <Tooltip
                  placement='top'
                  key={condition.id}
                  show
                  text={conditionText(call, condition)}
                >
                  <li>{`${getElementName(condition?.elementId || condition?.element?.id || 0, call)}`}</li>
                </Tooltip>,
              )}
            </$List>
          </>
          }
        </Grid>}
        {!!templates.length && <Grid item xs={6}>
          <$Paragraph>There are email templates using this element:</$Paragraph>
          <$List>
            {templates.map((template) => 
              <li key={template.id}>
                <$LinkItem target='_blank' rel='noopener noreferrer' href={Routes.TEMPLATE(template.id)} >{template.title}</$LinkItem>
              </li>,
            )}
          </$List>
        </Grid>}
      </Grid>
      <$Container container justifyContent='space-between'>
        <Grid item xs container justifyContent='flex-start'>
          <NewButton variant='outlined' onClick={handleCancel}>Cancel</NewButton>
        </Grid>
        <Grid item xs={3} container justifyContent='flex-end'>
          <NewButton disabled={!enableDelete} onClick={handleDelete}>
            Save
          </NewButton>
        </Grid>
      </$Container>
    </$ModalContainer>
  )
}
