import React from 'react'
import { IUserInfoContext } from './types'
import { User } from 'prace-common-components'
import { useGetUsersByIdsMutation } from 'store/api/users'

const UserInfoContext = React.createContext<IUserInfoContext>({
  ids: {},
  loading: false,
  getInfo: () => undefined,
})

export const UserInfoProvider: React.FC<any> = ({ children }) => {
  const [getUsersInfo] = useGetUsersByIdsMutation()
  const debounce = React.useRef<number>()
  const pool = React.useRef<Set<number>>(new Set()).current

  const [state, setState] = React.useState({
    ids: {},
    loading: false,
  })

  const fetchIdsInfo = async () => {
    const ids = [...pool]
    pool.clear()

    setState((s) => ({ ...s, loading: true  }))

    try {
      const users = await getUsersInfo(ids).unwrap()
      if (!users?.length) throw Error('No users found.')

      setState((s) => {
        const newIds: Record<number, User> = { ...s.ids }

        users.forEach((user) => {
          newIds[user.id] = user
        })

        return { ...s, loading: false, ids: newIds  }
      })
    } catch(error) {
      setState((s) => ({ ...s, loading: false  }))
    }
  }

  const getInfo = (id: number) => {
    pool.add(id)

    if (!state.loading) {
      clearTimeout(debounce.current)

      debounce.current =  window.setTimeout(() => {
        fetchIdsInfo()
      }, 600)
    }
  }

  return (
    <UserInfoContext.Provider value={{...state, getInfo}}>
      {children}
    </UserInfoContext.Provider>
  )
}
