import { FLOW_STATUS, FORM_ELEMENT_TYPES, ADMIN_PORTAL_ROLES } from '../';
import update from 'immutability-helper';
export const getSelectedStepAssignment = (user, application) => {
    let updatedApplication = application;
    const steps = application.call.flow || [];
    let editingAssignment = false;
    let editing = { step: 0, assignment: 0 };
    let moreAdvancedAssignment = { step: 0, assignment: 0 };
    let canDelete = false;
    let newDraft = true;
    const firstStepAssignments = steps[0]?.assignments || [];
    newDraft = steps[0]?.order ? false : (!firstStepAssignments.length ||
        (!!firstStepAssignments.length && firstStepAssignments[0].status == FLOW_STATUS.DRAFT));
    const assigningStep = steps.findIndex((step) => (step.permissions?.assign?.length && user) ? (step.permissions?.assign?.includes(user.role)) : false);
    steps.forEach((flowStep, i) => {
        if (editingAssignment)
            return;
        const assignments = flowStep.assignments || [];
        const editAssignment = assignments.findIndex((assignment) => assignment.ownerId ?
            (assignment.ownerId == user?.id &&
                (!assignment.submittedAt || assignment.status == FLOW_STATUS.ACCEPTED ||
                    (assignment.submittedAt && assignment.status == FLOW_STATUS.CHANGES_REQUESTED)))
            : ADMIN_PORTAL_ROLES.includes(user?.role));
        if (editAssignment > -1 && !editingAssignment) {
            editingAssignment = true;
            editing = { step: i, assignment: editAssignment };
            if ((i === 0) && !canDelete && (user?.id == application?.ownerId) &&
                assignments[editAssignment].status == FLOW_STATUS.DRAFT)
                canDelete = true;
        }
        else {
            if (firstStepAssignments.length && firstStepAssignments[0].status == FLOW_STATUS.DRAFT)
                moreAdvancedAssignment = { step: 0, assignment: 0 };
            else
                moreAdvancedAssignment = { step: assigningStep > -1 ? assigningStep : i, assignment: 0 };
        }
    });
    const newDatas = [];
    let childDatas = [];
    let editingAssign = undefined;
    if (editingAssignment) {
        const editStep = steps[editing.step];
        editingAssign = editStep.assignments[editing.assignment];
        if (editingAssign.status == FLOW_STATUS.CHANGES_REQUESTED
            || editingAssign.status == FLOW_STATUS.DRAFT
            || editingAssign.status == FLOW_STATUS.ACCEPTED) {
            for (let formIdx = 0; formIdx < (editStep.flow || []).length; formIdx++) {
                const editForm = editStep.flow[formIdx];
                for (let elementIdx = 0; elementIdx < (editForm.flow || []).length; elementIdx++) {
                    const element = editForm.flow[elementIdx];
                    if (element.elementType == FORM_ELEMENT_TYPES.GROUP) {
                        const groupData = editingAssign.data.find((data) => data.flowElementId == element.id);
                        if (!groupData && !element.config?.hidden) {
                            const groupFeId = `create-${element.id}-${0}`;
                            newDatas.push({ id: groupFeId, feId: groupFeId, flowElementId: element.id, needCreate: true, input: { values: [''], order: 0 } });
                            childDatas = [...childDatas, ...(element.flow || [])
                                    .filter((child) => !child.config?.hidden)
                                    .map((child, order) => ({ id: `create-${child.id}-${0}`, noData: true, feId: `create-${child.id}-${0}`, flowElementId: child.id, dataGroupId: groupFeId, order, input: { values: [''], order } }))];
                            updatedApplication = update(updatedApplication, {
                                call: {
                                    flow: {
                                        [editing.step]: {
                                            flow: {
                                                [formIdx]: {
                                                    flow: {
                                                        [elementIdx]: {
                                                            instances: {
                                                                $set: [{
                                                                        id: 0,
                                                                        flowElementId: element.id,
                                                                        isEditable: true,
                                                                        assignmentId: editingAssign.id,
                                                                        childInstances: element.flow.map((child, idx) => ({
                                                                            id: idx,
                                                                            flowElementId: child.id,
                                                                            assignmentId: editingAssign.id,
                                                                            dataGroupId: groupFeId,
                                                                            dataGroup: { flowElementId: element.id },
                                                                            elementType: child.elementType,
                                                                            value: '',
                                                                            noData: true,
                                                                        })),
                                                                    }],
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                            });
                        }
                    }
                }
            }
            updatedApplication = update(updatedApplication, {
                call: {
                    flow: {
                        [editing.step]: {
                            assignments: {
                                [editing.assignment]: {
                                    data: {
                                        $set: [...(editingAssign?.data || []), ...newDatas, ...childDatas],
                                    }
                                }
                            }
                        }
                    }
                },
            });
        }
    }
    return { editingAssign, editing, moreAdvancedAssignment, newDatas, childDatas, canDelete, newDraft, updatedApplication };
};
