import * as React from 'react';
import { Logo } from '../SvgIcon';
import { $Footer, $FooterContainer, $Description, $FooterLinks, $Link, $Name } from './styles';
export const Footer = ({ links, logo, name, homepage, desc }) => {
    return (React.createElement($Footer, null,
        React.createElement($FooterContainer, null,
            React.createElement($Link, { href: homepage },
                React.createElement(Logo, { logo: logo }),
                React.createElement($Name, null, name)),
            React.createElement("div", null,
                React.createElement($FooterLinks, null, links && links.map((link, idx) => React.createElement($Link, { key: idx, href: link.href, target: '_blank', rel: 'noopener' },
                    " ",
                    link.text,
                    " "))),
                React.createElement($Description, null, desc)))));
};
