import React from 'react'
import { getFlowElement } from 'prace-common-components'
import { insertVariable } from '../EditorController'
import { $Variables } from '../styles'
import { ValidatedVariable } from '../types'
import { VariablesProps } from './types'
import { Variable } from './Variable'
import { $AddVariableButton, $VariableButton } from './styles'

export const Variables: React.FC<VariablesProps> = (
  { activeEditor, variables, openAddVariable, onRemoveVariable, call },
) => {

  const handleRemove = (variable: ValidatedVariable) => {
    if(onRemoveVariable) onRemoveVariable(variable)
  }

  const handleAddVariable = (e: React.MouseEvent<HTMLDivElement>, variable: ValidatedVariable) => {
    const target = e.target as typeof e.target & { className: string }
    /* Makes sure that we do not handle remove mouse clicks */
    if(target.className?.includes('variable')) insertVariable(activeEditor, variable.name, variable.id, variable)
  }

  return (
    <$Variables container alignItems='center'>
      {variables.map((variable: ValidatedVariable) => {
        const variableEl = getFlowElement(call, variable.flowElementId)
        const defaultVariable = variable.variableType === 'default'
        let tooltipText = ''
        if(variableEl.element || defaultVariable) tooltipText = defaultVariable ? 'This is a default variable' :  `${variableEl.step?.title} > ${variableEl.form?.title} > ${variableEl.element?.title}`
        return <$VariableButton
          key={variable.id}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => handleAddVariable(e, variable)}
        >
          <Variable
            tooltipText={tooltipText}
            onRemove={() => handleRemove(variable)}
            defaultVar={defaultVariable}
            element={{
              type: 'variable',
              character: variable.name,
              id: variable.id,
              error: variable.error,
              element: variable.flowElementId,
              groupElement: variable.groupElement,
              children: [{ text: '' }],
            }}
          />
        </$VariableButton>})}
      <$AddVariableButton variant='outlined' creator onClick={openAddVariable}>
        Add variable
      </$AddVariableButton>
    </$Variables>
  )
}

