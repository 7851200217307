import styled from 'styled-components';
import Collapse from '@material-ui/core/Collapse';
export const $Collapse = styled(Collapse) `
  &.MuiCollapse-root {
    width: 100%;
  }
`;
export const $TitleContainer = styled.div `
  width: ${({ fullWidth }) => fullWidth ? '100%' : '33%'};
  margin-left: ${({ theme, fullWidth }) => !fullWidth && theme.spacing(0.5)};
`;
