import styled from 'styled-components';
import { $FormElementControl } from '../newFormElement/styles';
export const $FormControl = styled($FormElementControl) `
  width: 100%;
  &.MuiFormControl-root {
    display: flex;
    flex-direction: row;
    align-items: ${({ description }) => description ? 'center' : 'flex-end'};
    gap: 16px;
  }
`;
export const $InputFileHidden = styled.input `
  position: absolute;
  display: hidden;
  pointer-events: none;
  opacity: 0;
  height: 0;
  width: 0;
`;
