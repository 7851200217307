import styled from 'styled-components';
import { TextField } from '@material-ui/core';
export const $FormField = styled(TextField) `
  & .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.feedback.focus};
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.feedback.focus};
  }
`;
