import { FORM_ELEMENT_TYPES } from '../newFormElement';
export const getFlowElementProps = (element) => {
    const { elementType, title, description, optionsGroup, items, config } = element;
    const required = element.required || config?.required;
    switch (elementType) {
        case FORM_ELEMENT_TYPES.NUMBER:
        case FORM_ELEMENT_TYPES.TEXT_AREA:
        case FORM_ELEMENT_TYPES.PARTITION_RESOURCES:
            return { title, description, ...config, required };
        case FORM_ELEMENT_TYPES.DATE:
            return { title, description, ...config, required, min: config?.min ? new Date(config.min) : undefined, max: config?.max ? new Date(config.max) : undefined };
        case FORM_ELEMENT_TYPES.PHONE_NUMBER:
        case FORM_ELEMENT_TYPES.CHECKBOX:
            return { title, description, ...config, required };
        case FORM_ELEMENT_TYPES.TITLE:
            return { title };
        case FORM_ELEMENT_TYPES.INSTRUCTIONS:
            return { description };
        case FORM_ELEMENT_TYPES.TOGGLE:
        case FORM_ELEMENT_TYPES.MULTISELECT:
        case FORM_ELEMENT_TYPES.SELECT_PARTITION:
        case FORM_ELEMENT_TYPES.SELECT:
            return { title, description, optionsGroup, items, ...config, required };
        case FORM_ELEMENT_TYPES.SPACE:
        case FORM_ELEMENT_TYPES.DIVIDER:
            return {};
        default:
            return { title, description, ...config, required };
    }
};
