import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useWait } from '../../utils';
import { SvgIcon } from '../../SvgIcon';
import { $Sortable, $Handle, $SorTableContent } from './styles';
import { useTheme } from 'styled-components';
export const Sortable = ({ item, type, dropType, onMove, onDrop = () => null, onEndDrag = () => null, selected, alternate, children, onlyHandleDrag = true, notSortable, onlyHover, noPointerEvents, }) => {
    const theme = useTheme();
    const dragRef = useRef(null);
    const previewRef = useRef(null);
    const { wait, done } = useWait();
    const { id, order } = item;
    const [{ handlerId }, drop] = useDrop({
        accept: dropType,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        drop(dropItem, monitor) {
            const dragIndex = dropItem.order;
            const hoverIndex = order;
            const dragType = monitor.getItemType();
            if (dragIndex === hoverIndex && dragType === type)
                return;
            const dragItem = monitor.getItem();
            onDrop({ dragIndex, hoverIndex, type, dragType, dragItem });
        },
        hover(hoverItem, monitor) {
            if (!previewRef.current || !done)
                return;
            const dragIndex = hoverItem.order;
            const hoverIndex = order;
            if (id === hoverItem.id)
                return;
            const dragType = monitor.getItemType();
            if (dragIndex === hoverIndex && dragType === type)
                return;
            const hoverBoundingRect = previewRef.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
                return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
                return;
            const dragItem = monitor.getItem();
            wait(150);
            onMove({ dragIndex, hoverIndex, dragType, dragItem });
            hoverItem.order = hoverIndex;
        },
    });
    const [{ isDragging }, drag, preview] = useDrag({
        type,
        item,
        end: () => {
            onEndDrag();
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    if (onlyHandleDrag) {
        drop(preview(previewRef));
        drag(dragRef);
    }
    else {
        drop(drag(preview(previewRef)));
    }
    if (notSortable)
        return React.createElement(React.Fragment, null, children);
    return (React.createElement($Sortable, { ref: previewRef, selected: selected, alternate: alternate, isDragging: isDragging, "data-handler-id": handlerId, drag: !onlyHandleDrag, onlyHover: onlyHover },
        React.createElement($Handle, { ref: onlyHandleDrag ? dragRef : undefined },
            React.createElement(SvgIcon, { name: 'drag', color: alternate ? theme.colors.primary : theme.colors.white, size: 24 })),
        React.createElement($SorTableContent, { noPointerEvents: noPointerEvents, noPadding: alternate }, children)));
};
