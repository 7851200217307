import Grid from '@material-ui/core/Grid';
import styled, { css } from 'styled-components';
export const $Card = styled.div `
  margin: ${({ theme }) => `${theme.spacing(1)} 0px`};
  border: ${({ theme, noBorder }) => !noBorder && `1px solid ${theme.colors.cards.border}`};
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: ${({ theme, noPadding }) => !noPadding && `${theme.spacing(1)} ${theme.spacing(2)}`};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
${({ noBorder }) => !noBorder &&
    css `
      :hover {
        ${({ theme }) => theme.elevations[1]};
        border-left: 2px solid ${({ theme }) => theme.colors.primaries.heavy};
      }
    `};
`;
export const $CardTexts = styled(Grid) `
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  &.MuiGrid-item {
    margin-left: ${({ theme }) => theme.spacing(1.5)};
  }
`;
export const $CardElementTitle = styled.div `
  font-size: 1.2em;
  white-space: normal;
  color: ${({ theme, error }) => error ? theme.colors.feedback.error : theme.colors.primaries.heavy};
`;
export const $CardElementSubTitle = styled.div `
  font-size: 0.9em;
  white-space: normal;
  color: ${({ theme }) => theme.colors.black};
  a { color: inherit; }
`;
export const $CardElementSubText = styled.div `
  font-size: 0.8em;
  white-space: normal;
  color: #8F909A;
`;
