import React from 'react';
import { $InputLabel } from '../newInput';
import { $Select, $FormControl, $SelectPlaceholder, $MenuItem } from './styles';
import { $Input, $FormHelperText } from '../newInput/styles';
import { SvgIcon } from '../SvgIcon';
import { $Italic } from '../newTypography';
export const ExpandIcon = ({ ...rest }) => React.createElement(SvgIcon, { name: 'chevron-down', size: 24, ...rest });
export const Select = ({ title = '', type, hideItalic, multiline, disabled, error, errorMsg, required = false, description = null, noBorder = false, hideLabel = false, name, placeholder = '', items = [], value, onChange, onEditItem }) => {
    const handleChange = (e) => {
        e.preventDefault();
        const { value } = e.target;
        if (onChange)
            onChange(name, value === '' ? null : value);
    };
    const onEdit = (e, value) => {
        e.stopPropagation();
        if (onEditItem)
            onEditItem(value);
    };
    return (React.createElement($FormControl, null,
        !hideLabel && title &&
            React.createElement($InputLabel, { disableAnimation: true, shrink: true },
                title + (required ? '*' : ''),
                !hideItalic &&
                    React.createElement($Italic, null, type)),
        React.createElement($Select, { value: (value == null || value == '') ? '' : (value ? String(value) : undefined), error: error, multiline: multiline, hideLabel: hideLabel || !title, onChange: (e) => handleChange(e), input: React.createElement($Input, { autoComplete: 'new-password', name: name }), IconComponent: ExpandIcon, displayEmpty: true, name: name, noBorder: noBorder, disabled: disabled, renderValue: (v) => {
                const item = items.find((it) => it.value == v);
                return React.createElement($SelectPlaceholder, { noSelection: !item }, item?.label ? `${item.label}` : placeholder);
            }, MenuProps: {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
            } },
            !required && React.createElement($MenuItem, { value: '' },
                React.createElement("em", null, "None")),
            items.map((item) => React.createElement($MenuItem, { key: item.value, value: item.value },
                item.label,
                item.edit ? React.createElement(SvgIcon, { name: 'altEdit', clickable: true, size: 16, color: '#757778', onClick: (e) => onEdit(e, item.value) }) : null))),
        (description || errorMsg) &&
            React.createElement($FormHelperText, { error: error }, errorMsg || description)));
};
