import { ApplicationDocument, AssignmentData, FlowApplication, FlowAssignment, OptionGroup, withLoading } from 'prace-common-components'
import { clientApi } from '..'
import { AcceptAssignmentPayload, AssignPayload, ChangeUserVisibilityPayload, DeleteUploadPayload, FlowApplicationState, GetApplicationsResponse, ListOptionsPayload, ListOptionsResponse, ReviewAssignmentPayload, SaveAssignmentPayload, SearchApplicationsPayload, UnlockPayload, UpdateCoiDataPayload, UpdateCommentDataPayload, UpdateDeadlinePayload, UpdateLeadAssignmentPayload, UploadApplicationFilePayload } from './types'

export const applicationsApi = clientApi.injectEndpoints({
  endpoints: (build) => ({
    assign: build.mutation<FlowAssignment, AssignPayload>({
      ...withLoading,
      query: ({ id, ...body }) => ({
        url: `/v2/applications/${id}/assign`,
        method: 'POST',
        body,
      }),
    }),
    updateLeadAssignment: build.mutation<FlowApplicationState, UpdateLeadAssignmentPayload>({
      ...withLoading,
      query: ({ applicationId, assignmentId, ...body }) => ({
        url: `/v2/applications/${applicationId}/assignments/${assignmentId}/lead`,
        method: 'PATCH',
        body,
      }),
    }),
    searchApplications: build.mutation<GetApplicationsResponse, SearchApplicationsPayload>({
      ...withLoading,
      query: ({ limit, offset, ...body }) => ({
        url: `/v2/applications/search?limit=${limit}&offset=${offset}`,
        method: 'POST',
        body,
      }),
    }),
    getApplication: build.query<FlowApplication, FlowApplication['uid']>({
      ...withLoading,
      query: (id) => ({ url: `/v2/applications/${id}` }),
    }),
    getDocuments: build.query<ApplicationDocument[], FlowApplication['id']>({
      ...withLoading,
      query: (id) => ({ url: `/v2/applications/${id}/documents` }),
    }),
    uploadFile: build.mutation<AssignmentData, UploadApplicationFilePayload>({
      ...withLoading,
      query: ({ id, data }) => ({
        url: `/v2/assignments/${id}/upload`,
        method: 'POST',
        body: data,
        formData:true,
      }),
    }),
    updateDeadline: build.mutation<void, UpdateDeadlinePayload>({
      query: ({ id, ...body }) => ({
        url: `/v2/assignments/${id}/deadline`,
        method: 'PUT',
        body,
      }),
    }),
    updateRevoked: build.mutation<FlowAssignment, FlowAssignment['id']>({
      query: (id) => ({
        url: `/v2/assignments/${id}/revoke`,
        method: 'PUT',
      }),
    }),
    deleteUpload: build.mutation<AssignmentData, DeleteUploadPayload>({
      query: ({ id, uploadId }) => ({
        url: `/v2/assignments/${id}/upload/${uploadId}`,
        method: 'DELETE',
      }),
    }),
    saveAssignment: build.mutation<AssignmentData[], SaveAssignmentPayload>({
      query: ({ id, ...body }) => ({
        url: `/v2/assignments/${id}/save`,
        method: 'PUT',
        body,
      }),
    }),
    submitAssignment: build.mutation<void, SaveAssignmentPayload>({
      ...withLoading,
      query: ({ id, ...body }) => ({
        url: `/v2/assignments/${id}/submit`,
        method: 'PUT',
        body,
      }),
    }),
    deleteAssignmentData: build.mutation<void, {id: number; dataIds: number[]}>({
      query: ({ id, ...body }) => ({
        url: `/v2/assignments/${id}/data`,
        method: 'DELETE',
        body,
      }),
    }),
    acceptAssignment: build.mutation<void, AcceptAssignmentPayload>({
      ...withLoading,
      query: ({ id, ...body }) => ({
        url: `/v2/assignments/${id}/accept`,
        method: 'PUT',
        body,
      }),
    }),
    reviewAssignment: build.mutation<FlowAssignment, ReviewAssignmentPayload>({
      ...withLoading,
      query: ({ id, ...body }) => ({
        url: `/v2/assignments/${id}/review`,
        method: 'PUT',
        body,
      }),
    }),
    updateCoiData: build.mutation<void, UpdateCoiDataPayload>({
      ...withLoading,
      query: ({ id, dataId }) => ({
        url: `/v2/assignments/${id}/coi/${dataId}`,
        method: 'PUT',
      }),
    }),
    updateCommentData: build.mutation<void, UpdateCommentDataPayload>({
      ...withLoading,
      query: ({ id, dataId, ...body }) => ({
        url: `/v2/assignments/${id}/comment/${dataId}`,
        method: 'PUT',
        body,
      }),
    }),
    changeUserVisibility: build.mutation<void, ChangeUserVisibilityPayload>({
      ...withLoading,
      query: ({ id, ...body }) => ({
        url: `/v2/assignments/${id}/visibility`,
        method: 'PUT',
        body,
      }),
    }),
    listOptions: build.query<ListOptionsResponse, ListOptionsPayload>({
      ...withLoading,
      query: ({ limit = 999, offset = 0 }) => ({ url: `/v2/options?id=130&offset=${offset}&limit=${limit}` }),
      keepUnusedDataFor: 1800, /* 30 minutes cache */
    }),
    getOptions: build.query<OptionGroup, OptionGroup['id']>({
      ...withLoading,
      query: (id) => ({ url: `/v2/options/${id}` }),
      keepUnusedDataFor: 1800, /* 30 minutes cache */
    }),
    unlock: build.mutation<FlowAssignment, UnlockPayload>({
      ...withLoading,
      query: (body) => ({
        url: '/v2/applications/unlock',
        method: 'PUT',
        body,
      }),
    }),
    changeNextCutoff: build.mutation<FlowApplication, FlowApplication['id']>({
      ...withLoading,
      query: (id) => ({
        url: `/v2/applications/${id}/change_cutoff`,
        method: 'PUT',
      }),
    }),
  }),
})

export const {
  useAssignMutation,
  useUpdateLeadAssignmentMutation,
  useSearchApplicationsMutation,
  useGetApplicationQuery,
  useGetDocumentsQuery,
  useUploadFileMutation,
  useUpdateDeadlineMutation,
  useDeleteUploadMutation,
  useSaveAssignmentMutation,
  useSubmitAssignmentMutation,
  useDeleteAssignmentDataMutation,
  useAcceptAssignmentMutation,
  useListOptionsQuery,
  useGetOptionsQuery,
  useUnlockMutation,
  useChangeNextCutoffMutation,
  useUpdateRevokedMutation,
  useReviewAssignmentMutation,
  useUpdateCoiDataMutation,
  useUpdateCommentDataMutation,
  useChangeUserVisibilityMutation,
} = applicationsApi
