import { Editor, Element as SlateElement } from 'slate'
import { LIST_TYPES, GROUP_TYPES } from '../types'


const withGroups = (editor: Editor) => {
  const { insertBreak } = editor

  editor.insertBreak = () => {
    const { selection } = editor
    if (selection) {
      // TODO: Problem with nested objects of same time
      // Checking if we are inside a condition
      const [groupType] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          GROUP_TYPES.includes(n.type),
      })
      // TODO: Is there a way to do this in one go?
      // Checking if we are inside a list
      const [list] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          LIST_TYPES.includes(n.type),
      })
      // Can't do a break inside group/condition
      if (groupType && !list) {
        // Changed so a line break is inserted
        editor.insertNode({ type: 'paragraph', children: [{ text: '' }] })

        //const newElement = { type: 'paragraph', children: [] }
        //Transforms.insertNodes<SlateElement>(editor, newElement, { at: selection.focus?.path })

        //Transforms.insertText(editor, '', { at: selection.focus?.path })
        /* const block = { type: 'paragraph', children: [{ text: '' }] }
        Transforms.wrapNodes(editor, block) */
        return
      }
    }
    insertBreak()
  }

  return editor
}

export default withGroups
