import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useWait } from '../../utils';
import { $SortableRow, $Handle, $SvgIcon } from './styles';
import { TableCell } from '@material-ui/core';
import { useTheme } from 'styled-components';
export const SortableRow = ({ rowProps, item, type, dropType, onMove, onDrop = () => null, onEndDrag = () => null, selected, children, }) => {
    const theme = useTheme();
    const dragRef = useRef(null);
    const previewRef = useRef(null);
    const [hovering, setHovering] = useState(false);
    const { wait, done } = useWait();
    const { id, order } = item;
    const [{ handlerId }, drop] = useDrop({
        accept: dropType,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        drop(dropItem, monitor) {
            const dragIndex = dropItem.order;
            const hoverIndex = order;
            const dragType = monitor.getItemType();
            if (dragIndex === hoverIndex && dragType === type)
                return;
            const dragItem = monitor.getItem();
            onDrop({ dragIndex, hoverIndex, type, dragType, dragItem });
        },
        hover(hoverItem, monitor) {
            if (!previewRef.current || !done)
                return;
            const dragIndex = hoverItem.order;
            const hoverIndex = order;
            if (id === hoverItem.id)
                return;
            const dragType = monitor.getItemType();
            if (dragIndex === hoverIndex && dragType === type)
                return;
            const hoverBoundingRect = previewRef.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
                return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
                return;
            const dragItem = monitor.getItem();
            wait(150);
            onMove({ dragIndex, hoverIndex, dragType, dragItem });
            hoverItem.order = hoverIndex;
        },
    });
    const [{ isDragging }, drag, preview] = useDrag({
        type,
        item,
        end: () => {
            onEndDrag();
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(preview(previewRef));
    drag(dragRef);
    return (React.createElement($SortableRow, { ...rowProps, ref: previewRef, selected: selected, isDragging: isDragging, "data-handler-id": handlerId, onMouseEnter: () => setHovering(true), onMouseLeave: () => setHovering(false) },
        React.createElement(TableCell, { padding: 'checkbox' },
            React.createElement($Handle, { ref: dragRef },
                React.createElement($SvgIcon, { name: 'drag', color: hovering ? theme.colors.white : theme.colors.greyScales[400], size: 24 }))),
        children));
};
