import { FlowCall, Condition, getFlowElement, VALIDATION_TYPES, EFFECTS, IconNames } from 'prace-common-components'

export const conditionText = (call: FlowCall, cond: Condition): string[] => {
  const { validation } = cond
  const { elements, type, values } = validation
  return elements.map((flowElement: {id: number}) => {
    const { element } = getFlowElement(call, flowElement.id)
    const valuesLength = values.length
    const validConditionType = type && (type !== VALIDATION_TYPES.UNCONDITIONAL)
    const conditionTypeText = type === VALIDATION_TYPES.REQUIRED ?
      ` > ${type}` :
      ` > ${type} ${valuesLength > 1 ?`> [${values[0]}, ${values[1]}]` : valuesLength ? `> ${values[0]}` : ''}`

    return `${element?.title}` + (validConditionType ? conditionTypeText : '')
  })
}

export const conditionIcon = (effect: EFFECTS): string => {
  switch(effect) {
    case EFFECTS.EDITABLE:
      return 'altEdit'
    case EFFECTS.REQUIRED:
      return 'required'
    case EFFECTS.VISIBLE:
      return 'change_visibility'
    case EFFECTS.SUM:
      return 'add-circle'
    default:
      return 'valid'
  }
}

export const conditionsToolTips = (call: FlowCall, conds: Condition[]) => {
  /* Group conditions by effect type */
  const groupedConds = []
  for (const effect in EFFECTS) {
    const filteredConds = conds.filter((cond) => cond.effect == EFFECTS[effect as keyof typeof EFFECTS])
    if(filteredConds.length)
      groupedConds.push({
        text: filteredConds.map((cond) => '- ' + conditionText(call, cond).join('\n  ')).join('\n'),
        icon: conditionIcon(EFFECTS[effect as keyof typeof EFFECTS]),
      })
  }
  return groupedConds
}

const getSubTitle = (validation: Condition['validation'], elementNames: string[]) => {
  return (validation.elements.length < 2 && validation.type) ?
    elementNames[0] : `${validation.elements.length} elements`
}

export const getConditionCardProps = (call: FlowCall, condition: Condition) => {
  const icon = conditionIcon(condition.effect)
  const { validation } = condition

  const elementNames = conditionText(call, condition)
  const subTitle = getSubTitle(validation, elementNames)

  return {
    icon: icon as IconNames,
    title: condition.effect || '',
    subTitle,
    tooltipText: elementNames.join('\n'),
  }
}
