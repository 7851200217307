import React, { FC } from 'react'
import { Login } from './views'
import { Landing as LandingContainer } from 'prace-common-components'
import { CDN } from 'constants/global'

export const Landing: FC = () =>  (
  <LandingContainer image={`${CDN}/login.jpg`} logo={`${CDN}/logo.png`} description='Please, feel free to log in.'>
    <Login />
  </LandingContainer>
)
