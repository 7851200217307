import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { $InputLabel } from 'prace-common-components'

//TODO: Repeated code from FormModal
export const $ModalContainer = styled.div`
  min-width: 500px;
`

export const $Grid = styled(Grid)`
  gap: ${({ theme }) => theme.spacing(2)};
`

export const $Container = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`

export const $Label = styled($InputLabel)`
  &.MuiFormLabel-root {
    font-size: 1.2em;
  }
`

export const $Text = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
  margin-left: 4px;
`
