import * as React from 'react'
import { Grid } from '@material-ui/core'
import { ELEMENT_BASE_PARAMS, NewLoading as Loading, NotificationType, ElementOptions, ELEMENT_TYPES, Divider, ElementValue, FlowForm, ConfirmAction, Permissions } from 'prace-common-components'
import { $DeleteButton } from './styles'
import { ElementPermission } from 'components/ElementPermission'
import { useAppDispatch } from 'store/hooks'
import { useUpdateElementMutation, useDeleteFlowElementsMutation } from 'store/api/calls/elements'

export const EditForm: React.FC<{form: FlowForm}> = ({ form }) => {
  const dispatch = useAppDispatch()
  const [updateElement, { isLoading }] = useUpdateElementMutation()
  const [deleteElement, { isLoading: isDeleting }] = useDeleteFlowElementsMutation()

  const onChangeOption = async (name: string, value: ElementValue) => {
    try {
      const newValues = ELEMENT_BASE_PARAMS.includes(name)
        ? { id: form.id, [name]: value }
        : { id: form.id, config: { ...(form.config || {}), [name]: value }}
      await updateElement(newValues).unwrap()
      dispatch({ type: 'updateForm', payload: { newValues } })
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Form updated' } })
    } catch (err) {
      console.log(err)
    }
  }

  const onChangeElementPermission = async (name: string, value: ElementValue) => {
    try {
      const newValues = { id: form.id, permissions: { ...form.permissions, [name]: value } as Permissions}
      await updateElement(newValues).unwrap()
      dispatch({ type: 'updateForm', payload: { newValues } })
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Permissions updated' } })
    } catch (err) {
      console.log(err)
    }
  }

  const handleDelete = async () => {
    try {
      await deleteElement({ ids: [form.id] }).unwrap()
      dispatch({ type: 'deleteForm', payload: { formId: form.id } })
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Form deleted' } })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Grid container>
      <Loading loading={isLoading || isDeleting}/>
      <ElementOptions element={form} onChange={onChangeOption} />
      <Divider />
      <ElementPermission
        permissions={form.permissions}
        type={ELEMENT_TYPES.FORM}
        id={form.id}
        onChange={onChangeElementPermission}
      />
      <ConfirmAction deletion title='Delete form' description='Deleting this form will also delete all elements inside.' onConfirm={handleDelete}>
        <$DeleteButton error onClick={handleDelete}>Delete Form</$DeleteButton>
      </ConfirmAction>
    </Grid>
  )
}
