import React, { useState } from 'react';
import { $Collapse, $List, $ListItem, $ListItemText } from './styles';
import { Sortable } from '../';
import { ExpandIcon } from '../newIcons';
import { SvgIcon } from '../SvgIcon';
import { VerticalStepper } from '../newStepper';
import { useTheme } from 'styled-components';
export const SortableCollapseFlow = ({ selectedCollapse, selectedElement, workingStep = 0, flow = [], handleSelect, handleAdd, sortable, stepper, handleOnDrag = () => null, handleOnEndDrag = (_type) => null, }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const handleSelectChange = (order, type) => {
        if (selectedCollapse.type === type && selectedCollapse.order === order)
            setOpen((open) => !open);
        else
            setOpen(true);
        handleSelect(order, type);
    };
    return React.createElement($List, { component: 'div', disablePadding: true, "$stepper": stepper },
        flow.map((item, i) => {
            const isItemSelected = i == selectedCollapse.order;
            const itemFlow = item.flow || [];
            return React.createElement("div", { key: item.id },
                React.createElement(Sortable, { alternate: true, onlyHover: true, notSortable: !sortable, item: { id: item.id, type: item.type, order: i }, type: item.type, dropType: [item.type], selected: isItemSelected, onMove: handleOnDrag, onEndDrag: () => handleOnEndDrag(item.type) },
                    React.createElement($ListItem, { button: true, onClick: () => handleSelectChange(i, item.type), "$stepper": stepper },
                        React.createElement(SvgIcon, { name: (!stepper || item.workingStep) ? 'stop' : 'stop-outlined', size: 16, color: stepper ? theme.colors.secondary : theme.colors.primary }),
                        React.createElement($ListItemText, { "$stepper": stepper, error: item.error, selected: isItemSelected, primary: item.title }),
                        React.createElement(ExpandIcon, { expanded: isItemSelected && open }))),
                React.createElement($Collapse, { in: isItemSelected && open, timeout: 'auto', unmountOnExit: true }, stepper && itemFlow.length ?
                    React.createElement(VerticalStepper, { steps: itemFlow, activeStep: selectedElement.order, workingStep: workingStep, onChangeActiveStep: (order) => handleSelectChange(order, itemFlow[0].type) })
                    :
                        React.createElement($List, { component: 'div', disablePadding: true },
                            itemFlow.map((el, j) => {
                                const isElementSelected = j == selectedElement.order;
                                return React.createElement(Sortable, { key: item.id + el.order, alternate: true, onlyHover: true, notSortable: !sortable, item: { id: el.id, type: el.type, order: j }, type: el.type, dropType: [el.type], selected: isElementSelected, onMove: handleOnDrag, onEndDrag: () => handleOnEndDrag(el.type) },
                                    React.createElement($ListItem, { button: true, nested: true, selected: isElementSelected, onClick: () => handleSelectChange(j, el.type) },
                                        React.createElement(SvgIcon, { name: 'circle', size: 10, color: stepper ? theme.colors.secondary : theme.colors.primary }),
                                        React.createElement($ListItemText, { inset: true, selected: isElementSelected, primary: el.title || '' })));
                            }),
                            handleAdd &&
                                React.createElement($ListItem, { button: true, nested: true, secondary: true, onClick: () => handleAdd(selectedElement.type, item.order), "$stepper": stepper },
                                    React.createElement(SvgIcon, { name: 'plus', size: 16, color: theme.colors.secondary }),
                                    React.createElement($ListItemText, { inset: true, primary: `New ${selectedElement.type}` })))));
        }),
        handleAdd &&
            React.createElement($ListItem, { button: true, secondary: true, onClick: () => handleAdd(selectedCollapse.type, flow.length), "$stepper": stepper },
                React.createElement(SvgIcon, { name: 'plus', size: 16, color: theme.colors.secondary }),
                React.createElement($ListItemText, { inset: true, primary: `New ${selectedCollapse.type}` })));
};
