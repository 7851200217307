import { Editor, Transforms } from 'slate'
import { ParagraphElement } from '../custom-types'

// TODO: Change to force having at least 1 paragraph in the beginning and end of the editor all the time
const withLayout = (editor: Editor) => {
  const { normalizeNode } = editor

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length < 1) {
        const paragraph: ParagraphElement = {
          type: 'paragraph',
          children: [{ text: '' }],
        }
        Transforms.insertNodes(editor, paragraph, { at: path.concat(0) })
      }

      if (editor.children.length < 2) {
        const paragraph: ParagraphElement = {
          type: 'paragraph',
          children: [{ text: '' }],
        }
        Transforms.insertNodes(editor, paragraph, { at: path.concat(1) })
      }

      /* Force the last element of the editor to be text */
      if (editor.children[editor.children.length - 1].type !== 'paragraph') {
        const paragraph: ParagraphElement = {
          type: 'paragraph',
          children: [{ text: '' }],
        }
        Transforms.insertNodes(editor, paragraph, { at: [editor.children.length] })
      }

    }

    return normalizeNode([node, path])
  }

  return editor
}

export default withLayout
