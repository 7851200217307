import React, { MouseEvent, PropsWithChildren } from 'react'
import { Editor, Element as SlateElement, Transforms, Text, Range } from 'slate'
import { useSlate } from 'slate-react'
import { BlockFormat, AlignFormat, ConditionElement, GroupElement } from '../custom-types'
import { LIST_TYPES, GROUP_TYPES, TEXT_ALIGN_TYPES } from '../types'
import { $ToggleButton } from './styles'
import { TemplateCondition, TemplateElement } from 'store/api/templates/types'

export const isBlockActive = (editor: Editor, format: BlockFormat | AlignFormat) => {
  const { selection } = editor
  if (!selection) return false

  const blockType = TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    }),
  )
  return !!match
}

const getNewProperties = (isActive: boolean, isList: boolean, format: BlockFormat | AlignFormat) => {
  let newProperties: Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format as AlignFormat,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format as BlockFormat,
    }
  }
  return newProperties
}

export const removeGroup = (editor: Editor, format: BlockFormat) => {
  Transforms.removeNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n.type === format,
    mode: 'lowest',
  })
}

const newGroupElement = (type: string, data: TemplateCondition | TemplateElement, editor: Editor) => {
  let typeCount = 1
  /* Count number of occurrences */
  Transforms.setNodes(editor, {}, {
    at: [],
    voids: true,
    mode: 'all',
    match: (n: any) => { //TODO: Handle any
      if(n.type == type) typeCount = typeCount + 1
      return false
    },
  })
  switch(type) {
    case 'condition':
      return {
        type,
        id: typeCount,
        error: false,
        condition: data,
        children: [{ type: 'paragraph', children: [{ text: `Type inside the ${type}` }] }],
      } as ConditionElement
    default:
      return {
        type,
        id: typeCount,
        error: false,
        element: data,
        children: [{ type: 'paragraph', children: [{ text: `Type inside the ${type}` }] }],
      } as GroupElement
  }
}

export const toggleBlock = (
  editor: Editor,
  format: BlockFormat | AlignFormat,
  isActive: boolean,
  data?: TemplateCondition | TemplateElement,
) => {
  const { selection } = editor
  const isGroup = GROUP_TYPES.includes(format)

  /* If we dont want groups to be used in selections, use && (selection && Range.isCollapsed(selection)) */
  if(!isActive && isGroup && data !== undefined) {
    const newElement = newGroupElement(format, data, editor)
    Transforms.insertNodes<SlateElement>(editor, newElement)
    return
  }

  /* User wants to remove a group */
  if(isActive && isGroup) {
    /* With mode lowest, the deepest group element is removed (and everything inside) */
    removeGroup(editor, format as BlockFormat)
    return
  }
  const isList = LIST_TYPES.includes(format)

  /* Are we inside a group? */
  if (selection && GROUP_TYPES.includes((Editor.parent(editor, selection.focus?.path)[0] as SlateElement).type)) {
    if(isList && selection && Range.isCollapsed(selection)) {
      const newElement = {
        type: isActive ? 'paragraph' : format,
        children: [{
          type: 'list-item',
          children: [{
            text: '',
          }],
        }],
      }
      Transforms.insertNodes<SlateElement>(editor, newElement as SlateElement, { at: selection.focus?.path })
    } else {
      Transforms.unwrapNodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
      })
      const newProperties: Partial<SlateElement> = getNewProperties(isActive, isList, format)
      Transforms.setNodes<SlateElement>(
        editor,
        newProperties,
        {
          match: (n) =>
            !Editor.isEditor(n) &&
            (SlateElement.isElement(n) &&
              !GROUP_TYPES.includes(n.type)) || Text.isText(n),
        },
      )

      if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block as SlateElement, {
          match: (n) => !Editor.isEditor(n) &&
             (SlateElement.isElement(n) &&
             !GROUP_TYPES.includes(n.type)) || Text.isText(n)},
        )
      }
    }

  } else {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          LIST_TYPES.includes(n.type) &&
          !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    })
    const newProperties: Partial<SlateElement> = getNewProperties(isActive, isList, format)
    Transforms.setNodes<SlateElement>(editor, newProperties)
    if (!isActive && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block as SlateElement)
    }
  }
}

export const BlockButton: React.FC<PropsWithChildren<{
  format: BlockFormat | AlignFormat,
  withPadding?: boolean,
  onMouseDown?: (e: MouseEvent<HTMLButtonElement>, isActive: boolean) => void,
}>> = ({ format, withPadding = true, onMouseDown, children }) => {
  const editor = useSlate()
  const isActive = isBlockActive(editor, format)
  return (
    <$ToggleButton
      selected={isActive}
      withPadding={withPadding}
      onMouseDown={(e) => {
        if(onMouseDown) {
          onMouseDown(e, isActive)
        } else {
          toggleBlock(editor, format, isActive)
        }
      }}
    >
      {children}
    </$ToggleButton>
  )
}
