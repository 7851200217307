import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { $GridItem, $BackButton, $TopBar, $CallTitle, $EditCallgrid } from './styles'
import { NotFound404, ErrorNotice,FlowCall, SvgIcon, NewButton, Title, ConfirmAction, NotificationType, Loading } from 'prace-common-components'
import { CallFlow } from './CallFlow'
import { StepFlowController } from './StepFlow'
import { CallSaveStatus } from './CallSaveStatus'
import { EditElementController } from './EditElement'
import { EditCallModal } from 'components/EditCallModal'
import { EditRolesModal } from 'components/EditRolesModal'
import { useUpdateMyPresence } from '../../liveblocks.config'
import { useGetCallQuery, useUpdateCallMutation } from 'store/api/calls'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { RootState } from 'store'
import { Routes } from 'constants/global'
import { useListOptionsQuery } from 'store/api/options'
import { useGetTemplatesMutation } from 'store/api/templates'
import { useTheme } from 'styled-components'

export const EditCallContainer: React.FC<{callId: number}> = ({ callId }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const theme = useTheme() as { colors: { darkPrimary: string }}
  const [open, setOpen] = React.useState<boolean>(false)
  const [openRoles, setOpenRoles] = React.useState<boolean>(false)
  const call = useAppSelector((state: RootState) => state.calls.call)
  const errorStatus = useAppSelector((state: RootState) => state.calls.errorStatus)
  const editorSelector = useAppSelector((state: RootState) => state.calls.editorSelector)
  const { refetch, isLoading, isFetching } = useGetCallQuery(callId, { skip: !callId, refetchOnMountOrArgChange: true})
  const [updateCall] = useUpdateCallMutation()
  const [getTemplates] = useGetTemplatesMutation()
  useListOptionsQuery({})

  const updateMyPresence = useUpdateMyPresence()

  useEffect(() => {
    const getAllTemplates = async () => {
      try {
        await getTemplates({ limit: 999, offset: 0, callId: Number(callId) || 0 }).unwrap()
      } catch (err) {
        console.log(err)
      }
    }
    getAllTemplates().catch((err) => console.log(err))
  }, [getTemplates, callId])

  /* useEffect(() => {
    dispatch(OPTIONS_EVENTS.GET_OPTION_GROUPS)
    dispatch(TEMPLATE_EVENTS.GET_TEMPLATES, {})
  }, [dispatch]) */

  const handleUpdateCall = async (updatedCall: FlowCall) => {
    try {
      await updateCall({ ...updatedCall, id: call.id, flow: undefined }).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Call Updated' } })
      setOpen(false)
      dispatch({ type: 'updateCall', payload: { call: { ...call, ...updatedCall } }})
      refetch() // Need to do this to update all step timeframes if call cutoffs are upated
    } catch (err) {
      console.log(err)
    }
  }

  const onPublishCall = async () => {
    try {
      await updateCall({ id: call.id, published: !call.published }).unwrap()
      dispatch({ type: 'updateCall', payload: { call: { ...call, published: !call.published }}})
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: call.published ? 'Call Unpublished'  : 'Call Published' } })
      navigate(Routes.CALLS)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    updateMyPresence({ editorSelector })
  }, [editorSelector, updateMyPresence])

  if(isLoading || isFetching) return <Loading />
  if(errorStatus == 400 || errorStatus == 500) return <ErrorNotice message='An error occurred while fetching the call' />
  if(errorStatus == 404) return <NotFound404 message='The call you’re looking for couldn’t be found' />

  /* TODO: Temporary fix to avoid crashing when there are no steps or forms inside first step */
  if(call && call.flow && call.flow.length && !call.flow[0].flow.length) return null

  return (
    <div
    /* TODO: onPointerMove and onPointerLeave causes DnD to not work on Chromium browsers */
      /* onPointerMove={(event) => {
        event.preventDefault()
        // Update the user cursor position on every pointer move
        updateMyPresence({
          cursor: {
            x: Math.round(event.clientX),
            y: Math.round(event.clientY),
          },
          editorSelector,
        })
      }}
      onPointerLeave={() =>
      // When the pointer goes out, set cursor to null
        updateMyPresence({
          //cursor: null,
          editorSelector,
        })
      } */
    >
      <$TopBar container wrap='nowrap' alignItems='center' justifyContent='center'>
        <$GridItem item xs={2}>
          <$BackButton onClick={() => navigate(-1)}>
            <SvgIcon name='chevron-left' size='20' color={theme.colors.darkPrimary} />
            Back to Calls
          </$BackButton>
        </$GridItem>
        <$CallTitle container item xs={6} justifyContent='center' alignItems='center' wrap='nowrap'>
          <Title alternate>
            {call.title}
          </Title>
          <span>({call.uid})</span>
          <SvgIcon onClick={() => setOpen(true)} clickable name='altEdit' size='20' color='#8D9192' />
          <SvgIcon onClick={() => setOpenRoles(true)} clickable name='people' size='20' color='#8D9192' />
        </$CallTitle>
        <CallSaveStatus />
        <$GridItem container justifyContent='flex-end' item xs={2}>
          <ConfirmAction title={call.published ? 'Unpublish Call' : 'Publish Call'} description='This action will change the visibility of the call to everyone.' onConfirm={onPublishCall}>
            <NewButton icon={<SvgIcon name='save' size='20' color='#fff' />} onClick={onPublishCall}>{call.published ? 'Unpublish Call' : 'Publish Call'}</NewButton>
          </ConfirmAction>
        </$GridItem>
      </$TopBar>
      <$EditCallgrid container wrap='nowrap'>
        <CallFlow />
        <StepFlowController />
        <EditElementController />
      </$EditCallgrid>
      {open &&
        <EditCallModal
          modalTitle={String(call.uid)}
          onClose={() => setOpen(false)}
          call={call}
          handleUpdateCall={handleUpdateCall}
        />
      }
      {openRoles &&
        <EditRolesModal
          cutoffs={call?.cutoffs || []}
          callId={call?.id}
          onClose={() => setOpenRoles(false)}
        />
      }
    </div>
  )
}
