import styled from 'styled-components';
import { SvgIcon } from '../';
export const $Navigation = styled.nav `
  display: flex;
  flex-direction: column;
  position: sticky;
  box-sizing: border-box;
  width: 100%;
  height: max-content;
  z-index: 99;
  background-color: ${({ theme }) => theme.colors.primary};
  grid-area: nav;
  top: ${({ topMargin }) => topMargin ? '40px' : '0'};

  h1: {
    font-size: 24px;
    font-weight: 300;
    margin: 0;
  }

  b {
    font-weight: 600;
  }
`;
export const $MainNav = styled.div `
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 100%;
  max-width: 1200px;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};
  color:  ${({ theme }) => theme.colors.white};
  margin: 0 auto;
  height: 60px;
`;
export const $Tabs = styled.div `
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;
export const $Tab = styled.div `
  margin: 0 25px 0 25px;
  min-width: 80px;
  width: max-content;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  ${({ theme, selected }) => selected && `padding-bottom: 8px; border-bottom: 4px solid ${theme.colors.secondary}`};
`;
export const $GoBack = styled.div `
  display: flex;
  align-items: center;
`;
export const $GoBackBtn = styled(SvgIcon).attrs({ name: 'chevron-left-circle', size: '30px' }) `
  width: 32px;
  height: 32px;
  cursor: pointer;
`;
export const $GoBackLabel = styled.p `
  font-family: robot-light, sans-serif;
  font-size: 1.3em;
  color: inherit;
  margin: 5px 0 0 15px;
`;
export const $ProfileTitle = styled.p `
  color: white;
  font-weight: bold;
  white-space: nowrap;
  margin: 0 5px 0 0;
`;
export const $LoginContainer = styled.div `
  display: flex;
  align-items: center;
  .MuiButtonBase-root {
    padding: ${({ theme }) => `0 ${theme.spacing(2)}`};
  }

`;
export const $Login = styled.div `
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing(2)};
`;
export const $MenuWrapper = styled.div `
  float: right;
`;
