import { OptionGroup, withLoading } from 'prace-common-components'
import { clientApi } from '..'
import { CreateOptionsGroupApiPayload, ListOptionsApiPayload, ListOptionsSuccess, UpdateOptionsGroupApiPayload } from './types'

export const optionsApi = clientApi.injectEndpoints({
  endpoints: (build) => ({
    listOptions: build.query<ListOptionsSuccess, ListOptionsApiPayload>({
      ...withLoading,
      query: ({ limit = 999, offset = 0 }) => ({
        url: `/v2/options?offset=${offset}&limit=${limit}`,
      }),
      transformResponse: (res: ListOptionsSuccess, _meta, args) => ({ ...args, ...res }),
      keepUnusedDataFor: 30, /* 30 seconds cache */
    }),
    getOptions: build.query<OptionGroup, OptionGroup['id']>({
      query: (id) => ({
        url: `/v2/options/${id}`,
      }),
    }),
    createOptionsGroup: build.mutation<OptionGroup, CreateOptionsGroupApiPayload>({
      ...withLoading,
      query: (body) => ({
        url: '/v2/options',
        method: 'POST',
        body,
      }),
    }),
    updateOptionsGroup: build.mutation<OptionGroup, UpdateOptionsGroupApiPayload>({
      ...withLoading,
      query: ({ id, ...body }) => ({
        url: `/v2/options/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteOptionsGroup: build.mutation<OptionGroup['id'], OptionGroup['id']>({
      ...withLoading,
      query: (id) => ({
        url: `/v2/options/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (_res, _meta, args) => (args),
    }),
  }),
})

export const {
  useCreateOptionsGroupMutation,
  useDeleteOptionsGroupMutation,
  useLazyGetOptionsQuery,
  useListOptionsQuery,
  useUpdateOptionsGroupMutation,
} = optionsApi
