import styled from 'styled-components';
import { Breadcrumbs, Link } from '@material-ui/core';
export const $Breadcrumbs = styled(Breadcrumbs) `
  .MuiBreadcrumbs-ol {
    flex-wrap: nowrap;
    overflow-x: hidden;
  }
  .MuiTypography-colorPrimary {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
export const $Link = styled(Link) `
  cursor: pointer;
  font-size: 0.9em;
  font-weight: ${({ last }) => last ? 'bold' : 'normal'};
`;
