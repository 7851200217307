export var FILTER_TYPE;
(function (FILTER_TYPE) {
    FILTER_TYPE["TEXT"] = "text";
    FILTER_TYPE["SELECT"] = "select";
    FILTER_TYPE["NUMBER"] = "number";
    FILTER_TYPE["DATE"] = "datet";
    FILTER_TYPE["NONE"] = "none";
})(FILTER_TYPE || (FILTER_TYPE = {}));
export var CONDITIONS;
(function (CONDITIONS) {
    CONDITIONS["CONTAINS"] = "contains";
    CONDITIONS["NOT_CONTAINS"] = "not contains";
    CONDITIONS["GREATER"] = "greater";
    CONDITIONS["LOWER"] = "lower";
    CONDITIONS["EQUAL"] = "equal";
    CONDITIONS["NOT_EQUAL"] = "not equal";
})(CONDITIONS || (CONDITIONS = {}));
