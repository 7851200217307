import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import { EditCallContainer } from 'newContainers/EditCall'
import { MultiUserFeedback } from 'components/MultiUserFeedback'
import { DnDWrapper, NewLoading, NoMobile } from 'prace-common-components'
import { ClientSideSuspense } from '@liveblocks/react'
import { RoomProvider } from '../../liveblocks.config'
import { loginEmail, ORGANIZATION } from 'constants/global'

export const EditCall: FC<{isMobile?: boolean}> = ({ isMobile }) => {
  const { id } = useParams<{ id?: string }>()
  //TODO: Handle error better
  if(!id) return null
  if(isMobile) return <NoMobile />
  return (
    <RoomProvider id={`${id}-${ENV || ''}-${ORGANIZATION}`} initialPresence={{ /* cursor: null,  */userId: loginEmail }}>
      <ClientSideSuspense fallback={<NewLoading loading />}>
        {() => <>
          <MultiUserFeedback />
          <DnDWrapper>
            <EditCallContainer callId={Number(id) || 0} />
          </DnDWrapper>
        </>}
      </ClientSideSuspense>
    </RoomProvider>
  )
}
