import { FlowCall, OptionGroup, FlowElement, FlowForm, FlowFormElement, FlowStep, ELEMENT_TYPES, FORM_ELEMENT_TYPES, FlowElementTypes } from 'prace-common-components'
import { GROUP_TYPES } from 'store/calls/types'
import { getElementOptions } from './getElementOptions'

// TODO: Evaluate the need of elementProps and define specific typing for it
export const getElement = <T extends FlowStep | FlowForm | FlowFormElement,>(
  callId: Nullable<FlowCall['id']>,
  parentId: Nullable<FlowElement['id']>,
  order: FlowElement['order'],
  type: FlowElementTypes | GROUP_TYPES | string,
  elementProps?: any, //TODO: Handle any
  groupOptions?: OptionGroup[],
): T => {
  const common = {
    title: 'Element title', //TODO: This will be the element name also
    description: '', //TODO: This will be the element helperText
    parentId,
    type: ELEMENT_TYPES.ELEMENT,
    flow: [],
    effects: [],
    automations: [],
    permissions: {
      inherit: false,
      export: [],
      visibility: [],
    },
    config: {
      required: false,
    },
    order,
    ...getElementOptions(type, parentId, groupOptions),
  }
  const commonFlowElementProps = {
    elementType: type,
    effects: [],
    automations: [],
    value: '',
  }

  let optionsGroup: { optionsGroup: OptionGroup } | undefined = undefined
  if(groupOptions && elementProps && elementProps.optionsGroup) {
    const foundOptionsGroup = groupOptions.find((optGroup) => optGroup.tag === elementProps.optionsGroup.tag)
    if(foundOptionsGroup) optionsGroup = { optionsGroup: foundOptionsGroup }
  }

  switch(type) {
    case ELEMENT_TYPES.STEP: {
      return {
        ...common,
        title: 'Step title',
        config: {
          required: true,
        },
        callId,
        flow: [],
        permissions: {
          inherit: false,
          export: [],
          visibility: [],
          assignable: [],
          assign: [],
        },
        timeframes: [],
        min: undefined,
        max: undefined,
        ...elementProps,
        parentId: undefined,
        type: ELEMENT_TYPES.STEP,
      } as T
    }
    case ELEMENT_TYPES.FORM: {
      return {
        ...common,
        title: 'Form title',
        ...elementProps,
        type: ELEMENT_TYPES.FORM,
      } as T
    }
    case FORM_ELEMENT_TYPES.EMAIL: {
      return {
        ...common,
        title: 'Email',
        ...commonFlowElementProps,
        ...elementProps,
      } as T
    }
    case FORM_ELEMENT_TYPES.TITLE: {
      return {
        ...common,
        title: 'Title',
        ...commonFlowElementProps,
        ...elementProps,
      } as T
    }
    case FORM_ELEMENT_TYPES.TEXT_AREA: {
      return {
        ...common,
        title: 'Textarea',
        ...commonFlowElementProps,
        ...elementProps,
      } as T
    }
    case FORM_ELEMENT_TYPES.CHECKBOX: {
      return {
        ...common,
        title: 'Checkbox',
        ...commonFlowElementProps,
        value: false,
        ...elementProps,
      } as T
    }
    case FORM_ELEMENT_TYPES.TOGGLE: {
      /* By default Toggles are connected to the Yes/No tag */
      const foundOptionsGroup = groupOptions?.find((optGroup) => optGroup.tag === 'Yes/No')
      if(foundOptionsGroup && !optionsGroup) optionsGroup = { optionsGroup: foundOptionsGroup }
      return {
        ...common,
        name: 'Toggle',
        ...commonFlowElementProps,
        ...elementProps,
        ...optionsGroup,
      } as T
    }
    case FORM_ELEMENT_TYPES.NUMBER: {
      return {
        ...common,
        title: 'Number input',
        ...commonFlowElementProps,
        value: null,
        ...elementProps,
      } as T
    }
    case FORM_ELEMENT_TYPES.DATE: {
      return {
        ...common,
        title: 'Date',
        ...commonFlowElementProps,
        ...elementProps,
      } as T
    }
    case FORM_ELEMENT_TYPES.PHONE_NUMBER: {
      return {
        ...common,
        title: 'Phone Number',
        ...commonFlowElementProps,
        value: null,
        ...elementProps,
      } as T
    }
    case FORM_ELEMENT_TYPES.INSTRUCTIONS: {
      return {
        ...common,
        title: 'Instructions',
        ...commonFlowElementProps,
        ...elementProps,
      } as T
    }
    case FORM_ELEMENT_TYPES.UPLOAD: {
      return {
        ...common,
        title: 'Pdf Upload',
        ...commonFlowElementProps,
        ...elementProps,
      } as T
    }
    case FORM_ELEMENT_TYPES.SELECT: {
      return {
        ...common,
        title: 'Select',
        ...commonFlowElementProps,
        ...elementProps,
        ...optionsGroup,
      } as T
    }
    case FORM_ELEMENT_TYPES.SELECT_PARTITION: {
      return {
        ...common,
        title: 'Select partition',
        ...commonFlowElementProps,
        ...elementProps,
        ...optionsGroup,
      } as T
    }
    case FORM_ELEMENT_TYPES.PARTITION_RESOURCES: {
      return {
        ...common,
        title: 'Partition Resources',
        ...commonFlowElementProps,
        ...elementProps,
        ...optionsGroup,
      } as T
    }
    case FORM_ELEMENT_TYPES.MULTISELECT: {
      return {
        ...common,
        title: 'MultiSelect',
        ...commonFlowElementProps,
        ...elementProps,
        ...optionsGroup,
      } as T
    }
    case FORM_ELEMENT_TYPES.MULTITEXT: {
      return {
        ...common,
        title: 'MultiText',
        ...commonFlowElementProps,
        ...elementProps,
      } as T
    }
    case FORM_ELEMENT_TYPES.TEXT: {
      return {
        ...common,
        title: 'Text input',
        ...commonFlowElementProps,
        ...elementProps,
      } as T
    }
    case FORM_ELEMENT_TYPES.SPACE:
    case FORM_ELEMENT_TYPES.DIVIDER: {
      return {
        ...common,
        title: type,
        ...commonFlowElementProps,
        ...elementProps,
      } as T
    }
    default: { /* GROUP_TYPES */
      return {
        ...common,
        title: String(type),
        ...commonFlowElementProps,
        elementType: FORM_ELEMENT_TYPES.GROUP,
        ...elementProps,
      } as T
    }
  }
}
