import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
export const $GroupContainer = styled(Grid) `
  &.MuiGrid-root {
    padding: ${({ theme }) => theme.spacing(1)};
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
  h3 {
    padding-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;
export const $Buttons = styled(Grid) `
  &.MuiGrid-root {
    margin: ${({ theme }) => `${theme.spacing(2)} 0`};
  }
`;
export const $ButtonsStart = styled.div `
  button {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;
export const $ButtonsEnd = styled.div `
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;
export const $GroupButtons = styled(Grid) `
  padding:  ${({ theme }) => `0 ${theme.spacing(1)}`};
  margin-top: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1)};
`;
export const $WarningContainer = styled(Grid) `
  &.MuiGrid-item {
    width: 100%;
  }
`;
export const $WarningGrid = styled(Grid) `
  height: 100%;
  padding: ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.colors.errorShades[90]};
  &.MuiGrid-container {
    width: fit-content;
  }
`;
export const $Warning = styled.div `
  margin-right: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.colors.feedback.error};
  font-size: 0.9em;
`;
export const $Description = styled.div `
  white-space: pre-line;
`;
export const $InviteUser = styled(Grid) `
  &.MuiGrid-item {
    margin-bottom: 0;
    margin-top: auto;
  }
`;
export const $StepContent = styled(Grid) `
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
export const $BottomActions = styled(Grid) `
  margin: 0;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: white;
  //margin-top: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => `${theme.spacing(3)} 0`};
`;
export const $COIComment = styled.div `
  white-space: pre-line;
  display: inline-flex;
  width: fit-content;
  margin-right: ${({ theme }) => theme.spacing(2)};
`;
