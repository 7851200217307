import React from 'react';
import { ConfirmModal } from './ConfirmModal';
export const ConfirmAction = ({ title, deletion, ignore, description, onConfirm, question, disabled, children, }) => {
    const [open, setOpen] = React.useState(false);
    const handleClick = (e) => {
        e.stopPropagation();
        setOpen(true);
    };
    const handleConfirm = () => {
        onConfirm();
        setOpen(false);
    };
    return React.createElement(React.Fragment, null,
        React.createElement("div", { onClickCapture: ignore || disabled ? () => null : handleClick }, children),
        open &&
            React.createElement(ConfirmModal, { open: true, title: title, question: question, description: description, onConfirm: handleConfirm, onClose: () => setOpen(false), deletion: deletion }));
};
