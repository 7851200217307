import React from 'react';
import { Title, SvgIcon, ConfirmAction, Divider, NewButton, insertSelectItems, FormElement, FORM_ELEMENT_TYPES, validateFlowElement, newTheme } from '../';
import Grid from '@material-ui/core/Grid';
import { $GroupButtons, $GroupContainer, $InviteUser, $Warning, $WarningContainer, $WarningGrid, $COIComment } from './styles';
import { $FormHelperText } from '../newInput/styles';
export const ApplicationFlowForm = ({ call, formElements = [], activeCutOff, stepId, profile, assignment, onChangeValue, onChangeFile, openCOI, onChangeGroupElementValue, removeInstance, addInstance, options_groups, canEditAssignment, revokedAssignment, showCOI, onDownloadClick, changesRequested, allResources, onInviteUser, documents, srEmails, loading, }) => {
    const handleOnChange = (elementId, uploadId) => (name, newValue) => handleValueChange(name, newValue, elementId, uploadId);
    const handleValueChange = (name, value, elementId, uploadId) => {
        if (name === 'file') {
            onChangeFile(elementId, value, uploadId);
        }
        else {
            onChangeValue(value, elementId);
        }
    };
    const handleOnChangeGroup = (groupInstanceIdx, groupId, childElementId, groupElOrder, elementType, uploadId) => (name, newValue) => handleGroupElementValueChange(name, newValue, groupInstanceIdx, groupId, childElementId, groupElOrder, elementType, uploadId);
    const handleGroupElementValueChange = (name, value, instanceIdx, groupId, childElementId, groupElementOrder, elementType, uploadId) => {
        if (name === 'file') {
            onChangeFile(childElementId, value, uploadId, groupElementOrder, groupId, instanceIdx);
        }
        else {
            onChangeGroupElementValue(groupId, childElementId, groupElementOrder, instanceIdx, value, elementType);
        }
    };
    const handleAutoFill = (id, elementType, autofill, items, parentId, instanceIdx, groupElementIdx) => {
        if (!profile)
            return;
        let autofillVal = profile[autofill];
        if (!autofillVal)
            return;
        if (Array.isArray(autofillVal) && (FORM_ELEMENT_TYPES.MULTISELECT === elementType)) {
            autofillVal = autofillVal.map((val) => {
                const item = (items || [])?.find((item) => item.value == val);
                return item ? val : undefined;
            }).filter((val) => val);
            if (!autofillVal.length)
                return;
        }
        if (elementType === FORM_ELEMENT_TYPES.SELECT) {
            const item = (items || [])?.find((item) => item.value == autofillVal);
            if (!item)
                return;
        }
        if (parentId)
            handleGroupElementValueChange(elementType, autofillVal, instanceIdx || 0, parentId, id, groupElementIdx || 0, elementType);
        else
            handleValueChange(elementType, autofillVal, id);
    };
    const resources = activeCutOff?.resources ? activeCutOff.resources : [];
    const enhancedElements = insertSelectItems(formElements, options_groups);
    const allowedResourcePartitions = canEditAssignment ?
        resources.filter((resource) => !resource.partition.disabled) : allResources;
    const partitionIds = [...new Set(allowedResourcePartitions.map((resource) => resource?.partition?.id))] || [];
    const partitionOptions = partitionIds.map((id) => {
        const partitionName = allowedResourcePartitions.find((resource) => resource.partition?.id == id)?.partition?.name || '';
        return { value: id, label: partitionName };
    }) || [];
    const sortedPartitionOptions = partitionOptions?.sort((a, b) => {
        if (a.label < b.label)
            return -1;
        if (a.label > b.label)
            return 1;
        return 0;
    }) || [];
    return enhancedElements.map((element, elementIdx) => {
        const { id, flow, title, config, elementType, type, value, instances = [], isEditable, noData } = element;
        const instanceLength = instances.length;
        const { error, errorMsg, condition, resource, } = validateFlowElement(call, element, stepId, allowedResourcePartitions, instanceLength);
        const { visible, required, editable, sum = null } = condition;
        const displayError = element.error && error;
        const elementRequired = required || config?.required;
        const enhancedGroupElements = insertSelectItems(flow, options_groups);
        const isUpload = elementType === FORM_ELEMENT_TYPES.UPLOAD;
        const upload = documents.find((upload) => upload.id == value);
        const uploadValue = isUpload && value ? upload?.originalFilename : undefined;
        const hasSum = sum !== null;
        const isSelectPartition = elementType === FORM_ELEMENT_TYPES.SELECT_PARTITION;
        const isPartitionResource = elementType === FORM_ELEMENT_TYPES.PARTITION_RESOURCES;
        const elementParams = isPartitionResource && resource ? {
            min: resource.min,
            max: resource.max,
            value: resource.fixed && !value ? resource.fixed : value,
            disabled: !!resource.fixed || !editable || revokedAssignment || !canEditAssignment ||
                (changesRequested && !isEditable),
        } : {};
        const visibleElement = isPartitionResource ? !!resource : visible;
        if (canEditAssignment && visible && isPartitionResource && resource?.fixed && (value !== resource.fixed))
            handleValueChange(FORM_ELEMENT_TYPES.PARTITION_RESOURCES, resource.fixed, id);
        if (canEditAssignment && visible && isPartitionResource && value && !resource)
            handleValueChange(FORM_ELEMENT_TYPES.PARTITION_RESOURCES, null, id);
        if (canEditAssignment && visible && isSelectPartition && !!assignment?.partitionId && !value && elementRequired &&
            !!sortedPartitionOptions.find((p) => p.value == assignment.partitionId))
            handleValueChange(FORM_ELEMENT_TYPES.SELECT_PARTITION, assignment.partitionId, id);
        if (canEditAssignment && visible && noData && !value && config?.autofill && profile?.id)
            handleAutoFill(id, elementType, config.autofill, element.items);
        if (canEditAssignment && visible && hasSum && (sum != value))
            handleValueChange(elementType, sum, id);
        const isSR = (title || '').toLocaleLowerCase().includes('scientific') && (title || '').toLocaleLowerCase().includes('reviewer');
        const disableUpload = isUpload && loading;
        if (!visibleElement)
            return null;
        return elementType === FORM_ELEMENT_TYPES.GROUP ?
            instances.map((groupInstance, groupInstanceIdx) => React.createElement($GroupContainer, { container: true, spacing: 2, key: `${groupInstance.id}-${groupInstanceIdx}` },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Title, { color: displayError ? newTheme.colors.feedback.error : undefined, noMargin: true, bold: false },
                        title,
                        groupInstanceIdx !== 0 ? ` #${groupInstanceIdx + 1}` : '')),
                showCOI && isSR && !canEditAssignment && React.createElement(React.Fragment, null,
                    groupInstance.coi ?
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement($WarningContainer, { item: true },
                                React.createElement($WarningGrid, { container: true, alignItems: 'center' },
                                    React.createElement(SvgIcon, { name: 'warning', color: newTheme.colors.feedback.error, size: 16 }),
                                    React.createElement($Warning, null, "There is a conflict of interest"))))
                        : null,
                    React.createElement(Grid, { item: true, xs: 12 },
                        groupInstance.comment ? React.createElement($COIComment, null,
                            "Comment: ",
                            groupInstance.comment) : null,
                        React.createElement(NewButton, { variant: 'outlined', error: !!groupInstance.coi, onClick: () => openCOI(!!groupInstance.coi, groupInstance.comment || '', groupInstanceIdx, elementIdx, id) }, groupInstance.coi ? 'Edit Conflict' : 'Add Conflict'))),
                enhancedGroupElements.map((groupEl, groupElIdx) => {
                    const childInstance = groupInstance.childInstances
                        .find((child) => child.flowElementId == groupEl.id);
                    const childValue = childInstance?.value;
                    const { error, errorMsg, condition, resource } = validateFlowElement(call, groupEl, stepId, allowedResourcePartitions, 0, groupInstance, childValue);
                    const { visible, required, editable, sum = null } = condition;
                    const childRequired = required || groupEl.config?.required;
                    const groupElementIsEditable = childInstance?.isEditable || groupInstance?.isEditable;
                    const isSelectPartition = groupEl.elementType === FORM_ELEMENT_TYPES.SELECT_PARTITION;
                    const isPartitionResource = groupEl.elementType === FORM_ELEMENT_TYPES.PARTITION_RESOURCES;
                    const elementParams = isPartitionResource && resource ? {
                        min: resource.min,
                        max: resource.max,
                        value: resource.fixed ? resource.fixed : childValue,
                        disabled: !!resource.fixed || !editable || revokedAssignment || !canEditAssignment ||
                            (changesRequested && !groupElementIsEditable),
                    } : {};
                    const displayError = childInstance?.error && error;
                    const isUpload = groupEl.elementType === FORM_ELEMENT_TYPES.UPLOAD;
                    const upload = documents.find((upload) => upload.id == childValue);
                    const uploadValue = isUpload && childValue ? upload?.originalFilename : undefined;
                    const visibleElement = isPartitionResource ? !!resource && visible : visible;
                    const hasSum = sum !== null;
                    if (canEditAssignment && visible && isPartitionResource && resource?.fixed && (childValue !== resource.fixed))
                        handleGroupElementValueChange(FORM_ELEMENT_TYPES.PARTITION_RESOURCES, resource.fixed, groupInstanceIdx, element.id, groupEl.id, groupElIdx, FORM_ELEMENT_TYPES.PARTITION_RESOURCES);
                    if (canEditAssignment && visible && isPartitionResource && childValue && !resource)
                        handleGroupElementValueChange(FORM_ELEMENT_TYPES.PARTITION_RESOURCES, null, groupInstanceIdx, element.id, groupEl.id, groupElIdx, FORM_ELEMENT_TYPES.PARTITION_RESOURCES);
                    if (canEditAssignment && visible && childInstance?.noData && !childValue
                        && groupEl.config?.autofill && profile?.id)
                        handleAutoFill(groupEl.id, groupEl.elementType, groupEl.config.autofill, groupEl.items, element.id, groupInstanceIdx, groupElIdx);
                    if (canEditAssignment && visible && hasSum && (sum != childValue))
                        handleGroupElementValueChange(groupEl.elementType, sum, groupInstanceIdx, element.id, groupEl.id, groupElIdx, groupEl.elementType);
                    const showInviteEmail = !!onInviteUser && isSR &&
                        groupEl.elementType == FORM_ELEMENT_TYPES.EMAIL && !!childValue;
                    const canInvite = !srEmails.includes(String(childValue || '').toLowerCase());
                    const disableUpload = isUpload && loading;
                    if (!visibleElement)
                        return null;
                    return React.createElement(React.Fragment, null,
                        React.createElement(Grid, { item: true, xs: showInviteEmail ? 11 : 12, key: `${groupInstance.id}-${groupEl.id}`, id: `${groupEl.id}-${groupInstanceIdx}` },
                            React.createElement(FormElement, { element: {
                                    ...groupEl,
                                    allowFormat: !hasSum,
                                    id: `${groupInstance.id}-${groupEl.id}`,
                                    items: isSelectPartition ? sortedPartitionOptions : groupEl.items,
                                    debounce: true,
                                    value: isUpload ? uploadValue : hasSum ? sum : childValue,
                                    type: groupEl.type,
                                    elementType: groupEl.elementType,
                                    disabled: !editable || disableUpload || revokedAssignment || !canEditAssignment || hasSum ||
                                        (changesRequested && !groupElementIsEditable),
                                    required: childRequired,
                                    error: displayError,
                                    errorMsg: displayError ? errorMsg : '',
                                    hideItalic: true,
                                    name: groupEl.title,
                                    onDownloadClick: () => onDownloadClick(upload?.id || 0, groupEl.id),
                                    onChange: handleOnChangeGroup(groupInstanceIdx, element.id, groupEl.id, groupElIdx, groupEl.elementType, upload?.id),
                                    ...elementParams,
                                } })),
                        showInviteEmail &&
                            React.createElement($InviteUser, { item: true, xs: 1 },
                                React.createElement(SvgIcon, { name: 'person-add', color: canInvite ? 'primary' : newTheme.colors.grey, clickable: true, size: 24, onClick: () => { if (onInviteUser && canInvite)
                                        onInviteUser(groupInstance); } })));
                }),
                canEditAssignment ?
                    React.createElement(React.Fragment, null,
                        React.createElement($GroupButtons, { container: true, xs: 12 },
                            (instanceLength > 1 || (groupInstanceIdx > 0))
                                && (changesRequested ? groupInstance?.isEditable : true) &&
                                React.createElement(ConfirmAction, { disabled: loading, deletion: true, onConfirm: () => removeInstance(element.id, groupInstanceIdx), title: `Remove "${title} #${groupInstanceIdx + 1}"`, description: `All data inside this "${title}" will be deleted.` },
                                    React.createElement(NewButton, { disabled: loading, variant: 'outlined', error: true }, "Remove")),
                            (groupInstanceIdx >= (instanceLength - 1))
                                && (changesRequested ? groupInstance?.isEditable : true) &&
                                React.createElement(NewButton, { disabled: !!(config?.max && (instanceLength >= config.max)) || loading, creator: true, variant: 'outlined', onClick: () => addInstance(element, instanceLength) }, title)),
                        displayError && React.createElement($FormHelperText, { error: true }, errorMsg),
                        (groupInstanceIdx < (instanceLength - 1)) && React.createElement(Divider, { noMarginBottom: false }))
                    : null))
            :
                React.createElement(Grid, { item: true, xs: 12, key: id, id: String(id) },
                    React.createElement(FormElement, { element: {
                            ...element,
                            items: isSelectPartition ? sortedPartitionOptions : element.items,
                            debounce: true,
                            allowFormat: !hasSum,
                            value: isUpload ? uploadValue : hasSum ? sum : value,
                            type: type,
                            elementType: elementType,
                            disabled: !editable || disableUpload || revokedAssignment || !canEditAssignment || hasSum ||
                                (changesRequested && !isEditable),
                            required: elementRequired,
                            error: displayError,
                            name: title,
                            errorMsg: displayError ? errorMsg : '',
                            onDownloadClick: () => onDownloadClick(upload?.id || 0, id),
                            hideItalic: true,
                            onChange: handleOnChange(id, upload?.id),
                            ...elementParams,
                        } }));
    });
};
