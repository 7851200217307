import React from 'react';
import { $MaintenanceNotice, $ImgContainer, $SubTitle } from './styles';
import noMobile from '../assets/icons/noMobile.svg';
import { Title } from '../';
export const NoMobile = () => (React.createElement($MaintenanceNotice, null,
    React.createElement($ImgContainer, null,
        React.createElement("img", { src: noMobile, alt: 'no mobile version' })),
    React.createElement(Title, { alternate: true }, "This view is not available on mobile"),
    React.createElement($SubTitle, null,
        "We are currently working on making ",
        React.createElement("br", null),
        " applications mobile friendly")));
