import React, { useState } from 'react'
import { ADMIN_PORTAL_ROLES, Collapse, ELEMENT_TYPES, NewMultiSelect, ROLE_NAME, ROLES, Switch } from 'prace-common-components'
import { $FieldContainer, $Grid } from './styles'
import { RoleNameItems } from 'constants/global'
import { ElementPermissionProps } from './types'
import { getMultiSelectProps } from './getMultiSelectProps'

const FilteredRoleNameItems = RoleNameItems.filter((item) => ![
  ...ADMIN_PORTAL_ROLES, ROLES.USER, ROLES.SUPER_ADMIN].includes(item.value))

export const ElementPermission: React.FC<ElementPermissionProps> = ({ permissions, step, type, id, onChange }) => {
  const [open, setOpen] = useState(false)
  const isStep = type === ELEMENT_TYPES.STEP

  return <$FieldContainer container alignItems='center'>
    <Collapse title='Permissions' open={open} handleOpen={setOpen}>
      <$Grid container>
        {!isStep &&
          <Switch 
            name='inherit'
            title='Inherit permissions from parent'
            value={permissions?.inherit as boolean}
            onChange={onChange}
          />
        }
        {(!permissions?.inherit || isStep) && getMultiSelectProps(isStep, permissions, step).map((selectProps) =>
          <NewMultiSelect
            debounce
            key={`${id}-` + selectProps.name}
            items={['assign', 'assignable'].includes(selectProps.name) ? [{ label: ROLE_NAME[ROLES.ADMIN], value: ROLES.ADMIN}, ...FilteredRoleNameItems] : FilteredRoleNameItems}
            hideItalic
            onChange={(name: string, value: string[]) => onChange(name, value)}
            {...selectProps}
          />,
        )}
      </$Grid>
    </Collapse>
  </$FieldContainer>
}
